import React, { useState, useEffect, useCallback, memo } from 'react';
import { Container, Row, Col, Card, CardHeader, Button, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { useParams, useLocation, Link } from 'react-router-dom';
import { CaretLeft } from 'phosphor-react';

import Spinner from '../../Spinner/Spinner';
import { useToast } from '../../../hooks/Toast';
import { api, shopsApi } from '../../../services/api';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';
import { useAuth } from '../../../hooks/User';

const UpnidForm = ({ onSave = () => {}, className }) => {
  const { store, platform } = useParams();
  const location = useLocation();
  const { showToast } = useToast();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({ accessToken: '', id: null });

  const handleAccessToken = useCallback(e => {
    const { value } = e.target;
    setCredentials(state => ({ ...state, ...{ accessToken: value } }));
  }, []);

  const isOnboardingPage = useCallback(() => {
    if (location.pathname.includes('onboarding')) {
      return true;
    }

    return false;
  }, [location.pathname]);

  const onSubmit = useCallback(
    async event => {
      event.preventDefault();
      const requestType = credentials.id ? 'put' : 'post';

      try {
        setLoading(true);
        const { data } = await api(shopsApi[platform])[requestType](
          `api/v1/users/${platform}/stores/${store}/upnid-credentials/${credentials?.id || ''}`,
          credentials,
        );

        setCredentials({
          accessToken: data.upnidCredential.token,
          id: data.upnidCredential.id,
        });
        showToast({ message: 'Credenciais salvas com sucesso!', type: 'success' });
        onSave('upnid');

        if (isOnboardingPage()) {
          updateCustomerXTracking({
            email: user?.email,
            userId: user?.id,
            identifier: 'onboarding_has_added_gateway_credential',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [credentials, onSave, platform, showToast, store, user, isOnboardingPage],
  );

  const loadCredentials = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api(shopsApi[platform]).get(`api/v1/users/${platform}/stores/${store}/upnid-credentials`);

      if (data.upnidCredential) {
        setCredentials({
          accessToken: data.upnidCredential.token,
          id: data.upnidCredential.id,
        });
      }
    } finally {
      setLoading(false);
    }

    setLoading(false);
  }, [platform, store]);

  const isIntegrationPage = useCallback(() => {
    if (location.pathname.includes('integrations')) {
      return true;
    }

    return false;
  }, [location.pathname]);

  useEffect(() => {
    loadCredentials();
  }, [loadCredentials]);

  return (
    <Container className={className || 'pb-8 pt-5 pt-md-8'}>
      <Row>
        <Col className="order-xl-1" xl="12">
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <>
              {isIntegrationPage() && (
                <div className="mb-3 ">
                  <Link
                    to={`/${platform}/${store}/dashboard/config/integrations`}
                    className="d-flex align-items-center"
                    style={{ color: '#ADB8C4' }}
                  >
                    <CaretLeft size={20} className="mr-1" />
                    Voltar às integrações
                  </Link>
                </div>
              )}
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col>
                      <h3 className="mb-0">Configurações</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row className="pt-5 pb-3">
                    <Col md={6}>
                      <h2>Instruções de integração com Upnid</h2>
                      <p className="pr-4">
                        Integrar a Upnid com a Profitfy é bem simples! O passo a passo para isso se encontra{' '}
                        <a href="https://docs.profitfy.me/pt/article/como-gerar-api-key-upnid-1qs603s/" target="blank">
                          aqui
                        </a>
                        . Depois disso, basta copiar sua API Key no campo ao lado e salvar!
                      </p>
                    </Col>
                    <Col>
                      <Form>
                        <div className="pl-lg-4">
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label className="form-control-label" htmlFor="input-access-token">
                                  API Key
                                </Label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-access-token"
                                  type="text"
                                  onChange={handleAccessToken}
                                  value={credentials.accessToken}
                                />
                              </FormGroup>
                              <hr className="my-3" />
                              <div className="d-flex justify-content-center">
                                <Button
                                  size="lg"
                                  color="primary"
                                  type="submit"
                                  className="mt-4 px-4"
                                  onClick={onSubmit}
                                >
                                  <span className="text-uppercase">Salvar</span>
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default memo(UpnidForm);
