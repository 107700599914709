import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Toast } from './Toast';
import { User } from './User';
import { Plans } from './Plans';
import { FacebookMarketing } from './FacebookMarketing';
import { ProductDetails } from './ProductDetails';
import { AppmaxProvider } from './Appmax';

const AppProvider = ({ children }) => {
  return (
    <BrowserRouter>
      <User>
        <Toast>
          <Plans>
            <FacebookMarketing>
              <AppmaxProvider>
                <ProductDetails>{children}</ProductDetails>
              </AppmaxProvider>
            </FacebookMarketing>
          </Plans>
        </Toast>
      </User>
    </BrowserRouter>
  );
};

export default AppProvider;
