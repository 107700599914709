import React from 'react';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../styles.scss';

const FacebookWarn = () => (
  <Alert color="white" className="d-flex align-items-center warn-info warn-style">
    <span>
      <i className="fa fa-exclamation-triangle" /> O seu token do Facebook foi <strong>invalidado.</strong> Por favor,
      reconecte o seu perfil{' '}
      <Link className="alert-link" to="integrations/facebook">
        clicando aqui
      </Link>
    </span>
  </Alert>
);

export default FacebookWarn;
