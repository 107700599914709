import React, { useCallback } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import './styles.scss';

const Finish = () => {
  const history = useHistory();

  const handleStoreButton = useCallback(() => {
    history.push('/stores');
  }, [history]);

  const handlePreviousPageButton = useCallback(() => {
    history.push('/subscribe/payment');
  }, [history]);

  return (
    <div className="subscribe-finish-container">
      <div className="content-container">
        <div className="content-text">
          <h1>Você está quase lá!</h1>
          <p>
            Agora vamos começar a fazer as integrações mais importantes para que seu Dashboard seja alimentado em tempo
            real.
          </p>
        </div>
        <div className="content-buttons-container">
          <Button className="stores-button" onClick={handleStoreButton}>
            <i className="fas fa-arrow-right" />
            <span>Visualizar</span>
            <span>minhas lojas</span>
          </Button>
          <Button className="previous-page-button" onClick={handlePreviousPageButton}>
            <i className="fas fa-arrow-left" />
            <span>Voltar ao</span>
            <span>passo anterior</span>
          </Button>
        </div>
      </div>
      <div className="creative-container">
        <img
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/Subscribe/subscribe-finish.svg"
          alt="Profitfy.me finish"
        />
      </div>
    </div>
  );
};

export default Finish;
