import React, { memo } from 'react';
import { Table } from 'reactstrap';
import SubHeaders from './SubHeaders/SubHeaders';
import { numberBrFormatter } from '../../../../../../utils/utils';

const columns = [
  { name: 'markUp', description: 'MarkUp' },
  { name: 'finalPrice', description: 'Preço Final' },
  { name: 'maxCPA', description: 'Máx. CPA' },
  { name: 'setOfTen', description: '10 Conjuntos de' },
];

const TableBody = ({ markup, finalPrice, maxCpa }) => {
  return (
    <Table className="align-items-right table-flush" responsive>
      <thead className="thead-light">
        <tr className="text-right">
          <SubHeaders columns={columns} />
        </tr>
      </thead>
      <tbody>
        {markup.map((el, i) => {
          if (i !== 3 && i !== 4) {
            return (
              <tr key={el} className="align-items-center">
                <td className="text-right">
                  <p className={`font-weight-bold mb-0 ${i === 5 && 'text-gray'}`}>{numberBrFormatter(el, 2)}x</p>
                </td>
                <td className="text-right">
                  <p className="mb-0">R$ {numberBrFormatter(finalPrice[i], 2)}</p>
                </td>
                <td className="text-right">
                  <p className="font-weight-bold mb-0">R$ {numberBrFormatter(maxCpa[i], 2)}</p>
                </td>
                <td className="text-right">
                  <p className="mb-0">R$ {numberBrFormatter(maxCpa[i] / 10, 2)}</p>
                </td>
              </tr>
            );
          }

          return null;
        })}
      </tbody>
    </Table>
  );
};

export default memo(TableBody);
