import React, { memo, useState, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Input } from 'reactstrap';
import { WarningCircle, Barcode, CreditCard, Prohibit } from 'phosphor-react';
import { CircleSpinner } from 'react-spinners-kit';
import { useParams } from 'react-router-dom';

import { api, shopsApi } from '../../../../services/api';

import './styles.scss';

const EditIofModal = ({ isOpen, toggle, loadProducts }) => {
  const { platform, store } = useParams();

  const [selectedIof, setSelectedIof] = useState('TICKET');
  const [isLoadingIofChange, setIsLoadingIofChange] = useState(false);

  const handleIof = useCallback(iofType => setSelectedIof(iofType), []);

  const handleChangeIof = useCallback(async () => {
    setIsLoadingIofChange(true);

    try {
      await api(shopsApi[platform]).patch(`api/v1/users/${platform}/stores/${store}/products/buy-method`, {
        buyMethod: selectedIof,
      });

      toggle();

      await loadProducts();
    } finally {
      setIsLoadingIofChange(false);
    }
  }, [loadProducts, platform, selectedIof, store, toggle]);

  return (
    <Modal className="edit-iof-modal" isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="edit-iof-modal-header">
        <WarningCircle size={32} />
        <span>Selecionar o IOF</span>
      </ModalHeader>

      <ModalBody className="edit-iof-modal-body">
        <span>
          Essa opção será aplicado em <strong>todos os produtos</strong> caso clique no botão de{' '}
          <strong>Alterar</strong>
        </span>

        <FormGroup className="iof-options-input">
          <FormGroup>
            <Label className={`radio-input ${selectedIof === 'TICKET' && 'selected'}`}>
              <Barcode size={30} />
              <div>
                <span>Boleto</span>
                <span>0,38%</span>
              </div>
              <Input
                type="radio"
                name="iof"
                value="TICKET"
                onChange={() => handleIof('TICKET')}
                defaultChecked={selectedIof === 'TICKET'}
              />
            </Label>
          </FormGroup>

          <FormGroup>
            <Label className={`radio-input ${selectedIof === 'CARD' && 'selected'}`}>
              <CreditCard size={30} />
              <div>
                <span>Cartão</span>
                <span>6,38%</span>
              </div>
              <Input
                type="radio"
                name="iof"
                value="CARD"
                onChange={() => handleIof('CARD')}
                defaultChecked={selectedIof === 'CARD'}
              />
            </Label>
          </FormGroup>

          <FormGroup>
            <Label className={`radio-input none ${selectedIof === 'NONE' && 'selected'}`}>
              <div className="none-option">
                <Prohibit size={30} />
                <span>Nenhum</span>
              </div>
              <Input
                type="radio"
                name="iof"
                value="NONE"
                onChange={() => handleIof('NONE')}
                defaultChecked={selectedIof === 'NONE'}
              />
            </Label>
          </FormGroup>
        </FormGroup>

        <Button onClick={handleChangeIof} color="primary" disabled={isLoadingIofChange}>
          {isLoadingIofChange ? <CircleSpinner size={20} /> : 'Alterar'}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default memo(EditIofModal);
