import React, { memo, useMemo } from 'react';
import { Card } from 'reactstrap';
import { ResponsivePie } from '@nivo/pie';
import { Question } from 'phosphor-react';

import { numberBrFormatter } from '../../../utils/utils';
import Tooltip from './Tooltip/Tooltip';
import CompensatedPopover from './CompensatedPopover/CompensatedPopover';

import './styles.scss';

const noData = [
  {
    id: 'Sem dados',
    label: 'Sem dados',
    value: 1,
    color: '#CACCCF',
  },
];

const Boleto = ({ billets, storePeriod }) => {
  const compensatedPopover = useMemo(() => <CompensatedPopover storePeriod={storePeriod} />, [storePeriod]);

  const data = useMemo(
    () => [
      {
        id: 'Pagos',
        label: 'Pagos',
        value: billets.paid.amount,
        color: '#003266',
      },
      {
        id: 'Pendentes',
        label: 'Pendentes',
        value: billets.pending.amount,
        color: '#007EFF',
      },
    ],
    [billets],
  );

  const colors = ['#003266', '#007EFF'];

  const isBilletsAmountDifferentThanZero =
    (billets.paid.amount || billets.pending.amount || billets.compensated.amount) !== 0;

  return (
    <Card className="product-details-boleto-container">
      <div className="header">
        <span>BOLETOS</span>
      </div>

      <div className="body">
        <div className="legend">
          <div className="approved">
            <div>
              <span>R$</span>
              <span>
                {numberBrFormatter(billets.paid.amount, 2)} ({numberBrFormatter(billets.paid.total, 0)})
              </span>
            </div>

            <div>
              <div className="icon" />
              <span>Pagos</span>
            </div>
          </div>

          <div className="in-analysis">
            <div>
              <span>R$</span>
              <span>
                {numberBrFormatter(billets.pending.amount, 2)} ({numberBrFormatter(billets.pending.total, 0)})
              </span>
            </div>

            <div>
              <div className="icon" />
              <span>Pendentes</span>
            </div>
          </div>

          <div className="refused">
            <div>
              <span>R$</span>
              <span>
                {numberBrFormatter(billets.compensated.amount, 2)} ({numberBrFormatter(billets.compensated.total, 0)})
              </span>
            </div>

            <div id="popover-product-details-boleto-compensated">
              <span>
                Compensados <Question size={16} />
              </span>
            </div>
          </div>
        </div>

        <div className="chart">
          {isBilletsAmountDifferentThanZero ? (
            <ResponsivePie
              data={data}
              colors={colors}
              innerRadius={0.7}
              enableRadialLabels={false}
              enableSlicesLabels={false}
              tooltip={({ id, value }) => <Tooltip id={id} value={value} />}
            />
          ) : (
            <ResponsivePie
              data={noData}
              colors={['#CACCCF']}
              innerRadius={0.7}
              enableRadialLabels={false}
              enableSlicesLabels={false}
              isInteractive={false}
            />
          )}
          <div className="main-info">
            <span>{numberBrFormatter(billets.conversion.percentage, 2)}%</span>
            <span>Conversão</span>
          </div>
        </div>
      </div>
      {compensatedPopover}
    </Card>
  );
};

export default memo(Boleto);
