import React, { useState, useContext, memo, useMemo, useCallback } from 'react';
import moment from 'moment-timezone';
import { DateRangePicker } from 'react-dates';
import { FormGroup, InputGroupAddon, InputGroupText, InputGroup } from 'reactstrap';
import { VERTICAL_ORIENTATION, HORIZONTAL_ORIENTATION } from 'react-dates/constants';
import PreDefinedDates from './PreDefinedDates/PreDefinedDates';
import FirstTimeContext from '../../hooks/FirstTimeContext';
import './DatePicker.css';

const smallDevice = window.matchMedia('(max-width: 727px)').matches;
const orientation = smallDevice ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION;

const Datepicker = ({ onDate, startDate, endDate, position = 'end', showPreDefinedDates }) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);
  const value = useContext(FirstTimeContext);

  const handlePreDefinedDate = useCallback(
    (preStartDate, preEndDate) => {
      setSelectedStartDate(preStartDate);
      setSelectedEndDate(preEndDate);
      onDate({ startDate: preStartDate, endDate: preEndDate });
    },
    [onDate],
  );

  const preDefinedDates = useMemo(() => {
    if (showPreDefinedDates) {
      return {
        calendarInfoPosition: 'after',
        renderCalendarInfo: () => <PreDefinedDates handlePreDefinedDate={handlePreDefinedDate} />,
      };
    }

    return {};
  }, [showPreDefinedDates, handlePreDefinedDate]);

  return (
    <>
      <div className={`mr-2 header-date-picker d-flex justify-content-${position}`}>
        <FormGroup className="mb-0">
          <InputGroup className="input-group-alternative" readOnly>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
              <DateRangePicker
                readOnly
                startDatePlaceholderText="Data inicio"
                startDateId="startDate"
                startDate={selectedStartDate}
                endDatePlaceholderText="Data final"
                endDate={selectedEndDate}
                endDateId="endDate"
                onDatesChange={({ startDate, endDate }) => {
                  setSelectedStartDate(startDate);
                  setSelectedEndDate(endDate);

                  if (endDate && focusedInput === 'endDate') {
                    onDate({ startDate, endDate });
                  }
                }}
                initialVisibleMonth={() => moment()}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                isOutsideRange={date => {
                  return date.isBefore(moment(value.storeCreatedAt), 'day') || date.isAfter(moment(), 'day');
                }}
                showClearDates
                displayFormat="DD/MM/YYYY"
                orientation={orientation}
                withPortal
                noBorder
                minimumNights={0}
                {...preDefinedDates}
              />
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </div>
    </>
  );
};

export default memo(Datepicker);
