import React, { memo, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import FunnelGraph from 'funnel-graph-js';

import { numberBrFormatter } from '../../../utils/utils';

import './styles.scss';

const FunnelChart = ({ facebookInsights }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const funnelRef = useRef();
  const parentRef = useRef();

  const data = useMemo(
    () => ({
      labels: ['Page View', 'Initiate Checkout', 'Add Payment Info', 'Purchase'],
      colors: ['#53C443', '#7AD26E', '#97D48F'],
      values: [
        facebookInsights?.pageView || 0,
        facebookInsights?.initiatedCheckout || 0,
        facebookInsights?.addPaymentInfo || 0,
        facebookInsights?.purchase || 0,
      ],
    }),
    [facebookInsights],
  );

  const getWidth = useCallback(parentRef => {
    if (parentRef.current.offsetWidth > 577) return parentRef.current.offsetWidth;

    return 577;
  }, []);

  useEffect(() => {
    if (funnelRef?.current && parentRef?.current) {
      const funnel = new FunnelGraph({
        container: '.funnel',
        gradientDirection: 'horizontal',
        data,
        displayPercent: true,
        direction: 'horizontal',
        height: 210,
        width: getWidth(parentRef),
        subLabelValue: 'raw',
      });

      funnel.draw();

      if (funnel.getDataSize() > 1) {
        funnel.updateData(data);
      }
    }
  }, [data, windowWidth, getWidth]);

  const handleResize = useCallback(() => setWindowWidth(window.innerWidth), []);

  window.addEventListener('resize', handleResize);

  return (
    <div className="product-details-funnel">
      <div className="header">
        <span>FUNIL DE CAMPANHAS</span>
      </div>
      <div className="info-wrapper">
        <div className="info">
          <span>CPM</span>
          <span>
            <small>R$</small>
            {numberBrFormatter(facebookInsights?.cpm || 0, 2)}
          </span>
        </div>

        <div className="info">
          <span>CTR</span>
          <span>{numberBrFormatter(facebookInsights?.ctr || 0, 2)}%</span>
        </div>

        <div className="info">
          <span>CPC</span>
          <span>
            <small>R$</small>
            {numberBrFormatter(facebookInsights?.cpc || 0, 2)}
          </span>
        </div>

        <div className="info">
          <span>ROAS</span>
          <span>{numberBrFormatter(facebookInsights?.roas || 0, 1)}x</span>
        </div>
      </div>

      <div className="horizontal-rule" />

      <div className="funnel-wrapper" ref={parentRef}>
        <div key={Math.random()} className="funnel" ref={funnelRef} />
      </div>
    </div>
  );
};

export default memo(FunnelChart);
