import React, { useCallback, memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { numberBrFormatter } from '../../utils/utils';
import DefaultImage from '../DefaultImage/DefaultImage';

import './styles.scss';

const TopProduct = ({ ranking, product }) => {
  const history = useHistory();
  const { platform, store } = useParams();

  const getProductTrophy = useCallback(
    ranking =>
      ({
        1: 'https://profitfy-media.s3-us-west-2.amazonaws.com/svgs/Top+Products/top-product-first.svg',
        2: 'https://profitfy-media.s3-us-west-2.amazonaws.com/svgs/Top+Products/top-product-second.svg',
        3: 'https://profitfy-media.s3-us-west-2.amazonaws.com/svgs/Top+Products/top-product-third.svg',
      }[ranking]),
    [],
  );

  const handleProductClick = useCallback(
    id => {
      history.push(`/${platform}/${store}/dashboard/product-details/${id}/product-details`);
    },
    [history, platform, store],
  );

  return (
    <div
      className={`top-product rank-${ranking}`}
      onClick={() => handleProductClick(product.id)}
      role="button"
      tabIndex={0}
      onKeyPress={() => {}}
    >
      <img src={getProductTrophy(ranking)} alt="Profitfy.me Troféu" />
      <div>
        {product?.image ? <img src={product.image} alt="Profitfy.me Top Produto" /> : <DefaultImage size={64} />}
        <div className="details">
          <span>{product.title}</span>

          <div className="revenue">
            <span>Faturamento</span>
            <span>
              <small>R$</small>
              {product.total_revenue ? numberBrFormatter(product.total_revenue) : '0,00'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TopProduct);
