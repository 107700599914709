import React from 'react';
import { Card } from 'reactstrap';
import { CheckCircle } from 'phosphor-react';

import Next from '../../assets/img/icons/common/next.svg';
import './styles.scss';

const GatewayCard = ({ name, img, alt, description, className, id, isIntegrated, ...rest }) => {
  return (
    <Card className={`integration-card shadow ${className}`}>
      <div className="d-flex justify-content-between align-items-center" id={id} {...rest}>
        <div className="d-flex main-integration-content">
          <img src={img} alt={alt} />
          <div>
            <p className="integration-name" id={id}>
              {name}
            </p>
            <p className="integration-description" id={id}>
              {description}
            </p>
          </div>
        </div>
        <div className="icons-wrapper">
          {isIntegrated && <CheckCircle size={28} className="checked-icon" />}
          <img src={Next} alt="Profitfy.me next" id={id} />
        </div>
      </div>
    </Card>
  );
};

export default GatewayCard;
