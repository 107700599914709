import React, { useCallback, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

import GatewayCard from '../../../components/GatewayCard/GatewayCard';
import AppmaxForm from '../../../components/Gateways/AppmaxForm/AppmaxForm';
import CieloForm from '../../../components/Gateways/CieloForm/CieloForm';
import CloudfoxForm from '../../../components/Gateways/CloudfoxForm/CloudfoxForm';
import MercadoPagoForm from '../../../components/Gateways/MercadoPagoForm/MercadoPagoForm';
import PagarmeForm from '../../../components/Gateways/PagarmeForm/PagarmeForm';
import PagseguroForm from '../../../components/Gateways/PagseguroForm/PagseguroForm';
import UpnidForm from '../../../components/Gateways/UpnidForm/UpnidForm';
import HubsaleForm from '../../../components/Gateways/HubsaleForm/HubsaleForm';
import { gateways } from '../../../utils/gateway';
import { api } from '../../../services/api';
import Spinner from '../../../components/Spinner/Spinner';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';
import NoGatewayModal from '../../../components/NoGatewayModal/NoGatewayModal';

import './styles.scss';

const Gateway = () => {
  const { user } = useAuth();
  const { store, platform } = useParams();
  const history = useHistory();

  const [selectedGateway, setSelectedGateway] = useState('');
  const [gatewayComponent, setGatewayComponent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [storeInfo, setStoreInfo] = useState({});
  const [isSuggestionModalOpen, setIsSuggestionModalOpen] = useState(false);
  // const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  // const handlePopover = useCallback(() => setIsPopoverOpen(!isPopoverOpen), [isPopoverOpen]);

  const handleSuggestionModalOpen = useCallback(
    () => setIsSuggestionModalOpen(!isSuggestionModalOpen),
    [isSuggestionModalOpen],
  );

  const handleSave = useCallback(() => setSelectedGateway(''), []);

  const handleConnect = useCallback(
    gateway => {
      const component = {
        appMax: (
          <>
            <AppmaxForm onSave={handleSave} className="mt-0" />
          </>
        ),
        mercadoPago: (
          <>
            <MercadoPagoForm fluid={false} onSave={handleSave} className="mt-0" />
          </>
        ),
        upnid: (
          <>
            <UpnidForm onSave={handleSave} className="mt-0" />
          </>
        ),
        doppus: (
          <>
            <HubsaleForm onSave={handleSave} className="mt-0" />
          </>
        ),
        cielo: (
          <>
            <CieloForm onSave={handleSave} className="mt-0" />
          </>
        ),
        cloudFox: (
          <>
            <CloudfoxForm onSave={handleSave} className="mt-0" />
          </>
        ),
        pagseguro: (
          <>
            <PagseguroForm onSave={handleSave} className="mt-0" />
          </>
        ),
        pagarme: (
          <>
            <PagarmeForm onSave={handleSave} className="mt-0" />
          </>
        ),
      }[gateway];

      setGatewayComponent(component);
    },
    [handleSave],
  );

  const handleNextPage = useCallback(() => {
    history.push('marketing');
  }, [history]);

  const handleGatewayClick = useCallback(
    event => {
      setSelectedGateway(event.target.id);
      handleConnect(event.target.id);
    },
    [handleConnect],
  );

  const loadStore = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api('user-api').get(`/api/v1/users/stores`);

      const filteredStore = response?.data?.stores.filter(innerStore => innerStore.id === store);

      if (filteredStore) {
        setStoreInfo(filteredStore.pop());
      }
    } finally {
      setIsLoading(false);
    }
  }, [store]);

  const hasGatewayConnected = useCallback(() => {
    if (
      storeInfo?.appMaxCredentialId ||
      storeInfo?.cieloCredentialId ||
      storeInfo?.cloudFoxCredentialId ||
      storeInfo?.mercadoPagoCredentialId ||
      storeInfo?.pagSeguroCredentialId ||
      storeInfo?.pagarmeCredentialId ||
      storeInfo?.upnidCredentialId ||
      storeInfo?.hubSaleCredentialId
    ) {
      return true;
    }

    return false;
  }, [storeInfo]);

  useEffect(() => {
    if (!selectedGateway) {
      loadStore();
    }
  }, [selectedGateway, loadStore]);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_onboarding_gateway',
        type: 'screen',
      });
    }
  }, [user]);

  return (
    <div className="main-onboarding-gateway-container">
      <div className="content-container">
        <div className="content-header">
          <img
            className="profitfy-logo"
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/logo_v2.svg"
            alt="Profitfy.me"
          />

          <div className="step-container">
            <div className="bar-container">
              <div className="bar-progress" />
            </div>

            <span>1/6</span>
          </div>
        </div>

        {selectedGateway ? (
          gatewayComponent
        ) : (
          <>
            <span>CONECTE-SE A UM GATEWAY DE PAGAMENTO PARA OBTER AS TAXAS DE TRANSAÇÕES AUTOMÁTICAS.</span>
            <p>
              Essa etapa é muito importante para conseguirmos obter as informações transacionais como pagamentos, taxas,
              parcelamento. Podendo assim realizar a analise de dados verdadeiros.
            </p>
            {isLoading ? (
              <Spinner loading={isLoading} />
            ) : (
              <div className="gateways-container">
                {gateways.map(gateway => {
                  if (gateway.id === 'hubsale' && platform === 'cartx') {
                    return undefined;
                  }

                  return (
                    <GatewayCard
                      key={gateway.title}
                      className="gateway-card"
                      name={gateway.title}
                      img={gateway.logo}
                      alt={gateway.title}
                      description={gateway.description}
                      id={gateway.id}
                      onClick={handleGatewayClick}
                      isIntegrated={storeInfo[gateway.credentialName]}
                    />
                  );
                })}
              </div>
            )}
          </>
        )}
        <NoGatewayModal isOpen={isSuggestionModalOpen} toggle={handleSuggestionModalOpen} />

        <div className="suggestion-wrapper">
          <button className="suggestion-button" type="button" onClick={handleSuggestionModalOpen}>
            Não encontrou o gateway que você usa? Nos informe
          </button>
        </div>

        <div className="next-page-container">
          {selectedGateway ? (
            <Button type="button" className="prev-page" onClick={() => setSelectedGateway('')}>
              <i className="fas fa-arrow-left" />
              <span>Voltar</span>
            </Button>
          ) : (
            <Button
              color="primary"
              type="button"
              onClick={handleNextPage}
              outline
              disabled={!hasGatewayConnected()}
              // id="popover-gateway"
            >
              <span>Próximo Passo</span>
              <i className="fas fa-arrow-right" />
            </Button>
          )}
        </div>
        {/* {!hasGatewayConnected() && (
          <Popover
            placement="top"
            isOpen={isPopoverOpen}
            target="popover-gateway"
            toggle={handlePopover}
            trigger="hover"
          >
            <PopoverBody>
              Para poder avançar para o próximo passo é necessário realizar a integração com pelo menos <b>um</b>{' '}
              Gateway.
            </PopoverBody>
          </Popover>
        )} */}
      </div>

      <div className="creative-container">
        <div className="video-container">
          <img
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/New+User+Flux/new-user-video.svg"
            alt="Profitfy.me new user"
          />

          {/* <div className="video-container">
            <iframe
              title="Profitfy Trial Benefits"
              width="400"
              height="225"
              src="https://www.youtube.com/embed/FO0iwx3PUHo"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Gateway;
