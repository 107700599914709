/* eslint-disable no-constant-condition */
import React, { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import { isAuthenticated } from '../../services/auth';
import TermsOfUsage from '../TermsOfUsage/TermsOfUsage';
import { useAppmax } from '../../hooks/Appmax';

const tokenAuthName = process.env.REACT_APP_STAGE === 'prod' ? 'jwt' : 'twj';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { pathname } = useLocation();
  const { handleHasAccessedAppmaxPlanPage } = useAppmax();

  const tokenAuthentication = localStorage.getItem(tokenAuthName);

  useEffect(() => {
    if (pathname === '/subscribe/appmax' && !isAuthenticated()) {
      handleHasAccessedAppmaxPlanPage(true);
    }
  }, [pathname, handleHasAccessedAppmaxPlanPage]);

  useEffect(() => {
    if (!isAuthenticated()) {
      if (!tokenAuthentication) {
        localStorage.setItem('user', null);
      }
    }
  }, [tokenAuthentication]);

  return (
    <Route
      {...rest}
      render={props => {
        return isAuthenticated() ? (
          <>
            <Component {...props} />
            <TermsOfUsage />
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
