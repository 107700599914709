import React, { memo, useCallback, useState } from 'react';
import { Money, CaretDown, CaretUp, MonitorPlay, Check } from 'phosphor-react';
import { Input, FormGroup, Label, Button, Tooltip } from 'reactstrap';

import brazilianFlag from '../../../assets/img/theme/brasil.svg';
import americanFlag from '../../../assets/img/theme/eua_flag.svg';

import './styles.scss';

const CurrencyStep = ({ selectedStep, setSelectedStep, setSelectedCurrency, userStep, setUserStep }) => {
  const [currency, setCurrency] = useState('USD');
  const [isSelectCurrencyTooltipOpen, setIsSelectCurrencyTooltipOpen] = useState(false);

  const handleSelectCurrencyTooltipOpen = useCallback(
    () => setIsSelectCurrencyTooltipOpen(!isSelectCurrencyTooltipOpen),
    [isSelectCurrencyTooltipOpen],
  );

  const handleStepOpen = useCallback(() => {
    if (selectedStep !== 'currency') {
      setSelectedStep('currency');

      return;
    }

    setSelectedStep('');
  }, [selectedStep, setSelectedStep]);

  const handleSelectCurrency = useCallback(() => {
    setSelectedCurrency(currency);
    setSelectedStep('average-cost');
    setUserStep('average-cost');
  }, [currency, setSelectedStep, setSelectedCurrency, setUserStep]);

  return (
    <div
      className={`onboarding-currency-step ${selectedStep === 'currency' ? 'selected' : undefined} ${
        userStep !== 'currency' ? 'completed' : undefined
      }`}
    >
      <div className="header">
        <Money size={36} />

        <div className="title-and-completed-badge">
          <h2>Defina a moeda dos seus produtos</h2>

          {userStep !== 'currency' && (
            <div className="completed-step">
              <span>Conectado</span>
            </div>
          )}
        </div>

        {selectedStep === 'currency' ? (
          <CaretUp size={32} onClick={handleStepOpen} />
        ) : (
          <CaretDown size={32} onClick={handleStepOpen} />
        )}
      </div>

      <div className="body">
        {selectedStep === 'currency' ? (
          <div className="content">
            <div className="short-video" id="select-currency-tutorial-video">
              <MonitorPlay size={18} />
              <span>Precisa de ajuda? Assista a um video curto</span>
              <div className="duration-badge">
                <span>0:15</span>
              </div>
            </div>

            <Tooltip
              isOpen={isSelectCurrencyTooltipOpen}
              toggle={handleSelectCurrencyTooltipOpen}
              placement="bottom"
              target="select-currency-tutorial-video"
              className="select-currency-tutorial-video"
              autohide={false}
            >
              <video
                src="https://profitfy-media.s3-us-west-2.amazonaws.com/videos-tuto/cogs-currency-step.mp4"
                controls
                autoPlay
                muted
              >
                <track kind="captions" />
              </video>
            </Tooltip>

            <span>Em qual moeda o seu fornecedor opera?</span>

            <FormGroup className="currency-input">
              <FormGroup>
                <Label className={`radio-input ${currency === 'USD' && 'selected'}`}>
                  <img src={americanFlag} alt="Profitfy.me moeda dólar" />
                  <div>
                    <Input
                      type="radio"
                      name="currency"
                      value="USD"
                      onChange={() => setCurrency('USD')}
                      defaultChecked={currency === 'USD'}
                    />
                    <span>U$</span>
                  </div>
                </Label>
              </FormGroup>

              <FormGroup>
                <Label className={`radio-input ${currency === 'BRL' && 'selected'}`}>
                  <img src={brazilianFlag} alt="Profitfy.me moeda real" />
                  <div>
                    <Input
                      type="radio"
                      name="currency"
                      value="BRL"
                      onChange={() => setCurrency('BRL')}
                      defaultChecked={currency === 'BRL'}
                    />
                    <span>R$</span>
                  </div>
                </Label>
              </FormGroup>
            </FormGroup>

            {currency === 'USD' && (
              <div className="dollar-benefits">
                <div>
                  <Check size={16} weight="bold" />
                  <span>Conversão automática para real com a cotação do dólar do dia</span>
                </div>

                <div>
                  <Check size={16} weight="bold" />
                  <span>Maior precisão nos dados e métricas</span>
                </div>
              </div>
            )}

            <Button onClick={handleSelectCurrency} color="primary">
              Selecionar moeda
            </Button>
          </div>
        ) : (
          <span>O primeiro passo é escolher sua moeda de custo</span>
        )}
      </div>
    </div>
  );
};

export default memo(CurrencyStep);
