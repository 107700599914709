import React, { memo, useCallback, useEffect, useState } from 'react';
import { ArrowsClockwise, CaretUp, CaretDown, WarningCircle } from 'phosphor-react';
import { Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { MetroSpinner } from 'react-spinners-kit';

import { api } from '../../../services/api';
import usePersistedState from '../../../hooks/PersistedState';

import './styles.scss';

const SyncCampaigns = ({
  selectedStep,
  setSelectedStep,
  handleSyncingModal,
  loadCampaigns,
  setHasCompletedCampaignsOnboarding,
  handleActivatedCampaignModal,
}) => {
  const { platform } = useParams();
  const [syncFacebookCampaign, setSyncFacebookCampaign] = usePersistedState('syncFacebookCampaign');

  const [syncCampaignsLoading, setSyncCampaignsLoading] = useState(false);
  const [isSyncError, setIsSyncError] = useState(false);
  const [hasNoCampaigns, setHasNoCampaigns] = useState(false);

  const getQueryStatus = useCallback(
    async syncFacebookCampaign => {
      try {
        const interval = setInterval(async () => {
          const { data } = await api('user-api').get(
            `api/v1/users/facebook/ad-accounts/${platform}/campaigns/${syncFacebookCampaign.id}`,
          );

          syncFacebookCampaign = data.syncFacebookCampaign;

          if (syncFacebookCampaign.status === 'SUCCESS') {
            clearInterval(interval);
            setSyncCampaignsLoading(false);
            setSyncFacebookCampaign(syncFacebookCampaign);

            const campaigns = await loadCampaigns({ nameSearch: '', page: 0 });

            if (!campaigns[0]?.active) {
              handleActivatedCampaignModal();
            }

            setHasCompletedCampaignsOnboarding(true);
          }

          if (syncFacebookCampaign.status === 'FAILED') {
            clearInterval(interval);
            setSyncCampaignsLoading(false);
            setSyncFacebookCampaign(syncFacebookCampaign);
            setIsSyncError(true);
          }
        }, 10000);
      } catch {
        setSyncCampaignsLoading(false);
        setIsSyncError(true);
      }
    },
    [
      platform,
      setSyncFacebookCampaign,
      loadCampaigns,
      setHasCompletedCampaignsOnboarding,
      handleActivatedCampaignModal,
    ],
  );

  const handleSyncCampaigns = useCallback(async () => {
    setSyncCampaignsLoading(true);
    setIsSyncError(false);

    try {
      const { data } = await api('user-api').post(`api/v1/users/facebook/ad-accounts/${platform}/campaigns`);

      setSyncFacebookCampaign(data.syncFacebookCampaign);

      if (data.syncFacebookCampaign.status === 'PENDING') {
        handleSyncingModal();
      }

      if (data.syncFacebookCampaign.status === 'FAILED') {
        setIsSyncError(true);
      }

      if (data.syncFacebookCampaign.status !== 'PENDING') {
        await loadCampaigns({ nameSearch: '', page: 0 });
      }
    } catch {
      setSyncCampaignsLoading(false);
      setIsSyncError(true);
    }
  }, [platform, setSyncFacebookCampaign, handleSyncingModal, loadCampaigns]);

  const handleOpenClose = useCallback(() => {
    if (selectedStep !== 'sync-campaigns') {
      setSelectedStep('sync-campaigns');

      return;
    }
    setSelectedStep('');
  }, [setSelectedStep, selectedStep]);

  useEffect(() => {
    const verifySyncFacebookCampaignStatus = async () => {
      if (syncFacebookCampaign?.status === 'PENDING') {
        setHasNoCampaigns(false);

        setSyncCampaignsLoading(true);

        await getQueryStatus(syncFacebookCampaign);
      }

      if (syncFacebookCampaign?.status === 'SUCCESS') {
        setHasNoCampaigns(true);
      }
    };

    verifySyncFacebookCampaignStatus();
  }, [getQueryStatus, syncFacebookCampaign]);

  useEffect(() => {
    if (syncFacebookCampaign?.status === 'FAILED') {
      setIsSyncError(true);
    }
  }, [syncFacebookCampaign]);

  return (
    <div className="sync-campaigns-wrapper">
      <div className="facebook-icon">
        <ArrowsClockwise size={48} />
      </div>

      <div className="content">
        <div className="info" onClick={handleOpenClose} onKeyPress={() => {}} role="button" tabIndex={0}>
          <div className="title-description">
            <div className="header">
              <span>Sincronizar campanhas</span>

              <div
                className="open-close-icon"
                onClick={handleOpenClose}
                onKeyPress={() => {}}
                role="button"
                tabIndex={0}
              >
                {selectedStep === 'sync-campaigns' ? <CaretUp size={24} /> : <CaretDown size={24} />}
              </div>
            </div>

            {selectedStep === 'sync-campaigns' ? (
              <span>Certifique-se que adicionou o ID do produto no nome da campanha</span>
            ) : (
              <span>Para finalizar, você só precisa sincroniza-los.</span>
            )}
          </div>
        </div>

        {selectedStep === 'sync-campaigns' && (
          <div className="description">
            <p>
              Agora sincronize suas campanhas{' '}
              <strong>clicando no botão abaixo e ativaremos suas campanhas deste produto automaticamente.</strong>
            </p>
            {hasNoCampaigns && (
              <span className="no-campaigns-text">
                Não foi possível encontrar suas campanhas! Certifique-se que as contas configuradas nos passos
                anteriores estão configuradas de forma correta.
              </span>
            )}

            {syncFacebookCampaign?.status !== 'PENDING' && !isSyncError && !syncCampaignsLoading && (
              <Button color="primary" className="sync-campaigns-button" onClick={handleSyncCampaigns}>
                Sincronizar campanhas
              </Button>
            )}

            {(syncFacebookCampaign?.status === 'PENDING' || syncCampaignsLoading) && (
              <Button className="syncing-campaigns-button" disabled>
                <span>Sincronizando campanhas</span>
                <MetroSpinner size={20} color="#A0A4A8" loading />
              </Button>
            )}

            {isSyncError && (
              <>
                <div className="failed-text">
                  <WarningCircle size={24} />
                  <span>Falha ao tentar sincronizar suas campanhas ao seu ID</span>
                </div>
                <Button color="primary" outline className="failed-sync-campaigns-button" onClick={handleSyncCampaigns}>
                  <span>Tentar novamente</span>
                  <ArrowsClockwise size={24} />
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(SyncCampaigns);
