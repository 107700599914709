import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

import { useAuth } from '../../hooks/User';
import { api } from '../../services/api';

import './styles.scss';

const Trial = () => {
  const { loadUser, user } = useAuth();
  const history = useHistory();

  const handleSubmit = useCallback(async () => {
    await api('user-api').post(`api/v1/users/subscriptions/${user.subscription.id}/free-trial`);

    await loadUser();

    history.push('/stores');
  }, [history, user.subscription.id, loadUser]);

  return (
    <div className="main-trial-container">
      <div className="content-container">
        <img
          className="profitfy-logo"
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/logo_v2.svg"
          alt="Profitfy.me"
        />
        <p className="content-text-header">Que tal testar a plataforma sem precisar usar o cartão?</p>
        <img
          className="credit-card-image"
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/trial/Trial-Credit-Card.svg"
          alt="Profitfy.me Trial"
        />
        <Button className="trial-button" color="primary" onClick={handleSubmit}>
          Testar por 14 dias grátis
        </Button>
      </div>
      <div className="creative-container">
        <p className="description-text">Assista a esse vídeo para ver nossos benefícios imperdíveis!!</p>
        <div className="video-container">
          <img
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/trial/trial-video-benefits-bg.svg"
            alt="Profitfy.me benefits"
          />
          <div className="video-container">
            <iframe
              title="Profitfy Trial Benefits"
              width="400"
              height="225"
              src="https://www.youtube.com/embed/DjweMZFgWjg"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trial;
