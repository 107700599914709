import axios from 'axios';

export const hasRefusedOnboarding = ({ userId }) => {
  return axios.put(
    `https://sandbox.api.customerx.com.br/api/v1/clients?external_id_client=${userId}`,
    {
      custom_attributes: [
        {
          name: 'onboarding_recusado',
          label: 'Onboarding recusado',
          value: 'recusado',
        },
      ],
    },
    {
      headers: {
        credential: process.env.REACT_APP_CUSTOMERX_CREDENTIAL,
        key: process.env.REACT_APP_CUSTOMERX_KEY,
        Authorization: process.env.REACT_APP_CUSTOMERX_API,
      },
    },
  );
};

export const getContact = ({ userId }) => {
  return axios.get(`https://sandbox.api.customerx.com.br/api/v1/clients?external_id_client=${userId}`, {
    headers: {
      credential: process.env.REACT_APP_CUSTOMERX_CREDENTIAL,
      key: process.env.REACT_APP_CUSTOMERX_KEY,
      Authorization: process.env.REACT_APP_CUSTOMERX_API,
    },
  });
};

export const hasScheduled = ({ userId }) => {
  return axios.put(
    `https://sandbox.api.customerx.com.br/api/v1/clients?external_id_client=${userId}`,
    {
      custom_attributes: [
        {
          name: 'onboarding_agendado',
          label: 'Onboarding Agendado',
          value: 'agendado',
        },
      ],
    },
    {
      headers: {
        credential: process.env.REACT_APP_CUSTOMERX_CREDENTIAL,
        key: process.env.REACT_APP_CUSTOMERX_KEY,
        Authorization: process.env.REACT_APP_CUSTOMERX_API,
      },
    },
  );
};
