import React, { memo } from 'react';
import { CardHeader, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';

import './styles.scss';

const CampaignsTablePage = ({ handleCampaignsSearch }) => {
  return (
    <CardHeader className="campaigns-table-header">
      <h2>Campanhas</h2>

      <div className="search-campaigns">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="fa fa-search" />
            </InputGroupText>
          </InputGroupAddon>
          <Input placeholder="Nome da campanha" type="text" name="Search" onChange={handleCampaignsSearch} />
        </InputGroup>
      </div>
    </CardHeader>
  );
};

export default memo(CampaignsTablePage);
