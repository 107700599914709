import React, { useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { WarningCircle } from 'phosphor-react';

import './styles.scss';

const DeleteAllCredentialsWarn = ({ isOpen, toggle, handleTermsModalOpen }) => {
  const handleOnClick = useCallback(() => {
    toggle();

    handleTermsModalOpen();
  }, [toggle, handleTermsModalOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="appmax-delete-all-credentials-warn-modal" centered>
      <ModalHeader className="header">
        <WarningCircle size={28} />
        <span>Aviso</span>
      </ModalHeader>
      <ModalBody className="body">
        <span>Ao continuar, as suas credenciais de outros Gateways serão excluídas.</span>
        <p>
          Ao continuar com o plano Appmax,{' '}
          <strong>
            todas as credenciais de Gateways cadastradas na plataforma, exceto as credenciais da Appmax, serão
            removidas.
          </strong>{' '}
          Isso se dá ao fato deste plano ser exclusivo com a Appmax.
        </p>
        <Button color="danger" onClick={handleOnClick}>
          Entendi, quero continuar
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default DeleteAllCredentialsWarn;
