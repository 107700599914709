import React, { useState, useEffect, useCallback, memo } from 'react';
import useForm from 'react-hook-form';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input as StrapInput,
} from 'reactstrap';
import { useParams, useLocation, Link } from 'react-router-dom';
import { CaretLeft } from 'phosphor-react';

import Spinner from '../../Spinner/Spinner';
import { useToast } from '../../../hooks/Toast';
import { api, shopsApi } from '../../../services/api';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';
import { useAuth } from '../../../hooks/User';

const PagseguroForm = ({ onSave = () => {}, className }) => {
  const { store, platform } = useParams();
  const { showToast } = useToast();
  const location = useLocation();
  const { register, handleSubmit } = useForm({});
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({ email: '', accessToken: '', id: null });

  const isOnboardingPage = useCallback(() => {
    if (location.pathname.includes('onboarding')) {
      return true;
    }

    return false;
  }, [location.pathname]);

  const onSubmit = useCallback(
    async data => {
      const { accessToken, email } = data;

      const parsedData = { accessToken, email, id: credentials.id };

      const requestType = credentials.id ? 'put' : 'post';

      try {
        setLoading(true);
        const { data } = await api(shopsApi[platform])[requestType](
          `api/v1/users/${platform}/stores/${store}/pagseguro-credentials/${credentials?.id || ''}`,
          parsedData,
        );

        setCredentials({
          email: data.pagSeguroCredential.email,
          accessToken: data.pagSeguroCredential.token,
          id: data.pagSeguroCredential.id,
        });
        showToast({ message: 'Credenciais salvas com sucesso!', type: 'success' });
        onSave('pagseguro');

        if (isOnboardingPage()) {
          updateCustomerXTracking({
            email: user?.email,
            userId: user?.id,
            identifier: 'onboarding_has_added_gateway_credential',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [credentials, onSave, platform, showToast, store, user, isOnboardingPage],
  );

  const loadCredentials = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api(shopsApi[platform]).get(
        `api/v1/users/${platform}/stores/${store}/pagseguro-credentials`,
      );

      if (data.pagSeguroCredential) {
        setCredentials({
          accessToken: data.pagSeguroCredential.token,
          email: data.pagSeguroCredential.email,
          id: data.pagSeguroCredential.id,
        });
      }
    } finally {
      setLoading(false);
    }

    setLoading(false);
  }, [platform, store]);

  const isIntegrationPage = useCallback(() => {
    if (location.pathname.includes('integrations')) {
      return true;
    }

    return false;
  }, [location.pathname]);

  useEffect(() => {
    loadCredentials();
  }, [loadCredentials]);

  return (
    <Container className={className || 'pb-8 pt-5 pt-md-8'}>
      <Row>
        <Col className="order-xl-1" xl="12">
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <>
              {isIntegrationPage() && (
                <div className="mb-3 ">
                  <Link
                    to={`/${platform}/${store}/dashboard/config/integrations`}
                    className="d-flex align-items-center"
                    style={{ color: '#ADB8C4' }}
                  >
                    <CaretLeft size={20} className="mr-1" />
                    Voltar às integrações
                  </Link>
                </div>
              )}
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col>
                      <h3 className="mb-0">Configurações</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row className="px-4 align-items-center">
                    <Col md={6}>
                      <h2>Instruções de integração com Pagseguro</h2>
                      <p className="pr-4">
                        Para integrar com a Pagseguro, basta utilizar o token que foi enviado para seu email. Caso você
                        ainda <b>não tenha</b>, clicar em Gerar Token clicando
                        <a
                          href="https://pagseguro.uol.com.br/preferencias/integracoes.jhtml?_ga=2.60066477.1042405070.1590522943-1390704312.1585594571"
                          target="blank"
                        >
                          <strong className="font-weight-800"> aqui</strong>
                        </a>{' '}
                        copie seu Token, e cole neste campo.
                      </p>
                    </Col>
                    <Col>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="pl-lg-4">
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label className="form-control-label" htmlFor="input-access-token">
                                  Access Token
                                </Label>
                                <StrapInput
                                  name="accessToken"
                                  className="form-control-alternative"
                                  id="input-access-token"
                                  type="text"
                                  innerRef={register}
                                  placeholder="Access Token"
                                  defaultValue={credentials.accessToken}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label className="form-control-label" htmlFor="input-email">
                                  Email usado no Pagseguro
                                </Label>
                                <StrapInput
                                  name="email"
                                  className="form-control-alternative"
                                  id="input-email"
                                  type="text"
                                  innerRef={register}
                                  placeholder="Email"
                                  defaultValue={credentials.email}
                                />
                              </FormGroup>
                              <hr className="my-3" />
                              <div className="d-flex justify-content-center">
                                <Button size="lg" color="primary" type="submit" className="mt-4 px-4">
                                  <span className="text-uppercase">Salvar</span>
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default memo(PagseguroForm);
