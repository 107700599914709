import React, { createContext, useContext, useCallback, useState, useMemo } from 'react';

import { api, shopsApi, makeSearchRequest } from '../services/api';
import { useAuth } from './User';
import { updateCustomerXTracking } from '../utils/customerXTracking';

const FacebookContext = createContext();

export const FacebookMarketing = ({ children }) => {
  const { user } = useAuth();
  const [store, setStore] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingAdAccountStatus, setLoadingAdAccountStatus] = useState({ status: false, id: null });
  const [adAccounts, setAdAccounts] = useState([]);
  const [pagesCountAdAccount, setPagesCountAdAccount] = useState(1);
  const [pageAdAccount, setPageAdAccount] = useState(1);
  const [adAccountName, setAdAccountName] = useState('');
  const [pageProfiles, setPageProfiles] = useState(1);
  const limitAdAccounts = 10;
  const limitProfiles = 5;
  const [profiles, setProfiles] = useState([]);
  const [pagesCountProfiles, setPagesCountProfiles] = useState(1);
  const [hasConnectedProfileInThisSession, setHasConnectedProfileInThisSession] = useState(false);
  const [hasActivatedAdAccount, setHasActivatedAdAccount] = useState(false);
  const [isLoadingProfiles, setIsLoadingProfiles] = useState(false);
  const [isLoggingOutProfile, setIsLoggingOutProfile] = useState(false);
  const [isLoggingInProfile, setIsLoggingInProfile] = useState(false);

  const searchFetch = useMemo(
    () =>
      makeSearchRequest({
        api: shopsApi[platform],
      }),
    [platform],
  );

  const loadAdAccounts = useCallback(
    async ({ isLoading = true, store: innerStore, platform: innerPlatform }) => {
      setLoading(isLoading);

      if (platform) {
        const response = await searchFetch(
          `api/v1/users/${innerPlatform}/stores/${innerStore}/facebook/ad-accounts?page=${pageAdAccount}&name=${adAccountName}&limit=${limitAdAccounts}`,
        );

        if (response) {
          setAdAccounts(response.facebookAdAccounts);
          setPagesCountAdAccount(response.numberOfPages);
          setLoading(false);
        }
      }
    },
    [pageAdAccount, adAccountName, searchFetch, platform],
  );

  const loadProfiles = useCallback(async () => {
    setIsLoadingProfiles(true);

    try {
      const { data } = await api('user-api').get(
        `api/v1/users/facebook/credential?page=${pageProfiles}&limit=${limitProfiles}`,
      );

      setProfiles(data.credentials);
      setPagesCountProfiles(data.numberOfPages);
    } finally {
      setIsLoadingProfiles(false);
    }
  }, [pageProfiles]);

  const handleCheckBox = useCallback(
    async ({ event, store, platform }) => {
      const { checked, id } = event.target;
      let adAccount = {
        status: 'SUCCESS',
        adAccount: {},
      };

      try {
        setLoadingAdAccountStatus({ status: true, id });

        const { data } = await api(shopsApi[platform]).patch(
          `api/v1/users/${platform}/stores/${store}/facebook/ad-accounts/${id}`,
          {
            active: checked,
          },
        );

        if (checked) {
          setHasActivatedAdAccount(true);
        }

        if (user && checked) {
          updateCustomerXTracking({ email: user.email, userId: user.id, identifier: 'add_facebook_ad_account' });
        }

        adAccount = {
          status: 'SUCCESS',
          adAccount: data?.adAccount,
        };
      } catch {
        // event.target.checked = !checked;
      } finally {
        setLoadingAdAccountStatus({ status: false, id });
      }

      return adAccount;
    },
    [setLoadingAdAccountStatus, user],
  );

  const handleFacebookLogin = useCallback(
    async ({ responseFacebook, store, platform }) => {
      const parsedData = {
        name: responseFacebook.name || 'Sem nome',
        ...responseFacebook,
      };

      try {
        setIsLoggingInProfile(true);
        const { data } = await api('user-api').post(`api/v1/users/facebook/credential`, parsedData);

        await api('user-api').post(`api/v1/users/facebook/credential/${data.credential.id}/ad-accounts`);

        await loadProfiles();
        await loadAdAccounts({ isLoading: true, store, platform });

        setHasConnectedProfileInThisSession(true);
      } finally {
        setIsLoggingInProfile(false);
      }
    },
    [loadAdAccounts, loadProfiles],
  );

  const handleFacebookLogout = useCallback(
    async ({ profileId, isLoading, store, platform }) => {
      setIsLoggingOutProfile(true);

      try {
        await api('user-api').delete(`api/v1/users/facebook/credential/${profileId}`);
        loadProfiles();
        loadAdAccounts({ isLoading, store, platform });
      } finally {
        setIsLoggingOutProfile(false);
      }
    },
    [loadAdAccounts, loadProfiles],
  );

  const handleChangePageAdAccount = useCallback(page => {
    setPageAdAccount(page.selected + 1);
  }, []);

  const handleChangePageProfiles = useCallback(page => {
    setPageProfiles(page.selected + 1);
  }, []);

  return (
    <FacebookContext.Provider
      value={{
        handleCheckBox,
        handleFacebookLogin,
        handleFacebookLogout,
        handleChangePageAdAccount,
        handleChangePageProfiles,
        loadAdAccounts,
        loadProfiles,
        setLoading,
        setAdAccountName,
        pagesCountAdAccount,
        pagesCountProfiles,
        platform,
        store,
        profiles,
        loading,
        adAccounts,
        loadingAdAccountStatus,
        setPageAdAccount,
        setStore,
        setPlatform,
        hasConnectedProfileInThisSession,
        setHasActivatedAdAccount,
        hasActivatedAdAccount,
        isLoadingProfiles,
        isLoggingOutProfile,
        isLoggingInProfile,
      }}
    >
      {children}
    </FacebookContext.Provider>
  );
};

export const useFacebookMarketing = () => {
  const context = useContext(FacebookContext);

  if (!context) {
    throw new Error('useFacebookMarketing must be used within a FacebookProvider');
  }

  return context;
};
