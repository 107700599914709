import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import profitfyLogo from '../../../../assets/img/brand/logo-white.svg';
import appmaxLogo from '../../../../assets/img/brand/appmax/appmax-logo.png';
import plusImage from '../../../../assets/img/brand/appmax/plus.svg';
import { useAppmax } from '../../../../hooks/Appmax';

import './styles.scss';

const Appmax = () => {
  const history = useHistory();
  const { appmaxFailReason } = useAppmax();

  const handleNextStep = useCallback(() => {
    history.push('/stores');
  }, [history]);

  useEffect(() => {
    if (!appmaxFailReason) {
      history.push('/subscribe/appmax');
    }
  }, [history, appmaxFailReason]);

  return (
    <div className="subscribe-error-appmax-container">
      <div className="appmax-header-wrapper">
        <div className="appmax-header">
          <img className="profitfy" src={profitfyLogo} alt="Profitfy.me" />
          <img className="plus" src={plusImage} alt="Profitfy.me" />
          <img className="appmax" src={appmaxLogo} alt="Profitfy.me Appmax" />
        </div>
      </div>

      <div className="content">
        <div className="error-content">
          <i className="far fa-times-circle" />
          <span>Não foi possível assinar o plano Appmax</span>
        </div>

        {appmaxFailReason === 'email not found' && (
          <>
            <h2>Parece que você não atende um dos nossos requisitos.</h2>

            <ul>
              <li>
                <p>Verifique se o e-mail utilizado na Appmax é o mesmo utilizado na nossa plataforma.</p>
              </li>
              <li>
                <p>
                  Talvez o cadastro não tenha sido feito com o link da promoção. Para obter mais informações, entre em
                  contato com o nosso suporte.
                </p>
              </li>
            </ul>
          </>
        )}

        {appmaxFailReason === 'not allowed revenue' && (
          <>
            <h2>Parece que você não atende um dos nossos requisitos.</h2>

            <ul>
              <li>
                <p>
                  Verifique se o faturamento mensal é de pelo menos <strong>R$5.000,00</strong> ou mais.
                </p>
              </li>
            </ul>
          </>
        )}

        <button className="subscribe-button" type="button" onClick={handleNextStep}>
          Voltar ao dashboard
        </button>
      </div>
    </div>
  );
};

export default Appmax;
