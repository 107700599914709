import React, { useCallback } from 'react';
import { Label, InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';

const CartxInput = ({ setStoreData }) => {
  const handleInputData = useCallback(
    cartxProperty => {
      setStoreData(storeData => ({
        ...storeData,
        ...cartxProperty,
      }));
    },
    [setStoreData],
  );

  return (
    <>
      <Label className="form-control-label">Digite o nome da sua loja</Label>
      <InputGroup className="input-group-alternative">
        <Input onChange={e => handleInputData({ storeName: e.target.value })} />
        <InputGroupAddon addonType="append">
          <InputGroupText>.oncartx.io</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      <Label className="form-control-label">Digite o token</Label>
      <InputGroup className="input-group-alternative">
        <Input onChange={e => handleInputData({ token: e.target.value })} />
      </InputGroup>
    </>
  );
};

export default CartxInput;
