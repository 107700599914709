import { useFetch } from './Fetch';
import { shopsApi } from '../services/api';

export const useProductVariants = ({ productId, store, platform }) => {
  const { data, error, mutate } = useFetch({
    url: `/api/v1/users/${platform}/stores/${store}/products/${productId}/variants`,
    apiName: shopsApi[platform],
  });

  return {
    mutate,
    productVariantsData: data,
    isLoadingProductVariants: !error && !data,
    isError: error,
  };
};

export const useProduct = ({ platform, store, productId }) => {
  const { data, error, mutate } = useFetch({
    url: `/api/v1/users/${platform}/stores/${store}/products/${productId}`,
    apiName: shopsApi[platform],
  });

  return {
    mutate,
    productData: data,
    isLoadingProduct: !error && !data,
    isError: error,
  };
};

export const useProductVariantKits = ({ platform, store, productId, variantId }) => {
  const { data, error, mutate } = useFetch({
    url: `/api/v1/users/${platform}/stores/${store}/products/${productId}/variants/${variantId}/variants-kit`,
    apiName: shopsApi[platform],
  });

  return {
    mutate,
    variantKitsData: data,
    isLoadingVariantKits: !error && !data,
    isError: error,
  };
};
