import React, { memo, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../../components/ProductCost/ProductVariantTable/Header/Header';
import Body from '../../../components/ProductCost/ProductVariantTable/Body/Body';
import { useProductVariants, useProduct } from '../../../hooks/ProductCost';
import Spinner from '../../../components/Spinner/Spinner';
import SaveChanges from '../../../components/ProductCost/SaveChanges/SaveChanges';
import UnsavedModal from '../../../components/ProductCost/ProductVariantTable/UnsavedModal/UnsavedModal';
import CalculatePastOrdersModal from '../../../components/ProductCost/ProductVariantTable/CalculatePastOrdersModal/CalculatePastOrdersModal';
import { api, shopsApi } from '../../../services/api';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';

import './styles.scss';

const ProductCostVariantTable = () => {
  const { user } = useAuth();
  const { platform, store, productId } = useParams();
  const {
    isLoadingProductVariants,
    productVariantsData,
    mutate: mutateVariants,
  } = useProductVariants({
    platform,
    store,
    productId,
  });
  const { isLoadingProduct, productData, mutate: mutateProduct } = useProduct({ platform, store, productId });

  const [hasChangedData, setHasChangedData] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [userCurrency, setUserCurrency] = useState('USD');
  const [selectedIof, setSelectedIof] = useState('TICKET');
  const [allVariantsCost, setAllVariantsCost] = useState('');
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);
  const [editedVariants, setEditedVariants] = useState([]);
  const [isCalculatePastOrdersModalOpen, setIsCalculatePastOrdersModalOpen] = useState(false);
  const [isSavingEdit, setIsSavingEdit] = useState(false);
  const [showDeletedVariants, setShowDeletedVariants] = useState(false);

  const handleUnsavedModalOpen = useCallback(() => setIsUnsavedModalOpen(!isUnsavedModalOpen), [isUnsavedModalOpen]);

  const handleCalculatePastOrdersModalOpen = useCallback(
    () => setIsCalculatePastOrdersModalOpen(!isCalculatePastOrdersModalOpen),
    [isCalculatePastOrdersModalOpen],
  );

  const handleSaveChanges = useCallback(async () => {
    setIsSavingEdit(true);

    try {
      handleCalculatePastOrdersModalOpen();

      const updateCurrency = api(shopsApi[platform]).patch(
        `api/v1/users/${platform}/stores/${store}/products/${productId}/currency`,
        {
          currency: selectedCurrency,
        },
      );

      const updateIof = api(shopsApi[platform]).patch(
        `api/v1/users/${platform}/stores/${store}/products/${productId}/buy-method`,
        {
          buyMethod: selectedIof,
        },
      );

      const updateVariants = api(shopsApi[platform]).patch(
        `api/v1/users/${platform}/stores/${store}/products/${productId}/variants`,
        {
          variants: editedVariants,
        },
      );

      await Promise.all([updateCurrency, updateIof, updateVariants]);

      setUserCurrency(selectedCurrency);

      await Promise.all([mutateProduct(), mutateVariants()]);

      setHasChangedData(false);

      if (user) {
        updateCustomerXTracking({ email: user.email, userId: user.id, identifier: 'update_cogs' });
      }
    } finally {
      setIsSavingEdit(false);
    }
  }, [
    editedVariants,
    selectedCurrency,
    selectedIof,
    handleCalculatePastOrdersModalOpen,
    platform,
    productId,
    store,
    mutateProduct,
    mutateVariants,
    user,
  ]);

  useEffect(() => {
    if (productData?.product) {
      setUserCurrency(productData.product.currency);
      setSelectedCurrency(productData.product.currency);
    }
  }, [productData]);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_cogs_variants',
        type: 'screen',
      });
    }
  }, [user]);

  if (isLoadingProductVariants || isLoadingProduct) {
    return <Spinner loading />;
  }

  return (
    <>
      <CalculatePastOrdersModal isOpen={isCalculatePastOrdersModalOpen} toggle={handleCalculatePastOrdersModalOpen} />

      {isSavingEdit ? (
        <Spinner loading />
      ) : (
        <div className="product-cost-variant-table-container">
          <Header
            handleUnsavedModalOpen={handleUnsavedModalOpen}
            productData={productData}
            hasChangedData={hasChangedData}
          />
          <Body
            productVariantsData={productVariantsData}
            setHasChangedData={setHasChangedData}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            selectedIof={selectedIof}
            setSelectedIof={setSelectedIof}
            allVariantsCost={allVariantsCost}
            setAllVariantsCost={setAllVariantsCost}
            productData={productData}
            editedVariants={editedVariants}
            setEditedVariants={setEditedVariants}
            showDeletedVariants={showDeletedVariants}
            setShowDeletedVariants={setShowDeletedVariants}
            userCurrency={userCurrency}
          />

          <UnsavedModal isOpen={isUnsavedModalOpen} toggle={handleUnsavedModalOpen} />

          {hasChangedData && <SaveChanges handleSaveChanges={handleSaveChanges} />}
        </div>
      )}
    </>
  );
};

export default memo(ProductCostVariantTable);
