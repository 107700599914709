import React, { memo, useCallback, useMemo, useState } from 'react';
import { ChartBarHorizontal } from 'phosphor-react';
import { Link, useParams } from 'react-router-dom';
import { ResponsiveBar } from '@nivo/bar';

import Chart from './Chart/Chart';
import { numberBrFormatter } from '../../../../utils/utils';
import ChartTooltip from './Chart/ChartTooltip/ChartTooltip';
import './styles.scss';

const noData = [
  {
    id: 'Sem dados',
    no_data: 1,
  },
];

const Revenue = ({ storePeriod, profit }) => {
  const { platform, store } = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const handlePopover = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const grossAmount = useMemo(
    () => profit.mainCards.revenue + profit.mainCards.waitOrders + profit.mainCards.totalOrdersUnknownAmount,
    [profit],
  );

  const hasRevenue = useMemo(() => {
    if (grossAmount !== 0) return true;

    return false;
  }, [grossAmount]);

  const data = useMemo(
    () => [
      {
        id: 'Faturamento',
        gross: grossAmount,
        pending: profit.waitOrders,
        no_info: profit.totalOrdersUnknownAmount,
      },
    ],
    [grossAmount, profit],
  );

  return (
    <div className="product-details-revenue">
      <div className="header">
        <span>FATURAMENTO</span>
        <div>
          <ChartBarHorizontal size={16} weight="bold" />
        </div>
      </div>
      <div className="body">
        <div className="details">
          <div className="value-wrapper">
            <span>Bruto</span>
            <span>
              <small>R$</small>
              {numberBrFormatter(grossAmount, 2)}
            </span>
          </div>

          <div className="value-wrapper">
            <span>Pendente</span>
            <span>
              <small>{profit.mainCards.waitOrders !== 0 && '- '}R$</small>
              {numberBrFormatter(profit.mainCards.waitOrders, 2)}
            </span>
          </div>

          {profit?.mainCards?.totalOrdersUnknownAmount !== 0 && (
            <div className="more-information-wrapper">
              <div>
                <span>Pedidos sem informações</span>
                <span>
                  <small>- R$</small>
                  {numberBrFormatter(profit.mainCards.totalOrdersUnknownAmount, 2)}
                </span>
              </div>

              <Link
                to={`/${platform}/${store}/dashboard/orders?startDate=${storePeriod.startDate}&endDate=${storePeriod.endDate}&status=no_info`}
                className="text-link more-details"
              >
                Mais informações
              </Link>
            </div>
          )}
        </div>

        <hr />

        <div className="total-and-chart">
          <div className="total-wrapper">
            <span>LÍQUIDO</span>
            <span>
              <small>R$</small>
              {numberBrFormatter(profit.mainCards.revenue, 2)}
            </span>
          </div>

          <div className="chart" id="revenue-card-chart-tooltip">
            {hasRevenue ? (
              <Chart data={data} />
            ) : (
              <ResponsiveBar
                data={noData}
                keys={['no_data']}
                indexBy="id"
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                padding={0}
                layout="horizontal"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={['#CACCCF']}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={null}
                enableGridY={false}
                enableGridX={false}
                enableLabel={false}
                legends={[]}
                animate
                motionStiffness={90}
                motionDamping={15}
                isInteractive={false}
              />
            )}
          </div>
          <ChartTooltip
            data={profit.mainCards}
            target="revenue-card-chart-tooltip"
            toggle={handlePopover}
            isOpen={isOpen}
          />
        </div>
      </div>

      <div className="gradient-border-detail">
        <div />
      </div>
    </div>
  );
};

export default memo(Revenue);
