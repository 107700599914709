import React, { useState, useCallback, useEffect, memo } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import queryString from 'query-string';

import Spinner from '../../../../components/Spinner/Spinner';
import AudienceInsightsTableHeader from './TableHeader/TableHeader';
import AudienceInsightsTableFooter from './TableFooter/TableFooter';
import AudienceInsightsTableBody from './TableBody/TableBody';
import { api } from '../../../../services/api';
import emptyImage from '../../../../assets/img/theme/empty.svg';
import { useAuth } from '../../../../hooks/User';
import { updateCustomerXTracking } from '../../../../utils/customerXTracking';

const AudienceInsights = () => {
  const { user } = useAuth();

  const [audienceInsights, setAudienceInsights] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagesCount, setPagesCount] = useState(1);
  const [page, setPage] = useState(0);
  const [audienceInsightsFilter, setAudienceInsightsFilter] = useState({
    interest: '',
  });
  const [audienceInsightsSort, setAudienceInsightsSort] = useState({
    audience_size: 'desc',
  });

  const loadAudienceInsightsData = useCallback(
    async load => {
      const limit = 20;
      setLoading(Boolean(load));

      try {
        const response = await api('user-api').get(
          `api/v1/users/facebook/audiences?${queryString.stringify({
            sort: JSON.stringify(audienceInsightsSort),
          })}&${queryString.stringify({
            interest: JSON.stringify(audienceInsightsFilter.interest),
          })}&limit=${limit}&page=${page + 1}`,
        );

        if (response) {
          setAudienceInsights(response.data.interests);
          setPagesCount(response.data.numberOfPages);
        }
      } finally {
        setLoading(false);
      }
    },
    [audienceInsightsSort, page, audienceInsightsFilter],
  );

  const handleFilter = useCallback(
    async audienceInsightsParams => {
      setPage(0);
      setAudienceInsightsFilter({ ...audienceInsightsFilter, ...audienceInsightsParams });

      if (user) {
        updateCustomerXTracking({
          userId: user.id,
          email: user.email,
          identifier: 'update_audience_explorer',
        });
      }
    },
    [setAudienceInsightsFilter, audienceInsightsFilter, user],
  );

  const onChangePage = useCallback(page => {
    setPage(page.selected);
  }, []);

  useEffect(() => {
    loadAudienceInsightsData(true);
  }, [loadAudienceInsightsData]);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_audience_insight',
        type: 'screen',
      });
    }
  }, [user]);

  return (
    <Container fluid className="pb-8 pt-5 pt-md-8">
      <Row>
        <Col>
          <Card>
            <AudienceInsightsTableHeader audienceInsightsFilter={audienceInsightsFilter} handleFilter={handleFilter} />
            {loading ? (
              <Spinner loading={loading} />
            ) : (
              <AudienceInsightsTableBody
                audienceInsights={audienceInsights}
                audienceInsightsSort={audienceInsightsSort}
                setAudienceInsightsSort={setAudienceInsightsSort}
              />
            )}
            <AudienceInsightsTableFooter page={page} pages={pagesCount} onChangePage={onChangePage} />
          </Card>
        </Col>
      </Row>

      {audienceInsights.length <= 0 && !loading && (
        <Row className="align-items-center flex-column mt-6">
          <img src={emptyImage} width="300px" alt="no data" />
          <h1 className="mt-4 text-muted text-center">Não há nada para ver no momento.</h1>
        </Row>
      )}
    </Container>
  );
};

export default memo(AudienceInsights);
