import React, { useState, useEffect, useCallback, useContext, memo } from 'react';
import useForm from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Col,
  InputGroup,
  Input as StrapInput,
  InputGroupAddon,
  InputGroupText,
  CardBody,
  CardHeader,
  Card,
  Row,
  Button,
  Form,
} from 'reactstrap';
import moment from 'moment-timezone';

import { api, shopsApi } from '../../services/api';
import DatePicker from '../DatePicker/DatePicker';
import CustomSwitch from '../CustomSwitch/CustomSwitch';
import FirstTimeContext from '../../hooks/FirstTimeContext';
import { useToast } from '../../hooks/Toast';
import { useAuth } from '../../hooks/User';
import { updateCustomerXTracking } from '../../utils/customerXTracking';

import './styles.scss';

const CheckoutFee = ({ className }) => {
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkoutFee, setCheckoutFee] = useState({ id: null });
  const [period, setPeriod] = useState({ startDate: moment(), endDate: moment() });
  const { store, platform } = useParams();
  const { register, handleSubmit, errors } = useForm({});
  const firstTime = useContext(FirstTimeContext);
  const { showToast } = useToast();

  const onSubmit = async data => {
    const parsedData = {
      id: checkoutFee?.id,
      realAmount: Number(data.real.replace(/,/g, '.')) || 0,
      percentageAmount: Number(data.percentage.replace(/,/g, '.')) || 0,
    };

    if (parsedData.realAmount > 20 || parsedData.percentageAmount > 20) {
      showToast({
        message: 'Lembre-se que a checkout transparente é o valor cobrado por transação!',
        type: 'warn',
      });
    } else {
      try {
        setLoading(true);
        const requestType = checkoutFee?.id ? 'put' : 'post';
        const response = await api(shopsApi[platform])[requestType](
          `api/v1/users/${platform}/stores/${store}/checkout-fees/${parsedData?.id || ''}`,
          parsedData,
        );
        setCheckoutFee(response.data.checkoutFee);

        if (checked) {
          await api(shopsApi[platform]).put(
            `api/v1/users/${platform}/stores/${store}/orders/recalculate-checkout-fee?startDate=${period.startDate.format(
              'YYYY-MM-DD',
            )}&endDate=${period.endDate.format('YYYY-MM-DD')}`,
          );
        }

        showToast({ message: 'Taxas salvas com sucesso!', type: 'success' });

        if (user) {
          updateCustomerXTracking({ email: user.email, userId: user.id, identifier: 'update_checkout_fee' });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCheckBox = async event => {
    const { checked } = event.target;
    setChecked(checked);
  };

  const handleDate = ({ startDate, endDate }) => {
    if (!startDate || !endDate) return;
    if (!startDate.isValid() || !endDate.isValid()) return;
    setPeriod({ startDate, endDate });
  };

  const loadCheckoutTax = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api(shopsApi[platform]).get(`api/v1/users/${platform}/stores/${store}/checkout-fees`);
      setCheckoutFee(data.checkoutFee);
    } finally {
      setLoading(false);
    }
  }, [platform, store]);

  useEffect(() => {
    loadCheckoutTax();
  }, [loadCheckoutTax]);

  return (
    <Col className={className}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card className="checkout-fee-container">
          <CardHeader>
            <h3 className="mb-0">Taxa de Checkout</h3>
            <p className="text-muted text-sm mb-0">
              Aqui você pode colocar taxas do seu checkout, por exemplo (Xekout.me, Yampi, Guru, entre outros). Não
              confundir checkout com gateway!
            </p>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-percent fa-md text-blue-marine" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <StrapInput
                    name="percentage"
                    type="text"
                    placeholder={checkoutFee?.percentageAmount}
                    innerRef={register}
                    disabled={loading}
                  />
                </InputGroup>
              </Col>
              <Col className="pt-md-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <strong className="text-lg text-blue-marine">R</strong>
                      <i className="fas fa-dollar-sign fa-md text-blue-marine" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <StrapInput
                    name="real"
                    type="text"
                    innerRef={register}
                    disabled={loading}
                    placeholder={checkoutFee?.realAmount}
                  />
                  {errors?.real?.message}
                </InputGroup>
              </Col>
            </Row>
            <Row className="save-button-wrapper">
              {firstTime && !firstTime?.isNewUser && (
                <div>
                  <div>
                    <p className="font-weight-bold">Alterar a taxa para um período passado?</p>
                    <div className="d-flex align-items-center">
                      <CustomSwitch onClick={handleCheckBox} id="active-recalc" status={checked} />
                      {checked && (
                        <div className="ml-0 ml-md-3">
                          <DatePicker
                            onDate={handleDate}
                            startDate={period.startDate}
                            endDate={period.endDate}
                            position="start"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <Button color="primary" className="btn-icon btn-2 text-right mt-1" type="submit" disabled={loading}>
                <span className="btn-inner--text">{loading ? 'Carregando...' : 'Salvar'}</span>
                <span className="btn-inner--icon">
                  <i className="fas fa-paper-plane" />
                </span>
              </Button>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </Col>
  );
};

export default memo(CheckoutFee);
