import React, { useEffect, useState, useCallback } from 'react';
import { Row, Container, Card } from 'reactstrap';
import { api } from '../../services/api';
import Spinner from '../Spinner/Spinner';
import './Plans.scss';
import 'react-input-range/lib/css/index.css';
import DefaultPlans from './DefaultPlans/DefaultPlans';

const Plans = ({ onSubmitPlan, subTitle, loading, buttonText }) => {
  const [plans, setPlans] = useState(null);

  const loadPlans = useCallback(async () => {
    const { data } = await api('user-api').get(`api/v1/users/plans?category=default`);

    setPlans(data.plans);
  }, []);

  useEffect(() => {
    loadPlans();
  }, [loadPlans]);

  return plans ? (
    <Container className="plans px-md-6">
      <Row className="justify-content-center align-items-center">
        <Card className="shadow px-md-5 px-3 pt-4">
          <DefaultPlans
            onSubmitPlan={onSubmitPlan}
            plans={plans}
            subTitle={subTitle}
            loading={loading}
            buttonText={buttonText}
          />
        </Card>
      </Row>
    </Container>
  ) : (
    <Spinner loading />
  );
};

export default Plans;
