import React, { memo, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { WarningCircle } from 'phosphor-react';

import './styles.scss';

const ConnectWarningModal = ({ isOpen, toggle }) => {
  const handleCloseModal = useCallback(() => toggle(), [toggle]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="connect-facebook-warning-modal">
      <ModalHeader className="connect-facebook-warning-header">
        <WarningCircle size={32} />
        <span>Aviso</span>
      </ModalHeader>

      <ModalBody className="connect-facebook-warning-body">
        <div className="warning-content">
          <span>Conectando duas ou mais perfis</span>
          <p>
            Caso você esteja tentando conectar em <strong>duas ou mais perfis do Facebook</strong> na plataforma,
            conecte-se através da <strong>aba anônima</strong>, ou conecte-se em um{' '}
            <strong>navegador diferente,</strong> ou <strong>saia da sua conta do Facebook</strong> no site da rede
            social.
          </p>

          <Button color="primary" onClick={handleCloseModal}>
            Entendi
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default memo(ConnectWarningModal);
