import React, { memo, useCallback } from 'react';
import { Table, Button, Media, Badge } from 'reactstrap';
import { Link, useParams, useHistory } from 'react-router-dom';

import { numberBrFormatter } from '../../../../../utils/utils';
import DefaultImage from '../../../../../components/DefaultImage/DefaultImage';

const TopProductsTableBody = ({ products }) => {
  const history = useHistory();
  const { platform, store } = useParams();

  const handleProductDetails = useCallback(
    product => {
      history.push(`/${platform}/${store}/dashboard/product-details/${product.id}/product-details`);
    },
    [platform, store, history],
  );

  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Imagem</th>
          <th scope="col">Nome</th>
          <th scope="col" className="text-center">
            Faturamento
          </th>
          <th scope="col" className="text-center">
            Qtd. Pedidos
          </th>
          <th scope="col" className="text-center">
            ações
          </th>
        </tr>
      </thead>
      <tbody>
        {products.map(product => (
          <tr key={product.id} onClick={() => handleProductDetails(product)} style={{ cursor: 'pointer' }}>
            <td width="8%">
              <Media className="align-items-center">
                {product.image ? (
                  <span className="avatar avatar-lg rounded-circle">
                    <img alt="product" src={product.image} />
                  </span>
                ) : (
                  <DefaultImage size={58} />
                )}
              </Media>
            </td>
            <td className="text-truncate" style={{ maxWidth: '300px' }}>
              <span className="mb-0">{product.title}</span>
            </td>
            <td width="15%" className="text-center">
              <p className="mb-0 h2">
                <Badge color="green">R$ {numberBrFormatter(product.total_revenue)} </Badge>
              </p>
            </td>
            <td width="15%" className="text-center">
              <span>{product.total_orders} </span>
            </td>
            <td className="text-center" width="2%">
              <Link to={`/${platform}/${store}/dashboard/product-details/${product.id}/product-details`}>
                <Button className="btn">
                  <i className="fas fa-eye text-gray" />
                </Button>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default memo(TopProductsTableBody);
