import React, { memo } from 'react';
import { Table } from 'reactstrap';
import SubHeaders from './SubHeaders/SubHeaders';
import { numberBrFormatter } from '../../../../../utils/utils';
import { ReactComponent as GoogleIcon } from '../../../../../assets/img/icons/common/google.svg';
import { ReactComponent as FacebookIcon } from '../../../../../assets/img/icons/common/facebook2.svg';

import './styles.scss';

const columns = [
  { name: 'name', description: 'Interesse', sort: true },
  { name: 'topic', description: 'Tipo', sort: true },
  { name: 'audience_size', description: 'Tamanho', sort: true },
  { name: 'search', description: 'Explorar', sort: false },
];

const AudienceInsightsTableHeader = ({ audienceInsights, audienceInsightsSort, setAudienceInsightsSort }) => {
  return (
    <Table className="align-items-center table-flush audience-insights-table" responsive>
      <thead className="thead-light">
        <tr className="text-center">
          <SubHeaders
            audienceInsightsSort={audienceInsightsSort}
            setAudienceInsightsSort={setAudienceInsightsSort}
            columns={columns}
          />
        </tr>
      </thead>
      <tbody>
        {audienceInsights.map(el => {
          const lastIndex = el.path.length - 1;
          return (
            <tr key={el.id}>
              <td>
                <p className="mb-0">{el.name}</p>
              </td>
              <td>
                <p className="mb-0">
                  {el.path.map((path, i) => {
                    if (i !== lastIndex) return `${path} > `;

                    return path;
                  })}
                </p>
              </td>
              <td className="text-center">
                <p className="mb-0">{numberBrFormatter(el.audience_size, 0)}</p>
              </td>
              <td className="text-center">
                <a href={`https://www.google.com/search?q=${el.name}`} target="blank">
                  <GoogleIcon />
                </a>
                <a href={`https://www.facebook.com/search/pages/?q=${el.name}`} target="blank">
                  <FacebookIcon className="ml-3" />
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default memo(AudienceInsightsTableHeader);
