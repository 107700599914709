import React, { memo, useCallback } from 'react';
import { ArrowLeft } from 'phosphor-react';

import './styles.scss';

const Header = ({ handleUnsavedModalOpen, productData, hasChangedData, setSelectedProduct }) => {
  const { product } = productData;

  const goBack = useCallback(() => {
    if (hasChangedData) {
      handleUnsavedModalOpen();

      return;
    }

    setSelectedProduct(null);
  }, [setSelectedProduct, handleUnsavedModalOpen, hasChangedData]);

  return (
    <button className="product-cost-variant-header" type="button" onClick={goBack}>
      <ArrowLeft size={32} />

      <h2>{product.title}</h2>
    </button>
  );
};

export default memo(Header);
