/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo } from 'react';
import { Button, Card, Form, FormGroup, Input } from 'reactstrap';
import PaymentCard from 'react-payment-card-component';
import './styles.scss';

const CreditCard = ({ isLoading, cardData, isFlipped, handleFlipCard, onChangeCard, onSubmit }) => {
  return (
    <Card className="shadow credit-card-info-container">
      <Form role="form">
        <div className="d-flex justify-content-center">
          <PaymentCard
            brand={cardData.brand}
            number={cardData.number}
            cvv={cardData.cvv}
            holderName={cardData.holderName}
            expiration={`${cardData.month}/${cardData.year}`}
            flipped={isFlipped}
          />
        </div>
        <div className="mt-5">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-card-number">
              Número do Cartão
            </label>
            <Input
              placeholder="4111 1111 1111 1111"
              type="text"
              id="input-card-number"
              name="number"
              onChange={onChangeCard}
              maxLength="19"
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-holder-name">
              Nome <span className="font-weight-normal">(Impresso no Cartão)</span>
            </label>
            <Input
              id="input-holder-name"
              name="holderName"
              placeholder="YUNG BUDA"
              type="text"
              onChange={onChangeCard}
            />
          </FormGroup>
          <div className="d-flex">
            <FormGroup className="mr-5">
              <label className="form-control-label" htmlFor="input-expiration">
                Validade
              </label>
              <div className="d-flex">
                <Input
                  className=" mr-3"
                  type="select"
                  name="month"
                  id="input-expiration"
                  onChange={onChangeCard}
                  defaultValue={cardData.month}
                >
                  <option disabled>Mês</option>
                  <option value="01">01 - Janeiro</option>
                  <option value="02">02 - Fevereiro</option>
                  <option value="03">03 - Março</option>
                  <option value="04">04 - Abril</option>
                  <option value="05">05 - Maio</option>
                  <option value="06">06 - Junho</option>
                  <option value="07">07 - Julho</option>
                  <option value="08">08 - Agosto</option>
                  <option value="09">09 - Setembro</option>
                  <option value="10">10 - Outubro</option>
                  <option value="11">11 - Novembro</option>
                  <option value="12">12 - Dezembro</option>
                </Input>
                <Input type="select" name="year" id="input-year" onChange={onChangeCard} defaultValue={cardData.year}>
                  <option disabled>Ano</option>
                  {[...Array(11).keys()].map(el => (
                    <option key={el} value={el + 20}>{`20${el + 20}`}</option>
                  ))}
                </Input>
              </div>
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-cvv">
                Cod. Segurança
              </label>
              <Input
                placeholder="123"
                type="text"
                name="cvv"
                id="input-cvv"
                onChange={onChangeCard}
                onFocus={handleFlipCard}
                onBlur={handleFlipCard}
                maxLength="3"
              />
            </FormGroup>
          </div>
        </div>
        <div className="text-right">
          <Button className="btn-icon btn-2 btn-lg" disabled={isLoading} color="primary" onClick={onSubmit}>
            {isLoading ? 'Processando...' : 'Confirmar'}
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default memo(CreditCard);
