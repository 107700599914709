import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import { CheckCircle } from 'phosphor-react';

import Next from '../../../../assets/img/icons/common/next.svg';
import './styles.scss';

const CardIntegration = ({ name, img, alt, description, link, isIntegrated }) => {
  return (
    <Link to={link}>
      <Card className="integration-card shadow">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex main-integration-content align-items-center">
            <img src={img} alt={alt} />
            <div>
              <p className="integration-name">{name}</p>
              <p className="integration-description">{description}</p>
            </div>
          </div>
          <div className="icons-wrapper">
            {isIntegrated && <CheckCircle size={28} className="checked-icon" />}
            <img src={Next} alt="Profitfy.me next" />
          </div>
        </div>
      </Card>
    </Link>
  );
};

export default memo(CardIntegration);
