import React, { useState, useCallback, memo } from 'react';
import { Popover as ReactPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';

import './styles.scss';

const CompensatedPopover = ({ storePeriod }) => {
  const { platform, store } = useParams();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <ReactPopover
      placement="top"
      isOpen={isOpen}
      target="popover-billet-compensated"
      toggle={handleToggle}
      trigger="hover"
      className="w-100"
    >
      <PopoverHeader className="billets-compensated-tooltip-header">Veja mais</PopoverHeader>
      <PopoverBody>
        <div className="billets-compensated-tooltip">
          <p>O boleto compensado é diferente do boleto pago!</p>
          <p>
            O boleto pago se refere aos boletos gerados no período selecionado, e que constam pagos no Gateway, enquanto
            que o boleto compensado se refere aos boletos que tiveram a confirmação do pagamento do Gateway no período
            selecionado.
          </p>
          <p>O faturamento mostra apenas o faturamento dos boletos pagos.</p>
          <Link
            to={`/${platform}/${store}/dashboard/billet?startDate=${storePeriod.startDate}&endDate=${storePeriod.endDate}`}
            className="text-link"
          >
            Mais detalhes
          </Link>
        </div>
      </PopoverBody>
    </ReactPopover>
  );
};

export default memo(CompensatedPopover);
