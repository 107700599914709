import React, { useState, memo, useCallback } from 'react';
import moment from 'moment-timezone';
import { CardHeader, Button, Row, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import CustomSpendsWarn from '../../../../components/CustomSpendsWarn/CustomSpendsWarn';
import CustomSpendsModal from '../Modal/Modal';
import Filter from './Filter/Filter';
import './styles.scss';

const CustomSpendsTableHeader = ({ customSpends, setCustomSpends, handleFilter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const handleIsWarningModalOpen = useCallback(() => setIsWarningModalOpen(!isWarningModalOpen), [isWarningModalOpen]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <CustomSpendsWarn isOpen={isWarningModalOpen} toggle={handleIsWarningModalOpen} />

      <CustomSpendsModal
        toggle={toggle}
        isOpen={isOpen}
        customSpends={customSpends}
        setCustomSpends={setCustomSpends}
        customSpend={{
          id: null,
          description: null,
          amount: null,
          startDate: moment(),
          active: true,
          period: null,
        }}
        title="Insira um novo custo adicional"
        handleIsWarningModalOpen={() => handleIsWarningModalOpen()}
      />

      <CardHeader className="border-0 custom-spends-table-header ">
        <Row className="d-flex justify-content-between p-2">
          <div className="">
            <h3 className="mb-0">Valores Adicionais</h3>
            <p className="text-muted text-sm mb-0">
              Aqui você pode colocar valores adicionais (softwares/app, influencers, plano do shopify, taxas, salários,
              etc.).
            </p>
          </div>
          <div className="mt-2 mt-md-0 d-flex align-items-center custom-spends-input">
            <div className="custom-spends-search">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Pesquisar Descrição"
                  type="text"
                  name="description"
                  onChange={description => handleFilter({ description: description.target.value || null })}
                />
              </InputGroup>
            </div>
            <div className="d-flex">
              <Filter handleFilter={handleFilter} />
              <Button color="primary" className="btn-icon btn-2" onClick={toggle}>
                <span className="btn-inner--text">Adicionar</span>
                <span className="btn-inner--icon">
                  <i className="ni ni-archive-2" />
                </span>
              </Button>
            </div>
          </div>
        </Row>
      </CardHeader>
    </>
  );
};

export default memo(CustomSpendsTableHeader);
