import React, { memo, useCallback, useState } from 'react';
import { Input, Tooltip } from 'reactstrap';
import { Pencil } from 'phosphor-react';
import { useParams, useLocation } from 'react-router-dom';

import { useToast } from '../../../../hooks/Toast';
import { api, shopsApi } from '../../../../services/api';
import DefaultImage from '../../../DefaultImage/DefaultImage';
import { updateCustomerXTracking } from '../../../../utils/customerXTracking';
import { useAuth } from '../../../../hooks/User';

const ProductRow = ({ product, setSelectedProduct }) => {
  const { platform, store } = useParams();
  const { showToast } = useToast();
  const location = useLocation();
  const { user } = useAuth();

  const [innerProduct, setInnerProduct] = useState(product);
  const [isTooltipTitleOpen, setIsTooltipTitleOpen] = useState(false);

  const handleTooltipTitleOpen = useCallback(() => setIsTooltipTitleOpen(!isTooltipTitleOpen), [isTooltipTitleOpen]);

  const isOnboardingPage = useCallback(() => {
    if (location.pathname.includes('onboarding')) {
      return true;
    }

    return false;
  }, [location.pathname]);

  const editCurrency = useCallback(
    async event => {
      event.persist();

      try {
        await api(shopsApi[platform]).patch(
          `api/v1/users/${platform}/stores/${store}/products/${innerProduct.id}/currency`,
          {
            currency: event.target.value,
          },
        );

        setInnerProduct(prevState => ({ ...prevState, currency: event.target.value }));

        showToast({
          message: 'Moeda alterada com sucesso!',
          type: 'success',
        });

        if (isOnboardingPage()) {
          updateCustomerXTracking({
            email: user?.email,
            userId: user?.id,
            identifier: 'onboarding_has_edited_cogs',
          });
        }
      } catch {
        showToast({
          message: 'Não foi possível alterar a moeda! Por favor, tente novamente.',
          type: 'error',
        });

        event.target.value = innerProduct.currency;
      }
    },
    [platform, store, innerProduct, showToast, isOnboardingPage, user],
  );

  const editIof = useCallback(
    async event => {
      event.persist();

      try {
        await api(shopsApi[platform]).patch(
          `api/v1/users/${platform}/stores/${store}/products/${innerProduct.id}/buy-method`,
          {
            buyMethod: event.target.value,
          },
        );

        setInnerProduct(prevState => ({ ...prevState, buyMethod: event.target.value }));

        showToast({
          message: 'IOF alterado com sucesso!',
          type: 'success',
        });

        if (isOnboardingPage()) {
          updateCustomerXTracking({
            email: user.email,
            userId: user.id,
            identifier: 'onboarding_has_edited_cogs',
          });
        }
      } catch {
        showToast({
          message: 'Não foi possível alterar o IOF! Por favor, tente novamente.',
          type: 'error',
        });

        event.target.value = innerProduct.buyMethod;
      }
    },
    [innerProduct, store, platform, showToast, isOnboardingPage, user],
  );

  const goToEditProduct = useCallback(() => {
    setSelectedProduct(product);
  }, [product, setSelectedProduct]);

  return (
    <tr className="edit-product-cost-table-row">
      <td className="image-column">
        {product?.image ? (
          <img src={product.image} alt="Profitfy.me Custo de Produto" />
        ) : (
          <DefaultImage className="default-image" size={60} />
        )}
      </td>
      <td className="product-name-column">
        <span id={`product-${product?.id}`}>{product.title}</span>
      </td>
      <td className="currency-column">
        <Input
          className="currency-select"
          type="select"
          name="currency-select"
          id="currency-select"
          onChange={editCurrency}
          defaultValue={product.currency}
        >
          <option value="USD" defaultValue>
            U$
          </option>
          <option value="BRL">BRL</option>
        </Input>
      </td>
      <td className="iof-column">
        <Input
          className="iof-select"
          type="select"
          name="iof-select"
          id="iof-select"
          onChange={editIof}
          defaultValue={product.buyMethod}
        >
          <option value="TICKET" defaultValue>
            Boleto Bancário 0,38%
          </option>
          <option value="CARD">Cartão de Crédito 6,38%</option>
          <option value="NONE">Nenhum</option>
        </Input>
      </td>
      <td className="actions-column">
        <button type="button" onClick={goToEditProduct}>
          <span>Editar</span>
          <Pencil size={15} />
        </button>
      </td>
      <Tooltip
        isOpen={isTooltipTitleOpen}
        toggle={handleTooltipTitleOpen}
        trigger="hover"
        target={`product-${product?.id}`}
      >
        {product?.title}
      </Tooltip>
    </tr>
  );
};

export default memo(ProductRow);
