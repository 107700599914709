import React, { memo, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { WarningCircle } from 'phosphor-react';

import SelectCurrency from '../SelectCurrency/SelectCurrency';
import SelectAverageCost from '../SelectAverageCost/SelectAverageCost';

import './styles.scss';

const ConvertCurrencyModal = ({
  isOpen,
  toggle,
  setSelectedCurrency,
  selectedPercentage,
  setSelectedPercentage,
  handleConfirmButton,
}) => {
  const [convertCurrencyStep, setConvertCurrencyStep] = useState('currency');

  return (
    <Modal className="edit-product-cost-convert-currency-modal" isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="convert-currency-modal-header" toggle={toggle}>
        <WarningCircle size={32} />
        <span>Converter moeda</span>
      </ModalHeader>

      <ModalBody className="convert-currency-modal-body">
        <div>
          {convertCurrencyStep === 'currency' ? (
            <SelectCurrency setConvertCurrencyStep={setConvertCurrencyStep} setSelectedCurrency={setSelectedCurrency} />
          ) : (
            <SelectAverageCost
              setConvertCurrencyStep={setConvertCurrencyStep}
              selectedPercentage={selectedPercentage}
              setSelectedPercentage={setSelectedPercentage}
              handleConfirmButton={handleConfirmButton}
              toggle={toggle}
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default memo(ConvertCurrencyModal);
