export const TOKEN_KEY = process.env.REACT_APP_STAGE === 'qa' ? 'jwt' : 'twj';

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const login = token => localStorage.setItem(TOKEN_KEY, token);

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);
