import React, { memo, useCallback, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ChartBar, Megaphone, ArrowLeft, MonitorPlay } from 'phosphor-react';

import NewDatePicker from '../../NewDatePicker/NewDatePicker';
import VideoModal from '../../VideoModal/VideoModal';

import './styles.scss';

const Header = ({
  handlePageChange,
  page,
  storePeriod,
  onDate,
  setSelectedDate,
  selectedDate,
  campaigns,
  productDetailData,
}) => {
  const { platform, store } = useParams();
  const history = useHistory();

  const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);

  const handleTutorialModalOpen = useCallback(
    () => setIsTutorialModalOpen(!isTutorialModalOpen),
    [isTutorialModalOpen],
  );

  const goToProductAnalytics = useCallback(() => {
    history.push(`/${platform}/${store}/dashboard/product-analytics`);
  }, [history, platform, store]);

  return (
    <div className="product-details-header">
      <button className="go-back" type="button" onClick={goToProductAnalytics}>
        <ArrowLeft size={28} />
        <span>{productDetailData.product.title}</span>
      </button>

      <div className="tab-menu-and-tutorial">
        <div className="tab-menu">
          <button
            className={page === 'details' ? 'selected' : undefined}
            type="button"
            onClick={() => handlePageChange('details')}
          >
            <ChartBar size={20} />
            <span>Análise de Produto</span>
          </button>
          <button
            className={page === 'campaigns' ? 'selected' : undefined}
            type="button"
            onClick={() => handlePageChange('campaigns')}
          >
            <div className="icon-circle">
              <Megaphone size={12} />
            </div>
            <span>Campanhas</span>

            {campaigns.length === 0 && (
              <div className="pulse-circle-wrapper">
                <div className="circle" />
                <div className="pulse" />
                <div className="pulse-2" />
                <div className="pulse-3" />
              </div>
            )}
          </button>
        </div>

        <button type="button" onClick={handleTutorialModalOpen} className="tutorial">
          <div className="watch-tutorial">
            <MonitorPlay size={18} />
            <span>Assista a um tutorial</span>
          </div>
        </button>

        <VideoModal
          isOpen={isTutorialModalOpen}
          toggle={handleTutorialModalOpen}
          videoUrl="https://www.youtube.com/embed/yUkZhr5uf08"
        />
      </div>

      {page === 'details' && (
        <div className="datepicker">
          <NewDatePicker
            startDate={storePeriod.startDate}
            endDate={storePeriod.endDate}
            onDate={onDate}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
          />
        </div>
      )}
    </div>
  );
};

export default memo(Header);
