import React from 'react';
import { Card } from 'reactstrap';
import { numberBrFormatter } from '../../../../utils/utils';
import './styles.scss';

const CardInfo = ({ metrics }) => {
  return (
    <Card className="card-info-content">
      <div className="d-flex card-info-body">
        <div className="info-group">
          <div className="card-content">
            <div className="d-flex content-header">
              <div className="icon-badge">
                <i className="fas fa-ticket-alt" />
              </div>
              <span>Ticket Médio</span>
            </div>
            <div className="content-value d-flex">
              <span>
                <small>R$ </small>
                {numberBrFormatter(metrics.averageTicket.amount, 2)}
              </span>
            </div>
          </div>
          <div className="card-content">
            <div className="d-flex content-header">
              <div className="icon-badge">
                <i className="fas fa-cart-arrow-down" />
              </div>
              <span>Total de Pedidos</span>
            </div>
            <div className="content-value d-flex">
              <span>{numberBrFormatter(metrics.totalOrders.amount, 0)}</span>
            </div>
          </div>
        </div>

        <div className="info-group">
          <div className="card-content">
            <div className="d-flex content-header">
              <div className="icon-badge">
                <i className="fas fa-chart-pie" />
              </div>
              <span>Margem de Lucro</span>
            </div>
            <div className="content-value">
              <span>{numberBrFormatter(metrics.profitMargin.percentage)}%</span>
            </div>
          </div>

          <div className="card-content">
            <div className="d-flex content-header">
              <div className="icon-badge">
                <i className="fas fa-shopping-basket" />
              </div>
              <span>Pedidos Pendentes</span>
            </div>
            <div className="content-value d-flex">
              <span>
                <small>R$ </small>
                {numberBrFormatter(metrics.pendingOrders.amount, 2)}
              </span>
            </div>
          </div>
        </div>

        <div className="info-group">
          <div className="card-content">
            <div className="d-flex content-header">
              <div className="icon-badge">
                <i className="fas fa-rocket" />
              </div>
              <span>Custo por Aquisição (CPA)</span>
            </div>
            <div className="content-value">
              <span>
                <small>R$ </small>
                {numberBrFormatter(metrics.cpa.amount, 2)}
              </span>
            </div>
          </div>

          <div className="card-content">
            <div className="d-flex content-header">
              <div className="icon-badge">
                <i className="fas fa-file-import" />
              </div>
              <span>ROI</span>
            </div>
            <div className="content-value">
              <span>{numberBrFormatter(metrics.roi.value, 2)}x</span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CardInfo;
