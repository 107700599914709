import React, { useCallback } from 'react';
import { UncontrolledPopover, PopoverBody, Row, Col, Button, Input, Form } from 'reactstrap';
import useForm from 'react-hook-form';

const Filter = ({ handleFilter }) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = useCallback(
    customSpendFilters => {
      if (customSpendFilters.status === 'null') customSpendFilters.status = null;
      if (customSpendFilters.period === 'null') customSpendFilters.period = null;
      if (customSpendFilters.type === 'null') customSpendFilters.type = null;
      if (customSpendFilters.category === 'null') customSpendFilters.category = null;

      handleFilter(customSpendFilters);
    },
    [handleFilter],
  );

  return (
    <div className="mr-3">
      <Button className="text-blue-marine" id="filter" type="button">
        Filtro
        <i className="ml-2 fa fa-filter" />
      </Button>

      <UncontrolledPopover placement="left" className="w-100" flip target="filter">
        <PopoverBody className="py-4">
          <Form onSubmit={handleSubmit(onSubmit)} role="form">
            <Row>
              <Col sm="12" md="3" className="mt-3 mt-md-0">
                <div className="">
                  <span className="font-weight-bold mb-5 mt-sm-3 mt-md-0">Status</span>
                  <Input
                    className="input-group-alternative"
                    type="select"
                    name="status"
                    id="status"
                    defaultValue="null"
                    innerRef={register}
                  >
                    <option value="null">Nenhum</option>
                    <option value="FINISHED">Finalizado</option>
                    <option value="NOT_FINISHED">Não Finalizado</option>
                  </Input>
                </div>
              </Col>
              <Col sm="12" md="3" className="mt-3 mt-md-0">
                <div className="">
                  <span className="font-weight-bold mb-1 mt-sm-3 mt-md-0">Frequência</span>
                  <Input
                    className="input-group-alternative"
                    type="select"
                    name="period"
                    id="period"
                    defaultValue="null"
                    innerRef={register}
                  >
                    <option value="null">Todas</option>
                    <option value="NONE">Uma vez</option>
                    <option value="DAILY">Diária</option>
                    <option value="WEEKLY">Semanal</option>
                    <option value="MONTHLY">Mensal</option>
                    <option value="ANNUALLY">Anual</option>
                  </Input>
                </div>
              </Col>
              <Col sm="12" md="3" className="mt-3 mt-md-0">
                <div className="">
                  <span className="font-weight-bold mb-1 mt-sm-3 mt-md-0">Tipo</span>
                  <Input
                    className="input-group-alternative"
                    type="select"
                    name="type"
                    id="type"
                    defaultValue="null"
                    innerRef={register}
                  >
                    <option value="null">Nenhum</option>
                    <option value="IN">Entrada</option>
                    <option value="OUT">Saída</option>
                  </Input>
                </div>
              </Col>
              <Col sm="12" md="3" className="mt-3 mt-md-0">
                <div className="">
                  <span className="font-weight-bold mb-1 mt-sm-3 mt-md-0">Categoria</span>
                  <Input
                    className="input-group-alternative"
                    type="select"
                    name="category"
                    id="category"
                    defaultValue="null"
                    innerRef={register}
                  >
                    <option value="null">Todas</option>
                    <option value="APP">App</option>
                    <option value="MARKETING">Marketing</option>
                    <option value="EMPLOYEE">Funcionários</option>
                    <option value="COSTS">Custos</option>
                    <option value="NONE">Nenhuma</option>
                  </Input>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="text-right">
                <Button type="submit" color="primary">
                  Buscar <i className="ml-2 fa fa-search" />
                </Button>
              </Col>
            </Row>
          </Form>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};

export default Filter;
