import React, { useState, useCallback, useEffect } from 'react';
import { Container, Card, CardHeader, CardBody, FormGroup, Label, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';

import Spinner from '../Spinner/Spinner';
import { useStoreConfigurations } from '../../hooks/AliexpressForm';
import { api, shopsApi } from '../../services/api';

import './styles.scss';

const AliexpressForm = () => {
  const { store, platform } = useParams();

  const { isLoading, storeConfigurationsData } = useStoreConfigurations({ store, platform });
  const [updateAliexpressCostToVariant, setUpdateAliexpressCostToVariant] = useState(false);
  const [storeConfiguration, setStoreConfiguration] = useState({ id: null });
  const [isUpdatingCheckbox, setIsUpdatingCheckbox] = useState(false);

  const handleCheckbox = useCallback(
    async event => {
      const { checked } = event.target;

      try {
        setIsUpdatingCheckbox(true);
        setUpdateAliexpressCostToVariant(checked);

        const requestType = storeConfiguration.id ? 'put' : 'post';

        const { data } = await api(shopsApi[platform])[requestType](
          `/api/v1/users/${platform}/stores/${store}/store-configurations/${storeConfiguration?.id || ''}`,
          {
            ...storeConfiguration,
            updateAliexpressCostToVariant: checked,
          },
        );

        setStoreConfiguration(data.storeConfiguration);
        setUpdateAliexpressCostToVariant(checked);

        setIsUpdatingCheckbox(false);
      } catch {
        setIsUpdatingCheckbox(false);

        setUpdateAliexpressCostToVariant(!checked);
      }
    },
    [platform, store, storeConfiguration],
  );

  useEffect(() => {
    if (storeConfigurationsData?.storeConfiguration) {
      setStoreConfiguration(storeConfigurationsData.storeConfiguration);
      setUpdateAliexpressCostToVariant(storeConfigurationsData.storeConfiguration.updateAliexpressCostToVariant);
    }
  }, [storeConfigurationsData]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Container fluid className="pb-8 pt-5 pt-md-8 aliexpress-form-container">
      <Card className="shadow bg-secondary card-content">
        <CardHeader className="bg-white border-0">
          <h3>Integração com Aliexpress</h3>
        </CardHeader>

        <CardBody className="card-body">
          <p className="mt-3">
            Antes de tudo instale nossa <b>extensão</b> para o google chrome no link abaixo: 👇
          </p>
          <a href="https://chrome.google.com/webstore/detail/profitfy/aidoclnkpklaaeikjeodkddifooblllp?hl=pt-BR">
            <p className="font-weight-bold"> Instalar extensão profitfy.me</p>
          </a>

          <FormGroup>
            <Label>
              <Input
                checked={updateAliexpressCostToVariant}
                type="checkbox"
                onChange={handleCheckbox}
                disabled={isUpdatingCheckbox}
              />
              Ao sincronizar os custos de produtos com AliExpress, guardar o último custo da variante conciliada em
              nossa plataforma.
            </Label>
          </FormGroup>

          <p>
            Após ter instalado, assista <b>atentamente</b> o vídeo, para que funcione corretamente.
          </p>

          <video
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/videos-tuto/video-aliexpress.mov"
            controls
            playsInline
          >
            <track default kind="captions" srcLang="en" />
          </video>
        </CardBody>
      </Card>
    </Container>
  );
};

export default AliexpressForm;
