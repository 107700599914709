import React, { memo } from 'react';

const SubHeaders = ({ columns }) => {
  return columns.map(column => {
    const props = {
      key: column.name,
      className: 'px-3',
      scope: 'col',
    };

    return <th {...props}>{column.description}</th>;
  });
};

export default memo(SubHeaders);
