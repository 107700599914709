import React, { useCallback, useEffect, useState, memo } from 'react';
import { useParams } from 'react-router-dom';

// import MawaCardIntegration from '../../../components/MawaPost/CardIntegration/CardIntegration';
import CardIntegration from './Card/Card';
import mercadoPago from '../../../assets/img/logos/mercadopago.svg';
import cielo from '../../../assets/img/logos/cielo.svg';
import appmax from '../../../assets/img/logos/appmax.svg';
import cloudFox from '../../../assets/img/logos/cloudfox.svg';
import pagseguro from '../../../assets/img/logos/pagseguro.png';
import upnid from '../../../assets/img/logos/upnid.svg';
import facebook from '../../../assets/img/logos/facebook.svg';
import aliexpress from '../../../assets/img/logos/aliexpress.svg';
import pagarme from '../../../assets/img/logos/pagarme.svg';
import doppus from '../../../assets/img/logos/doppus.svg';
import { api } from '../../../services/api';
import Spinner from '../../../components/Spinner/Spinner';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';
import NoGatewayModal from '../../../components/NoGatewayModal/NoGatewayModal';
import NoMarketingModal from '../../../components/NoMarketingModal/NoMarketingModal';

import './styles.scss';

const Integrations = () => {
  const { user } = useAuth();
  const { store, platform } = useParams();

  // const [mawaTokenObject, setMawaTokenObject] = useState({});
  // const [isLoadingMawa, setIsLoadingMawa] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [storeInfo, setStoreInfo] = useState({});
  const [isGatewaySuggestionModalOpen, setIsGatewaySuggestionModalOpen] = useState(false);
  const [isMarketingSuggestionModalOpen, setIsMarketingSuggestionModalOpen] = useState(false);

  const handleGatewaySuggestionModal = useCallback(
    () => setIsGatewaySuggestionModalOpen(!isGatewaySuggestionModalOpen),
    [isGatewaySuggestionModalOpen],
  );

  const handleMarketingSuggestionModal = useCallback(
    () => setIsMarketingSuggestionModalOpen(!isMarketingSuggestionModalOpen),
    [isMarketingSuggestionModalOpen],
  );

  const loadStore = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api('user-api').get(`/api/v1/users/stores`);

      if (response.data) {
        const filteredStore = response.data.stores.filter(innerStore => innerStore.id === store);

        setStoreInfo(filteredStore.pop());
      }
    } finally {
      setIsLoading(false);
    }
  }, [store]);

  const makeURL = link => {
    return `/${platform}/${store}/dashboard/integrations/${link}`;
  };

  // const loadMawaTokenObject = useCallback(async () => {
  //   try {
  //     setIsLoadingMawa(true);

  //     const { data } = await api('user-api').get('api/v1/users/mawa-post-token');

  //     setMawaTokenObject(data.mawaPostToken);
  //   } finally {
  //     setIsLoadingMawa(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   loadMawaTokenObject();
  // }, [loadMawaTokenObject]);

  useEffect(() => {
    loadStore();
  }, [loadStore]);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_integrations',
        type: 'screen',
      });
    }
  }, [user]);

  if (isLoading) {
    return <Spinner loading />;
  }

  return (
    <div className="main-integration">
      <NoGatewayModal isOpen={isGatewaySuggestionModalOpen} toggle={handleGatewaySuggestionModal} />
      <NoMarketingModal isOpen={isMarketingSuggestionModalOpen} toggle={handleMarketingSuggestionModal} />

      <div className="gateway-integration">
        <div className="header-wrapper">
          <h3>Gateways de Pagamentos</h3>

          <div className="suggestion-wrapper">
            <button className="suggestion-button" type="button" onClick={handleGatewaySuggestionModal}>
              Não encontrou o gateway que você usa? Nos informe
            </button>
          </div>
        </div>
        <CardIntegration
          name="Mercado Pago"
          img={mercadoPago}
          alt="Mercado Pago"
          description="Integração com o gateway de pagamento Mercado Pago"
          link={makeURL('mercado-pago')}
          isIntegrated={storeInfo?.mercadoPagoCredentialId}
        />
        <CardIntegration
          name="Cielo"
          img={cielo}
          alt="Cielo"
          description="Integração com o gateway de pagamento Cielo"
          link={makeURL('cielo')}
          isIntegrated={storeInfo?.cieloCredentialId}
        />
        <CardIntegration
          name="Appmax"
          img={appmax}
          alt="Appmax"
          description="Integração com o gateway de pagamento Appmax"
          link={makeURL('appmax')}
          isIntegrated={storeInfo?.appMaxCredentialId}
        />
        <CardIntegration
          name="CloudFox"
          img={cloudFox}
          alt="CloudFox"
          description="Integração com o gateway de pagamento CloudFox"
          link={makeURL('cloudfox')}
          isIntegrated={storeInfo?.cloudFoxCredentialId}
        />
        <CardIntegration
          name="Pagseguro"
          img={pagseguro}
          alt="Pagseguro"
          description="Integração com o gateway de pagamento Pagseguro"
          link={makeURL('pagseguro')}
          isIntegrated={storeInfo?.pagSeguroCredentialId}
        />
        <CardIntegration
          name="Upnid"
          img={upnid}
          alt="Upnid"
          description="Integração com o gateway de pagamento Upnid"
          link={makeURL('upnid')}
          isIntegrated={storeInfo?.upnidCredentialId}
        />
        <CardIntegration
          name="Pagar.me"
          img={pagarme}
          alt="Pagar.me"
          description="Integração com o gateway de pagamento Pagar.me"
          link={makeURL('pagarme')}
          isIntegrated={storeInfo?.pagarmeCredentialId}
        />
        {platform !== 'cartx' && (
          <CardIntegration
            name="Doppus"
            img={doppus}
            alt="Doppus"
            description="Integração com o gateway de pagamento Doppus"
            link={makeURL('doppus')}
            isIntegrated={storeInfo?.hubSaleCredentialId}
          />
        )}
      </div>
      <div className="marketing-integration">
        <div className="header-wrapper">
          <h3>Marketing</h3>

          <div className="suggestion-wrapper">
            <button className="suggestion-button" type="button" onClick={handleMarketingSuggestionModal}>
              Não encontrou o marketing que você usa? Nos informe
            </button>
          </div>
        </div>

        <CardIntegration
          name="Facebook"
          img={facebook}
          alt="Facebook"
          description="Integração com o Facebook"
          link={makeURL('facebook')}
        />
      </div>
      <div className="other-integration">
        <h3>Outros</h3>
        <CardIntegration
          name="Aliexpress"
          img={aliexpress}
          alt="Aliexpress"
          description="Integração com o Aliexpress"
          link={makeURL('aliexpress')}
        />
        {/* <MawaCardIntegration mawaTokenObject={mawaTokenObject} setMawaTokenObject={setMawaTokenObject} /> */}
      </div>
    </div>
  );
};

export default memo(Integrations);
