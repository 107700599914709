import React, { memo, useState, useCallback, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';

import Header from '../../../components/ProductDetails/Header/Header';
import DetailsPage from '../../../components/ProductDetails/DetailsPage/DetailsPage';
import MarketingOnboarding from '../../../components/ProductDetails/MarketingOnboarding/MarketingOnboarding';
import { useFacebookMarketing } from '../../../hooks/FacebookMarketing';
import { shopsApi, makeSearchRequest } from '../../../services/api';
import Spinner from '../../../components/Spinner/Spinner';
import useQuery from '../../../hooks/Query';
import Video from '../../../components/Video/Video';
import usePersistedState from '../../../hooks/PersistedState';
import { useProductDetail, useProductCampaigns } from '../../../hooks/ProductDetails';
import CampaignsTablePage from '../../../components/ProductDetails/CampaignsTablePage/CampaignsTablePage';
import NoActivatedCampaignModal from '../../../components/ProductDetails/NoActivatedCampaignModal/NoActivatedCampaignModal';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';

import './styles.scss';

const ProductDetails = () => {
  const { user } = useAuth();
  const { store, platform, productId } = useParams();
  const { loadProfiles, isLoadingProfiles } = useFacebookMarketing();
  const query = useQuery();
  const [isProductDetailsVideoModalOpen, setIsProductDetailsVideoModalOpen] = usePersistedState(
    'isProductDetailsVideoModalOpen',
    true,
  );
  const [hasCompletedCampaignsOnboarding, setHasCompletedCampaignsOnboarding] = usePersistedState(
    'hasCompletedCampaignsOnboarding',
    false,
  );

  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false);
  const [campaignNameSearch, setCampaignNameSearch] = useState('');
  const [campaignsPage, setCampaignsPage] = useState(0);
  const [campaignsPageCount, setCampaignsPageCount] = useState(1);
  const [campaigns, setCampaigns] = useState([]);
  const [page, setPage] = useState('details');
  const [productDetailPeriod, setProductDetailPeriod] = useState({ startDate: moment(), endDate: moment() });
  const [selectedDate, setSelectedDate] = useState('today');
  const [isActivatedCampaignModalOpen, setIsActivatedCampaignModalOpen] = useState(false);

  const { productDetailData, isLoadingProductDetail } = useProductDetail({
    productId,
    startDate: productDetailPeriod.startDate,
    endDate: productDetailPeriod.endDate,
  });

  const { campaignsData, isLoadingInitialCampaigns } = useProductCampaigns();

  const onboarding = useMemo(() => query.get('campaigns'), [query]);

  const searchFetch = useMemo(
    () =>
      makeSearchRequest({
        api: shopsApi[platform],
      }),
    [platform],
  );

  const handleActivatedCampaignModal = useCallback(
    () => setIsActivatedCampaignModalOpen(!isActivatedCampaignModalOpen),
    [isActivatedCampaignModalOpen],
  );

  const onDate = useCallback(({ startDate, endDate }) => {
    if (!startDate || !endDate) return;

    if (!startDate.isValid() || !endDate.isValid()) return;

    setProductDetailPeriod({ startDate, endDate });
  }, []);

  const loadCampaigns = useCallback(
    async ({ nameSearch, page }) => {
      const limit = 10;
      setIsLoadingCampaigns(true);
      let campaigns = [];

      try {
        const data = await searchFetch(
          `api/v1/users/${platform}/stores/${store}/products/${productId}/facebook/campaigns?page=${
            page + 1
          }&name=${nameSearch}&limit=${limit}`,
        );

        if (data) {
          setCampaigns(data.facebookCampaigns);
          setCampaignsPageCount(data.numberOfPages);
          campaigns = data.facebookCampaigns;
        }
      } finally {
        setIsLoadingCampaigns(false);
      }

      return campaigns;
    },
    [store, platform, productId, searchFetch],
  );

  const handlePaginationChange = useCallback(
    page => {
      setCampaignsPage(page.selected);

      loadCampaigns({ nameSearch: campaignNameSearch, page: page.selected });
    },
    [loadCampaigns, campaignNameSearch],
  );

  const handleVideoModal = useCallback(
    () => setIsProductDetailsVideoModalOpen(false),
    [setIsProductDetailsVideoModalOpen],
  );

  const handleCampaignsSearch = useCallback(
    async event => {
      const { value } = event.target;

      setCampaignsPage(0);
      setCampaignNameSearch(value);

      loadCampaigns({ nameSearch: value, page: campaignsPage });
    },
    [loadCampaigns, campaignsPage],
  );

  const handlePageChange = useCallback(selectedPage => setPage(selectedPage), []);

  useEffect(() => {
    loadProfiles();
  }, [loadProfiles]);

  useEffect(() => {
    if (onboarding) {
      setPage('campaigns');
    }
  }, [onboarding]);

  useEffect(() => {
    if (campaignsData) {
      setCampaigns(campaignsData.facebookCampaigns);
      setCampaignsPageCount(campaignsData.numberOfPages);
    }
  }, [campaignsData]);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_product_details',
        type: 'screen',
      });
    }
  }, [user]);

  if (isLoadingInitialCampaigns || isLoadingProfiles || isLoadingProductDetail) {
    return <Spinner loading />;
  }

  return (
    <div className="product-details-container">
      <NoActivatedCampaignModal isOpen={isActivatedCampaignModalOpen} toggle={handleActivatedCampaignModal} />

      <Video
        thumbnail="https://profitfy-media.s3-us-west-2.amazonaws.com/Thumbnails/thumbnail-profitfy.me3-min.png"
        thumbnailAlt="Profitfy.me Análise de Produto"
        videoUrl="https://www.youtube.com/embed/yUkZhr5uf08"
        title="Como utilizar a Análise de Produtos"
        duration="2:57"
        isOpen={isProductDetailsVideoModalOpen}
        toggle={handleVideoModal}
      />
      <Header
        handlePageChange={handlePageChange}
        page={page}
        storePeriod={productDetailPeriod}
        onDate={onDate}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        campaigns={campaigns}
        productDetailData={productDetailData}
      />

      {page === 'details' ? (
        <DetailsPage
          product={productDetailData?.product}
          profit={productDetailData?.profit}
          facebookInsights={productDetailData?.facebookInsights}
          setPage={setPage}
          campaigns={campaigns}
          storePeriod={productDetailPeriod}
        />
      ) : (
        <>
          {campaigns.length === 0 ? (
            <MarketingOnboarding
              loadCampaigns={loadCampaigns}
              isLoadingCampaigns={isLoadingCampaigns}
              setHasCompletedCampaignsOnboarding={setHasCompletedCampaignsOnboarding}
              hasCompletedCampaignsOnboarding={hasCompletedCampaignsOnboarding}
              campaigns={campaigns}
              handleActivatedCampaignModal={handleActivatedCampaignModal}
            />
          ) : (
            <CampaignsTablePage
              campaigns={campaigns}
              handlePaginationChange={handlePaginationChange}
              pageCount={campaignsPageCount}
              page={campaignsPage}
              handleCampaignsSearch={handleCampaignsSearch}
              isLoadingCampaigns={isLoadingCampaigns}
              loadCampaigns={loadCampaigns}
              hasCompletedCampaignsOnboarding={hasCompletedCampaignsOnboarding}
            />
          )}
        </>
      )}
    </div>
  );
};

export default memo(ProductDetails);
