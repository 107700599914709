import React, { memo, useState } from 'react';

import CurrencyStep from '../CurrencyStep/CurrencyStep';
import AverageCostStep from '../AverageCostStep/AverageCostStep';
import IofStep from '../IofStep/IofStep';

import './styles.scss';

const ProductCostOnboarding = ({
  selectedIof,
  selectedCurrency,
  selectedPercentage,
  setSelectedIof,
  setSelectedCurrency,
  setSelectedPercentage,
  handleConfirmButton,
  isProcessing,
  isProcessingError,
}) => {
  const [selectedStep, setSelectedStep] = useState('currency');
  const [userStep, setUserStep] = useState('currency');

  return (
    <div className="product-cost-onboarding-wrapper">
      <CurrencyStep
        selectedStep={selectedStep}
        setSelectedStep={setSelectedStep}
        setSelectedCurrency={setSelectedCurrency}
        selectedCurrency={selectedCurrency}
        userStep={userStep}
        setUserStep={setUserStep}
      />
      <div className={`step-connection ${userStep !== 'currency' ? 'completed' : undefined}`} />
      <AverageCostStep
        selectedStep={selectedStep}
        setSelectedStep={setSelectedStep}
        userStep={userStep}
        setUserStep={setUserStep}
        selectedPercentage={selectedPercentage}
        setSelectedPercentage={setSelectedPercentage}
      />
      <div className={`step-connection ${userStep === 'iof' ? 'completed' : undefined}`} />
      <IofStep
        selectedStep={selectedStep}
        setSelectedStep={setSelectedStep}
        userStep={userStep}
        setUserStep={setUserStep}
        selectedIof={selectedIof}
        setSelectedIof={setSelectedIof}
        handleConfirmButton={handleConfirmButton}
        isProcessing={isProcessing}
        isProcessingError={isProcessingError}
      />
    </div>
  );
};

export default memo(ProductCostOnboarding);
