import React, { memo } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';

import './styles.scss';

const ProcessingDataModal = ({ isOpen, toggle }) => {
  return (
    <Modal className="processing-data-modal" isOpen={isOpen} toggle={toggle} centered>
      <ModalBody className="modal-body">
        <span>Calculando histórico</span>
        <p>
          Pronto, processamos seus dados de <strong>hoje e de ontem</strong>. Agora estamos calculando seus{' '}
          <strong>dados de 30 dias</strong>, você pode usar a <strong>plataforma normalmente</strong> enquanto estamos
          calculando. <strong>Assim que estiver tudo pronto, vamos te enviar um e-mail.</strong>.
        </p>
        <Button color="primary" onClick={toggle}>
          Entendi
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default memo(ProcessingDataModal);
