import React, { memo } from 'react';
import { CardHeader, Row } from 'reactstrap';

const TableHeader = () => {
  return (
    <CardHeader className="border-0">
      <Row className="d-flex justify-content p-2">
        <div>
          <h3 className="mb-0">Facebook Ads - Break Even de uma venda</h3>
          <p className="text-muted text-sm mb-0">Veja quanto você pode gastar para alcançar seu break even.</p>
        </div>
      </Row>
    </CardHeader>
  );
};

export default memo(TableHeader);
