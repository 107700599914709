import moment from 'moment-timezone';

const colors = [
  'rgba(58, 236, 159, 0.8)',
  'rgba(48, 64, 155, 0.86)',
  'rgba(166, 132, 248, 0.93)',
  'rgba(253, 139, 73, 0.55)',
  'rgba(255, 214, 0, 0.49)',
];

const parseBarData = data => {
  const parsedData = data.dates.map((date, i) => {
    const parsedDate = moment(date).format('DD/MM');

    return {
      notParsedDate: date,
      day: parsedDate,
      Faturamento: data.revenue[i].value,
      'Custo dos Produtos': data.cogs[i].value,
      Marketing: data.marketing[i].value,
      'Taxa de Transações': data.transactionTax[i].value,
      'Lucro Líquido': data.profit[i].value,
    };
  });

  return parsedData;
};

const parseLineData = data => {
  const parsedData = [
    {
      id: 'Lucro Líquido',
      data: data.dates.map((date, i) => ({
        notParsedDate: date,
        x: moment(date).format('DD/MM'),
        y: data.profit[i].value,
      })),
    },
    {
      id: 'Taxa de Transações',
      data: data.dates.map((date, i) => ({
        notParsedDate: date,
        x: moment(date).format('DD/MM'),
        y: data.transactionTax[i].value,
      })),
    },
    {
      id: 'Marketing',
      data: data.dates.map((date, i) => ({
        notParsedDate: date,
        x: moment(date).format('DD/MM'),
        y: data.marketing[i].value,
      })),
    },
    {
      id: 'Custo dos Produtos',
      data: data.dates.map((date, i) => ({
        notParsedDate: date,
        x: moment(date).format('DD/MM'),
        y: data.cogs[i].value,
      })),
    },
    {
      id: 'Faturamento',
      data: data.dates.map((date, i) => ({
        notParsedDate: date,
        x: moment(date).format('DD/MM'),
        y: data.revenue[i].value,
      })),
    },
  ];

  return parsedData;
};

const parsedCustomSpendsDataOut = customSpends => {
  const parsedData = [
    {
      id: 'App',
      label: 'App',
      value: customSpends.appOut,
      color: 'bg-purple-9',
    },
    {
      id: 'Marketing',
      label: 'Marketing',
      value: customSpends.marketingOut,
      color: 'bg-purple-7',
    },
    {
      id: 'Funcionários',
      label: 'Funcionários',
      value: customSpends.employeeOut,
      color: 'bg-purple-5',
    },
    {
      id: 'Custos',
      label: 'Custos',
      value: customSpends.costOut,
      color: 'bg-purple-3',
    },
    {
      id: 'Nenhum',
      label: 'Nenhum',
      value: customSpends.noneOut,
      color: 'bg-purple-1',
    },
  ];

  return parsedData;
};

const parsedCustomSpendsDataIn = customSpends => {
  const parsedData = [
    {
      id: 'App',
      label: 'App',
      value: customSpends.appIn,
      color: 'bg-purple-9',
    },
    {
      id: 'Funcionários',
      label: 'Funcionários',
      value: customSpends.employeeIn,
      color: 'bg-purple-5',
    },
    {
      id: 'Custos',
      label: 'Custos',
      value: customSpends.costIn,
      color: 'bg-purple-3',
    },
    {
      id: 'Nenhum',
      label: 'Nenhum',
      value: customSpends.noneIn,
      color: 'bg-purple-1',
    },
  ];

  return parsedData;
};

const parseProjectionData = data => {
  const parsedData = data.map(projectionDate => {
    const parsedDate = moment(projectionDate.day).format('DD/MM');

    return {
      day: parsedDate,
      notParsedDate: projectionDate.day,
      revenue: projectionDate.revenue,
      projection:
        projectionDate.projection - projectionDate.revenue < 0 ? 0 : projectionDate.projection - projectionDate.revenue,
    };
  });

  return parsedData;
};

const parseTooltipData = (data, notParsedDate) => {
  const index = data.dates.indexOf(notParsedDate);
  const [profitColor, transactionTaxColor, marketingColor, cogsColor, revenueColor] = colors;

  return {
    data: {
      date: moment(data.dates[index]).format('DD/MM'),
      revenue: data.revenue[index].value,
      cogs: data.cogs[index].value,
      marketing: data.marketing[index].value,
      transactionTax: data.transactionTax[index].value,
      profit: data.profit[index].value,
    },
    color: {
      revenue: revenueColor,
      cogs: cogsColor,
      marketing: marketingColor,
      transactionTax: transactionTaxColor,
      profit: profitColor,
    },
  };
};

const parseProjectionTooltipData = (parsedData, notParsedDate) => {
  const projectionData = parsedData.filter(projection => projection.day === notParsedDate);

  return projectionData[0];
};

const getTransactionTaxesColor = bar =>
  ({
    total: '#e9ecef',
    gateway: '#ff22ff',
    checkout: '#ff22ff',
    tax: '#ff22ff',
  }[bar.id]);

const getProjectionBilletsColor = bar =>
  ({
    revenue: '#ff8944',
    projection: '#CACCCF',
  }[bar.id]);

export {
  parseBarData,
  parseLineData,
  parseTooltipData,
  parseProjectionData,
  parseProjectionTooltipData,
  parsedCustomSpendsDataOut,
  parsedCustomSpendsDataIn,
  getTransactionTaxesColor,
  getProjectionBilletsColor,
  colors,
};
