import React, { useState, memo, useCallback } from 'react';
import { Megaphone, Plus, Question } from 'phosphor-react';
import { Button, Tooltip } from 'reactstrap';
import moment from 'moment-timezone';

import MarketingModal from '../../MarketingModal/MarketingModal';
import { numberBrFormatter } from '../../../../utils/utils';
import CustomSpendsWarn from '../../../CustomSpendsWarn/CustomSpendsWarn';

import './styles.scss';

const Marketing = ({ mainCards, mutateStoreData }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCustomSpendsWarnOpen, setIsCustomSpendsWarnOpen] = useState(false);

  const handleIsCustomSpendsWarnOpen = useCallback(
    () => setIsCustomSpendsWarnOpen(!isCustomSpendsWarnOpen),
    [isCustomSpendsWarnOpen],
  );

  const handleIsModalOpen = useCallback(() => setIsModalOpen(!isModalOpen), [isModalOpen]);

  const handleTooltip = useCallback(() => setIsTooltipOpen(!isTooltipOpen), [isTooltipOpen]);

  return (
    <div className="card-details-marketing">
      <CustomSpendsWarn isOpen={isCustomSpendsWarnOpen} toggle={handleIsCustomSpendsWarnOpen} />

      <MarketingModal
        toggle={handleIsModalOpen}
        isOpen={isModalOpen}
        customSpend={{
          id: null,
          description: null,
          amount: null,
          startDate: moment(),
          active: true,
          period: null,
        }}
        title="Insira um novo marketing manual"
        handleIsWarningModalOpen={handleIsCustomSpendsWarnOpen}
        mutateStoreData={mutateStoreData}
      />
      <div className="header">
        <span>MARKETING</span>
        <div>
          <Megaphone size={16} weight="bold" />
        </div>
      </div>
      <div className="body">
        <div className="value-details">
          <div className="value">
            <span>Ads</span>
            <span>
              <small>R$</small>
              {numberBrFormatter(mainCards.adAccountsSpendAmount, 2)}
            </span>
          </div>

          <div className="value">
            <span>Ads Manual</span>
            <span>
              <small>R$</small>
              {numberBrFormatter(mainCards.marketingCustomSpendAmount, 2)}
            </span>
          </div>

          <hr />

          <div className="total-wrapper">
            <span>TOTAL</span>
            <span>
              <small>R$</small>
              {numberBrFormatter(mainCards.marketing, 2)}
            </span>
          </div>
        </div>

        <div className="content">
          <div className="text">
            <span id="custom-marketing-tooltip">
              Marketing Adicional <Question size={16} />
            </span>
            <span>Insira custos de marketing de plataformas não integradas</span>
            <Tooltip
              placement="bottom"
              isOpen={isTooltipOpen}
              toggle={handleTooltip}
              target="custom-marketing-tooltip"
              style={{ maxWidth: '205px', padding: '8px', textAlign: 'left' }}
            >
              Aqui você pode lançar custos adicionais como influenciadores, google ads, taboola, entre outros.
            </Tooltip>
          </div>

          <Button onClick={handleIsModalOpen}>
            <span>Adicionar</span>
            <Plus size={16} />
          </Button>
        </div>
      </div>

      <div className="gradient-border-detail">
        <div />
      </div>
    </div>
  );
};

export default memo(Marketing);
