import React, { memo, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { Info } from 'phosphor-react';

import './styles.scss';

const ConfirmSyncModal = ({ isOpen, toggle, onConfirm }) => {
  const handleConfirmButtonClick = useCallback(() => {
    toggle();

    onConfirm();
  }, [toggle, onConfirm]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="confirm-sync-modal">
      <ModalHeader className="header">
        <Info size={32} />
        <span>Aviso</span>
      </ModalHeader>
      <ModalBody className="body">
        <span>Deseja sincronizar suas campanhas?</span>
        <p>A sincronização das campanhas geralmente levam alguns minutos até ser concluída.</p>

        <div className="buttons-wrapper">
          <button type="button" className="cancel-button" onClick={toggle}>
            Voltar
          </button>
          <Button color="primary" onClick={handleConfirmButtonClick}>
            Sincronizar
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default memo(ConfirmSyncModal);
