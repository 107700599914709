import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import profitfyLogo from '../../../../assets/img/brand/logo-white.svg';
import appmaxLogo from '../../../../assets/img/brand/appmax/appmax-logo.png';
import plusImage from '../../../../assets/img/brand/appmax/plus.svg';
import { useAppmax } from '../../../../hooks/Appmax';

import './styles.scss';

const Appmax = () => {
  const history = useHistory();
  const { isAppmaxPlanSuccess } = useAppmax();

  const handleNextStep = useCallback(() => {
    history.push('/stores');
  }, [history]);

  useEffect(() => {
    if (!isAppmaxPlanSuccess) {
      history.push('/subscribe/appmax');
    }
  }, [history, isAppmaxPlanSuccess]);

  return (
    <div className="subscribe-success-appmax-container">
      <div className="appmax-header-wrapper">
        <div className="appmax-header">
          <img className="profitfy" src={profitfyLogo} alt="Profitfy.me" />
          <img className="plus" src={plusImage} alt="Profitfy.me" />
          <img className="appmax" src={appmaxLogo} alt="Profitfy.me Appmax" />
        </div>
      </div>

      <div className="content">
        <div className="success-content">
          <i className="far fa-check-circle" />
          <span>Plano assinado com sucesso</span>
        </div>

        <h1>Pronto! Agora você poderá desfrutar da profitfy de forma gratuita.</h1>

        <h2>Você poderá continuar a utilizar a profitfy gratuitamente enquanto:</h2>

        <ul>
          <li>
            <p>
              O participante atinja um faturamento mensal <strong>igual ou superior a R$ 5.000,00</strong>, com vendas
              processadas via cartão de crédito dentro da plataforma;
            </p>
          </li>
          <li>
            <p>
              Enquanto o participante continuar utilizando os{' '}
              <strong>serviços das duas empresas simultaneamente;</strong>
            </p>
          </li>
        </ul>

        <button className="subscribe-button" type="button" onClick={handleNextStep}>
          Acessar dashboard
        </button>
      </div>
    </div>
  );
};

export default Appmax;
