import React from 'react';
import { Badge, Button, Card, CardBody, CardHeader } from 'reactstrap';
import { useAuth } from '../../../../hooks/User';
import Spinner from '../../../Spinner/Spinner';
import { getCardBrandImage, getStatusColor, parseStatusText } from '../../../../utils/subscribeUtils';
import { api } from '../../../../services/api';
import './styles.scss';

const FreeSubscribe = ({ handleOpenTypeForm, toggleCreditCard, togglePlans }) => {
  const { user } = useAuth();

  const [isLoading, setIsLoading] = React.useState(false);
  const [card, setCard] = React.useState({
    lastDigits: '0000',
    brand: null,
  });

  const loadData = React.useCallback(async () => {
    try {
      setIsLoading(true);

      const {
        data: { card },
      } = await api('user-api').get(`api/v1/users/cards/${user.subscription.cardId}`);

      setCard(card);
    } finally {
      setIsLoading(false);
    }
  }, [user.subscription.cardId]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Card className="shadow user-plan">
      {isLoading ? (
        <Spinner loading={isLoading} />
      ) : (
        <>
          <CardHeader className="d-flex justify-content-between">
            <h3 className="mb-0">Meu plano</h3>
            <div>
              <Badge color={getStatusColor('free')}>
                <span className="text-lg text-capitalize">{parseStatusText('free')}</span>
              </Badge>
            </div>
          </CardHeader>
          <CardBody className="p-0">
            <div className="bg-gradient-light-dark-100 p-3 px-md-5 pt-md-3">
              <div className="d-flex justify-content-between align-items-center">
                <span className="text-uppercase text-muted text-sm ls-1">Plano Atual</span>
                <div>
                  <Button className="btn-link border-0 text-primary" onClick={togglePlans}>
                    <span> Mudar de Plano</span>
                    <i className="fa fa-pen" />
                  </Button>
                </div>
              </div>
              <p className="mb-0 font-weight-bold text-uppercase text-lg">Sob demanda</p>
              <p className="mt-1 text-md">
                Será cobrado <strong>R$ 4,99</strong> a cada <strong>R$ 1.000,00</strong> de faturamento.
              </p>
              <div className="mt-5 ml-0 cancel-button-container">
                <Button className="cancel-button btn-link text-muted" onClick={handleOpenTypeForm}>
                  <span>Cancelar</span>
                </Button>
              </div>
            </div>
            <div className="bg-gradient-light-dark-200 px-5 py-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <img src={getCardBrandImage(card.brand)} alt="card" width="35px" />
                  <p className="text-uppercase font-weight-bold text-blue-marine ls-1 mb-0 ml-3">
                    ***************{card.lastDigits}
                  </p>
                </div>
                <Button className="btn-link border-0 text-blue text-right" onClick={toggleCreditCard}>
                  <span> Alterar</span>
                </Button>
              </div>
            </div>
          </CardBody>
        </>
      )}
    </Card>
  );
};

export default React.memo(FreeSubscribe);
