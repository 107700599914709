import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { WarningCircle } from 'phosphor-react';

import { shopsApi, makeSearchRequest, api } from '../../../services/api';
// import ImportCSV from './ImportCSV/ImportCSV';
import Header from '../../../components/ProductCost/EditProductCostTable/Header/Header';
import Body from '../../../components/ProductCost/EditProductCostTable/Body/Body';
import Footer from '../../../components/ProductCost/EditProductCostTable/Footer/Footer';
import { percentageInfoUtils } from '../../../utils/percentageInfo';
import usePersistedState from '../../../hooks/PersistedState';
import ProcessingData from '../../../components/ProductCost/ProcessingData/ProcessingData';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';

import './styles.scss';

const ProductCost = () => {
  const { user } = useAuth();
  const { store, platform } = useParams();
  const [processingStatus, setProcessingStatus] = usePersistedState('productCostProcessingStatus');
  const [isConvertCurrencyModalOpen, setIsConvertCurrencyModalOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [productNameSearch, setProductNameSearch] = useState('');
  const [products, setProducts] = useState([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [period] = useState({
    startDate: moment().subtract(2, 'days'),
    endDate: moment(),
  });
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [selectedPercentage, setSelectedPercentage] = useState(4);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isProcessingError, setIsProcessingError] = useState(false);

  const searchFetch = useMemo(
    () =>
      makeSearchRequest({
        api: shopsApi[platform],
      }),
    [platform],
  );

  const loadProducts = useCallback(async () => {
    const limit = 10;
    setIsLoadingProducts(true);

    const data = await searchFetch(
      `api/v1/users/${platform}/stores/${store}/products?page=${
        page + 1
      }&title=${productNameSearch}&limit=${limit}&startDate=${period.startDate.format(
        'YYYY-MM-DD',
      )}&endDate=${period.startDate.format('YYYY-MM-DD')}`,
    );

    if (data) {
      setPageCount(data.numberOfPages);
      setProducts(data.products);
      setIsLoadingProducts(false);
    }
  }, [page, platform, productNameSearch, searchFetch, store, period]);

  const onPageChange = useCallback(page => {
    setPage(page.selected);
  }, []);

  const getQueryStatus = useCallback(
    notification => {
      const interval = setInterval(async () => {
        try {
          const { data } = await api(shopsApi[platform]).get(
            `/api/v1/users/${platform}/stores/${store}/variants/calculate/cost/notification/${notification.id}`,
          );

          notification = data.variantCalculateNotification;

          if (notification.status === 'FAILED') {
            clearInterval(interval);
            setIsProcessing(false);
            setIsProcessingError(true);
            setProcessingStatus(notification);
          }

          if (notification.status === 'SUCCESS') {
            clearInterval(interval);
            setIsProcessing(false);
            setProcessingStatus(notification);
            await loadProducts();
          }
        } catch {
          setIsProcessingError(true);
          setProcessingStatus(null);
        }
      }, 10000);
    },
    [store, platform, setProcessingStatus, loadProducts],
  );

  const handleConfirmButton = useCallback(async () => {
    const body = {
      currency: selectedCurrency,
      percentageOfCost: percentageInfoUtils.getPercentageInfo(selectedPercentage).value,
    };

    try {
      setIsProcessing(true);

      const { data } = await api(shopsApi[platform]).post(
        `/api/v1/users/${platform}/stores/${store}/variants/calculate/cost`,
        body,
      );

      if (data?.variantCalculateNotification.status === 'PENDING') {
        setProcessingStatus(data?.variantCalculateNotification);

        return;
      }

      setIsProcessing(false);
    } catch {
      setIsProcessingError(true);
      setIsProcessing(false);
    }
  }, [setProcessingStatus, platform, selectedCurrency, selectedPercentage, store]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  useEffect(() => {
    if (processingStatus?.status === 'PENDING') {
      setIsProcessing(true);
      getQueryStatus(processingStatus);
    }
  }, [getQueryStatus, processingStatus]);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_cogs',
        type: 'screen',
      });
    }
  }, [user]);

  return (
    <div className="edit-product-cost-container">
      {isProcessingError && (
        <div className="processing-error-alert">
          <WarningCircle size={24} weight="fill" />

          <span>
            Ocorreu um imprevisto ao processar seus dados. Tente novamente{' '}
            <button type="button" onClick={() => setIsConvertCurrencyModalOpen(true)}>
              clicando aqui
            </button>
          </span>
        </div>
      )}

      {isProcessing ? (
        <ProcessingData />
      ) : (
        <>
          <Header
            setProductNameSearch={setProductNameSearch}
            onPageChange={onPageChange}
            loadProducts={loadProducts}
            setSelectedCurrency={setSelectedCurrency}
            selectedPercentage={selectedPercentage}
            setSelectedPercentage={setSelectedPercentage}
            handleConfirmButton={handleConfirmButton}
            setIsConvertCurrencyModalOpen={setIsConvertCurrencyModalOpen}
            isConvertCurrencyModalOpen={isConvertCurrencyModalOpen}
          />
          <Body isLoadingProducts={isLoadingProducts} products={products} />
          <Footer page={page} onPageChange={onPageChange} pageCount={pageCount} />
        </>
      )}
    </div>
  );
};

export default memo(ProductCost);
