import React, { memo, useCallback, useEffect } from 'react';
import moment from 'moment-timezone';
import jwt from 'jsonwebtoken';
import { useLocation } from 'react-router-dom';
import _refiner from 'refiner-js';
import TagManager from 'react-gtm-module';

import { getToken, logout } from './services/auth';
import { useAuth } from './hooks/User';
import ServiceWorkerWrapper from './components/ServiceWorkerWrapper/ServiceWorkerWrapper';
import usePersistedState from './hooks/PersistedState';
import { Routes } from './routes/routes';
import CookiesUsage from './components/CookiesUsage';
import { updateCustomerXTracking } from './utils/customerXTracking';
import { sendCustomAttributesToCustomerX, sendNPSToCustomerX } from './utils/refinerUtils';
import OnboardingModal from './components/OnboardingModal/OnboardingModal';

import './App.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-phone-input-2/lib/style.css';
import './assets/scss/react-phone-overrides.scss';

const App = () => {
  window.$crisp.push(['set', 'session:data', [[['app_version', `${process.env.REACT_APP_VERSION}`]]]]);

  const { user, loadUser } = useAuth();
  const [lastSyncUser, setLastSyncUser] = usePersistedState('syncUser', moment().format('DD-MM-YYYY'));

  const location = useLocation();

  const trackingLoggingIn = useCallback(async () => {
    updateCustomerXTracking({ userId: user.id, email: user.email, identifier: 'has_logged_in', type: 'login' });

    TagManager.dataLayer({
      dataLayer: {
        event: 'login',
        userId: user.id,
      },
    });
  }, [user]);

  useEffect(() => {
    if (user) {
      trackingLoggingIn();
    }
  }, [user, trackingLoggingIn]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  useEffect(() => {
    if (moment(lastSyncUser).isBefore(moment().format('DD-MM-YYYY'))) {
      setLastSyncUser(moment().format('DD-MM-YYYY'));
      loadUser();
    }
  }, [lastSyncUser, setLastSyncUser, loadUser]);

  useEffect(() => {
    const authenticationToken = getToken();

    if (authenticationToken && jwt.decode(authenticationToken)) {
      const jwtExpirationDate = jwt.decode(authenticationToken).exp;

      if (Date.now() < jwtExpirationDate) {
        logout();

        window.location.href = 'https://dashboard.profitfy.me';
      }
    }
  });

  React.useEffect(() => {
    if (user) {
      window.$crisp.push(['set', 'user:email', [`${user.email}`]]);

      window.$crisp.push(['set', 'user:nickname', [`${user.firstName} ${user.lastName}`]]);

      window.$crisp.push([
        'set',
        'session:data',
        [
          [
            ['user_id', `${user.id}`],
            ['name', `${user.firstName} ${user.lastName}`],
            ['email', `${user.email}`],
            ['plan', `${user.subscription?.planName || null}`],
            ['updated_at', `${user.updatedAt}`],
            ['signed_up_at', `${user.createdAt}`],
            ['phone', `${user.phone}`],
            ['company_name', `${user.companyName}`],
            ['monthly_revenue', `${user.monthlyRevenue}`],
            ['document', `${user.cnpj || user.cpf}`],
            ['payment_status', `${user.subscription?.status || null}`],
            ['last_payment_status', `${user.subscription?.lastStatus || null}`],
            ['canceled_at', `${user.subscription?.canceledAt || null}`],
            ['plan_period_end', `${user.subscription?.currentPeriodEnd || null}`],
            ['plan_period_start', `${user.subscription?.currentPeriodStart || null}`],
          ],
        ],
      ]);
    }
  }, [user]);

  React.useEffect(() => {
    window.$crisp.push([
      'set',
      'session:data',
      [[['current_page', `https://dashboard.profitfy.me${location?.pathname}`]]],
    ]);
  }, [location]);

  _refiner('setProject', 'fb7bb6e0-fed1-11eb-ab7f-058278e007dd');
  _refiner('identifyUser', {
    id: user?.id,
    email: user?.email,
    name: `${user?.firstName} ${user?.lastName}`,
    plan: user?.subscription?.planName,
    signed_up_at: user?.createdAt,
    phone: user?.phone,
  });

  _refiner('onComplete', async (formId, data) => {
    if (data?.nps) {
      await sendNPSToCustomerX({
        nps: data.nps,
        feedback: data?.feedback,
        user: user?.email,
      });
    } else {
      await sendCustomAttributesToCustomerX({ externalId: user?.id, data });
    }
  });

  return (
    <>
      {user && <OnboardingModal />}
      <ServiceWorkerWrapper />
      <Routes />
      <CookiesUsage />
    </>
  );
};

export default memo(App);
