import axios from 'axios';

export const updateCustomerXTracking = ({ identifier, userId, email, type }) => {
  return axios.post(
    'https://tracking.customerx.com.br/api/v1/trackings',
    {
      external_id_client: userId,
      type_tracking: type || 'action',
      identifier,
      email,
    },
    {
      headers: {
        credential: process.env.REACT_APP_CUSTOMERX_CREDENTIAL,
        key: process.env.REACT_APP_CUSTOMERX_KEY,
      },
    },
  );
};

const makeRequestCreator = ({ userId, identifier, email }) => {
  let tokenCancel;

  return async () => {
    if (tokenCancel) {
      tokenCancel.cancel();
    }

    tokenCancel = await axios.CancelToken.source();

    try {
      await axios.post(
        'https://tracking.customerx.com.br/api/v1/trackings',
        {
          external_id_client: userId,
          type_tracking: 'action',
          identifier,
          email,
        },
        {
          cancelToken: tokenCancel.token,
          headers: {
            credential: process.env.REACT_APP_CUSTOMERX_CREDENTIAL,
            key: process.env.REACT_APP_CUSTOMERX_KEY,
          },
        },
      );

      return null;
    } catch {
      return null;
    }
  };
};

export const updateCustomerXTrackingSearchRequest = ({ userId, identifier, email }) =>
  makeRequestCreator({ userId, identifier, email });
