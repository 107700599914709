import React, { memo } from 'react';
import { Popover, PopoverBody } from 'reactstrap';

import { numberBrFormatter } from '../../../../../../utils/utils';

import './styles.scss';

const ChartTooltip = ({ data, target, toggle, isOpen }) => {
  return (
    <Popover placement="bottom" isOpen={isOpen} toggle={toggle} target={target} trigger="hover">
      <PopoverBody className="product-details-chart-transaction-taxes-tooltip">
        <span>TAXA DE TRANSAÇÕES</span>
        <div className="transaction-taxes-tooltip">
          <div>
            <span>Gateway:</span>
            <span>Checkout:</span>
            <span>Impostos:</span>
          </div>
          <div>
            <span>R$ {numberBrFormatter(data.gateway || 0, 2)}</span>
            <span>R$ {numberBrFormatter(data.checkout || 0, 2)}</span>
            <span>R$ {numberBrFormatter(data.tax || 0, 2)}</span>
          </div>
        </div>
      </PopoverBody>
    </Popover>
  );
};

export default memo(ChartTooltip);
