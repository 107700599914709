import React, { useCallback, useEffect, useState, memo } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row, Input } from 'reactstrap';
import { CaretLeft } from 'phosphor-react';

import { useToast } from '../../../hooks/Toast';
import Spinner from '../../Spinner/Spinner';
import { api, shopsApi } from '../../../services/api';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';
import { useAuth } from '../../../hooks/User';

const HubsaleForm = ({ fluid = true, className, onSave = () => {} }) => {
  const { store, platform } = useParams();
  const { showToast } = useToast();
  const location = useLocation();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({ clientId: '', clientSecret: '', id: null });

  const handleClientId = useCallback(
    event => {
      const { value } = event.target;

      setCredentials({ ...credentials, ...{ clientId: value } });
    },
    [credentials],
  );

  const handleClientSecret = useCallback(
    event => {
      const { value } = event.target;

      setCredentials({ ...credentials, ...{ clientSecret: value } });
    },
    [credentials],
  );

  const isOnboardingPage = useCallback(() => {
    if (location.pathname.includes('onboarding')) {
      return true;
    }

    return false;
  }, [location.pathname]);

  const handleOnSubmit = useCallback(
    async event => {
      event.preventDefault();

      const requestType = credentials.id ? 'put' : 'post';

      setLoading(true);

      try {
        const { data } = await api(shopsApi[platform])[requestType](
          `api/v1/users/${platform}/stores/${store}/hubsale-credentials/${credentials?.id || ''}`,
          credentials,
        );

        setCredentials({
          clientId: data.hubSaleCredential.clientId,
          clientSecret: data.hubSaleCredential.clientSecret,
          id: data.hubSaleCredential.id,
        });

        showToast({ message: 'Credenciais salvas com sucesso!', type: 'success' });
        onSave('hubsale');

        if (isOnboardingPage()) {
          updateCustomerXTracking({
            email: user?.email,
            userId: user?.id,
            identifier: 'onboarding_has_added_gateway_credential',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [credentials, platform, store, onSave, showToast, user, isOnboardingPage],
  );

  const loadCredentials = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await api(shopsApi[platform]).get(
        `api/v1/users/${platform}/stores/${store}/hubsale-credentials`,
      );

      if (data.hubSaleCredential) {
        setCredentials({
          clientId: data.hubSaleCredential.clientId,
          clientSecret: data.hubSaleCredential.clientSecret,
          id: data.hubSaleCredential.id,
        });
      }
    } finally {
      setLoading(false);
    }
  }, [platform, store]);

  const isIntegrationPage = useCallback(() => {
    if (location.pathname.includes('integrations')) {
      return true;
    }

    return false;
  }, [location.pathname]);

  useEffect(() => {
    loadCredentials();
  }, [loadCredentials]);

  return (
    <Container fluid={fluid} className={className || 'pb-8 pt-5 pt-md-8'}>
      <Row>
        <Col className="order-xl-1" xl="12">
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <>
              {isIntegrationPage() && (
                <div className="mb-3 ">
                  <Link
                    to={`/${platform}/${store}/dashboard/config/integrations`}
                    className="d-flex align-items-center"
                    style={{ color: '#ADB8C4' }}
                  >
                    <CaretLeft size={20} className="mr-1" />
                    Voltar às integrações
                  </Link>
                </div>
              )}
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Configurações</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button color="primary" size="md" onClick={handleOnSubmit}>
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h2>Instruções de integração com a Doppus</h2>
                    <p className="text-muted mb-5">
                      <a href="https://hub.sale/settings/api/" target="blank">
                        <span className="font-weight-bold">Clique aqui </span>
                      </a>
                      para obter seu Client Id e a Client Secret para se conectar a Doppus.
                    </p>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Label className="form-control-label" htmlFor="input-client-id">
                              Client Id
                            </Label>
                            <Input
                              className="form-control-alternative"
                              id="input-client-id"
                              defaultValue={credentials.clientId}
                              type="text"
                              onChange={handleClientId}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Label className="form-control-label" htmlFor="input-client-token">
                              Client Secret
                            </Label>
                            <Input
                              className="form-control-alternative"
                              id="input-client-secret"
                              type="text"
                              onChange={handleClientSecret}
                              defaultValue={credentials.clientSecret}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default memo(HubsaleForm);
