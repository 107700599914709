import React, { memo, useMemo } from 'react';
import { Popover, PopoverBody } from 'reactstrap';

import { numberBrFormatter } from '../../../../../../utils/utils';

import './styles.scss';

const ChartTooltip = ({ data, target, toggle, isOpen }) => {
  const grossAmount = useMemo(() => data.revenue + data.waitOrders + data.totalOrdersUnknownAmount, [data]);

  return (
    <Popover placement="bottom" isOpen={isOpen} toggle={toggle} target={target} trigger="hover">
      <PopoverBody className="product-details-chart-revenue-tooltip">
        <span>FATURAMENTO</span>
        <div className="revenue-tooltip">
          <div>
            <span>Bruto: </span>
            <span>Pendente: </span>
            <span>Sem informações: </span>
          </div>
          <div>
            <span>R$ {numberBrFormatter(grossAmount || 0, 2)}</span>
            <span>R$ {numberBrFormatter(data.waitOrders || 0, 2)}</span>
            <span>R$ {numberBrFormatter(data.totalOrdersUnknownAmount || 0, 2)}</span>
          </div>
        </div>
      </PopoverBody>
    </Popover>
  );
};

export default memo(ChartTooltip);
