import React, { useEffect, useState, useCallback } from 'react';
import { Container } from 'reactstrap';
import { videos } from '../../../utils/videos';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';

import './styles.scss';

const Videos = () => {
  const { user } = useAuth();

  const [mainVideo, setMainVideo] = useState(videos[0]);

  const handleVideoClick = useCallback(video => setMainVideo(video), []);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_mentoring',
        type: 'screen',
      });
    }
  }, [user]);

  return (
    <Container fluid className="pb-8 pt-0 pt-md-8">
      <div className="d-flex justify-content-center videos-partners-content">
        <div className="main-partner-video">
          <h1 className="main-video-title">{mainVideo.title}</h1>
          <iframe
            title={mainVideo.title}
            width="1032"
            height="580"
            src={`https://www.youtube.com/embed/${mainVideo.url}?showinfo=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            controls={0}
            showinfo={0}
          />
        </div>
        <div className="partners-videos-playlist">
          {videos.map(video => (
            <div
              key={video.url}
              className="d-flex partner-video"
              role="button"
              onKeyPress={() => {}}
              tabIndex="0"
              onClick={() => handleVideoClick(video)}
            >
              <img src={video.thumbnail} alt={`Profitfy - ${video.title}`} />
              <div className="playlist-video-info">
                <span>{video.title}</span>
                <span>{video.author}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Videos;
