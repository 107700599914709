import React, { memo } from 'react';
import './styles.scss';
import moment from 'moment-timezone';

const PreDefinedDates = ({ handlePreDefinedDate }) => {
  return (
    <div className="pre-defined-dates">
      <button type="button" onClick={() => handlePreDefinedDate(moment(), moment())}>
        <span>Hoje</span>
      </button>

      <button
        type="button"
        onClick={() => handlePreDefinedDate(moment().subtract(1, 'day'), moment().subtract(1, 'day'))}
      >
        <span>Ontem</span>
      </button>

      <button type="button" onClick={() => handlePreDefinedDate(moment().subtract(6, 'days'), moment())}>
        <span>Últimos 7 dias</span>
      </button>

      <button type="button" onClick={() => handlePreDefinedDate(moment().subtract(29, 'days'), moment())}>
        <span>Últimos 30 dias</span>
      </button>

      <button type="button" onClick={() => handlePreDefinedDate(moment().startOf('month'), moment())}>
        <span>Este mês</span>
      </button>

      <button
        type="button"
        onClick={() =>
          handlePreDefinedDate(moment().subtract(1, 'M').startOf('month'), moment().subtract(1, 'M').endOf('month'))
        }
      >
        <span>Mês Passado</span>
      </button>
    </div>
  );
};

export default memo(PreDefinedDates);
