import React, { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card } from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment-timezone';
import useSWR from 'swr';

import Spinner from '../../../../components/Spinner/Spinner';
import TableHeader from './TableHeader/TableHeader';
import TableBody from './TableBody/TableBody';
import TableFooter from './TableFooter/TableFooter';
import { shopsApi, makeSearchRequest } from '../../../../services/api';
import TopProduct from '../../../../components/TopProduct/TopProduct';
import NewDatePicker from '../../../../components/NewDatePicker/NewDatePicker';

const TopProducts = () => {
  const [filter, setFilter] = useState({ field: 'revenue', sort: 'DESC', title: '' });
  const [page, setPage] = useState(0);
  const [selectedDate, setSelectedDate] = useState('thirty');
  const { platform, store } = useParams();
  const [period, setPeriod] = useState({
    startDate: moment().subtract(29, 'days'),
    endDate: moment(),
  });

  const rowsPerPage = 10;

  const searchFetch = useMemo(
    () =>
      makeSearchRequest({
        api: shopsApi[platform],
      }),
    [platform],
  );

  const { data, error } = useSWR(
    `/api/v1/users/${platform}/stores/${store}/products/orders?${queryString.stringify({
      filter: JSON.stringify(filter),
    })}&startDate=${moment(period.startDate).format('YYYY-MM-DD')}&endDate=${moment(period.endDate).format(
      'YYYY-MM-DD',
    )}&page=${page + 1}&limit=${rowsPerPage}`,
    searchFetch,
  );

  const isLoading = !data && !error;

  const handleChangePage = useCallback(page => {
    setPage(page.selected);
  }, []);

  const handleSearch = useCallback(
    async event => {
      const { value } = event.target;
      setPage(0);
      setFilter({ ...filter, ...{ title: value } });
    },
    [filter],
  );

  const handleDate = useCallback(
    ({ startDate, endDate }) => {
      if (!startDate || !endDate) return;
      if (!startDate.isValid() || !endDate.isValid()) return;
      setPeriod({ startDate, endDate });
    },
    [setPeriod],
  );

  return (
    <div className="top-products">
      <Row>
        <div className="datepicker">
          <NewDatePicker
            startDate={period.startDate}
            endDate={period.endDate}
            onDate={handleDate}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
          />
        </div>
      </Row>
      <Row>
        <Col>
          <h1 className="font-weight-light mb-4">Selecione um produto</h1>
        </Col>
      </Row>

      {isLoading ? (
        <Spinner loading={isLoading} />
      ) : (
        <div className="top-three">
          {data?.topProducts
            .filter(product => product)
            .map((product, index) => (
              <TopProduct ranking={index + 1} product={product} key={product.id} />
            ))}
        </div>
      )}
      <Row className="mt-5">
        <Col>
          <Card className="shadow">
            <TableHeader handleSearch={handleSearch} />
            {isLoading ? <Spinner loading={isLoading} /> : <TableBody products={data.products} />}
            <TableFooter handleChangePage={handleChangePage} pages={data?.pagesCount} page={page} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TopProducts;
