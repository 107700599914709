import React, { memo, useCallback, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import Header from './Header/Header';
import Body from './Body/Body';
import { useProductVariants, useProduct } from '../../../hooks/ProductCost';
import { api, shopsApi } from '../../../services/api';
import Spinner from '../../Spinner/Spinner';
import UnsavedModal from '../../ProductCost/ProductVariantTable/UnsavedModal/UnsavedModal';
import SaveChanges from '../../ProductCost/SaveChanges/SaveChanges';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';

const ProductVariantTable = ({ selectedProduct, setSelectedProduct, handleHasEditedOnboardingCurrency }) => {
  const { user } = useAuth();
  const { platform, store } = useParams();
  const {
    isLoadingProductVariants,
    productVariantsData,
    mutate: mutateVariants,
  } = useProductVariants({
    platform,
    store,
    productId: selectedProduct?.id,
  });
  const {
    isLoadingProduct,
    productData,
    mutate: mutateProduct,
  } = useProduct({
    platform,
    store,
    productId: selectedProduct?.id,
  });
  const location = useLocation();

  const [hasChangedData, setHasChangedData] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [userCurrency, setUserCurrency] = useState(selectedProduct?.currency);
  const [selectedIof, setSelectedIof] = useState('TICKET');
  const [allVariantsCost, setAllVariantsCost] = useState('');
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);
  const [editedVariants, setEditedVariants] = useState([]);
  const [isSavingEdit, setIsSavingEdit] = useState(false);
  const [showDeletedVariants, setShowDeletedVariants] = useState(false);

  const handleUnsavedModalOpen = useCallback(() => setIsUnsavedModalOpen(!isUnsavedModalOpen), [isUnsavedModalOpen]);

  const isOnboardingPage = useCallback(() => {
    if (location.pathname.includes('onboarding')) {
      return true;
    }

    return false;
  }, [location.pathname]);

  const handleSaveChanges = useCallback(async () => {
    setIsSavingEdit(true);

    try {
      const updateCurrency = api(shopsApi[platform]).patch(
        `api/v1/users/${platform}/stores/${store}/products/${selectedProduct?.id}/currency`,
        {
          currency: selectedCurrency,
        },
      );

      const updateIof = api(shopsApi[platform]).patch(
        `api/v1/users/${platform}/stores/${store}/products/${selectedProduct?.id}/buy-method`,
        {
          buyMethod: selectedIof,
        },
      );

      const updateVariants = api(shopsApi[platform]).patch(
        `api/v1/users/${platform}/stores/${store}/products/${selectedProduct?.id}/variants`,
        {
          variants: editedVariants,
        },
      );

      await Promise.all([updateCurrency, updateIof, updateVariants]);

      setUserCurrency(selectedCurrency);

      await Promise.all([mutateProduct(), mutateVariants()]);

      setHasChangedData(false);

      if (user) {
        updateCustomerXTracking({ email: user.email, userId: user.id, identifier: 'update_cogs' });
      }

      if (isOnboardingPage()) {
        updateCustomerXTracking({
          email: user?.email,
          userId: user?.id,
          identifier: 'onboarding_has_edited_cogs',
        });
      }

      handleHasEditedOnboardingCurrency();
    } finally {
      setIsSavingEdit(false);
    }
  }, [
    editedVariants,
    selectedCurrency,
    selectedIof,
    platform,
    store,
    mutateProduct,
    mutateVariants,
    selectedProduct.id,
    user,
    isOnboardingPage,
    handleHasEditedOnboardingCurrency,
  ]);

  if (isLoadingProductVariants || isLoadingProduct) {
    return <Spinner loading />;
  }

  return (
    <>
      {isSavingEdit ? (
        <Spinner loading />
      ) : (
        <div className="onboarding-product-cost-variant-table-container">
          <Header
            handleUnsavedModalOpen={handleUnsavedModalOpen}
            productData={productData}
            hasChangedData={hasChangedData}
            setSelectedProduct={setSelectedProduct}
          />
          <Body
            productVariantsData={productVariantsData}
            setHasChangedData={setHasChangedData}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            selectedIof={selectedIof}
            setSelectedIof={setSelectedIof}
            allVariantsCost={allVariantsCost}
            setAllVariantsCost={setAllVariantsCost}
            productData={productData}
            editedVariants={editedVariants}
            setEditedVariants={setEditedVariants}
            showDeletedVariants={showDeletedVariants}
            setShowDeletedVariants={setShowDeletedVariants}
            selectedProduct={selectedProduct}
            userCurrency={userCurrency}
          />

          <UnsavedModal
            isOpen={isUnsavedModalOpen}
            toggle={handleUnsavedModalOpen}
            page="onboarding"
            setSelectedProduct={setSelectedProduct}
          />

          {hasChangedData && <SaveChanges handleSaveChanges={handleSaveChanges} />}
        </div>
      )}
    </>
  );
};

export default memo(ProductVariantTable);
