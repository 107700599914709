import React, { memo } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { Info } from 'phosphor-react';

import './styles.scss';

const SyncingModal = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="syncing-campaigns-modal">
      <ModalHeader className="header">
        <Info size={32} />
        <span>Aviso</span>
      </ModalHeader>
      <ModalBody className="body">
        <img
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/svgs/custom-spends-warn.svg"
          alt="Profitfy.me Sincronizando Campanhas"
        />
        <span>Isso pode levar alguns minutos!</span>
        <p>
          Estamos <strong>processando</strong> as suas campanhas, e essa operação pode demorar alguns minutos! Por
          favor, aguarde até finalizarmos o processamento.
        </p>
        <Button color="primary" onClick={toggle}>
          Entendi
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default memo(SyncingModal);
