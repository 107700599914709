import React, { memo, useState, useCallback, useRef } from 'react';
import { Modal, ModalBody, FormGroup, Label, Input, Button, Form } from 'reactstrap';
import Swal from 'sweetalert2';

import { useAuth } from '../../hooks/User';
import { api } from '../../services/api';
import { getCancelationOption } from '../../utils/cancelationOption';
import Spinner from '../Spinner/Spinner';

import './styles.scss';

const CancelationForm = ({ isOpen, toggle }) => {
  const descriptionInputRef = useRef(null);
  const { user, loadUser } = useAuth();

  const [isRequiredDescriptionForm, setIsRequiredDescriptionForm] = useState(false);
  const [selectedOption, setSelectedOption] = useState('OPTION-1');
  const [descriptionInput, setDescriptionInput] = useState(null);
  const [hasDescriptionInputError, setHasDescriptionInputError] = useState(false);
  const [isCancelingSubscription, setIsCancelingSubscription] = useState(false);

  const handleOptionalDescriptionInputOption = useCallback(event => {
    const { value } = event?.target;

    setIsRequiredDescriptionForm(false);
    setDescriptionInput(null);
    setSelectedOption(value);
    setHasDescriptionInputError(false);

    if (descriptionInputRef?.current) {
      descriptionInputRef.current.value = '';
    }
  }, []);

  const handleRequiredDescriptionInputOption = useCallback(event => {
    const { value } = event?.target;

    setIsRequiredDescriptionForm(true);
    setSelectedOption(value);
  }, []);

  const handleRequiredDescriptionInput = useCallback(event => {
    const { value } = event?.target;

    setDescriptionInput(value);
  }, []);

  const isDescriptionInputValid = useCallback(() => {
    if (!descriptionInput) return false;

    if (descriptionInput.length < 6) return false;

    return true;
  }, [descriptionInput]);

  const handleCancelSubscription = useCallback(async () => {
    if (isRequiredDescriptionForm && !isDescriptionInputValid()) {
      setHasDescriptionInputError(true);

      return;
    }

    setIsCancelingSubscription(true);

    try {
      await api('user-api').put(`api/v1/users/subscriptions/${user?.subscription?.id}`, {
        cancellationReason: getCancelationOption(selectedOption),
        cancellationReasonDescription: descriptionInput,
      });

      await loadUser();

      toggle();
      setSelectedOption('OPTION-1');
      setDescriptionInput(null);

      await Swal.fire({
        icon: 'success',
        title: 'Plano cancelado',
        text: 'É uma pena que tenha cancelado, caso precise de ajuda entre em contato conosco!',
        showConfirmButton: false,
        timer: 5000,
      });
    } finally {
      setIsCancelingSubscription(false);
    }
  }, [
    isDescriptionInputValid,
    isRequiredDescriptionForm,
    toggle,
    user.subscription.id,
    descriptionInput,
    selectedOption,
    loadUser,
  ]);

  return (
    <Modal isOpen={isOpen} className="cancelation-form-modal">
      <ModalBody className="cancelation-form-body">
        {isCancelingSubscription ? (
          <Spinner loading />
        ) : (
          <>
            <span>Estamos tristes por ver você ir!</span>
            <span>
              Antes de você cancelar, nos deixe saber a razão da sua partida. O seu feedback nos ajudará muito!
            </span>

            <Form role="form">
              <FormGroup>
                <FormGroup>
                  <Label className="radio-input">
                    <Input
                      type="radio"
                      name="cancelation"
                      value="OPTION-1"
                      onChange={handleOptionalDescriptionInputOption}
                      defaultChecked
                    />
                    <span>Estou fechando minha empresa</span>
                  </Label>
                </FormGroup>

                <FormGroup>
                  <Label className="radio-input">
                    <Input
                      type="radio"
                      name="cancelation"
                      value="OPTION-2"
                      onChange={handleRequiredDescriptionInputOption}
                    />
                    <span>Faltou integrações</span>
                  </Label>
                </FormGroup>

                <FormGroup>
                  <Label className="radio-input">
                    <Input
                      type="radio"
                      name="cancelation"
                      value="OPTION-3"
                      onChange={handleRequiredDescriptionInputOption}
                    />
                    <span>Meus dados estão incorretos</span>
                  </Label>
                </FormGroup>

                <FormGroup>
                  <Label className="radio-input">
                    <Input
                      type="radio"
                      name="cancelation"
                      value="OPTION-4"
                      onChange={handleRequiredDescriptionInputOption}
                    />
                    <span>Tive problemas técnicos com a plataforma</span>
                  </Label>
                </FormGroup>

                <FormGroup>
                  <Label className="radio-input">
                    <Input
                      type="radio"
                      name="cancelation"
                      value="OPTION-5"
                      onChange={handleOptionalDescriptionInputOption}
                    />
                    <span>Achei o valor alto</span>
                  </Label>
                </FormGroup>

                <FormGroup>
                  <Label className="radio-input">
                    <Input
                      type="radio"
                      name="cancelation"
                      value="OPTION-6"
                      onChange={handleOptionalDescriptionInputOption}
                    />
                    <span>A plataforma é muito complexa</span>
                  </Label>
                </FormGroup>

                <FormGroup>
                  <Label className="radio-input">
                    <Input
                      type="radio"
                      name="cancelation"
                      value="OPTION-7"
                      onChange={handleOptionalDescriptionInputOption}
                    />
                    <span>Não recebi o suporte necessário</span>
                  </Label>
                </FormGroup>

                <FormGroup>
                  <Label className="radio-input">
                    <Input
                      type="radio"
                      name="cancelation"
                      value="OPTION-8"
                      onChange={handleRequiredDescriptionInputOption}
                    />
                    <span>Faltou ferramentas para atender meu negócio</span>
                  </Label>
                </FormGroup>

                <FormGroup>
                  <Label className="radio-input">
                    <Input
                      type="radio"
                      name="cancelation"
                      value="OPTION-9"
                      onChange={handleRequiredDescriptionInputOption}
                    />
                    <span>Vou migrar para outra plataforma</span>
                  </Label>
                </FormGroup>

                <FormGroup>
                  <Label className="radio-input">
                    <Input
                      type="radio"
                      name="cancelation"
                      value="OPTION-10"
                      onChange={handleRequiredDescriptionInputOption}
                      innerRef={descriptionInputRef}
                    />
                    <span>Outros</span>
                  </Label>
                </FormGroup>

                {isRequiredDescriptionForm && (
                  <FormGroup>
                    <Input
                      className="description-input"
                      placeholder="Descreva o que aconteceu"
                      type="textarea"
                      name="description"
                      onChange={handleRequiredDescriptionInput}
                    />
                    {hasDescriptionInputError && !descriptionInput && (
                      <span className="description-error-message">É necessário descrever o motivo</span>
                    )}
                    {hasDescriptionInputError && descriptionInput && descriptionInput?.length < 6 && (
                      <span className="description-error-message">
                        A descrição do motivo deve conter mais que seis caractéres
                      </span>
                    )}
                  </FormGroup>
                )}
              </FormGroup>

              <div className="buttons-wrapper">
                <Button onClick={toggle} color="primary">
                  Deixa pra lá, não quero cancelar
                </Button>
                <button type="button" onClick={handleCancelSubscription}>
                  Cancelar assinatura
                </button>
              </div>
            </Form>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default memo(CancelationForm);
