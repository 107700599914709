import React, { memo, useCallback } from 'react';
import { Package } from 'phosphor-react';
import { Button } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';

import { numberBrFormatter } from '../../../../utils/utils';

import './styles.scss';

const ProductCost = ({ profit }) => {
  const history = useHistory();
  const { store, platform } = useParams();

  const handleEditCost = useCallback(() => {
    history.push(`/${platform}/${store}/dashboard/config/products-costs`);
  }, [history, store, platform]);

  return (
    <div className="product-details-product-cost">
      <div className="header">
        <span>CUSTO DOS PRODUTOS</span>
        <div>
          <Package size={16} weight="bold" />
        </div>
      </div>
      <div className="body">
        <div className="total-wrapper">
          <span>TOTAL</span>
          <span>
            <small>R$</small>
            {numberBrFormatter(profit.mainCards.cogs, 2)}
          </span>
        </div>

        <hr />

        <div className="content">
          <div className="text">
            <span>Seus custos estão com valores incorretos?</span>
            <a
              href="https://docs.profitfy.me/pt-BR/articles/5043264-sobre-o-funcionamento-da-sincronizacao-com-o-aliexpress-e-dsers"
              target="blank"
            >
              <span>Veja como sincronizar os custos com Aliexpress</span>
            </a>
          </div>

          <Button onClick={handleEditCost}>Editar Custos</Button>
        </div>
      </div>

      <div className="gradient-border-detail">
        <div />
      </div>
    </div>
  );
};

export default memo(ProductCost);
