import React, { memo, useState, useCallback } from 'react';
import { MonitorPlay, Check } from 'phosphor-react';
import { Input, FormGroup, Label, Button, Tooltip } from 'reactstrap';

import brazilianFlag from '../../../../assets/img/theme/brasil.svg';
import americanFlag from '../../../../assets/img/theme/eua_flag.svg';

import './styles.scss';

const SelectCurrency = ({ setSelectedCurrency, setConvertCurrencyStep }) => {
  const [currency, setCurrency] = useState('USD');
  const [isSelectCurrencyTooltipOpen, setIsSelectCurrencyTooltipOpen] = useState(false);

  const handleSelectCurrencyTooltipOpen = useCallback(
    () => setIsSelectCurrencyTooltipOpen(!isSelectCurrencyTooltipOpen),
    [isSelectCurrencyTooltipOpen],
  );

  const handleSelectCurrency = useCallback(() => {
    setSelectedCurrency(currency);
    setConvertCurrencyStep('average-cost');
  }, [setSelectedCurrency, currency, setConvertCurrencyStep]);

  return (
    <div className="edit-product-cost-select-currency">
      <div className="short-video" id="select-currency-tutorial-video">
        <MonitorPlay size={18} />
        <span>Precisa de ajuda? Assista a um video curto</span>
        <div className="duration-badge">
          <span>0:15</span>
        </div>
      </div>

      <Tooltip
        isOpen={isSelectCurrencyTooltipOpen}
        toggle={handleSelectCurrencyTooltipOpen}
        placement="bottom"
        target="select-currency-tutorial-video"
        className="select-currency-tutorial-video"
        autohide={false}
      >
        <video
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/videos-tuto/cogs-currency-step.mp4"
          controls
          autoPlay
          muted
        >
          <track kind="captions" />
        </video>
      </Tooltip>

      <span>Em qual moeda o seu fornecedor opera?</span>

      <FormGroup className="currency-input">
        <FormGroup>
          <Label className={`radio-input ${currency === 'USD' && 'selected'}`}>
            <img src={americanFlag} alt="Profitfy.me moeda dólar" />
            <div>
              <Input
                type="radio"
                name="currency"
                value="USD"
                onChange={() => setCurrency('USD')}
                defaultChecked={currency === 'USD'}
              />
              <span>U$</span>
            </div>
          </Label>
        </FormGroup>

        <FormGroup>
          <Label className={`radio-input ${currency === 'BRL' && 'selected'}`}>
            <img src={brazilianFlag} alt="Profitfy.me moeda real" />
            <div>
              <Input
                type="radio"
                name="currency"
                value="BRL"
                onChange={() => setCurrency('BRL')}
                defaultChecked={currency === 'BRL'}
              />
              <span>R$</span>
            </div>
          </Label>
        </FormGroup>
      </FormGroup>

      {currency === 'USD' && (
        <div className="dollar-benefits">
          <div>
            <Check size={16} weight="bold" />
            <span>Conversão automática para real com a cotação do dólar do dia</span>
          </div>

          <div>
            <Check size={16} weight="bold" />
            <span>Maior precisão nos dados e métricas</span>
          </div>
        </div>
      )}

      <Button onClick={handleSelectCurrency} color="primary">
        Selecionar moeda
      </Button>
    </div>
  );
};

export default memo(SelectCurrency);
