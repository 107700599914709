import {
  House,
  ChartPieSlice,
  ChartBar,
  ShoppingBagOpen,
  Barcode,
  Storefront,
  Cube,
  Archive,
  Wrench,
  PlayCircle,
  Percent,
} from 'phosphor-react';

import ShopAnalytics from '../pages/Dashboard/ShopAnalytics/ShopAnalytics';
import ProductAnalytics from '../pages/Dashboard/ProductAnalytics/ProductAnalytics';
import Orders from '../pages/Dashboard/Orders/Orders';
import Integrations from '../pages/Dashboard/Integrations/Integrations';
import MercadoPagoForm from '../components/Gateways/MercadoPagoForm/MercadoPagoForm';
import ProductCost from '../pages/Dashboard/ProductCost/ProductCost';
import Billets from '../pages/Dashboard/Billets/Billets';
import CustomSpends from '../pages/Dashboard/CustomSpends/CustomSpends';
import AliexpressForm from '../components/AliexpressForm/AliexpressForm';
import CieloForm from '../components/Gateways/CieloForm/CieloForm';
import Dashboard from '../pages/Admin/Dashboard/Dashboard';
import AppmaxForm from '../components/Gateways/AppmaxForm/AppmaxForm';
import CloudfoxForm from '../components/Gateways/CloudfoxForm/CloudfoxForm';
import PagseguroForm from '../components/Gateways/PagseguroForm/PagseguroForm';
import UpnidForm from '../components/Gateways/UpnidForm/UpnidForm';
import PagarmeForm from '../components/Gateways/PagarmeForm/PagarmeForm';
import HubsaleForm from '../components/Gateways/HubsaleForm/HubsaleForm';
import Tools from '../pages/Dashboard/Tools/Tools';
import Videos from '../pages/Dashboard/Videos/Videos';
import Home from '../pages/Dashboard/Home/Home';
import Taxes from '../pages/Dashboard/Taxes/Taxes';
import Facebook from '../pages/Dashboard/Integrations/Facebook/Facebook';
import PricingTool from '../pages/Dashboard/Tools/Pricing/Pricing';
import AudienceInsights from '../pages/Dashboard/Tools/AudienceInsights/AudienceInsights';

const menuRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard Admin',
    icon: ChartPieSlice,
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/home',
    name: 'Home',
    icon: House,
    component: Home,
    layout: '/dashboard',
  },
  {
    path: '/shop-analytics',
    name: 'Análise Loja',
    icon: ChartPieSlice,
    component: ShopAnalytics,
    layout: '/dashboard',
    // productTourLink: 'shop-analytics?&product_tour_id=147538',
  },
  {
    path: '/product-analytics',
    name: 'Análise Produtos',
    icon: ChartBar,
    component: ProductAnalytics,
    layout: '/dashboard',
    // productTourLink: 'shop-analytics?product_tour_id=129897',
  },
  {
    path: '/orders',
    name: 'Pedidos',
    icon: ShoppingBagOpen,
    component: Orders,
    layout: '/dashboard',
  },
  {
    path: '/billet',
    name: 'Boletos',
    icon: Barcode,
    component: Billets,
    layout: '/dashboard',
  },

  {
    path: '/products-costs',
    name: 'Custo dos Produtos',
    icon: Storefront,
    component: ProductCost,
    layout: '/dashboard/config',
    productTourLink: 'config/products-costs?product_tour_id=132132',
  },
  {
    path: '/integrations',
    name: 'Integrações',
    icon: Cube,
    component: Integrations,
    layout: '/dashboard/config',
  },
  {
    path: '/custom-spends',
    name: 'Valores Adicionais',
    icon: Archive,
    component: CustomSpends,
    layout: '/dashboard/config',
    // productTourLink: 'config/custom-spends?product_tour_id=131350',
  },
  {
    path: '/taxes',
    name: 'Impostos e Taxas',
    icon: Percent,
    component: Taxes,
    layout: '/dashboard/config',
  },
  {
    path: '/a',
    name: 'Ferramentas',
    icon: Wrench,
    component: Tools,
    layout: '/dashboard/tools',
  },
  {
    path: '/audience-insights',
    name: 'Audience Insights',
    component: AudienceInsights,
    layout: '/dashboard/tools',
  },
  {
    path: '/pricing-tool',
    name: 'Ferramenta de Precificação',
    component: PricingTool,
    layout: '/dashboard/tools',
  },
  {
    path: '/',
    name: 'Mentoria',
    icon: PlayCircle,
    component: Videos,
    layout: '/dashboard/videos',
  },
  {
    path: '/mercado-pago',
    name: 'Integração com Mercado Pago',
    component: MercadoPagoForm,
    layout: '/dashboard/integrations',
  },
  {
    path: '/facebook',
    name: 'Integração com Facebook',
    component: Facebook,
    layout: '/dashboard/integrations',
  },
  {
    path: '/aliexpress',
    name: 'Integração com AliExpress',
    component: AliexpressForm,
    layout: '/dashboard/integrations',
  },
  {
    path: '/cielo',
    name: 'Integração com Cielo',
    component: CieloForm,
    layout: '/dashboard/integrations',
  },
  {
    path: '/appmax',
    name: 'Integração com AppMax',
    component: AppmaxForm,
    layout: '/dashboard/integrations',
  },
  {
    path: '/cloudfox',
    name: 'Integração com Cloudfox',
    component: CloudfoxForm,
    layout: '/dashboard/integrations',
  },
  {
    path: '/pagseguro',
    name: 'Integração com Pagseguro',
    component: PagseguroForm,
    layout: '/dashboard/integrations',
  },
  {
    path: '/upnid',
    name: 'Integração com Upnid',
    component: UpnidForm,
    layout: '/dashboard/integrations',
  },
  {
    path: '/pagarme',
    name: 'Integração com Pagar.me',
    component: PagarmeForm,
    layout: '/dashboard/integrations',
  },
  {
    path: '/doppus',
    name: 'Integração com Doppus',
    component: HubsaleForm,
    layout: '/dashboard/integrations',
  },
];

export default menuRoutes;
