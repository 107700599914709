import React, { memo, useCallback } from 'react';

import ProductCostConfig from '../ProductCostConfig/ProductCostConfig';
import VariantTable from '../VariantTable/VariantTable';

const Body = ({
  productVariantsData,
  setHasChangedData,
  selectedCurrency,
  setSelectedCurrency,
  selectedIof,
  setSelectedIof,
  allVariantsCost,
  setAllVariantsCost,
  productData,
  editedVariants,
  setEditedVariants,
  showDeletedVariants,
  setShowDeletedVariants,
  userCurrency,
}) => {
  const { variants } = productVariantsData;

  const getVariants = useCallback(() => {
    if (showDeletedVariants) return variants;

    return variants.filter(variant => !variant.deleted);
  }, [variants, showDeletedVariants]);

  return (
    <>
      <ProductCostConfig
        setHasChangedData={setHasChangedData}
        selectedCurrency={selectedCurrency}
        setSelectedCurrency={setSelectedCurrency}
        selectedIof={selectedIof}
        setSelectedIof={setSelectedIof}
        setAllVariantsCost={setAllVariantsCost}
        productData={productData}
        setEditedVariants={setEditedVariants}
        editedVariants={editedVariants}
        variants={variants}
        showDeletedVariants={showDeletedVariants}
        setShowDeletedVariants={setShowDeletedVariants}
      />

      {variants.length > 0 && (
        <VariantTable
          variants={getVariants()}
          allVariantsCost={allVariantsCost}
          setHasChangedData={setHasChangedData}
          editedVariants={editedVariants}
          setEditedVariants={setEditedVariants}
          showDeletedVariants={showDeletedVariants}
          selectedCurrency={selectedCurrency}
          userCurrency={userCurrency}
        />
      )}
    </>
  );
};

export default memo(Body);
