import React, { memo, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { api, shopsApi } from '../../../services/api';
import SwitchInput from '../../SwitchInput/SwitchInput';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';

import './styles.scss';

const CampaignsTableRow = ({ campaign }) => {
  const { platform, store, productId } = useParams();
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [campaignData, setCampaignData] = useState(campaign);

  const handleCheckbox = useCallback(
    async event => {
      const { checked, id } = event.target;

      try {
        const data = api(shopsApi[platform]).patch(
          `api/v1/users/${platform}/stores/${store}/products/${productId}/facebook/campaigns/${id}`,
          {
            active: checked,
          },
        );

        if (user && checked) {
          await updateCustomerXTracking({ email: user.email, userId: user.id, identifier: 'add_campaigns' });
        }

        return data;
      } catch {
        return {};
      }
    },
    [platform, store, productId, user],
  );

  const handleSwitchInput = useCallback(
    async event => {
      event.persist();

      setIsLoading(true);

      try {
        const newCampaign = await handleCheckbox(event);

        setCampaignData({ ...campaign, ...newCampaign.campaign });
      } catch {
        event.target.checked = !event.target.checked;
      } finally {
        setIsLoading(false);
      }
    },
    [campaign, handleCheckbox],
  );

  return (
    <tr className="campaign-table-row">
      <td className="text-truncate" style={{ maxWidth: '300px' }}>
        <span>{campaignData.name}</span>
      </td>
      <td>
        <span>{campaignData.facebook_ad_account?.name}</span>
      </td>
      <td>
        <SwitchInput
          id={campaignData.id}
          defaultChecked={campaignData.active}
          onChange={handleSwitchInput}
          disabled={isLoading}
          isLoading={isLoading}
          status={campaignData.active}
        />
      </td>
    </tr>
  );
};

export default memo(CampaignsTableRow);
