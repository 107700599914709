import React from 'react';
import { Card } from 'reactstrap';

const SelectStoreCard = ({ stores, handleSelectStore, selectedStore }) => {
  return stores.map(store => (
    <Card
      key={store.name}
      className={`shadow create-store-card ${selectedStore === store.name && 'selected-store-card'}`}
      onClick={() => handleSelectStore(store.name)}
    >
      {selectedStore === store.name && <i className="fas fa-check-circle create-store-selected" />}
      <img src={store.image} alt={store.name} />
    </Card>
  ));
};

export default SelectStoreCard;
