import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row, FormGroup, Label, Input } from 'reactstrap';
import moment from 'moment-timezone';

import DatePicker from '../../../../../components/DsersSyncDatepicker/DsersSyncDatepicker';
import { useToast } from '../../../../../hooks/Toast';

import './styles.scss';

const ConfirmScreen = ({
  isLoadingDsersCredentials,
  syncDsersOrders,
  period,
  setPeriod,
  isLoadingSyncOrders,
  checked,
  onChange,
  disabled,
}) => {
  const { showToast } = useToast();

  const [isWithinDateRange, setIsWithinDateRange] = useState(true);

  const handleDate = ({ startDate, endDate }) => {
    if (!startDate || !endDate) return;
    if (!startDate.isValid() || !endDate.isValid()) return;
    setPeriod({ startDate, endDate });
  };

  const handleSubmit = useCallback(async () => {
    await syncDsersOrders({
      startDate: period.startDate,
      endDate: period.endDate,
    });
  }, [syncDsersOrders, period]);

  useEffect(() => {
    const differenceDays = moment(period.endDate).diff(period.startDate, 'days');

    if (differenceDays >= 15) {
      setIsWithinDateRange(false);

      showToast({
        message: 'O período selecionado deve ser no máximo 15 dias!',
        type: 'error',
      });

      return;
    }

    setIsWithinDateRange(true);
  }, [period, showToast]);

  return (
    <>
      <h2 className="mb-3">Leia com atenção!</h2>
      <p>
        Ao clicar em sincronizar, vamos sincronizar os custos dos pedidos já enviados (fulfillment) que estão no Dsers.
        Isso trará uma precisão grande nos custos de produtos.
      </p>
      <span>Após terminarmos a sincronização voce receberá um </span>{' '}
      <span className="font-weight-bold">Email de confirmação.</span>
      <p className="text-orange mt-4">Você pode sincronizar 5 vezes por dia. </p>
      <hr className="my-3" />
      <p className="font-weight-bold">Qual período você deseja sincronizar?</p>
      <DatePicker onDate={handleDate} startDate={period.startDate} endDate={period.endDate} position="center" />
      <FormGroup className="mb-0 mt-3 dsers-aliexpress-sync">
        <Label>
          <Input checked={checked} type="checkbox" onChange={onChange} disabled={disabled} />
          Ao sincronizar os custos de produtos com AliExpress, guardar o último custo da variante conciliada em nossa
          plataforma.
        </Label>
      </FormGroup>
      <Row className="mt-5">
        <Col className="d-flex justify-content-end">
          <Button
            type="button"
            color="primary"
            disabled={isLoadingSyncOrders || !isWithinDateRange || isLoadingDsersCredentials}
            onClick={handleSubmit}
          >
            {isLoadingSyncOrders ? 'Enviando ...' : 'Sincronizar'} <i className="fa fa-refresh ml-2" />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ConfirmScreen;
