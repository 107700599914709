import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';
import { Card, Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

import CustomSpendsTableHeader from './TableHeader/TableHeader';
import CustomSpendsTableBody from './TableBody/TableBody';
import CustomSpendsTableFooter from './TableFooter/TableFooter';
import Spinner from '../../../components/Spinner/Spinner';
import { shopsApi, makeSearchRequest } from '../../../services/api';
import NoCustomSpends from './NoCustomSpends/NoCustomSpends';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';

const CustomSpends = () => {
  const { user } = useAuth();

  const [customSpends, setCustomSpends] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagesCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [customSpendFilters, setCustomSpendFilters] = useState({
    status: null,
    period: null,
    type: null,
    category: null,
  });
  const [customSpendSort, setCustomSpendSort] = useState({
    createdAt: 'desc',
    description: '',
    startDate: '',
    currency: '',
    amount: '',
    type: '',
    active: '',
  });
  const { store, platform } = useParams();

  const searchFetch = useMemo(
    () =>
      makeSearchRequest({
        api: shopsApi[platform],
      }),
    [platform],
  );

  const loadCustomSpendsData = useCallback(
    async load => {
      const limit = 15;
      setLoading(Boolean(load));

      try {
        const response = await searchFetch(
          `api/v1/users/${platform}/stores/${store}/custom-spends?page=${
            page + 1
          }&limit=${limit}&${queryString.stringify({
            filter: JSON.stringify(customSpendFilters),
          })}&${queryString.stringify({
            sort: JSON.stringify(customSpendSort),
          })}`,
        );

        if (response) {
          setCustomSpends(response.customSpends);
          setPageCount(response.numberOfPages);
        }
      } finally {
        setLoading(false);
      }
    },
    [page, platform, store, customSpendFilters, customSpendSort, searchFetch],
  );

  const handleFilter = async customSpendParams => {
    setPage(0);
    setCustomSpendFilters({ ...customSpendFilters, ...customSpendParams });
  };

  const onChangePage = page => {
    setPage(page.selected);
  };

  useEffect(() => {
    loadCustomSpendsData(true);
  }, [loadCustomSpendsData]);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_custom_spends',
        type: 'screen',
      });
    }
  }, [user]);

  return (
    <Container fluid className="pb-8 pt-5 pt-md-8">
      <Row>
        <Col>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <>
              {customSpends.length <= 0 ? (
                <NoCustomSpends customSpends={customSpends} setCustomSpends={setCustomSpends} />
              ) : (
                <Card className="shadow">
                  <CustomSpendsTableHeader
                    customSpends={customSpends}
                    setCustomSpends={setCustomSpends}
                    handleFilter={handleFilter}
                  />
                  <CustomSpendsTableBody
                    customSpends={customSpends}
                    setCustomSpends={setCustomSpends}
                    customSpendSort={customSpendSort}
                    setCustomSpendSort={setCustomSpendSort}
                  />
                  <CustomSpendsTableFooter pages={pagesCount} onChangePage={onChangePage} page={page} />
                </Card>
              )}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default memo(CustomSpends);
