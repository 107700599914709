import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';

import CheckoutFee from '../../../components/CheckoutFee/CheckoutFee';
import Tax from '../../../components/Tax/Tax';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';

const Taxes = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_tax_and_fees',
        type: 'screen',
      });
    }
  }, [user]);

  return (
    <Container fluid className="pb-8 pt-5 pt-md-8">
      <Row className="mb-5 align-items-start">
        <CheckoutFee />
        <Tax />
      </Row>
    </Container>
  );
};

export default Taxes;
