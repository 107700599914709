import React, { memo, useMemo, useState, useCallback } from 'react';
import { ShoppingCart } from 'phosphor-react';
import { ResponsiveBar } from '@nivo/bar';

import Chart from './Chart/Chart';
import ChartTooltip from './Chart/ChartTooltip/ChartTooltip';
import { numberBrFormatter } from '../../../../utils/utils';

import './styles.scss';

const noData = [
  {
    id: 'Sem dados',
    no_data: 1,
  },
];

const TransactionTaxes = ({ mainCards, transactionTaxes }) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasTransactionTaxes = useMemo(() => {
    const totalTransactionTaxes = transactionTaxes.gateway + transactionTaxes.checkout + transactionTaxes.tax;

    if (totalTransactionTaxes !== 0) return true;

    return false;
  }, [transactionTaxes]);

  const data = useMemo(
    () => [
      {
        id: 'Taxa de Transações',
        gateway: transactionTaxes.gateway,
        checkout: transactionTaxes.checkout,
        taxes: transactionTaxes.tax,
      },
    ],
    [transactionTaxes],
  );

  const handlePopover = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <div className="card-details-transaction-taxes">
      <div className="header">
        <span>TAXA DE TRANSAÇÕES</span>
        <div>
          <ShoppingCart size={16} weight="bold" />
        </div>
      </div>
      <div className="body">
        <div className="details">
          <div className="value-wrapper">
            <span>Gateway</span>
            <span>
              <small>R$</small>
              {numberBrFormatter(transactionTaxes.gateway, 2)}
            </span>
          </div>

          <div className="value-wrapper">
            <span>Checkout</span>
            <span>
              <small>R$</small>
              {numberBrFormatter(transactionTaxes.checkout, 2)}
            </span>
          </div>

          <div className="value-wrapper">
            <span>Impostos</span>
            <span>
              <small>R$</small>
              {numberBrFormatter(transactionTaxes.tax, 2)}
            </span>
          </div>
        </div>

        <hr />

        <div className="total-and-chart">
          <div className="total-wrapper">
            <span>TOTAL</span>
            <span>
              <small>R$</small>
              {numberBrFormatter(mainCards.transactionTaxes, 2)}
            </span>
          </div>

          <div className="chart" id="transaction-taxes-chart-tooltip">
            {hasTransactionTaxes ? (
              <Chart data={data} />
            ) : (
              <ResponsiveBar
                data={noData}
                keys={['no_data']}
                indexBy="id"
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                padding={0}
                layout="horizontal"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={['#CACCCF']}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={null}
                enableGridY={false}
                enableGridX={false}
                enableLabel={false}
                legends={[]}
                animate
                motionStiffness={90}
                motionDamping={15}
                isInteractive={false}
              />
            )}
          </div>
          <ChartTooltip
            data={transactionTaxes}
            target="transaction-taxes-chart-tooltip"
            toggle={handlePopover}
            isOpen={isOpen}
          />
        </div>
      </div>

      <div className="gradient-border-detail">
        <div />
      </div>
    </div>
  );
};

export default memo(TransactionTaxes);
