import React, { useCallback, useState } from 'react';
import useForm from 'react-hook-form';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input as StrapInput,
  Button,
  Row,
  Col,
} from 'reactstrap';
import Swal from 'sweetalert2';
import * as yup from 'yup';

import Spinner from '../../../components/Spinner/Spinner';
import { useToast } from '../../../hooks/Toast';
import { api } from '../../../services/api';

import './styles.scss';

const ResetPassword = () => {
  const { resetToken } = useParams();
  const history = useHistory();
  const { showToast } = useToast();

  const [loading, setLoading] = useState(false);

  const patternPassword = /^[a-zA-Z0-9!@#$%&*]{3,25}$/;

  const validationSchema = yup.object().shape({
    password: yup.string().matches(patternPassword, 'A senha deve no mínimo 6 caracteres'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'As senhas estão diferentes.'),
  });

  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    validationSchema,
  });

  const handleOnSubmit = useCallback(
    async data => {
      setLoading(true);

      try {
        await api('auth-api').post(`/auth/reset-password?resetToken=${resetToken}`, {
          password: data.password,
        });

        await Swal.fire({
          icon: 'success',
          title: 'Senha alterada com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });

        history.push('/auth/login');
      } catch (error) {
        const message = error?.response?.data?.message || 'Entre em contato com o suporte';

        showToast({ message, type: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [history, resetToken, showToast],
  );

  return (
    <div className="main-reset-password-form-container">
      <div className="content-container">
        <img
          className="profitfy-logo"
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/logo.svg"
          alt="Profitfy.me"
        />

        <div className="form-title">
          <p>Cadastre uma nova senha!</p>
        </div>

        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <Form className="form-container" onSubmit={handleSubmit(handleOnSubmit)} role="form">
            <FormGroup className={errors.password && 'has-danger'}>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className={`${errors.password && 'text-red'} ni ni-lock-circle-open`} />
                  </InputGroupText>
                </InputGroupAddon>
                <StrapInput placeholder="Nova Senha" type="password" innerRef={register} name="password" />
              </InputGroup>
              {errors.password && <small className="ml-3 text-danger">É necessário uma senha válida</small>}
            </FormGroup>

            <FormGroup className={errors.confirmPassword && 'has-danger'}>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className={`${errors.confirmPassword && 'text-red'} ni ni-lock-circle-open`} />
                  </InputGroupText>
                </InputGroupAddon>
                <StrapInput
                  placeholder="Confirmar Nova Senha"
                  type="password"
                  innerRef={register}
                  name="confirmPassword"
                />
              </InputGroup>
              {errors.confirmPassword && <small className="ml-3 text-danger">{errors.confirmPassword.message}</small>}
            </FormGroup>

            <div className="text-center">
              <Button className="mt-4 btn-icon btn-2 sign-in-button" color="primary" type="submit">
                <span className="btn-inner--icon">
                  <i className="ni ni-send" />
                </span>

                <span className="btn-inner--text button-text">Enviar</span>
              </Button>
            </div>

            <Row className="mt-3 link-container">
              <Col xs="6">
                <Link className="" to="/auth/register">
                  <span>Registrar</span>
                </Link>
              </Col>

              <Col className="text-right" xs="6">
                <Link className="" to="/auth/login">
                  <span>Entrar</span>
                </Link>
              </Col>
            </Row>
          </Form>
        )}
      </div>

      <div className="creative-container">
        <img
          className="image-description"
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/Forgot+Password/reset-password.svg"
          alt="Profitfy.me"
        />
      </div>
    </div>
  );
};

export default ResetPassword;
