import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import moment from 'moment-timezone';

import Spinner from '../../../components/Spinner/Spinner';
import { api, shopsApi } from '../../../services/api';
import usePersistedState from '../../../hooks/PersistedState';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';

import './styles.scss';

const Finish = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { platform, store } = useParams();
  const [, setCalcPastThirtyDaysNotification] = usePersistedState('calcPastThirtyDaysNotification', {});
  const [, setCalcPastTwoDaysNotification] = usePersistedState('calcPastTwoDaysNotification', {});

  const [isLoading, setIsLoading] = useState(false);

  const handleDashboardButton = useCallback(async () => {
    setIsLoading(true);

    try {
      await api(shopsApi[platform]).patch(`/api/v1/users/${platform}/stores/${store}/first-time`, {
        isFirstTime: false,
      });

      const calcPastThirtyDaysOrdersApi = api(shopsApi[platform]).get(
        `/api/v1/users/${platform}/stores/${store}/calculate-historic?endDate=${moment().format(
          'YYYY-MM-DD',
        )}&startDate=${moment().subtract(30, 'days').format('YYYY-MM-DD')}`,
      );

      const calcPastTwoDaysOrdersApi = api(shopsApi[platform]).get(
        `/api/v1/users/${platform}/stores/${store}/calculate-historic?endDate=${moment().format(
          'YYYY-MM-DD',
        )}&startDate=${moment().subtract(2, 'days').format('YYYY-MM-DD')}`,
      );

      const [pastThirtyDaysData, pastTwoDaysData] = await Promise.all([
        calcPastThirtyDaysOrdersApi,
        calcPastTwoDaysOrdersApi,
      ]);

      setCalcPastThirtyDaysNotification(prevState => ({
        ...prevState,
        [store]: pastThirtyDaysData?.data?.calculateHistoric,
      }));
      setCalcPastTwoDaysNotification(prevState => ({
        ...prevState,
        [store]: pastTwoDaysData?.data?.calculateHistoric,
      }));

      history.push(`/${platform}/${store}/dashboard/shop-analytics?firstTime=true`);
    } finally {
      setIsLoading(false);
    }
  }, [platform, store, history, setCalcPastThirtyDaysNotification, setCalcPastTwoDaysNotification]);

  const handlePreviousPageButton = useCallback(() => {
    history.push(`/${platform}/${store}/onboarding/phone`);
  }, [history, platform, store]);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_onboarding_finish',
        type: 'screen',
      });
    }
  }, [user]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="onboarding-finish-container">
      <div className="content-container">
        <div className="content-text">
          <h1>Parabéns! Terminamos.</h1>
          <p>
            Agora vamos alimentar seu Dashboard automáticamente fazendo um retroativo de 30 dias atrás. Ao finalizar, te
            enviaremos um <b>e-mail</b> de conclusão.
          </p>
        </div>
        <div className="content-buttons-container">
          <Button className="stores-button" onClick={handleDashboardButton}>
            <i className="fas fa-arrow-right" />
            <span>Ir para o</span>
            <span>Dashboard</span>
          </Button>
          <Button className="previous-page-button" onClick={handlePreviousPageButton}>
            <i className="fas fa-arrow-left" />
            <span>Voltar ao</span>
            <span>passo anterior</span>
          </Button>
        </div>
      </div>
      <div className="creative-container">
        <img
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/New+User+Flux/onboarding-finish.svg"
          alt="Profitfy.me finish"
        />
      </div>
    </div>
  );
};

export default Finish;
