import React, { useEffect, useState, useCallback, memo } from 'react';
import { Button, Card } from 'reactstrap';
import { Link } from 'react-router-dom';

import Spinner from '../../components/Spinner/Spinner';
import Footer from '../../components/Footer/Footer';
import { api } from '../../services/api';
import { useAuth } from '../../hooks/User';
import StoreCard from './StoreCard/StoreCard';
import ProfitfyLogo from '../../assets/img/brand/logo.svg';
import { getDomain } from '../../utils/storeDomain';
import NewStoreModal from './NewStoreModal/NewStoreModal';
import AddCircle from '../../assets/img/icons/common/add-circle.svg';
import { useAppmax } from '../../hooks/Appmax';
import TrialingWarn from '../../components/Appmax/TrialingWarn/TrialingWarn';

import './styles.scss';

const Stores = ({ history }) => {
  const { user, logout } = useAuth();
  const { hasAccessedAppmaxPlanPage, hasAccessedAppmaxPlanPagePersistedState } = useAppmax();

  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isTrialingWarnOpen, setIsTrialingWarnOpen] = useState(false);

  const handleTrialingWarnOpen = useCallback(() => setIsTrialingWarnOpen(!isTrialingWarnOpen), [isTrialingWarnOpen]);

  const loadStore = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api('user-api').get(`/api/v1/users/stores`);
      setStores(response?.data?.stores);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (hasAccessedAppmaxPlanPage) {
      history.push('/subscribe/appmax');
    }
  }, [hasAccessedAppmaxPlanPage, history]);

  useEffect(() => {
    if (
      user?.subscription?.lastStatus === 'appmax_plan_paid' &&
      user?.subscription?.status === 'appmax_plan_canceled' &&
      !user?.subscription?.cardId
    ) {
      history.push('/subscribe/plans');
    }
  }, [history, user]);

  useEffect(() => {
    if (hasAccessedAppmaxPlanPagePersistedState && !user?.subscription?.status) {
      history.push('/subscribe/appmax');
    }
  }, [hasAccessedAppmaxPlanPagePersistedState, user, history]);

  useEffect(() => {
    const isSuperUser = user?.role?.includes('ADMIN') || user?.role?.includes('VIP');

    if (
      !user?.subscription?.status &&
      !isSuperUser &&
      !hasAccessedAppmaxPlanPage &&
      !hasAccessedAppmaxPlanPagePersistedState
    ) {
      history.push('/trial');
    }

    loadStore();
  }, [loadStore, history, user, hasAccessedAppmaxPlanPage, hasAccessedAppmaxPlanPagePersistedState]);

  const onOpenStore = useCallback(
    ({ storeId, storePlatform }) => {
      history.push(`/${storePlatform}/${storeId}/dashboard/home`);
    },
    [history],
  );

  const renderSpinner = (
    <div className="spinner-center">
      <Spinner />
    </div>
  );

  if (loading) return renderSpinner;

  return (
    <>
      <NewStoreModal isOpen={isOpen} handleToggle={handleToggle} loadStore={loadStore} />
      <div className="stores-main-content">
        <img src={ProfitfyLogo} alt="Profitfy.me" />
        <p className="user-name">Olá {user?.firstName}!</p>
        <div className="d-flex justify-content-between profile">
          <p>Qual loja deseja acessar?</p>
          <Link to="/stores/user-profile">
            <Button className="profile-link" color="link">
              Meu Perfil
            </Button>
          </Link>
        </div>
        {stores.map(store => (
          <StoreCard
            key={store.domain}
            storeName={store.name}
            storePlatform={store.platform}
            storeId={store.id}
            domain={getDomain(store)}
            handleClick={onOpenStore}
          />
        ))}
        <Card className="shadow store-card" onClick={handleToggle}>
          <div className="d-flex justify-content-between align-items-center create-store">
            <p>Cadastrar Nova Loja</p>
            <img src={AddCircle} alt="" />
          </div>
        </Card>
        {user?.role === 'ADMIN' && (
          <Button className="admin-button" color="link">
            Ir para admin
          </Button>
        )}
        <Button className="exit-button" color="link" onClick={logout}>
          Sair
        </Button>
      </div>
      <div className="stores-footer">
        <Footer />
      </div>

      <TrialingWarn isOpen={isTrialingWarnOpen} toggle={handleTrialingWarnOpen} />
    </>
  );
};

export default memo(Stores);
