import React, { memo, useMemo } from 'react';
import { Card } from 'reactstrap';
import { ResponsivePie } from '@nivo/pie';

import { numberBrFormatter } from '../../../../utils/utils';

import './styles.scss';

const PaymentMethodConversion = ({ billetsCards }) => {
  const dataPie = useMemo(
    () => [
      {
        id: 'Cartão de Crédito',
        label: 'Cartão de Crédito',
        value: billetsCards.creditCard,
        color: '#003266',
      },
      {
        id: 'Boleto Bancário',
        label: 'Boleto Bancário',
        value: billetsCards.billet,
        color: '#0071E5',
      },
      {
        id: 'PIX',
        label: 'PIX',
        value: billetsCards.bankTransfer,
        color: '#80BFFF',
      },
    ],
    [billetsCards.creditCard, billetsCards.billet, billetsCards.bankTransfer],
  );

  const noData = [
    {
      id: 'Sem dados',
      label: 'Sem dados',
      value: 1,
      color: '#CACCCF',
    },
  ];

  const colors = ['#003266', '#0071E5', '#80BFFF'];

  const creditCardPercentage = useMemo(
    () =>
      billetsCards.billet + billetsCards.creditCard + billetsCards.bankTransfer === 0
        ? 0
        : (100 * billetsCards.creditCard) / (billetsCards.billet + billetsCards.creditCard + billetsCards.bankTransfer),
    [billetsCards.billet, billetsCards.creditCard, billetsCards.bankTransfer],
  );

  const billetPercentage = useMemo(
    () =>
      billetsCards.billet + billetsCards.creditCard + billetsCards.bankTransfer === 0
        ? 0
        : (100 * billetsCards.billet) / (billetsCards.billet + billetsCards.creditCard + billetsCards.bankTransfer),
    [billetsCards.billet, billetsCards.creditCard, billetsCards.bankTransfer],
  );

  const pixPercentage = useMemo(
    () =>
      billetsCards.billet + billetsCards.creditCard + billetsCards.bankTransfer === 0
        ? 0
        : (100 * billetsCards.bankTransfer) /
          (billetsCards.billet + billetsCards.creditCard + billetsCards.bankTransfer),
    [billetsCards.billet, billetsCards.creditCard, billetsCards.bankTransfer],
  );

  const isBilletsCardsAmountDifferentThanZero =
    (billetsCards.creditCard || billetsCards.billet || billetsCards.bankTransfer) !== 0;

  return (
    <Card className="payment-method-conversion-card">
      <div className="header">
        <h3>CONVERSÃO POR MEIO DE PAGAMENTO</h3>
      </div>
      <div className="body">
        <div className="d-flex">
          <div className="legend">
            <div className="legend-data">
              <p>{numberBrFormatter(creditCardPercentage, 2)}%</p>
              <div>
                <span className="credit-card" />
                <p>Cartão de Crédito</p>
              </div>
            </div>

            <div className="legend-data">
              <p>{numberBrFormatter(billetPercentage, 2)}%</p>
              <div>
                <span className="billet" />
                <p>Boleto Bancário</p>
              </div>
            </div>

            <div className="legend-data">
              <p>{numberBrFormatter(pixPercentage, 2)}%</p>
              <div>
                <span className="pix" />
                <p>PIX</p>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <div className="card-chart d-flex">
              {isBilletsCardsAmountDifferentThanZero ? (
                <ResponsivePie
                  data={dataPie}
                  colors={colors}
                  innerRadius={0.7}
                  enableRadialLabels={false}
                  enableSlicesLabels={false}
                />
              ) : (
                <ResponsivePie
                  data={noData}
                  colors={['#CACCCF']}
                  innerRadius={0.7}
                  enableRadialLabels={false}
                  enableSlicesLabels={false}
                  isInteractive={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default memo(PaymentMethodConversion);
