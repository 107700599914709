/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo, useState, useCallback } from 'react';
import { Button, Col, Form, FormGroup, Row, Input as StrapInput, Card } from 'reactstrap';
import useForm from 'react-hook-form';
import cepPromise from 'cep-promise';

import { masks } from '../../utils/utils';
import { useAuth } from '../../hooks/User';

import './styles.scss';

const UserPersonalInfo = ({ onSubmit, loading }) => {
  const { user } = useAuth();
  const { register, handleSubmit } = useForm();

  const [userType, setUserType] = useState('physical');
  const [cep, setCep] = useState(user?.address?.zipcode);
  const [cpf, setCpf] = useState(user?.cpf);
  const [cnpj, setCnpj] = useState(user?.cnpj);
  const [address, setAddress] = useState({
    state: user?.address?.uf,
    city: user?.address?.city,
    street: user?.address?.street,
    neighborhood: user?.address?.neighborhood,
    complementary: user?.address?.complementary,
    number: user?.address?.number,
  });

  const handleUserType = useCallback(type => {
    setUserType(type);
  }, []);

  const handleGetAddressByCEP = useCallback(async () => {
    const cepFormatted = cep.replace('-', '');
    const result = await cepPromise(cepFormatted);
    setAddress({ ...address, ...result, ...{ uf: result?.state || result?.uf } });
  }, [cep, address]);

  return (
    <Card className="shadow credit-card main-user-personal-info-container">
      <Form onSubmit={handleSubmit(data => onSubmit({ data, cpf, cnpj }))} role="form">
        <div className="">
          <div className="mb-4">
            <Button
              type="button"
              color={`${userType === 'physical' && 'primary'}`}
              onClick={() => handleUserType('physical')}
              className={`user-type-button ${userType === 'physical' && 'selected-user-type-button'}`}
            >
              Pessoa Física
            </Button>
            <Button
              type="button"
              color={`${userType === 'business' && 'primary'}`}
              onClick={() => handleUserType('business')}
              className={`user-type-button ${userType === 'business' && 'selected-user-type-button'}`}
            >
              Pessoa Jurídica
            </Button>
          </div>
          {userType === 'physical' ? (
            <FormGroup className="cpf">
              <label className="form-control-label" htmlFor="cpf">
                CPF
              </label>
              <StrapInput
                value={cpf}
                type="text"
                id="cpf"
                name="cpf"
                placeholder="000.000.000-00"
                onChange={e => setCpf(masks.cpf(e.target.value))}
              />
            </FormGroup>
          ) : (
            <Row>
              <Col sm="12" md="5">
                <FormGroup>
                  <label className="form-control-label" htmlFor="cnpj">
                    CNPJ
                  </label>
                  <StrapInput
                    value={cnpj}
                    type="text"
                    id="cnpj"
                    name="cnpj"
                    placeholder="00.000.000/0001-00"
                    onChange={e => setCnpj(masks.cnpj(e.target.value))}
                  />
                </FormGroup>
              </Col>
              <Col sm="12" md="7">
                <FormGroup>
                  <label className="form-control-label" htmlFor="companyName">
                    Razão Social
                  </label>
                  <StrapInput
                    type="text"
                    id="companyName"
                    name="companyName"
                    innerRef={register}
                    defaultValue={user?.companyName}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <FormGroup className="cep">
                <label className="form-control-label " htmlFor="cep">
                  CEP
                </label>
                <StrapInput
                  value={cep}
                  type="text"
                  id="cep"
                  name="cep"
                  onChange={e => setCep(masks.cep(e.target.value))}
                  onBlur={handleGetAddressByCEP}
                  innerRef={register}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="city">
                  Cidade
                </label>
                <StrapInput defaultValue={address.city} type="text" id="city" name="city" innerRef={register} />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="state">
                  Estado
                </label>
                <StrapInput
                  defaultValue={address.state}
                  type="text"
                  id="state"
                  name="uf"
                  innerRef={register}
                  maxLength="2"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="8">
              <FormGroup className="flex-1">
                <label className="form-control-label" htmlFor="address">
                  Endereço
                </label>
                <StrapInput id="address" name="street" type="text" defaultValue={address.street} innerRef={register} />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="address-number">
                  Número
                </label>
                <StrapInput
                  id="address-number"
                  className="address-number"
                  name="number"
                  type="text"
                  defaultValue={address.number}
                  innerRef={register}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="neighborhood">
                  Bairro
                </label>
                <StrapInput
                  type="text"
                  name="neighborhood"
                  id="neighborhood"
                  defaultValue={address.neighborhood}
                  innerRef={register}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="complement">
                  Complemento
                </label>
                <StrapInput
                  type="text"
                  name="complementary"
                  id="complement"
                  placeholder="Ex: casa"
                  innerRef={register}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="text-right">
          <Button type="submit" className="btn-icon btn-2 btn-lg" disabled={loading} color="primary">
            {loading ? 'Processando...' : 'Próximo'}
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default memo(UserPersonalInfo);
