import React, { memo } from 'react';
import {
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input as StrapInput,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import './styles.scss';

const MarketingCost = ({ setMarketing }) => {
  return (
    <Col>
      <Card className="shadow marketing-cost ml-2 mr-2">
        <CardHeader>
          <h3 className="mb-0">Custo de Marketing</h3>
        </CardHeader>
        <CardBody className="marketing-cost-body">
          <Row className="justify-content-between align-items-center mb-3 pr-4 pl-4">
            <span>Marketing estimado por aquisição (%)</span>
            <InputGroup className="input-group-alternative input-size" size="sm">
              <StrapInput
                className="text-right"
                type="number"
                placeholder="20"
                name="marketingPercentage"
                onChange={e => setMarketing(Number(e.target.value) / 100 || 0.2)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>%</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default memo(MarketingCost);
