import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { CurrencyDollarSimple, DotsThree } from 'phosphor-react';
import { Popover, PopoverHeader, PopoverBody, Button } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment-timezone';

import { api, shopsApi } from '../../../../services/api';
import { numberBrFormatter } from '../../../../utils/utils';

import './styles.scss';

const Profit = ({ mainCards, storeConfigurationData, customSpends, mutateStoreData, storePeriod, refunds }) => {
  const { platform, store } = useParams();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isRefundedPopoverOpen, setIsRefundedPopoverOpen] = useState(false);
  const [storeConfiguration, setStoreConfiguration] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const customSpendsAmount = useMemo(() => {
    const customSpendsIn = customSpends?.appIn + customSpends?.costIn + customSpends?.employeeIn + customSpends?.noneIn;

    const customSpendsOut =
      customSpends?.appOut + customSpends?.costOut + customSpends?.employeeOut + customSpends?.noneOut;

    return customSpendsIn - customSpendsOut;
  }, [customSpends]);

  const handleCustomSpends = useCallback(async () => {
    const status = storeConfiguration?.addCustomSpendInProfit || false;
    const requestType = storeConfiguration?.id ? 'put' : 'post';

    setIsLoading(true);

    try {
      const { data } = await api(shopsApi[platform])[requestType](
        `/api/v1/users/${platform}/stores/${store}/store-configurations/${storeConfiguration?.id || ''}`,
        {
          updateAliexpressCostToVariant: false,
          ...storeConfiguration,
          addCustomSpendInProfit: !status,
        },
      );

      await mutateStoreData();

      setStoreConfiguration(data.storeConfiguration);
    } finally {
      setIsLoading(false);
    }
  }, [platform, store, storeConfiguration, mutateStoreData]);

  const handlePopover = useCallback(() => setIsPopoverOpen(!isPopoverOpen), [isPopoverOpen]);

  const handleRefundedPopover = useCallback(
    () => setIsRefundedPopoverOpen(!isRefundedPopoverOpen),
    [isRefundedPopoverOpen],
  );

  useEffect(() => {
    if (storeConfigurationData?.storeConfiguration) {
      setStoreConfiguration(storeConfigurationData.storeConfiguration);
    }
  }, [storeConfigurationData]);

  return (
    <div className="card-details-profit">
      <div className="header">
        <span>LUCRO LÍQUIDO</span>
        <div className="icons">
          <div>
            <DotsThree size={28} weight="bold" id="custom-spends-popover" />
            <Popover
              trigger="legacy"
              isOpen={isPopoverOpen}
              toggle={handlePopover}
              placement="bottom-end"
              target="custom-spends-popover"
              className="profit-custom-spends-popover"
            >
              <PopoverHeader className="header">CONFIGURAÇÕES</PopoverHeader>
              <PopoverBody className="body">
                <div>
                  <span>Incluir valores adicionais</span>
                  {storeConfiguration?.addCustomSpendInProfit ? (
                    <Button className="deactivate" disabled={isLoading} outline onClick={handleCustomSpends}>
                      Desativar
                    </Button>
                  ) : (
                    <Button
                      className="activate"
                      disabled={isLoading}
                      outline
                      color="primary"
                      onClick={handleCustomSpends}
                    >
                      Ativar
                    </Button>
                  )}
                </div>
              </PopoverBody>
            </Popover>
          </div>
          <div>
            <CurrencyDollarSimple size={16} weight="bold" />
          </div>
        </div>
      </div>
      <div className="body">
        <div className="details">
          <div className="value-wrapper">
            <span>Faturamento Líquido</span>
            <span>
              <small>R$</small>
              {numberBrFormatter(mainCards.revenue, 2)}
            </span>
          </div>

          <div className="value-wrapper">
            <span>Custo dos Produtos</span>
            <span>
              <small>{mainCards.cogs !== 0 && '- '}R$</small>
              {numberBrFormatter(mainCards.cogs, 2)}
            </span>
          </div>

          <div className="value-wrapper">
            <span>Marketing</span>
            <span>
              <small>{mainCards.marketing !== 0 && '- '}R$</small>
              {numberBrFormatter(mainCards.marketing, 2)}
            </span>
          </div>

          <div className="value-wrapper">
            <span>Taxa de Transações</span>
            <span>
              <small>{mainCards.transactionTaxes !== 0 && '- '}R$</small>
              {numberBrFormatter(mainCards.transactionTaxes, 2)}
            </span>
          </div>

          {mainCards.chargedBackAmount + mainCards.refundedAmount !== 0 && (
            <div className="value-wrapper refunded-chargeback">
              <span id="refunded-popover">Estornos e Chargebacks</span>
              <span>
                <small>- R$</small>
                {numberBrFormatter(mainCards.chargedBackAmount + mainCards.refundedAmount, 2)}
              </span>

              <Popover
                trigger="hover"
                isOpen={isRefundedPopoverOpen}
                toggle={handleRefundedPopover}
                placement="top-end"
                target="refunded-popover"
                className="profit-refunded-popover"
              >
                <PopoverBody className="body">
                  <div>
                    <p>
                      Veja quais pedidos foram devolvidos e estornados{' '}
                      <Link
                        to={`/${platform}/${store}/dashboard/orders?startDate=${moment(
                          refunds?.refundedCreatedAt,
                        )}&endDate=${storePeriod.endDate}&selectedStartDate=${
                          storePeriod.startDate
                        }&refusedAndChargedback=true`}
                      >
                        clicando aqui
                      </Link>
                      .
                    </p>
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          )}

          {storeConfiguration?.addCustomSpendInProfit && (
            <div className="value-wrapper">
              <span>Valores Adicionais</span>
              <span>
                <small>{customSpendsAmount !== 0 && '- '}R$</small>
                {customSpendsAmount < 0
                  ? numberBrFormatter(customSpendsAmount * -1, 2)
                  : numberBrFormatter(customSpendsAmount, 2)}
              </span>
            </div>
          )}
        </div>

        <hr />

        <div className="total-wrapper">
          <span>LÍQUIDO</span>
          <span>
            <small>{mainCards.profit < 0 && '- '}R$</small>
            {mainCards.profit < 0
              ? numberBrFormatter(mainCards.profit * -1, 2)
              : numberBrFormatter(mainCards.profit, 2)}
          </span>
        </div>
      </div>

      <div className="gradient-border-detail">
        <div />
      </div>
    </div>
  );
};

export default memo(Profit);
