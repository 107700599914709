import React, { useCallback, useMemo, useState, Fragment } from 'react';
import {
  Collapse,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useParams, useHistory, NavLink as NavLinkRRD, Link } from 'react-router-dom';
import { SignOut, UserCircleGear, Storefront } from 'phosphor-react';

import { useAuth } from '../../../hooks/User';
import { getPlatformImage } from '../../../utils/platformImage';

import './styles.scss';

const smallDevice = window.matchMedia('(max-width: 1279px)').matches;

const SidebarMobile = ({ routes, stores, logo }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { logout } = useAuth();

  const { store: id, platform } = useParams();
  const history = useHistory();

  const toggleCollapse = useCallback(() => {
    setCollapseOpen(!collapseOpen);
  }, [collapseOpen]);

  const closeCollapse = useCallback(() => {
    setCollapseOpen(false);
  }, []);

  const selectedStore = useMemo(() => stores.find(store => store.id === id), [stores, id]);

  const dashboardMenuRoutes = useMemo(() => {
    return routes.map(prop => {
      if (prop.layout === '/dashboard' && !(smallDevice && prop.path === '/home')) {
        return (
          <NavItem key={prop.name}>
            <NavLink
              to={`/${platform}/${id}${prop.layout}${prop.path}`}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              activeClassName="active"
              className="text-light"
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      }
      return [];
    });
  }, [closeCollapse, platform, routes, id]);

  const dropdownRoutes = useMemo(() => {
    return routes.map(prop => {
      if (prop.layout === '/dashboard/config') {
        return (
          <NavItem key={prop.name}>
            <NavLink
              to={`/${platform}/${id}${prop.layout}${prop.path}`}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              activeClassName="active"
              className="text-light "
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      }
      return [];
    });
  }, [closeCollapse, platform, routes, id]);

  const handleChangeStore = useCallback(
    (id, platform) => {
      history.push(`/${platform}/${id}/dashboard/home`);

      setIsMenuOpen(false);
    },
    [history],
  );

  const handleNewStore = useCallback(() => {
    history.push('/stores');

    setIsMenuOpen(false);
  }, [history]);

  const handleProfile = useCallback(() => {
    history.push(`/${platform}/${id}/dashboard/user-profile`);

    setIsMenuOpen(false);
  }, [history, platform, id]);

  const handleIsMenuOpen = useCallback(() => setIsMenuOpen(!isMenuOpen), [isMenuOpen]);

  return (
    <Navbar className="navbar-vertical fixed-left navbar-dark bg-gradient-gray-dark d-md-none" id="sidenav-main">
      <Container fluid>
        {/* Toggler */}
        <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}

        <div className="img-fluid text-center d-none d-md-inline mt-3">
          <img alt={logo.imgAlt} className="img-fluid" width="100%" src={logo?.imgSrc} />
        </div>

        <Nav className="align-items-center justify-content-end flex-grow-1 d-md-none">
          <UncontrolledDropdown isOpen={isMenuOpen} toggle={handleIsMenuOpen} className="gray-dark flex-grow-1" nav>
            <DropdownToggle className="text-right" nav>
              <span className="font-weight-bold text-md text-secondary">{selectedStore?.name}</span>
              <i className="ni ni-bold-down text-secondary mr-3 pl-3" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <span>Você está visualizando</span>
              <span>{selectedStore?.name}</span>
              <span>{selectedStore?.domain}</span>
              <span>Outras lojas:</span>

              {stores.map(store => (
                <Fragment key={store.id}>
                  <button
                    type="button"
                    className="navbar-other-store"
                    onClick={() => handleChangeStore(store.id, store.platform)}
                  >
                    <img src={getPlatformImage(store.platform)} alt={store.platform} />
                    <span>{store.name}</span>
                    <i className="fas fa-chevron-right" />
                  </button>
                  <hr />
                </Fragment>
              ))}

              <button type="button" className="new-store" onClick={handleNewStore}>
                <Storefront size={28} weight="bold" />
                <span>Registrar nova loja</span>
              </button>
              <button type="button" className="profile" onClick={handleProfile}>
                <UserCircleGear size={28} weight="bold" />
                <span>Meu Perfil</span>
              </button>
              <button type="button" className="exit" onClick={logout}>
                <SignOut size={28} weight="bold" />
                <span>Sair</span>
              </button>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              <Col className="collapse-brand" xs="6">
                {logo?.innerLink ? (
                  <Link to={logo.innerLink}>
                    <img alt={logo.imgAlt} src={logo.imgSrc} />
                  </Link>
                ) : (
                  <Link href={logo.outterLink}>
                    <img alt={logo.imgAlt} src={logo.imgSrc} />
                  </Link>
                )}
              </Col>

              <Col className="collapse-close" xs="6">
                <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>

          {/* Navigation */}

          <Nav navbar>{dashboardMenuRoutes}</Nav>
          {/* Divider */}
          <hr className="my-3 bg-primary" />
          <h2 className="navbar-heading text-secondary">Configurações</h2>
          <Nav navbar>{dropdownRoutes}</Nav>
          <hr className="my-3 bg-primary" />
          {/* Heading */}
          <h6 className="navbar-heading text-secondary">Central de Ajuda</h6>
          {/* Navigation */}
          <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink href="https://docs.profitfy.me/tutoriais/integracao-com-mercado-pago" className="text-light">
                <i className="ni ni-support-16 text-green" />
                Tutoriais
              </NavLink>

              <NavLink href="https://docs.profitfy.me/outros/fale-conosco" className="text-light">
                <i className="ni ni-mobile-button text-light" />
                Contate-nos
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default SidebarMobile;
