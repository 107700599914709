import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { ChartBarHorizontal, Question } from 'phosphor-react';
import { Link, useParams } from 'react-router-dom';
import { Popover, PopoverBody } from 'reactstrap';
import { ResponsiveBar } from '@nivo/bar';

import Chart from './Chart/Chart';
import { numberBrFormatter } from '../../../../utils/utils';
import ChartTooltip from './Chart/ChartTooltip/ChartTooltip';
import usePersistedState from '../../../../hooks/PersistedState';
import shopifySvg from '../../../../assets/img/brand/shopify.svg';
import cartxSvg from '../../../../assets/img/brand/cartx.svg';

import './styles.scss';

const noData = [
  {
    id: 'Sem dados',
    no_data: 1,
  },
];

const Revenue = ({ mainCards, storePeriod }) => {
  const { platform, store } = useParams();
  const [isRevenueTipOpen, setIsRevenueTipOpen] = usePersistedState('isRevenueTipOpen', true);

  const [isOpen, setIsOpen] = useState(false);
  const [isTipOpen, setIsTipOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleTip = useCallback(() => setIsTipOpen(true), []);

  const handlePopoverOpen = useCallback(() => setIsPopoverOpen(!isPopoverOpen), [isPopoverOpen]);

  const handlePopover = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const handleUnderstoodTip = useCallback(() => setIsRevenueTipOpen(false), [setIsRevenueTipOpen]);

  const grossAmount = useMemo(
    () => mainCards.revenue + mainCards.waitOrders + mainCards.totalOrdersUnknownAmount,
    [mainCards],
  );

  const hasRevenue = useMemo(() => {
    if (grossAmount !== 0) return true;

    return false;
  }, [grossAmount]);

  const data = useMemo(
    () => [
      {
        id: 'Faturamento',
        gross: grossAmount,
        pending: mainCards.waitOrders,
        no_info: mainCards.totalOrdersUnknownAmount,
      },
    ],
    [grossAmount, mainCards],
  );

  useEffect(() => {
    setIsTipOpen(true);
  }, []);

  return (
    <div className="card-details-revenue">
      <div className="header">
        <span>FATURAMENTO</span>
        <div>
          <ChartBarHorizontal size={16} weight="bold" />
        </div>
      </div>
      <div className="body">
        <div className="details">
          <div className="value-wrapper" id="platform-revenue">
            <span>
              Bruto <Question size={14} />
            </span>
            <span>
              <small>R$</small>
              {numberBrFormatter(grossAmount, 2)}
            </span>
          </div>

          <Popover
            placement="bottom"
            isOpen={isPopoverOpen}
            target="platform-revenue"
            toggle={handlePopoverOpen}
            trigger="hover"
            className="custom-platform-revenue-tip"
          >
            <PopoverBody className="body">
              <div className="revenue-data">
                <div>
                  <img src={platform === 'shopify' ? shopifySvg : cartxSvg} alt="Profitfy.me faturamento" />
                  <span>Faturamento na {platform === 'shopify' ? 'Shopify' : 'CartX'}:</span>
                </div>

                <span>R${numberBrFormatter(mainCards?.platformRevenue, 2)}</span>
              </div>

              <p>
                O <strong>faturamento da {platform}</strong> pode estar <strong>diferente do faturamento bruto </strong>
                devido ao fato de <strong>consultarmos cada pedido através do seu Gateway</strong>, o que já{' '}
                <strong>inclui a absorção de parcelas e descontos</strong> (não inclui as taxas de transações).
              </p>
            </PopoverBody>
          </Popover>

          {isRevenueTipOpen && (
            <Popover
              placement="bottom"
              isOpen={isTipOpen}
              target="platform-revenue"
              toggle={handleTip}
              trigger="hover"
              className="custom-platform-revenue-tip"
            >
              <PopoverBody className="body">
                <div className="revenue-data">
                  <div>
                    <img src={platform === 'shopify' ? shopifySvg : cartxSvg} alt="Profitfy.me faturamento" />
                    <span>Faturamento na {platform === 'shopify' ? 'Shopify' : 'CartX'}:</span>
                  </div>

                  <span>R${numberBrFormatter(mainCards?.platformRevenue, 2)}</span>
                </div>

                <p>
                  O <strong>faturamento da {platform}</strong> pode estar{' '}
                  <strong>diferente do faturamento bruto </strong>
                  devido ao fato de <strong>consultarmos cada pedido através do seu Gateway</strong>, o que já{' '}
                  <strong>inclui a absorção de parcelas e descontos</strong> (não inclui as taxas de transações).
                </p>

                <button type="button" onClick={handleUnderstoodTip}>
                  Entendi
                </button>
              </PopoverBody>
            </Popover>
          )}

          <div className="value-wrapper">
            <span>Pendente</span>
            <span>
              <small>{mainCards.waitOrders !== 0 && '- '}R$</small>
              {numberBrFormatter(mainCards.waitOrders, 2)}
            </span>
          </div>

          {mainCards?.totalOrdersUnknownAmount !== 0 && (
            <div className="more-information-wrapper">
              <div>
                <span>Pedidos sem informações</span>
                <span>
                  <small>- R$</small>
                  {numberBrFormatter(mainCards.totalOrdersUnknownAmount, 2)}
                </span>
              </div>

              <Link
                to={`/${platform}/${store}/dashboard/orders?startDate=${storePeriod.startDate}&endDate=${storePeriod.endDate}&status=no_info`}
                className="text-link more-details"
              >
                Mais informações
              </Link>
            </div>
          )}
        </div>

        <hr />

        <div className="total-and-chart">
          <div className="total-wrapper">
            <span>LÍQUIDO</span>
            <span>
              <small>R$</small>
              {numberBrFormatter(mainCards.revenue, 2)}
            </span>
          </div>

          <div className="chart" id="revenue-card-chart-tooltip">
            {hasRevenue ? (
              <Chart data={data} />
            ) : (
              <ResponsiveBar
                data={noData}
                keys={['no_data']}
                indexBy="id"
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                padding={0}
                layout="horizontal"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={['#CACCCF']}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={null}
                enableGridY={false}
                enableGridX={false}
                enableLabel={false}
                legends={[]}
                animate
                motionStiffness={90}
                motionDamping={15}
                isInteractive={false}
              />
            )}
          </div>
          <ChartTooltip data={mainCards} target="revenue-card-chart-tooltip" toggle={handlePopover} isOpen={isOpen} />
        </div>
      </div>

      <div className="gradient-border-detail">
        <div />
      </div>
    </div>
  );
};

export default memo(Revenue);
