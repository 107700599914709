import React, { useCallback } from 'react';
import { Modal } from 'reactstrap';
import { InlineWidget, CalendlyEventListener } from 'react-calendly';

import { useToast } from '../../hooks/Toast';
import { useAuth } from '../../hooks/User';
import { hasScheduled } from '../../utils/customerx';

import './styles.scss';

const CalendarSchedule = ({ isOpen, toggle }) => {
  const { showToast } = useToast();
  const { user } = useAuth();

  const onScheduled = useCallback(async () => {
    showToast({ message: 'Onboarding marcado com sucesso!', type: 'success' });

    await hasScheduled({ userId: user.id });

    toggle();
  }, [showToast, toggle, user]);

  return (
    <Modal className="onboarding-calendar" centered isOpen={isOpen}>
      <CalendlyEventListener onEventScheduled={onScheduled}>
        <InlineWidget url="https://calendly.com/gabrielrodrigoprofitfy-me/onboarding" />
      </CalendlyEventListener>
    </Modal>
  );
};

export default CalendarSchedule;
