import React, { useEffect, useState, useCallback, memo, useMemo } from 'react';
import { Switch, Redirect, useParams, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { AnimatePresence } from 'framer-motion';

import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';
import menuRoutes from '../../routes/menuRoutes';
import FirstTimeContext from '../../hooks/FirstTimeContext';
import ProductDetails from './ProductDetails/ProductDetails';
import Profile from '../../components/Profile/Profile';
import logo from '../../assets/img/brand/logo-white.svg';
import { api, shopsApi } from '../../services/api';
import Spinner from '../../components/Spinner/Spinner';
import ProcessingData from '../../components/ProcessingData/ProcessingData';
import usePersistedState from '../../hooks/PersistedState';
import CalcPastThirtyDaysAlert from '../../components/CalcPastThirtyDaysAlert/CalcPastThirtyDaysAlert';
import notificationSound from '../../assets/audio/notification.mp3';
import ProductCostVariantTable from './ProductCostVariantTable/ProductCostVariantTable';
import { useAuth } from '../../hooks/User';

const Dashboard = props => {
  const [calcPastThirtyDaysNotification, setCalcPastThirtyDaysNotification] = usePersistedState(
    'calcPastThirtyDaysNotification',
    {},
  );
  const [calcPastTwoDaysNotification, setCalcPastTwoDaysNotification] = usePersistedState(
    'calcPastTwoDaysNotification',
    {},
  );
  const location = useLocation();
  const { store, platform } = useParams();
  const { user } = useAuth();
  const history = useHistory();

  const [isNewUser, setIsNewUser] = useState(false);
  const [storeCreatedAt, setStoreCreatedAt] = useState(null);
  const [stores, setStores] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessingLastTwoDaysOrders, setIsProcessingLastTwoDaysOrders] = useState(false);
  const [isProcessingLastThirtyDaysOrders, setIsProcessingLastThirtyDaysOrders] = useState(false);

  const locationMemo = useMemo(() => location, [location]);

  const value = useMemo(() => ({ storeCreatedAt, isNewUser }), [storeCreatedAt, isNewUser]);

  const { brandText, productTourLink } = useMemo(() => {
    const result = menuRoutes.find(route => {
      const locationPath = locationMemo.pathname.split('/').pop();
      const routePath = route.path.split('/').join('');

      return locationPath.includes(routePath);
    });

    return { brandText: result?.name, productTourLink: result?.productTourLink };
  }, [locationMemo.pathname]);

  const renderDashboardRoutes = useMemo(() => {
    return (
      <>
        <PrivateRoute
          path="/:platform/:store/dashboard/product-details/:productId/product-details"
          component={props => <ProductDetails {...props} />}
        />
        <PrivateRoute
          path={`/${platform}/${store}/dashboard/user-profile`}
          component={props => <Profile {...props} />}
        />
        <PrivateRoute
          exact
          path="/:platform/:store/dashboard/config/products-costs/:productId/details"
          component={props => <ProductCostVariantTable {...props} />}
        />
      </>
    );
  }, [platform, store]);

  const renderMenuRoutes = useMemo(() => {
    return menuRoutes.map(prop => {
      if (prop.layout.includes('/dashboard')) {
        return (
          <PrivateRoute
            {...props}
            path={`/:platform/:store${prop.layout}${prop.path}`}
            component={prop.component}
            key={prop.name}
            exact
          />
        );
      }
      return null;
    });
  }, [props]);

  const getCalcPastThirtyDaysStatus = useCallback(async () => {
    const { data } = await api(shopsApi[platform]).get(
      `/api/v1/users/${platform}/stores/${store}/calculate-historic/${calcPastThirtyDaysNotification[store]?.id}`,
    );

    if (data?.calculateHistoric?.status === 'SUCCESS') {
      setCalcPastThirtyDaysNotification(prevState => ({ ...prevState, [store]: data.calculateHistoric }));
      setIsProcessingLastThirtyDaysOrders(false);

      return;
    }

    const interval = setInterval(async () => {
      const { data: intervalData } = await api(shopsApi[platform]).get(
        `/api/v1/users/${platform}/stores/${store}/calculate-historic/${calcPastThirtyDaysNotification[store]?.id}`,
      );

      if (intervalData?.calculateHistoric?.status === 'SUCCESS') {
        clearInterval(interval);
        setCalcPastThirtyDaysNotification(prevState => ({ ...prevState, [store]: intervalData.calculateHistoric }));
        setIsProcessingLastThirtyDaysOrders(false);

        const audioNotification = new Audio(notificationSound);
        audioNotification.play();
      }
    }, 60000);
  }, [calcPastThirtyDaysNotification, platform, store, setCalcPastThirtyDaysNotification]);

  const getCalcPastTwoDaysStatus = useCallback(async () => {
    const { data } = await api(shopsApi[platform]).get(
      `/api/v1/users/${platform}/stores/${store}/calculate-historic/${calcPastTwoDaysNotification[store]?.id}`,
    );

    if (data?.calculateHistoric?.status === 'SUCCESS') {
      setCalcPastTwoDaysNotification(prevState => ({ ...prevState, [store]: data.calculateHistoric }));
      setIsProcessingLastTwoDaysOrders(false);

      return;
    }

    const interval = setInterval(async () => {
      const { data: intervalData } = await api(shopsApi[platform]).get(
        `/api/v1/users/${platform}/stores/${store}/calculate-historic/${calcPastTwoDaysNotification[store]?.id}`,
      );

      if (intervalData?.calculateHistoric?.status === 'SUCCESS') {
        clearInterval(interval);
        setCalcPastTwoDaysNotification(prevState => ({ ...prevState, [store]: intervalData.calculateHistoric }));
        setIsProcessingLastTwoDaysOrders(false);

        const audioNotification = new Audio(notificationSound);
        audioNotification.play();
      }
    }, 30000);
  }, [calcPastTwoDaysNotification, platform, store, setCalcPastTwoDaysNotification]);

  const loadStoreData = useCallback(async () => {
    const { data } = await api(shopsApi[platform]).get(`/api/v1/users/${platform}/stores/${store}/first-time`);

    setIsNewUser(data.isFirstTime);
    setStoreCreatedAt(data.storeCreatedAt);
    moment.tz.setDefault(data.timezone);
  }, [platform, store]);

  const loadStores = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await api('user-api').get(`/api/v1/users/stores`);

      // response.data.stores.map(innerStore => {
      //   if (innerStore.slug === store) {
      //     const actualLocation = location.pathname.split('/dashboard/').pop();

      //     history.push(`/${platform}/${innerStore.id}/dashboard/${actualLocation}`);
      //   }

      //   return null;
      // });

      setStores(response.data.stores);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadStores();
  }, [loadStores]);

  useEffect(() => {
    loadStoreData();
  }, [loadStoreData]);

  useEffect(() => {
    if (
      user?.subscription?.lastStatus === 'appmax_plan_paid' &&
      user?.subscription?.status === 'appmax_plan_canceled' &&
      !user?.subscription?.cardId
    ) {
      history.push('/subscribe/plans');
    }
  }, [history, user]);

  useEffect(() => {
    const storeCalcPastThirtyDaysNotification = calcPastThirtyDaysNotification[store];

    if (storeCalcPastThirtyDaysNotification?.status === 'PENDING') {
      setIsProcessingLastThirtyDaysOrders(true);
      getCalcPastThirtyDaysStatus();
    }
  }, [calcPastThirtyDaysNotification, getCalcPastThirtyDaysStatus, store]);

  useEffect(() => {
    const storeCalcPastTwoDaysNotification = calcPastTwoDaysNotification[store];

    if (storeCalcPastTwoDaysNotification?.status === 'PENDING') {
      setIsProcessingLastTwoDaysOrders(true);
      getCalcPastTwoDaysStatus();
    }
  }, [calcPastTwoDaysNotification, getCalcPastTwoDaysStatus, store]);

  return (
    <>
      {isNewUser ? (
        <Redirect to={`/${platform}/${store}/onboarding/gateway`} />
      ) : (
        <FirstTimeContext.Provider value={value}>
          <AnimatePresence exitBeforeEnter>
            {isProcessingLastTwoDaysOrders ? (
              <ProcessingData />
            ) : (
              <>
                <Sidebar
                  stores={stores}
                  logo={{
                    innerLink: '/admin/index',
                    imgSrc: logo,
                    imgAlt: 'logo-profitfy',
                  }}
                />
                <div className="main-content">
                  <Navbar {...props} brandText={brandText} productTourLink={productTourLink} stores={stores} />
                  <div className="vh-90">
                    <Switch>
                      {isLoading ? (
                        <Spinner loading />
                      ) : (
                        <>
                          {isProcessingLastThirtyDaysOrders && <CalcPastThirtyDaysAlert />}
                          {renderMenuRoutes}
                          {renderDashboardRoutes}
                        </>
                      )}
                    </Switch>
                  </div>
                  <Footer />
                </div>
              </>
            )}
          </AnimatePresence>
        </FirstTimeContext.Provider>
      )}
    </>
  );
};

export default memo(Dashboard);
