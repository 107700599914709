const getProcessingTips = tipIndex =>
  ({
    0: '"As métricas são como uma bússola para um navio que quer chegar ao norte."',
    1: '"Você sabia que é possível encontrar o valor ideal de venda de seus produtos com a calculadora de precificação da Profitfy.me?"',
    2: '"O que não é medido não pode ser melhorado"',
    3: '"Você sabia que temos uma ferramenta para encontrar públicos ocultos dentro da plataforma?"',
    4: '"Quer aprender mais sobre o dropshipping e e-commerce? Acesse a página de mentorias dentro da plataforma."',
    5: '"Você sabia que a margem de lucro média de uma operação do Dropshipping internacional gira em torno de 20%?"',
    6: '"Você sabia que a ferramenta de Análise de Produto te ajuda a testar mais de 10 produtos ao mesmo tempo sem perder o controle dos mesmos?"',
  }[tipIndex]);

export { getProcessingTips };
