import React, { memo, useState, useCallback } from 'react';
import { InputGroup, Input, Label, InputGroupAddon, Button } from 'reactstrap';

import { masks, currencyToNumber } from '../../../../utils/utils';
import DefaultImage from '../../../DefaultImage/DefaultImage';

import './styles.scss';

const ProductCostConfig = ({
  setHasChangedData,
  setSelectedCurrency,
  setSelectedIof,
  setAllVariantsCost,
  productData,
  setEditedVariants,
  variants,
  showDeletedVariants,
  setShowDeletedVariants,
  selectedCurrency,
}) => {
  const { product } = productData;

  const [variantsCost, setVariantsCost] = useState('');

  const handleSelectCurrency = useCallback(
    event => {
      setSelectedCurrency(event.target.value);
      setHasChangedData(true);
    },
    [setSelectedCurrency, setHasChangedData],
  );

  const handleSelectIof = useCallback(
    event => {
      setSelectedIof(event.target.value);
      setHasChangedData(true);
    },
    [setSelectedIof, setHasChangedData],
  );

  const handleCostToVariants = useCallback(event => {
    event.target.value = masks.currency(event.target.value);

    setVariantsCost(event.target.value);
  }, []);

  const getParsedVariants = useCallback(
    () => variants.map(variant => ({ ...variant, cost: currencyToNumber(variantsCost) })),
    [variants, variantsCost],
  );

  const handleApplyToAllVariants = useCallback(() => {
    if (variantsCost) {
      const parsedVariants = getParsedVariants();

      setEditedVariants(parsedVariants);
      setAllVariantsCost(variantsCost);
      setHasChangedData(true);
    }
  }, [variantsCost, setAllVariantsCost, setHasChangedData, getParsedVariants, setEditedVariants]);

  return (
    <div className="product-cost-config">
      {product?.image ? (
        <img src={product.image} alt="Profitfy.me produto" />
      ) : (
        <DefaultImage className="default-image" size={112} />
      )}

      <div className="configurations">
        <span>Configurações</span>

        <div className="currency-and-iof-inputs">
          <InputGroup className="currency-input-select">
            <Label>
              <span>Moeda</span>
            </Label>
            <Input
              type="select"
              name="currency"
              id="currency"
              onChange={handleSelectCurrency}
              defaultValue={product.currency || 'USD'}
            >
              <option value="USD">U$</option>
              <option value="BRL">BRL</option>
            </Input>
          </InputGroup>

          <InputGroup className="iof-input-select">
            <Label>
              <span>IOF</span>
            </Label>
            <Input
              type="select"
              name="iof"
              id="iof"
              onChange={handleSelectIof}
              defaultValue={product.buyMethod || 'TICKET'}
            >
              <option value="TICKET">Boleto 0,38%</option>
              <option value="CARD">Cartão de Crédito 6,38%</option>
              <option value="NONE">Nenhum</option>
            </Input>
          </InputGroup>
        </div>

        <div className="cost-to-all-variants-and-show-deleted-variants">
          <div className="apply-to-all-variants-input">
            <Label>
              <span>Aplicar um custo a todas as variantes</span>
            </Label>

            <InputGroup className="cost-input-group">
              <InputGroupAddon addonType="prepend">{selectedCurrency === 'USD' ? 'U$' : 'R$'}</InputGroupAddon>
              <Input
                placeholder="0,00"
                name="cost-to-all"
                id="cost-to-all"
                type="text"
                onChange={handleCostToVariants}
              />
              <InputGroupAddon addonType="append">
                <Button onClick={handleApplyToAllVariants} color="primary">
                  Aplicar a todas
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>

          <InputGroup className="show-deleted-variants">
            <Label check>
              <Input
                type="checkbox"
                color="primary"
                checked={showDeletedVariants}
                name="show-deleted-variants"
                id="show-deleted-variants"
                onChange={event => setShowDeletedVariants(event.target.checked)}
              />
              <span>Exibir variantes excluídas</span>
            </Label>
          </InputGroup>
        </div>
      </div>
    </div>
  );
};

export default memo(ProductCostConfig);
