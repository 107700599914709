import React, { useEffect, useState, useCallback } from 'react';
import { CircleSpinner } from 'react-spinners-kit';

import profitfyLogo from '../../../assets/img/brand/logo-white.svg';
import appmaxLogo from '../../../assets/img/brand/appmax/appmax-logo.png';
import plusImage from '../../../assets/img/brand/appmax/plus.svg';
import { useAppmax } from '../../../hooks/Appmax';
import DeleteAllCredentialsWarn from '../../../components/Appmax/DeleteAllCredentials/DeleteAllCredentialsWarn';
import AppmaxTermsOfUsageModal from '../../../components/AppmaxTermsOfUsage/AppmaxTermsOfUsage';

import './styles.scss';

const Appmax = () => {
  const {
    handleHasAccessedAppmaxPlanPage,
    isValidatingAppmax,
    hasOthersGatewaysCredentials,
    isLoadingStores,
    handleHasAccessedAppmaxPlanPagePersistedState,
  } = useAppmax();

  const [isDeleteAllCredentialsWarnOpen, setIsDeleteAllCredentialsWarnOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const handleDeleteAllCredentialsWarn = useCallback(
    () => setIsDeleteAllCredentialsWarnOpen(!isDeleteAllCredentialsWarnOpen),
    [isDeleteAllCredentialsWarnOpen],
  );

  const handleTermsModalOpen = useCallback(() => setIsTermsModalOpen(!isTermsModalOpen), [isTermsModalOpen]);

  useEffect(() => {
    handleHasAccessedAppmaxPlanPage(false);
    handleHasAccessedAppmaxPlanPagePersistedState();
  }, [handleHasAccessedAppmaxPlanPage, handleHasAccessedAppmaxPlanPagePersistedState]);

  return (
    <div className="subscribe-appmax-container">
      <div className="appmax-header-wrapper">
        <div className="appmax-header">
          <img className="profitfy" src={profitfyLogo} alt="Profitfy.me" />
          <img className="plus" src={plusImage} alt="Profitfy.me" />
          <img className="appmax" src={appmaxLogo} alt="Profitfy.me Appmax" />
        </div>
      </div>

      <div className="content">
        <h1>
          Cliente Profitfy tem seu <h1>plano mensal grátis</h1> processando com a Appmax
        </h1>

        <h2>Quais os critérios para participar?</h2>

        <h3>
          Leia com atenção os critérios abaixo para participar da promoção entra a Appmax e a Profitfy. Em caso de
          dúvidas, entre em contato conosco por um de nossos canais de atendimento.
        </h3>

        <ul>
          <li>
            <p>
              Para poder ativar o plano Appmax, é necessário ter{' '}
              <strong>aberto uma conta na Appmax através deste link:</strong>{' '}
              <a href="https://appmax.com.br/cadastro?i=10269" rel="noopener noreferrer" target="_blank">
                <strong>https://appmax.com.br/cadastro?i=10269</strong>
              </a>
              ;
            </p>
          </li>
          <li>
            <p>O e-mail cadastrado na Appmax precisa ser o mesmo utilizado na nossa plataforma;</p>
          </li>
          <li>
            <p>
              Têm direito ao benefício clientes que tenham, nos últimos 30 dias,{' '}
              <strong>
                faturamento mensal igual ou superior a R$ 5.000,00 (cinco mil reais.) via cartão de crédito, dentro da
                plataforma Appmax, não incluindo vendas processadas em outras plataformas.
              </strong>
            </p>
          </li>
          <li>
            <p>
              A mensalidade do <strong>serviço da Profitfy será paga pela Appmax</strong> enquanto o participante
              continuar utilizando os <strong>serviços das duas empresas simultaneamente;</strong>
            </p>
          </li>
          <li>
            <p>
              A mensalidade do plano Profitfy a ser paga pela Appmax é calculada{' '}
              <strong>a partir do faturamento do e-commerce,</strong> não havendo limite de valor (máximo ou mínimo);
            </p>
          </li>
          <li>
            <p>
              Caso você <strong>possua uma conta na Appmax, mas não a utiliza há mais de 3 meses</strong>, entre em{' '}
              <strong>contato com o nosso suporte (Profitfy)</strong> para poder participar dessa promoção.
            </p>
          </li>
        </ul>

        <button
          className="subscribe-button"
          type="button"
          disabled={isValidatingAppmax || isLoadingStores}
          onClick={hasOthersGatewaysCredentials() ? handleDeleteAllCredentialsWarn : handleTermsModalOpen}
        >
          {isValidatingAppmax ? <CircleSpinner size={28} /> : 'Ativar plano Appmax'}
        </button>

        <DeleteAllCredentialsWarn
          isOpen={isDeleteAllCredentialsWarnOpen}
          toggle={handleDeleteAllCredentialsWarn}
          handleTermsModalOpen={handleTermsModalOpen}
        />

        <AppmaxTermsOfUsageModal isOpen={isTermsModalOpen} toggle={handleTermsModalOpen} />
      </div>
    </div>
  );
};

export default Appmax;
