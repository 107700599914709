import React, { memo } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { Info } from 'phosphor-react';

import './styles.scss';

const NoActivatedCampaignModal = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="no-activated-campaigns-modal">
      <ModalHeader className="header">
        <Info size={32} />
        <span>Aviso</span>
      </ModalHeader>
      <ModalBody className="body">
        <img
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/svgs/custom-spends-warn.svg"
          alt="Profitfy.me Sincronizando Campanhas"
        />
        <span className="text-center">Não foi possível ativar suas campanhas automaticamente</span>
        <p>
          <strong>Não se preocupe,</strong> você pode ativá-las de forma manual.
        </p>
        <Button color="primary" onClick={toggle}>
          Entendi
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default memo(NoActivatedCampaignModal);
