const orderUtils = {
  parseStatusName: status =>
    ({
      PAID: 'Sem Informações',
      REFUNDED: 'Sem Informações',
      PENDING: 'Pendente',
      in_mediation: 'Com Disputa',
      in_process: 'Em análise',
      cancelled: 'Cancelado',
      rejected: 'Rejeitado',
      approved: 'Aprovado',
      refunded: 'Devolvido',
      charged_back: 'Estornado',
      pending: 'Pendente',
      canceled: 'Sem Informação',
      success: 'Sem Informações',
      authorized: 'Autorizado',
    }[status] || 'Sem Status'),

  getStatusColor: status =>
    ({
      PAID: 'dark',
      REFUNDED: 'dark',
      PENDING: 'orange',
      in_mediation: 'purple',
      in_process: 'blue',
      cancelled: 'danger',
      rejected: 'danger',
      approved: 'dark-green',
      refunded: 'yellow',
      charged_back: 'danger',
      pending: 'orange',
      canceled: 'dark',
      success: 'dark',
      authorized: 'yellow',
    }[status] || 'gray'),

  getProfitColor: profit => {
    if (profit > 0) return 'success';
    if (profit < 0) return 'danger';

    return 'gray';
  },

  getTypeData: type =>
    ({
      credit_card: { icon: 'fa fa-credit-card', name: 'Cartão' },
      ticket: { icon: 'fa fa-barcode', name: 'Boleto' },
      account_money: { icon: 'fas fa-file-invoice-dollar', name: 'Depósito' },
      digital_currency: { icon: 'fab fa-bitcoin', name: 'PIX / Transferência' },
      bank_transfer: { icon: 'fa fa-bank', name: 'PIX / Transferência' },
      debit_card: { icon: 'fa fa-credit-card', name: 'Cartão' },
      atm: { icon: 'fas fa-cloud', name: 'Desconhecido' },
      prepaid_card: { icon: 'fa fa-credit-card', name: 'Cartão' },
    }[type] || { icon: 'fa fa-question', name: 'Sem dados' }),
};

export { orderUtils };
