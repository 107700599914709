import { useFetch } from './Fetch';
import { shopsApi } from '../services/api';

export const useStoreConfigurations = ({ platform, store }) => {
  const { data, error, mutate } = useFetch({
    url: `/api/v1/users/${platform}/stores/${store}/store-configurations`,
    apiName: shopsApi[platform],
  });

  return {
    mutate,
    storeConfigurationsData: data,
    isLoading: !error && !data,
    isError: error,
  };
};
