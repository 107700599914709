import React, { memo } from 'react';
import { Card } from 'reactstrap';
import ProfitEstimateTableHeader from './TableHeader/TableHeader';
import ProfitEstimateTableBody from './TableBody/TableBody';

const ProfitEstimate = ({ markup, finalPrice, fixedCost, marketingCost, profit, maxCpa, profitMargin }) => {
  return (
    <Card className="shadow">
      <ProfitEstimateTableHeader />
      <ProfitEstimateTableBody
        markup={markup}
        finalPrice={finalPrice}
        fixedCost={fixedCost}
        marketingCost={marketingCost}
        profit={profit}
        maxCpa={maxCpa}
        profitMargin={profitMargin}
      />
    </Card>
  );
};

export default memo(ProfitEstimate);
