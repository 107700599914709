import React, { memo, useCallback, useMemo } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input as StrapInput,
  Badge,
} from 'reactstrap';
import FixedCost from './FixedCost/FixedCost';
import MarketingCost from './MarketingCost/MarketingCost';
import CustomMarkup from './CustomMarkup/CustomMarkup';
import { numberBrFormatter } from '../../../../../utils/utils';
import { updateCustomerXTrackingSearchRequest } from '../../../../../utils/customerXTracking';
import { useAuth } from '../../../../../hooks/User';

import './styles.scss';

const PricingCalculator = ({
  setProductCost,
  setIofAliexpress,
  setCheckout,
  setGateway,
  setTax,
  setMarketing,
  setCustomMarkup,
  finalPrice,
  setShipmentFee,
}) => {
  const suggestedPrice = finalPrice[2];

  const { user } = useAuth();

  const searchFetch = useMemo(
    () =>
      updateCustomerXTrackingSearchRequest({
        email: user?.email,
        userId: user?.id,
        identifier: 'update_pricing_calculator',
      }),
    [user],
  );

  const handleProductCostInputChange = useCallback(
    async event => {
      setProductCost(Number(event.target.value) || 0);

      if (user) {
        await searchFetch();
      }
    },
    [setProductCost, searchFetch, user],
  );

  return (
    <Card className="shadow pricing-calculator">
      <CardHeader>
        <Row className="d-flex p-2">
          <div>
            <h3 className="mb-0">Calculadora de Precificação</h3>
            <p className="text-muted text-sm mb-0">
              Adicione os valores abaixo para calcularmos o preço dos seu produto.
            </p>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="justify-content-between align-items-center ml-2 mr-2">
          <h3>Custo do Produto</h3>
          <InputGroup className="input-group-alternative product-cost-input" size="sm">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>R$</InputGroupText>
            </InputGroupAddon>
            <StrapInput type="number" placeholder="0,00" onChange={handleProductCostInputChange} />
          </InputGroup>
        </Row>
        <Row>
          <FixedCost
            setIofAliexpress={setIofAliexpress}
            setCheckout={setCheckout}
            setGateway={setGateway}
            setTax={setTax}
            setShipmentFee={setShipmentFee}
          />
        </Row>
        <Row>
          <MarketingCost setMarketing={setMarketing} />
        </Row>
        <Row>
          <CustomMarkup setCustomMarkup={setCustomMarkup} />
        </Row>
        <Row className="justify-content-between align-items-center ml-2 mr-2 mt-2">
          <h3 className="mb-0">Preço Recomendado</h3>
          <Badge color="primary">R$ {numberBrFormatter(suggestedPrice, 2)}</Badge>
        </Row>
      </CardBody>
    </Card>
  );
};

export default memo(PricingCalculator);
