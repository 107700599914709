import React, { memo, useMemo } from 'react';
import { Button, UncontrolledPopover, PopoverBody } from 'reactstrap';
import aliexpressIcon from '../../../../../assets/img/theme/aliexpress-icon.png';
import './styles.scss';

const RowActions = ({ rowHovered, order, handleEditOrder }) => {
  const showTrackingInfo = useMemo(
    () => order.supplier.every(({ trackingNumber }) => trackingNumber),
    [order.supplier],
  );

  return (
    <>
      <td width="15%">
        <i
          className={`fa fa-truck text-lg text-${showTrackingInfo ? 'default' : 'light'} truck-button `}
          id="tracking-number"
        />

        {rowHovered === order.id && (
          <>
            <Button className="btn-link" onClick={() => handleEditOrder(order)}>
              <i className="fa fa-pen text-purple text-lg" />
            </Button>
            <UncontrolledPopover trigger="hover" placement="top" flip target="tracking-number">
              {showTrackingInfo && (
                <PopoverBody>
                  {order.supplier.map(({ trackingNumber, supplierOrderId }) => (
                    <div key={trackingNumber}>
                      <p className="text-purple font-weight-bold mb-1">{trackingNumber}</p>
                      {supplierOrderId && (
                        <a
                          className="text-orange font-weight-bold"
                          href={`https://trade.aliexpress.com/order_detail.htm?orderId=${supplierOrderId}`}
                          target="blank"
                        >
                          Ir para aliexpress <img src={aliexpressIcon} width="16px" alt="aliexpress profitfy" />
                        </a>
                      )}
                      <hr className="my-1" />
                    </div>
                  ))}
                </PopoverBody>
              )}
            </UncontrolledPopover>
          </>
        )}
      </td>
    </>
  );
};

export default memo(RowActions);
