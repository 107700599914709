import React, { useAuth } from 'react';
import { Route, Redirect } from 'react-router-dom';

const AdminRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={props => {
        return user?.role === 'ADMIN' ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/stores',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default AdminRoute;
