import React, { memo } from 'react';
import { GuardSpinner } from 'react-spinners-kit';

import './styles.scss';

const CalcPastThirtyDaysAlert = () => {
  return (
    <div className="calculating-past-thirty-days-alert">
      <GuardSpinner size={16} color="#7DD56F" />
      <span>
        Estamos preparando seus dados de <strong>30 dias</strong> atrás.
      </span>
    </div>
  );
};

export default memo(CalcPastThirtyDaysAlert);
