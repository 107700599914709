import React, { memo, useCallback, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { WarningCircle } from 'phosphor-react';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';

import DatePicker from '../../../DatePicker/DatePicker';
import { api, shopsApi } from '../../../../services/api';
import { useToast } from '../../../../hooks/Toast';

import './styles.scss';

const CalculatePastOrdersModal = ({ isOpen, toggle }) => {
  const { showToast } = useToast();
  const { store, platform, productId } = useParams();
  const [hasSelectedToCalculatePastOrders, setHasSelectedToCalculatePastOrders] = useState(false);
  const [period, setPeriod] = useState({
    startDate: moment(),
    endDate: moment(),
  });

  const handleSelectCalculatePastOrders = useCallback(
    () => setHasSelectedToCalculatePastOrders(!hasSelectedToCalculatePastOrders),
    [hasSelectedToCalculatePastOrders],
  );

  const handleDate = useCallback(({ startDate, endDate }) => {
    if (!startDate || !endDate) return;

    if (!startDate.isValid() || !endDate.isValid()) return;

    setPeriod({ startDate, endDate });
  }, []);

  const handleCancel = useCallback(() => toggle(), [toggle]);

  const handleCalculatePastOrders = useCallback(async () => {
    try {
      toggle();

      await api(shopsApi[platform]).put(
        `api/v1/users/${platform}/stores/${store}/products/${productId}/orders/recalculate-cogs?startDate=${period.startDate.format(
          'YYYY-MM-DD',
        )}&endDate=${period.endDate.format('YYYY-MM-DD')}`,
      );

      showToast({
        message: 'Pedidos alterados com sucesso!',
        type: 'success',
      });
    } catch {
      showToast({
        message: 'Não foi possível realizar a tarefa no momento, tente novamente mais tarde.',
        type: 'error',
      });
    } finally {
      handleSelectCalculatePastOrders();
    }
  }, [platform, store, productId, showToast, toggle, period, handleSelectCalculatePastOrders]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="calculate-past-orders-modal">
      <ModalHeader className="calculate-past-orders-header">
        <WarningCircle size={32} />
        <span>Aviso</span>
      </ModalHeader>

      <ModalBody className="calculate-past-orders-body">
        {hasSelectedToCalculatePastOrders ? (
          <div className="datepicker-content">
            <span>Selecione o período que deseja alterar os custos</span>

            <DatePicker onDate={handleDate} startDate={period.startDate} endDate={period.endDate} position="start" />

            <Button onClick={handleCalculatePastOrders} color="primary">
              Confirmar
            </Button>
          </div>
        ) : (
          <div className="warning-content">
            <span>Alterar custos para pedidos anteriores?</span>
            <p>
              Caso você <strong>não altere</strong>, os <strong>custos alterados</strong> só serão aplicados para os{' '}
              <strong>próximos pedidos</strong>.
            </p>

            <div>
              <button className="not-calculate-button" type="button" onClick={handleCancel}>
                Não alterar
              </button>
              <Button className="calculate-button" onClick={handleSelectCalculatePastOrders} color="primary">
                Alterar
              </Button>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default memo(CalculatePastOrdersModal);
