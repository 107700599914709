import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';

const EmailSent = () => {
  return (
    <div className="main-forgot-password-email-sent-container">
      <div className="content-container">
        <div className="email-sent-content">
          <img
            className="profitfy-logo"
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/logo.svg"
            alt="Profitfy.me"
          />
          <h3>E-mail enviado com sucesso!</h3>
          <p>Enviamos as instruções de como criar sua nova senha ao seu e-mail! Aguardamos você novamente!</p>
        </div>
        <Link to="login" className="return-icon">
          <i className="fas fa-arrow-left" />
        </Link>
      </div>
      <div className="creative-container">
        <img
          className="image-description"
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/Forgot+Password/forgot-password-sent.svg"
          alt="Profitfy.me"
        />
      </div>
    </div>
  );
};

export default memo(EmailSent);
