import React, { useState, useContext, memo, useCallback } from 'react';
import useForm from 'react-hook-form';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import * as yup from 'yup';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Form,
  Input as StrapInput,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
} from 'reactstrap';
import { SingleDatePicker } from 'react-dates';
import { VERTICAL_ORIENTATION, HORIZONTAL_ORIENTATION } from 'react-dates/constants';
import {
  Money,
  CurrencyCircleDollar,
  Folders,
  Article,
  CurrencyDollar,
  CalendarBlank,
  ListBullets,
  MinusCircle,
} from 'phosphor-react';

import CustomSwitch from '../../../../components/CustomSwitch/CustomSwitch';
import FirstTimeContext from '../../../../hooks/FirstTimeContext';
import { api, shopsApi } from '../../../../services/api';
import { useToast } from '../../../../hooks/Toast';
import { currencyFormatter, numberBrFormatter } from '../../../../utils/utils';
import { useAuth } from '../../../../hooks/User';
import { updateCustomerXTracking } from '../../../../utils/customerXTracking';

import './styles.scss';

const smallDevice = window.matchMedia('(max-width: 727px)').matches;
const orientation = smallDevice ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION;

const CustomSpendsModal = ({
  toggle,
  isOpen,
  customSpend,
  title,
  customSpends,
  setCustomSpends,
  handleIsWarningModalOpen,
}) => {
  const { store, platform } = useParams();
  const { showToast } = useToast();
  const [date, setDate] = useState(moment(customSpend.startDate));
  const [focusedInput, setFocusedInput] = useState(false);
  const [checked, setCheck] = useState(customSpend.active);
  const value = useContext(FirstTimeContext);
  const [isMarketing, setIsMarketing] = useState(false);
  const [showWarning, setShowWarning] = useState(true);
  const { user } = useAuth();

  const handleMarketing = useCallback(e => {
    if (e.target.value === 'MARKETING') {
      setIsMarketing(true);
    } else {
      setIsMarketing(false);
    }
  }, []);

  const schema = yup.object().shape({
    description: yup
      .string()
      .min(1, 'Precisa ter no mínimo 1 carácter')
      .max(60, 'Pode ter no máximo 60 caracteres')
      .required('Este campo é requirido'),
    amount: yup.string().required('É necessário informar um valor'),
  });

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    validationSchema: schema,
  });

  const handleCheck = useCallback(() => setCheck(!checked), [checked]);

  const onSubmit = useCallback(
    async data => {
      const parsedData = {
        ...data,
        amount: data.amount.replaceAll('.', '').replace(',', '.'),
        startDate: date.format('YYYY-MM-DD'),
        id: customSpend.id,
        active: checked,
      };

      const requestType = parsedData.id ? 'put' : 'post';
      const response = await api(shopsApi[platform])[requestType](
        `/api/v1/users/${platform}/stores/${store}/custom-spends/${parsedData?.id || ''}`,
        parsedData,
      );

      showToast({ message: 'Custo adicional salvo com sucesso!', type: 'success' });

      const filtered = customSpends.filter(spend => spend.id !== parsedData.id);
      const newCustomSpend = response.data.customSpend;
      setCustomSpends([...filtered, newCustomSpend]);
      toggle();

      if (showWarning) handleIsWarningModalOpen();

      if (user && isMarketing) {
        updateCustomerXTracking({ email: user.email, userId: user.id, identifier: 'add_custom_marketing' });
      }

      if (user && !isMarketing) {
        updateCustomerXTracking({ email: user.email, userId: user.id, identifier: 'add_cost' });
      }
    },
    [
      checked,
      customSpend.id,
      customSpends,
      date,
      platform,
      setCustomSpends,
      showToast,
      store,
      toggle,
      handleIsWarningModalOpen,
      showWarning,
      user,
      isMarketing,
    ],
  );

  const onDateChange = useCallback(date => {
    if (!date.isBefore(moment())) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }

    setDate(date);
  }, []);

  return (
    <Modal isOpen={isOpen} fade toggle={toggle} className="custom-spends-modal">
      <ModalHeader toggle={toggle} className="header">
        {title}
      </ModalHeader>
      <ModalBody>
        <Form role="form" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup className="form-group">
            <Label className="input-label">Status</Label>
            <CustomSwitch title="Status" onClick={handleCheck} id="active" status={checked} />
          </FormGroup>

          <FormGroup className="type-input-group form-group">
            <Label className="input-label">Tipo</Label>
            <div>
              <FormGroup className="mb-0">
                <Label className="radio-input">
                  <MinusCircle size={36} />
                  <span>Custo/Despesa</span>
                  <StrapInput
                    type="radio"
                    name="type"
                    value="OUT"
                    innerRef={register}
                    defaultChecked={customSpend.type !== 'IN'}
                  />
                </Label>
              </FormGroup>
              <FormGroup className="mb-0">
                <Label className="radio-input">
                  <CurrencyCircleDollar size={36} />
                  <span>Receita/Comissão</span>
                  <StrapInput
                    type="radio"
                    name="type"
                    value="IN"
                    innerRef={register}
                    defaultChecked={customSpend.type === 'IN'}
                  />
                </Label>
              </FormGroup>
            </div>
          </FormGroup>

          <FormGroup className="form-group">
            <Label className="input-label">Moeda</Label>

            <InputGroup className="modal-input currency">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Money size={24} />
                </InputGroupText>
              </InputGroupAddon>
              <StrapInput
                type="select"
                name="currency"
                id="currency"
                defaultValue={customSpend.currency}
                innerRef={register}
              >
                <option value="BRL">Real (R$)</option>
                <option value="USD">Dólar (U$)</option>
              </StrapInput>
            </InputGroup>
          </FormGroup>

          <div className="value-and-date">
            <FormGroup className="form-group">
              <Label className="input-label">Valor</Label>

              <InputGroup className="modal-input">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <CurrencyDollar size={24} />
                  </InputGroupText>
                </InputGroupAddon>
                <StrapInput
                  placeholder="Valor"
                  defaultValue={customSpend?.amount && numberBrFormatter(customSpend.amount)}
                  id={customSpend.id}
                  type="text"
                  name="amount"
                  onChange={event => {
                    event.target.value = currencyFormatter(event.target.value);
                  }}
                  innerRef={register}
                />
              </InputGroup>
              {errors.amount && <small className="text-danger">O valor é obrigatório.</small>}
            </FormGroup>

            <FormGroup className="form-group">
              <Label className="input-label">Começa em</Label>
              <InputGroup className="modal-input">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <CalendarBlank size={24} />
                  </InputGroupText>
                  <SingleDatePicker
                    date={date}
                    onDateChange={onDateChange}
                    initialVisibleMonth={() => moment()}
                    focused={focusedInput.focused}
                    onFocusChange={setFocusedInput}
                    isOutsideRange={date => {
                      return date.isBefore(moment(value.storeCreatedAt), 'day');
                    }}
                    displayFormat="DD/MM/YYYY"
                    orientation={orientation}
                    numberOfMonths={1}
                  />
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </div>

          <FormGroup className="form-group">
            <Label className="input-label">Categoria</Label>
            <InputGroup className="modal-input">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Folders size={24} />
                </InputGroupText>
              </InputGroupAddon>
              <StrapInput
                type="select"
                name="category"
                id="category"
                defaultValue={customSpend.category}
                innerRef={register}
                onChange={handleMarketing}
              >
                <option value="APP">App</option>
                <option value="MARKETING">Marketing</option>
                <option value="EMPLOYEE">Funcionários</option>
                <option value="COSTS">Custos</option>
                <option value="NONE">Nenhuma</option>
              </StrapInput>
            </InputGroup>
            {isMarketing && (
              <span className="text-orange d-inline-block mt-2">
                Ao selecionar esta categoria, será contabilizado os valores no card de Marketing, em análise de loja.
              </span>
            )}
          </FormGroup>

          <FormGroup className="form-group">
            <Label className="input-label">Descrição</Label>
            <InputGroup className="modal-input">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Article size={24} />
                </InputGroupText>
              </InputGroupAddon>
              <StrapInput
                placeholder="Descrição"
                defaultValue={customSpend.description}
                type="text"
                name="description"
                innerRef={register}
              />
            </InputGroup>
            {errors.description && <small className="text-danger">A descrição deve ter no máximo 50 caracteres.</small>}
          </FormGroup>

          <FormGroup className="form-group">
            <Label className="input-label">Frequência</Label>
            <InputGroup className="modal-input">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <ListBullets size={24} />
                </InputGroupText>
              </InputGroupAddon>
              <StrapInput type="select" name="period" id="period" defaultValue={customSpend.period} innerRef={register}>
                <option value="NONE">Uma vez</option>
                <option value="DAILY">Diária</option>
                <option value="WEEKLY">Semanal</option>
                <option value="MONTHLY">Mensal</option>
                <option value="ANNUALLY">Anual</option>
              </StrapInput>
            </InputGroup>
          </FormGroup>

          <div className="text-right">
            <Button className="btn-icon btn-2" color="primary" type="submit">
              <span className="btn-inner--icon">
                <i className="ni ni-send" />
              </span>
              <span className="btn-inner--text">Salvar</span>
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default memo(CustomSpendsModal);
