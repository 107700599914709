import React, { useState, useCallback, memo, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Button, Label, Input } from 'reactstrap';

import { useAuth } from '../../hooks/User';
import { api } from '../../services/api';

import './styles.scss';

const TermsOfUsage = () => {
  const { user, loadUser } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);

  const handleConfirmButton = useCallback(async () => {
    setHasAgreed(false);

    try {
      await api('user-api').put('/api/v1/users/me', {
        ...user,
        usageTerms: true,
      });

      await loadUser();

      setIsOpen(false);
    } finally {
      setHasAgreed(true);
    }
  }, [user, loadUser]);

  useEffect(() => {
    if (user && !user?.usageTerms) {
      setIsOpen(true);
    }
  }, [user]);

  return (
    <Modal className="terms-of-usage-container" isOpen={isOpen} size="lg" centered fade>
      <ModalHeader className="header">
        <img src="https://s3-us-west-2.amazonaws.com/profitfy.me/images/icon.svg" alt="Profitfy.me Icon" />
        <div className="title">
          <span>Termos de Uso</span>
          <span>Atualizado em 24/02/2021</span>
        </div>
        <div />
      </ModalHeader>
      <ModalBody className="body">
        <div className="terms-of-usage-content">
          {/* QUEM SOMOS */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>QUEM SOMOS</span>
              </div>
              <div className="content">
                <p>Somos um Dashboard de análises de dados estratégicos para e-commerce.</p>
                <p>
                  A experiência de possuir em mãos uma ferramenta com um UX impecável, e muito intuitivo facilita na
                  tomada de decisão de seu negócio.
                </p>
                <p>
                  Em seu Dashboard você poderá ter uma análise geral de sua loja, para olhar o macro e acompanhar sua
                  saúde financeira, como seu faturamento, custo de produtos, custo de marketing, taxas, e seu lucro
                  líquido em tempo real.
                </p>
                <p>
                  Em seguida, a análise de produtos individuais, onde se torna muito mais prático entender como seu
                  produto tem reagido sob suas campanhas, podendo assim entender seus gargalos e melhorar o que tem que
                  ser melhorado, dando muita previsibilidade.
                </p>
                <p>
                  Você poderá ter acesso dentre outras abas como, controle de pedidos, boletos, cartões, taxas,
                  estornos, devolvidos, valores adicionais, análise de campanhas, entre outras funcionalidades que
                  qualifica a performance de suas decisões, fazendo com que você possua um negócio sólido e altamente
                  lucrativo.
                </p>
              </div>
            </div>
          </div>
          {/* ACEITE DOS TERMOS */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>ACEITE DOS TERMOS</span>
              </div>
              <div className="content">
                <p>
                  Este Contrato de Licença de Usuário Final (“EULA”) é um acordo legal entre o licenciado (pessoa física
                  ou jurídica) (o “LICENCIADO”) e o Profitfy.me (aqui nessa página referida como “Profitfy.me”, “Nós” ou
                  “Nosso”, CNPJ: 37.365.788/0001-40), (a “LICENCIANTE”) para uso do programa de computador denominado
                  Profitfy.me, disponibilizado neste ato pela LICENCIANTE (o “SOFTWARE”) por meio do site “profitfy.me”
                  (“Site”), pelo determinado pelo LICENCIADO no ato do licenciamento do SOFTWARE, compreendendo o
                  programa de computador e podendo incluir os meios físicos associados, bem como quaisquer materiais
                  impressos e qualquer documentação online ou eletrônica. Ao utilizar o SOFTWARE, mesmo que parcialmente
                  ou a título de teste, o LICENCIADO estará vinculado aos termos deste EULA, concordando com suas
                  disposições, principalmente com relação ao CONSENTIMENTO PARA o ACESSO, COLETA, USO, ARMAZENAMENTO,
                  TRATAMENTO E TÉCNICAS DE PROTEÇÃO ÀS INFORMAÇÕES do LICENCIADO pela LICENCIANTE, necessárias para a
                  integral execução das funcionalidades ofertadas pelo SOFTWARE. Em caso de discordância com os termos
                  aqui apresentados, a utilização do SOFTWARE deve ser imediatamente interrompida pelo LICENCIADO.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Nossa sede fica na cidade de São Paulo e, ao usar a Profitfy.me, você concorda com os termos e condições
                estabelecidos neste documento para utilizar nossa plataforma.
              </p>
            </div>
          </div>
          {/* 1. DA PROPRIEDADE INTELECTUAL */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>1. DA PROPRIEDADE INTELECTUAL</span>
              </div>
              <div className="content">
                <p>
                  O LICENCIADO não adquire, pelo presente instrumento ou pela utilização do SOFTWARE, nenhum direito de
                  propriedade intelectual ou outros direitos exclusivos, incluindo patentes, desenhos, marcas, direitos
                  autorais ou quaisquer direitos sobre informações confidenciais ou segredos de negócio, bem como todo o
                  conteúdo disponibilizado no Site, incluindo, mas não se limitando a textos, gráficos, imagens,
                  logotipos, ícones, fotografias, conteúdo editorial, notificações, softwares e qualquer outro material,
                  sobre ou relacionados ao SOFTWARE ou nenhuma parte dele. O LICENCIADO também não adquire nenhum
                  direito sobre ou relacionado ao SOFTWARE ou qualquer componente dele, além dos direitos expressamente
                  licenciados ao LICENCIADO sob o presente EULA ou em qualquer outro contrato mutuamente acordado por
                  escrito entre o LICENCIADO e a LICENCIANTE. Quaisquer direitos não expressamente concedidos sob o
                  presente instrumento são reservados.
                </p>
                <p>
                  Também será de propriedade exclusiva da LICENCIANTE, ou está devidamente licenciado, todo o conteúdo
                  disponibilizado no site, incluindo, mas não se limitando a textos, gráficos, imagens, logos, ícones,
                  fotografias, conteúdo editorial, notificações, softwares e qualquer outro material.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                A propriedade do produto Profitfy.me é da empresa PROFITFY TECNOLOGIA LTDA e concede a você apenas o
                direito de usar a plataforma para ajudar na gestão da sua empresa.
              </p>
            </div>
          </div>
          {/* 2. DECLARAÇÕES DO LICENCIADO */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>2. DECLARAÇÕES DO LICENCIADO</span>
              </div>
              <div className="content">
                <p>
                  O LICENCIADO declara ter pleno conhecimento dos direitos e obrigações decorrentes do presente EULA,
                  constituindo este instrumento o acordo completo entre as partes. Declara, ainda, ter lido,
                  compreendido e aceito todos os seus termos e condições.
                </p>
                <p>
                  O LICENCIADO declara que leu, compreendeu e aceitou a política de privacidade, consentindo livre e
                  expressamente às ações de coleta, uso, armazenamento e tratamento das referidas informações e dados.
                </p>
                <p>
                  O LICENCIADO declara estar ciente de que as operações que correspondam à aceitação do presente EULA,
                  de determinadas condições e opções, bem como eventual rescisão do presente instrumento e demais
                  alterações, serão registradas nos bancos de dados da LICENCIANTE, juntamente com a data e hora em que
                  foram realizadas pelo LICENCIADO, podendo tais informações serem utilizadas como prova pelas partes,
                  independentemente do cumprimento de qualquer outra formalidade.
                </p>
                <p>
                  O LICENCIADO declara que está ciente de que, em qualquer hipótese, deve atender rigorosamente a
                  legislação, seja de natureza principal ou acessória, bem como cumprir a regulação de proteção de dados
                  aplicável e quaisquer outras, entendendo que o Software objeto deste EULA trata-se de uma condição de
                  meio e não de resultado, não dispensando, portanto, a correta alimentação das informações e
                  parametrizações necessárias pelo LICENCIADO com base na legislação em vigor.
                </p>
                <p>
                  O LICENCIADO poderá acessar aplicativo de terceiros através de API’s de conexão com a finalidade de
                  compartilhar suas Informações ou por meio de download sobre determinados Aplicativos Terceiros. O
                  LICENCIADO compreende e concorda que tais Informações poderão ser atribuídas à sua conta no Aplicativo
                  de Terceiros e poderão ser publicadas em tal serviço. Embora essas informações possam ser
                  automaticamente compartilhadas por definição, o LICENCIADO poderá, a qualquer tempo, suspender o
                  compartilhamento ou quando for o caso desinstalar tais Aplicativos Terceiros. O Aplicativo de
                  Terceiros também poderá fornecer controles semelhantes, porém o acesso deverá ser feito diretamente
                  através do Aplicativo de Terceiros, objetivando suspender tal acesso.
                </p>
                <p>
                  O LICENCIADO compreende e concorda que o uso de suas Informações por um Aplicativo de Terceiros
                  coletadas junto ao mesmo (ou conforme autorizado pelo LICENCIADO) é regido pelas políticas de
                  privacidade do Aplicativo de Terceiros e por suas configurações no respectivo serviço, e o uso de tais
                  informações por parte do LICENCIADO é regido por este Termo de Uso e pelas configurações da sua conta
                  no SOFTWARE da LICENCIANTE. A LICENCIANTE não será responsável, sob qualquer hipótese, pelo tratamento
                  dado pelas informações por um Aplicativo Terceiro.
                </p>
                <p>
                  O LICENCIADO declara ainda que está ciente de que para usufruir de algumas das funcionalidades do
                  SOFTWARE, em especial, dos serviços, deverá disponibilizar à LICENCIANTE as INFORMAÇÕES DE CONTA para
                  que o SOFTWARE, de maneira automatizada, colete informações diretamente nos sites e/ou outros meios
                  virtuais disponibilizados pelas plataformas, com as quais mantenha relacionamento, agindo a
                  LICENCIANTE, neste caso, como representante e mandatária do LICENCIADO nestes atos.
                </p>
                <p>
                  Compartilhamos informações relacionadas aos nossos usuários com terceiros selecionados que nos
                  fornecem uma variedade de serviços que suportam a entrega dos nossos serviços (chamaremos eles de
                  “Processadores Terceirizados”). Esses Processadores Terceirizados variam de fornecedores de
                  infraestrutura técnica a serviço de atendimento ao cliente e ferramentas de autenticação. Asseguramos
                  que o gerenciamento de informações feito em nosso nome por Processadores Terceirizados será feito de
                  acordo com termos contratuais, que requerem que essas informações sejam mantidas seguras, sejam
                  processadas de acordo com as leis de proteção de dados e usadas somente conforme instruímos e não para
                  os propósitos dos Processadores Terceirizados (a menos que tenham a sua autorização).
                </p>
                <p>
                  Processadores Terceirizados podem estar alocados ou processar suas informações fora do país onde você
                  está baseado. Nos casos em que o nosso uso de Processadores Terceirizados envolver a transferência de
                  dados pessoais nós tomaremos as medidas necessárias para garantir que os dados pessoais sejam
                  devidamente protegidos.
                </p>
                <p>
                  Os tipos de Processadores Terceirizados com os quais podemos compartilhar elementos dos seus dados
                  pessoais incluem:
                </p>
                <p>
                  processadores de pagamento acionados por nós para armazenar ou gerenciar informações de pagamento com
                  segurança, como detalhes de cartão de crédito ou débito. fornecedores de gerenciamento de e-mail e
                  ferramentas de distribuição, por exemplo, se você assinar o recebimento de newsletters do da
                  LICENCIANTE ou outras mensagens comerciais, gerenciaremos seu envio usando uma ferramenta terceirizada
                  de distribuição de e-mail; fornecedores de serviços de segurança e prevenção de fraudes. Por exemplo,
                  usamos esses fornecedores para identificar agentes de software automatizado que podem prejudicar
                  nossos serviços ou para prevenir o uso indevido de nossas APIs;
                </p>
                <p>
                  fornecedores de plataformas de software que nos ajudam na comunicação ou no fornecimento de serviços
                  de atendimento ao cliente, por exemplo, gerenciamos e respondemos todas as mensagens enviadas por meio
                  da nossa central de ajuda usando uma ferramenta terceirizada de gerenciamento de comunicações;
                </p>
                <p>
                  fornecedores relacionados ao sistema bancário; fornecedores de serviços de armazenamento na nuvem
                  online e outros serviços de TI essenciais; e
                </p>
                <p>
                  Quando processamos seus dados pessoais podemos contar com sua autorização ou não, mas sempre que você
                  optar por retirá-la somente com a sua autorização, você pode retirar essa autorização a qualquer
                  momento usando a funcionalidade disponível no recurso respectivo do produto ou entrando em contato
                  através do seguinte endereço eletrônico: victorlevi@profitfy.me. A partir do momento que você retira
                  alguma autorização, poderá ficar impedido de gerar automaticamente alguma atividade que era realizada
                  de forma automatizada. Ao revogar seu consentimento, você não vai mais poder usar os serviços e
                  recursos que exigem a coleta ou o uso das informações que coletávamos ou usávamos com base no
                  consentimento.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Ao ler este instrumento, você precisa compreendê-lo e aceitá-lo para utilizar os serviços da
                PROFITFY.ME, bem como, concordará com nossa Política de Privacidade e as condições para o tratamento de
                dados entre as Partes, respeitando-se sempre a confidencialidade de algumas informações. Você
                compartilhará conosco certas informações específicas e que são necessárias para proporcionarmos, por
                meio de funcionalidades automáticas.
              </p>
              <p>
                Além disso, aplicativos de terceiros podem se conectar a Profitfy.me através de API’s de conexão. E você
                concorda em disponibilizar seus dados e informações para esses aplicativos, podendo interromper essa
                troca de informações a qualquer momento, inclusive, em caso de instabilidade dos API’s de conexão. Mas,
                neste caso, a responsabilidade será do provedor dos aplicativos e não nossa.
              </p>
              <p>
                As informações coletadas através dessas integrações devem obedecer aos Termos de Uso do aplicativo e da
                nossa plataforma.
              </p>
            </div>
          </div>
          {/* 3. LICENÇA DE USO DO SOFTWARE */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>3. LICENÇA DE USO DO SOFTWARE</span>
              </div>
              <div className="content">
                <p>
                  Sujeito aos termos e condições aqui estabelecidos, este EULA concede ao LICENCIADO uma licença
                  revogável, não exclusiva e intransferível para uso do SOFTWARE. O LICENCIADO não poderá utilizar e nem
                  permitir que o SOFTWARE seja utilizado para outra finalidade que não seja o processamento de suas
                  informações ou de pessoas jurídicas indicadas pelo LICENCIADO no ato do cadastramento. Esta licença
                  não implica a capacidade de acessar outros softwares além daqueles originalmente localizados no
                  SOFTWARE. Em nenhuma hipótese o LICENCIADO terá acesso ao código fonte do SOFTWARE ora licenciado, por
                  este se tratar de propriedade intelectual da LICENCIANTE.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Nós poderemos celebrar o mesmo contrato com inúmeras pessoas e iremos disponibilizar para você todas as
                funcionalidades adquiridas no ato da contratação, porém, a propriedade intelectual será sempre da
                Profitfy.me.
              </p>
            </div>
          </div>
          {/* 4. DAS RESTRIÇÕES */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>4. DAS RESTRIÇÕES</span>
              </div>
              <div className="content">
                <p>Em hipótese alguma é permitido ao LICENCIADO ou a terceiros, de forma geral:</p>
                <p>
                  Copiar, ceder, sublicenciar, vender, dar em locação ou em garantia, reproduzir, doar, alienar de
                  qualquer forma, transferir total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente,
                  provisória ou permanentemente, o SOFTWARE objeto deste EULA, assim como quaisquer informações a ele
                  relativas; Retirar ou alterar, total ou parcialmente, os avisos de reserva de direito existente no
                  SOFTWARE e na documentação; Praticar engenharia reversa, descompilação ou desmontagem do SOFTWARE.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Você não poderá dar, emprestar, vender ou fazer cópia da Profitfy.me para ninguém (se desejar, você pode
                indicar a plataforma para seus amigos e conhecidos).
              </p>
              <p>
                Você também não poderá mudar as características do produto e nem deve tentar desvendar o funcionamento
                técnico do software.
              </p>
            </div>
          </div>
          {/* 5. DO PRAZO */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>5. DO PRAZO</span>
              </div>
              <div className="content">
                <p>
                  O presente EULA entra em vigor na data de seu aceite pelo LICENCIADO e vigorará pelo prazo do Plano
                  contratado pelo LICENCIADO. Este EULA será automaticamente renovado por iguais períodos caso o
                  LICENCIADO não se manifeste expressamente em contrário, nos mesmos termos e condições, inclusive no
                  que se refere à eventuais penalidades. Este EULA poderá ser rescindido conforme estabelecido abaixo
                  neste instrumento.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Este contrato é válido pelo prazo do plano contratado por você e será automaticamente renovado caso você
                não nos comunique sua falta de interesse na renovação.
              </p>
              <p>Porém, ele poderá ser terminado conforme previsto abaixo neste instrumento.</p>
              <br />
              <p>
                Em caso de renovação automática, renova-se o contrato na íntegra, em todas as suas cláusulas, inclusive
                multas.
              </p>
            </div>
          </div>
          {/* 6. DA REMUNERAÇÃO E FORMA DE PAGAMENTO */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>6. DA REMUNERAÇÃO E FORMA DE PAGAMENTO</span>
              </div>
              <div className="content">
                <p>
                  O LICENCIADO deverá pagar à LICENCIANTE o valor do plano de licenciamento escolhido e de acordo com a
                  periodicidade definida entre as opções de pagamento disponibilizadas ao LICENCIADO no ato da
                  contratação.
                </p>
                <p>
                  Caso o LICENCIADO, no decorrer da vigência do presente instrumento, opte por outro plano de
                  licenciamento superior (upgrade), os valores serão alterados de acordo com o respectivo plano
                  escolhido.
                </p>
                <p>
                  Se o LICENCIADO, no decorrer da vigência do presente instrumento, optar por um plano de licenciamento
                  inferior (downgrade), a alteração de valores será efetuada na próxima renovação, sendo vedada a
                  devolução de valores já pagos
                </p>
                <p>
                  A falta de pagamento de quaisquer valores nas respectivas datas de vencimento não acarretará na
                  rescisão automática do EULA, mas causará a suspensão do acesso do LICENCIADO ao SOFTWARE até que as
                  pendências financeiras tenham sido regularizadas. O acesso ao SOFTWARE somente será restabelecido após
                  a identificação pela LICENCIANTE do pagamento integral de todos os valores devidos enquanto o mesmo
                  esteve suspenso. A identificação poderá ocorrer em até dois dias úteis após a data de pagamento pelo
                  LICENCIADO. Caso o LICENCIADO não resolva a pendência financeira no prazo de 2 (dois) dias, no plano
                  pago, ou 5 (cinco) dias, no plano Free, contados do vencimento do valor não pago, a LICENCIANTE se
                  reserva o direito de rescindir o presente EULA.
                </p>
                <p>
                  Caso o LICENCIADO não resolva a pendência financeira, a LICENCIANTE se reserva no direito de apagar de
                  forma definitiva e irrecuperável todas as informações do LICENCIADO que por ventura estejam
                  armazenadas no SOFTWARE, nos termos da Cláusula 7.
                </p>
                <p>
                  Os valores dos planos de licenciamento estabelecidos no ato do licenciamento do SOFTWARE poderão ser
                  atualizados anualmente de acordo com a tabela de planos e preços vigente no momento do reajuste, caso
                  isto ocorra o LICENCIADO será informado com no mínimo 7 dias de antecedência da renovação em que
                  ocorrerá o reajuste de preços. Eventualmente poderão ocorrer alterações nos preços dos planos e
                  adicionais contratados, caso isto ocorra o LICENCIADO será informado com no mínimo 7 dias de
                  antecedência da sua próxima renovação.
                </p>
                <p>
                  A LICENCIANTE poderá, a seu critério, por mera liberalidade, oferecer descontos nos valores dos planos
                  de licenciamento. Neste caso, os descontos serão válidos por até 12 (doze) meses. A LICENCIANTE poderá
                  renovar ou não os percentuais de desconto, podendo ainda cancelar os descontos após o período de
                  validade, sem aviso prévio.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Durante o prazo contratado, você deverá nos pagar o valor do pacote que você escolheu e que confirmamos
                por e-mail.
              </p>
              <p>
                A data de vencimento será a do ato de contratação, com um novo pagamento a cada renovação da
                periodicidade escolhida.
              </p>
              <br />
              <p>
                A cada ano podemos atualizar o valor deste pacote conforme a tabela de planos e preços vigente no
                momento do reajuste. Poderão ocorrer alterações nos preços dos nossos planos e adicionais, caso isto
                ocorra, você receberá uma comunicação no mínimo 7 dias antes do seu plano ser renovado. Mas caso você
                tenha recebido descontos no ato da contratação, não avisaremos com antecedência o término do prazo deste
                desconto.
              </p>
              <p>
                Se você precisar de funcionalidades adicionais que ainda não contratou, você poderá mudar seu plano de
                licenciamento para um plano superior, e começaremos a cobrar o novo valor após você trocá-lo.
              </p>
              <p>
                Caso você opte, em algum momento, por algum plano de licenciamento inferior, o novo valor será cobrado
                após a troca também.
              </p>
              <p>
                Se esta operação gerar créditos eles serão utilizados nas próximas renovações. Importante deixar claro
                que não fazemos devolução de valores já pagos.
              </p>
              <br />
              <p>
                Se por algum motivo você deixar de realizar algum pagamento na data combinada, seu acesso a Profitfy.me
                será bloqueado.
              </p>
              <p>
                Caso você não resolva a pendência financeira no prazo de 5 (cinco) dias, no plano pago, ou 5 (cinco)
                dias no plano Free, contados do vencimento do valor não pago, nos reservamos o direito de rescindir o
                presente EULA.
              </p>
            </div>
          </div>
          {/* 7. RESTITUIÇÃO DAS INFORMAÇÕES */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>7. RESTITUIÇÃO DAS INFORMAÇÕES</span>
              </div>
              <div className="content">
                <p>
                  Suspenso o acesso do LICENCIADO ao SOFTWARE, a LICENCIANTE manterá as informações do LICENCIADO
                  armazenadas no SOFTWARE por tempo indeterminado.
                </p>
                <p>
                  Conforme descrito na cláusula 6 acima, passados os dias da suspensão do acesso do LICENCIADO ao
                  SOFTWARE, todas as INFORMAÇÕES do LICENCIADO, incluindo as INFORMAÇÕES PESSOAIS, INFORMAÇÕES DE CONTA
                  e INFORMAÇÕES FINANCEIRAS, em poder da LICENCIANTE poderão excluídos permanentemente do banco de dados
                  da LICENCIANTE.
                </p>
                <p>
                  Não obstante o disposto acima, as informações referentes à data e hora de acesso e ao endereço de
                  protocolo de internet utilizado pelo LICENCIADO para acessar o Site e o SOFTWARE permanecerão
                  armazenadas pela LICENCIANTE por 6 (meses) a contar da data de cada acesso realizado,
                  independentemente do término da relação jurídica e comercial entre a LICENCIANTE e o LICENCIADO, em
                  cumprimento ao disposto no Artigo 15 da Lei nº 12.965/2014, podendo ser armazenados por um período
                  maior de tempo mediante ordem judicial.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Durante o período em que o seu acesso a Profitfy.me estiver suspenso, a plataforma manterá as
                informações do usuário armazenadas por tempo indeterminado. Dados sobre as datas de seus acessos e o
                endereço IP utilizado permanecerão armazenados por, no mínimo, 6 (seis) meses contados a partir de cada
                acesso.
              </p>
            </div>
          </div>
          {/* 8. DAS OBRIGAÇÕES DO LICENCIADO */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>8. DAS OBRIGAÇÕES DO LICENCIADO</span>
              </div>
              <div className="content">
                <p>Obriga-se o LICENCIADO a:</p>
                <p>
                  Manter pessoal treinado para a operação do SOFTWARE e para a comunicação com a LICENCIANTE e prover,
                  sempre que ocorrerem quaisquer problemas com o SOFTWARE, toda a documentação, relatórios e demais
                  informações que relatem as circunstâncias em que os problemas ocorreram, objetivando facilitar e
                  agilizar os trabalhos; Manter, às suas expensas, linha de telecomunicação, modem, software de
                  comunicação, endereço de correio eletrônico e outros recursos necessários à comunicação com a
                  LICENCIANTE;
                </p>
                <p>
                  Responder pelas INFORMAÇÕES e dados inseridos no SOFTWARE, pelo cadastramento, permissões, senhas e
                  modo de utilização de seus usuários, incluindo, mas não se limitando, às informações fornecidas no que
                  diz respeito aos dados de pagamento. A LICENCIANTE em hipótese alguma será responsável pelo conteúdo
                  (INFORMAÇÕES, senhas, cópias de informações, etc.) incluído no SOFTWARE, não sendo, portanto, estas
                  INFORMAÇÕES revisadas em momento algum.{' '}
                </p>
                <p>
                  A responsabilidade pelas INFORMAÇÕES inseridas ou excluídas no SOFTWARE é sempre do LICENCIADO, sendo
                  este o único responsável pela realização de backup das informações, especialmente antes da exclusão. A
                  LICENCIANTE não será responsável pelo armazenamento de informações excluídas pelo LICENCIADO;
                </p>
                <br />
                <p>
                  Certificar-se de que não está proibido por determinação legal e/ou contratual de passar INFORMAÇÕES,
                  INFORMAÇÕES FINANCEIRAS, INFORMAÇÕES DE CONTA e INFORMAÇÕES PESSOAIS, bem como quaisquer outros dados
                  à LICENCIANTE, necessários para a execução do serviço oferecido por este EULA;
                </p>
                <br />
                <p>
                  Garantir a regularidade dos dados pessoais inseridos no SOFTWARE, em especial, no que tange ao
                  cumprimento de bases legais e direitos dos titulares de dados;
                </p>
                <br />
                <p>
                  A qualquer tempo a LICENCIANTE poderá bloquear acesso ao SOFTWARE caso constate qualquer prática pelo
                  LICENCIADO ou terceiros de violação ao presente Termo de uso e/ou qualquer tentativa de fraude ou dê a
                  entender tratar-se de uma tentativa, não reduzindo essa ação a responsabilidade do LICENCIADO sobre
                  seus atos.
                </p>
                <br />
                <p>
                  Não utilizar o SOFTWARE de qualquer forma que possa implicar em ato ilícito, infração, violação de
                  direitos ou danos à LICENCIANTE ou terceiros, incluindo, mas não se limitando ao uso para invasão de
                  dispositivo informático com o objetivo de obter, adulterar ou destruir dados ou informações sem a
                  autorização expressa do titular de tais dados ou do dispositivo ou servidor nos quais estes estejam
                  armazenados;
                </p>
                <br />
                <p className="small-height">
                  Não publicar, enviar ou transmitir qualquer arquivo que contenha vírus, worms, cavalos de tróia ou
                  qualquer outro programa que possa contaminar, destruir ou interferir no bom funcionamento do SOFTWARE;
                </p>
                <br />
                <p>
                  Informar a LICENCIANTE sempre que houver qualquer alteração das INFORMAÇÕES fornecidas à LICENCIANTE e
                  que possam impedir, limitar ou prejudicar o acesso da LICENCIANTE às INFORMAÇÕES necessárias para a
                  execução das funcionalidades ofertadas pelo SOFTWARE; Atender rigorosamente a legislação brasileira e
                  toda obrigação legal imposta e/ou decorrente de sua atividade e em razão da utilização deste SOFTWARE.
                  Caso o LICENCIADO acredite que seu login e senha de acesso ao SOFTWARE tenham sido roubados ou sejam
                  de conhecimento de outras pessoas, por qualquer razão, o LICENCIADO deverá imediatamente comunicar tal
                  fato à LICENCIANTE, sem prejuízo da alteração da sua senha imediatamente, por meio do SOFTWARE.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                A Profitfy.me é um produto de fácil utilização, mas se você precisar entrar em contato conosco por
                qualquer motivo, nossos atendentes farão algumas perguntas e poderão solicitar algumas informações para
                fazer um diagnóstico e ajudar você. Lembre-se que, quanto mais detalhadas e precisas forem suas
                respostas, melhor será a ajuda que conseguiremos lhe dar.
              </p>
              <br />
              <p>
                Além disso, você é responsável pelas informações que coloca na plataforma e deve ter as autorizações
                necessárias para incluí-las, caso não seja o titular dessas informações. Você não deve usar a plataforma
                de forma ilegal e/ou danosa a nós ou a terceiros (não deve utilizá-lo para a prática de crimes e nem
                para o envio de vírus, por exemplo).
              </p>
              <br />
              <p>
                É necessário que você atenda a legislação brasileira e toda obrigação legal imposta e/ou decorrente de
                sua atividade e em razão da utilização da Profitfy.me.
              </p>
              <br />
              <p>
                Caso você mantenha seus dados desatualizados ou não inclua um endereço de e-mail válido, a Profitfy.me
                não será responsável por qualquer prejuízo decorrente desses dados incorretos.
              </p>
              <br />
              <p>Seus dados serão sempre tratados com confidencialidade por nós.</p>
            </div>
          </div>
          {/* 9. DAS OBRIGAÇÕES DO LICENCIANTE */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>9. DAS OBRIGAÇÕES DO LICENCIANTE</span>
              </div>
              <div className="content">
                <p>Obriga-se a LICENCIANTE a:</p>
                <p>
                  A LICENCIANTE garante ao LICENCIADO que o SOFTWARE deverá funcionar regularmente, se respeitadas as
                  condições de uso definidas na documentação. Na ocorrência de falhas de programação (“bugs”), a
                  LICENCIANTE obrigar-se-á a corrigir tais falhas, podendo a seu critério substituir a cópia dos
                  programas com falhas por cópias corrigidas;
                </p>
                <br />
                <p>
                  A LICENCIANTE manterá atualizada as funções existentes no SOFTWARE com relação às variáveis
                  normalmente alteradas pela legislação federal. A interpretação legal das normas editadas pelo governo
                  será efetuada com base no entendimento majoritário dos “usuários” do SOFTWARE, doutrinadores e
                  jurisprudência pátria.
                </p>
                <p>Fornecer, ato contínuo ao aceite deste EULA, acesso ao SOFTWARE durante a vigência deste EULA;</p>
                <br />
                <p>
                  Suspender o acesso ao SOFTWARE do LICENCIADO que esteja desrespeitando as regras de conteúdo aqui
                  estabelecidas ou as normas legais em vigor; Alterar as especificações e/ou características do SOFTWARE
                  licenciados para a melhoria e/ou correções de erros, de acordo com o plano de produtos definido pela
                  LICENCIANTE;
                </p>
                <br />
                <p>
                  Disponibilizar acesso aos serviços gratuitos de suporte compreendido de segunda a sexta-feira,
                  <br />
                  <strong>a)</strong> das 10h00 às 18h20 (horário de Brasília), sem intervalo, via chat (localizado no
                  interior do SOFTWARE),
                  <br />
                  <strong>b)</strong> das 10h30 às 19h00 (horário de Brasília) por meio de correio eletrônico
                  (contato@profitfy.me), e ainda,
                  <br />
                  <strong>c)</strong> através da página de suporte (contato@profitfy.me, disponível 24 horas por dia e
                  todos os dias da semana) para esclarecimento de dúvidas de ordem não técnica diretamente relacionadas
                  ao SOFTWARE (conforme o plano contratado).
                </p>
                <p>
                  Manter as INFORMAÇÕES FINANCEIRAS, INFORMAÇÕES DE CONTA e INFORMAÇÕES PESSOAIS do LICENCIADO, bem como
                  seus registros de acesso, em sigilo, sendo que as referidas INFORMAÇÕES serão armazenadas em ambiente
                  seguro, sendo respeitadas a intimidade, a vida privada, a honra e a imagem do LICENCIADO, em
                  conformidade com as disposições da Lei nº 12.965/2014. A LICENCIANTE não será responsável pelo
                  armazenamento de informações excluídas pelo LICENCIADO.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Nós vamos entregar a melhor plataforma que pudermos e, se eventualmente ocorrerem falhas, nos
                esforçaremos para consertá-las, o mais rápido possível. Nós poderemos fazer melhorias na plataforma e
                disponibilizá-las assim que as testarmos. As melhorias serão feitas a critério exclusivo da nossa equipe
                e não por solicitação dos clientes (apesar das sugestões enviadas a nós terem forte peso nesta decisão).
              </p>
              <p>
                Nós estaremos prontos para ajudá-los, das 10h00 às 18h20 (horário de Brasília) por meio de chat; e
                das10h30 às 19h00 por meio de e-mail (conforme o plano contratado).
              </p>
              <p className="small-height">
                Garantimos a segurança das informações e dados pessoais de acesso incluídos na sua conta, de acordo com
                a Lei nº 12.965/2014, também conhecida como Marco Civil da Internet.
              </p>
            </div>
          </div>
          {/* 10. NÍVEL DE SERVIÇO */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>10. NÍVEL DE SERVIÇO</span>
              </div>
              <div className="content">
                <p>
                  A LICENCIANTE empreenderá esforços comercialmente razoáveis para tornar o SOFTWARE disponível, no
                  mínimo, 99,7% (noventa e nove vírgula sete por cento) durante cada Ano de Serviço (conforme definido
                  abaixo) (“Compromisso de Nível de Serviço”). Na hipótese de a LICENCIANTE não cumprir o Compromisso de
                  Nível de Serviço, o LICENCIADO terá o direito de receber o crédito correspondente a 1 (um) mês de
                  mensalidade, 1/3 avos (para planos trimestrais) ou 1/12 avos (para planos anuais) (“Crédito de
                  Serviço”).
                </p>
                <p>
                  Por “Ano de Serviço” entende-se os 365 dias precedentes à data de uma reivindicação relacionada ao
                  nível de serviço. Se o LICENCIADO estiver se utilizando do SOFTWARE durante período inferior a 365
                  dias, o Ano de Serviço que lhe corresponde será, ainda assim, considerado como os 365 dias
                  precedentes; no entanto, os dias anteriores a seu uso dos serviços serão considerados como de 100% de
                  disponibilidade. Os períodos de inatividade operacional que ocorrerem antes de uma reivindicação
                  bem-sucedida de Crédito de Serviço não poderão ser usados para efeito de reivindicações futuras.
                </p>
                <p>
                  O Compromisso de Nível de Serviço não se aplica caso as circunstâncias de indisponibilidade resultem:
                </p>
                <p>
                  <strong>(i)</strong> de uma interrupção do fornecimento de energia elétrica ou paradas emergenciais
                  não superiores a 2 (duas) horas ou que ocorram no período das 24h às 6:00h (horário de Brasília);
                </p>
                <p>
                  <strong>(ii)</strong> forem causadas por fatores que fujam ao razoável controle da LICENCIANTE,
                  inclusive casos de força maior ou de acesso à Internet e problemas correlatos;
                </p>
                <p>
                  <strong>(iii)</strong> resultem de quaisquer atos ou omissões do LICENCIADO, de terceiros ou de
                  aplicativos terceiros;
                </p>
                <p>
                  <strong>(iv)</strong> resultem do equipamento, software ou outras tecnologias que o LICENCIADO usar
                  que impeçam o acesso regular do SOFTWARE;
                </p>
                <p>
                  <strong>(v)</strong> resultem de falhas de instâncias individuais não atribuíveis à indisponibilidade
                  do LICENCIADO; (vi) resultem de alterações realizadas na forma de acesso a INFORMAÇÕES FINANCEIRAS
                  e/ou INFORMAÇÕES DE CONTA do LICENCIADO pelas instituições financeiras;
                </p>
                <p>
                  <strong>(vi)</strong> resultem de práticas de gerenciamento da rede que possam afetar sua qualidade.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Se por culpa nossa você ficar impossibilitado de usar a Profitfy.me por mais que 52 horas em um ano, nós
                lhe pagamos o valor equivalente a uma mensalidade do plano de licenciamento contratado por você. 52
                horas significa pouco mais de dois dias.
              </p>
              <br />
              <p>
                Algumas funcionalidades da plataforma dependem também de terceiros, portanto, essas funcionalidades de
                terceiros ficam excluídas do Compromisso de Nível de Serviço. Se esses serviços de terceiros não
                funcionarem ou tiverem qualquer indisponibilidade, a responsabilidade não é da Profitfy.me, mas dos
                terceiros.
              </p>
            </div>
          </div>
          {/* 11. ISENÇÃO DE RESPONSABILIDADE DA LICENCIANTE */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>11. ISENÇÃO DE RESPONSABILIDADE DA LICENCIANTE</span>
              </div>
              <div className="content">
                <p>A LICENCIANTE não se responsabiliza:</p>
                <p>
                  Por falha de operação, operação por pessoas não autorizadas ou qualquer outra causa em que não exista
                  culpa da LICENCIANTE;
                </p>
                <br />
                <p>
                  Pelos danos ou prejuízos decorrentes de decisões administrativas, gerenciais ou comerciais tomadas com
                  base nas informações fornecidas pelo SOFTWARE;
                </p>
                <br />
                <p>
                  Por problemas definidos como “caso fortuito” ou “força maior”, contemplados pelo Art. 393 do Código
                  Civil Brasileiro;
                </p>
                <br />
                <p>
                  Por eventuais problemas oriundos de ações de terceiros que possam interferir na qualidade do serviço;
                </p>
                <br />
                <p>Por danos causados a terceiros em razão de conteúdo gerado pelo LICENCIANTE através de SOFTWARE;</p>
                <br />
                <p>
                  Pela indisponibilidade ou lentidão de aplicativos terceiros que se conectam com o SOFTWARE por meio de
                  API ou ainda, de fornecedores de telecomunicação do LICENCIADO;
                </p>
                <br />
                <p>
                  Por revisar as INFORMAÇÕES DE CONTA fornecidas pelo LICENCIADO, bem como as INFORMAÇÕES DE PAGAMENTO;
                </p>
                <p>
                  A LICENCIANTE adota as medidas de segurança adequadas de acordo com os padrões de mercado para a
                  proteção das INFORMAÇÕES do LICENCIADO armazenadas no SOFTWARE, assim como para o acesso às
                  INFORMAÇÕES DE PAGAMENTO do LICENCIADO. Contudo, o LICENCIADO reconhece que nenhum sistema, servidor
                  ou software está absolutamente imune a ataques e/ou invasões de hackers e outros agentes maliciosos,
                  não sendo a LICENCIANTE responsável por qualquer exclusão, obtenção, utilização ou divulgação não
                  autorizada de INFORMAÇÕES resultantes de ataques que a LICENCIANTE não poderia razoavelmente evitar
                  por meio dos referidos padrões de segurança.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Você, ou quem você autorizar, poderá colocar informações na Profitfy.me e, por esse motivo, nós não
                podemos nos responsabilizar por quaisquer danos causados a você ou a terceiros pelas informações que
                você, ou essa pessoa autorizada, colocarem na plataforma.
              </p>
              <br />
              <p>
                Nós também não nos responsabilizamos por revisar informações incluídas na Profitfy.me, e nem por
                informações obtidas, ou produtos contratados por você junto a instituições financeiras ou qualquer
                terceiro.
              </p>
              <br />
              <p>
                Apesar de tomarmos medidas de segurança adequadas para proteger suas informações, a Profitfy.me pode ser
                objeto de ataques de hackers (indivíduos que se dedicam a conhecer e modificar aspectos internos de
                dispositivos, programas e redes de computadores), que tentem obter os seus dados. Assim, nós não seremos
                responsáveis por eventuais ataques que, apesar de todas as nossas precauções e esforços, não puderem ser
                evitados.
              </p>
            </div>
          </div>
          {/* 12. DA RETOMADA DOS SOFTWARES */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>12. DA RETOMADA DOS SOFTWARES</span>
              </div>
              <div className="content">
                <p>
                  A LICENCIANTE se reserva o direito de cancelar imediatamente o acesso do LICENCIADO ao SOFTWARE nos
                  casos em que o LICENCIADO usar o SOFTWARE de forma diversa daquela estipulada no presente instrumento.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Se você utilizar o software desrespeitando as condições estabelecidas no EULA, nós podemos encerrar sua
                conta na Profitfy.me.
              </p>
            </div>
          </div>
          {/* 13. DAS GARANTIAS LIMITADAS */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>13. DAS GARANTIAS LIMITADAS</span>
              </div>
              <div className="content">
                <p>
                  Na extensão máxima permitida pela lei em vigor, o SOFTWARE é fornecido “no estado em que se encontra”
                  e “conforme a disponibilidade”, com todas as falhas e sem garantia de qualquer espécie.
                </p>
                <p>
                  Não obstante o disposto no item 9.a acima, a LICENCIANTE não garante que as funções contidas no
                  SOFTWARE atendam às suas necessidades, que a operação do SOFTWARE será ininterrupta ou livre de erros,
                  que qualquer serviço continuará disponível, que os defeitos no SOFTWARE serão corrigidos ou que o
                  SOFTWARE será compatível ou funcione com qualquer SOFTWARE, aplicações ou serviços de terceiros.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Apesar de nossos esforços em fornecer um produto estável e em corrigir as falhas que forem
                identificadas, não podemos garantir que a Profitfy.me funcione sem interrupções e que todos os defeitos
                serão corrigidos.
              </p>
              <p>
                E também não garantimos a compatibilidade da plataforma com sistemas ou serviços de terceiros diferentes
                do anunciado.
              </p>
            </div>
          </div>
          {/* 14. LIMITAÇÃO DE RESPONSABILIDADE */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>14. LIMITAÇÃO DE RESPONSABILIDADE</span>
              </div>
              <div className="content">
                <p>
                  Em nenhum caso a LICENCIANTE será responsável por danos pessoais ou qualquer prejuízo incidental,
                  especial, indireto ou consequente, incluindo, sem limitação, prejuízos por perda de lucro, corrupção
                  ou perda de dados, falha de transmissão ou recepção de dados, não continuidade do negócio ou qualquer
                  outro prejuízo ou perda comercial, decorrentes ou relacionados ao seu uso ou sua inabilidade em usar o
                  SOFTWARE, por qualquer outro motivo. Sob nenhuma circunstância a responsabilidade integral da
                  LICENCIANTE com relação ao LICENCIADO por todos os danos excederá a quantia correspondente ao último
                  plano de licenciamento pago pelo LICENCIADO à LICENCIANTE pela obtenção da presente licença de
                  SOFTWARE.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Não nos responsabilizamos por perdas e danos resultantes ou relacionados ao uso da Profitfy.me que não
                estejam previstos neste documento.
              </p>
            </div>
          </div>
          {/* 15. CONSENTIMENTO LIVRE, EXPRESSO E INFORMADO PARA ACESSO A INFORMAÇÕES CONFIDENCIAIS E DADOS PESSOAIS */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>
                  15. CONSENTIMENTO LIVRE, EXPRESSO E INFORMADO PARA ACESSO A INFORMAÇÕES CONFIDENCIAIS E DADOS PESSOAIS
                </span>
              </div>
              <div className="content">
                <p>
                  O LICENCIADO, ao aceitar utilizar o SOFTWARE, além de aceitar integralmente este “EULA”, também
                  consente, livre e expressamente, que a LICENCIANTE colete, use, armazene e faça o tratamento de suas
                  INFORMAÇÕES, incluindo seus dados pessoais e financeiros, os quais serão necessários para que o
                  serviço ofertado seja prestado em sua integralidade.
                </p>
                <p>
                  Para tanto, o LICENCIADO consente, livre e expressamente, em fornecer os dados que permitam o acesso
                  às INFORMAÇÕES necessárias para que o SOFTWARE execute todas as funções para as quais foi projetado.
                </p>
                <p>
                  O LICENCIADO, por meio deste EULA e fornecendo as INFORMAÇÕES DE CONTA, autoriza e consente
                  expressamente que a LICENCIANTE acesse suas INFORMAÇÕES DE CONTA e INFORMAÇÕES DE PAGAMENTO na
                  qualidade de mandatária, sendo absolutamente vedada a LICENCIANTE a realização de quaisquer transações
                  divergentes ao pagamento da licença.
                </p>
                <p>
                  O LICENCIADO consente que, ao acessar o site da LICENCIANTE, esta poderá coletar informações técnicas
                  de navegação, tais como tipo de navegador do computador utilizado para acesso ao site, endereço de
                  protocolo de Internet, páginas visitadas e tempo médio gasto no site. Tais informações poderão ser
                  usadas para orientar o próprio LICENCIADO e melhorar os serviços ofertados.
                </p>
                <p>
                  O LICENCIADO consente livre e expressamente que suas INFORMAÇÕES poderão ser transferidas a terceiros
                  em decorrência da venda, aquisição, fusão, reorganização societária ou qualquer outra mudança no
                  controle da LICENCIANTE. A LICENCIANTE, contudo, compromete-se, nestes casos, a informar o LICENCIADO.
                </p>
                <p>
                  O LICENCIADO consente livre e expressamente que a LICENCIANTE utilize cookies apenas para controlar a
                  audiência e a navegação em seu site e possibilitar a identificação de serviços segmentados e
                  personalizados ao perfil do LICENCIADO. A LICENCIANTE garante que estas informações coletadas por meio
                  de cookies são estatísticas e não pessoais, bem como que não serão utilizadas para propósitos diversos
                  dos expressamente previstos neste EULA, comprometendo-se a adotar todas as medidas necessárias a fim
                  de evitar o acesso e o uso de tais informações por quaisquer terceiros, sem a devida autorização.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Aceitando nossos termos de uso, ficará a seu critério compartilhar conosco as{' '}
                <strong>INFORMAÇÕES DE PAGAMENTO</strong> para você ter acesso a plataforma.
              </p>
              <p>
                Se tivermos acesso a essas informações, atuaremos como seu mandatário, e comprometemo-nos a proteger
                suas informações, de acordo com a Lei nº 12.965/14, além de não realizar transações em seu nome.
              </p>
            </div>
          </div>
          {/* 16. DA RESCISÃO */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>16. DA RESCISÃO</span>
              </div>
              <div className="content">
                <p>
                  O LICENCIADO poderá solicitar devolução total dos valores pagos em até 7 (sete) dias após a data de
                  contratação ou conforme a adesão de campanhas promocionais. Após este prazo de 7 dias, não haverá
                  devolução dos valores pagos e o LICENCIADO poderá solicitar somente o cancelamento da renovação
                  automática, desde que comunique à LICENCIANTE, mediante função específica da plataforma, devendo pagar
                  o saldo devedor do plano de licenciamento contratado, se existente. Para os planos de licenciamento
                  com pagamento antecipado, caso o LICENCIADO decida rescindir este EULA antes do término do prazo
                  contratado, o LICENCIANTE não restituirá ao LICENCIADO o saldo restante do plano de licenciamento
                  contratado (exceto em campanhas promocionais que prevejam a devolução dos valores pagos). Este valor
                  será retido pela LICENCIANTE para cobrir os custos operacionais.
                </p>
                <p>
                  A LICENCIANTE poderá rescindir este EULA a qualquer tempo, desde que efetue o cancelamento de
                  renovação automática, mediante função específica da plataforma, ao LICENCIADO, devendo neste caso
                  restituir ao LICENCIADO o saldo devedor do plano de licenciamento contratado, se existente. Tal
                  condição, assim como a obrigação de restituição acima, não se aplicarão nos casos previstos na
                  cláusula 12 acima e nos casos de violação do presente instrumento.
                </p>
                <p>
                  A LICENCIANTE poderá rescindir o EULA a qualquer momento em caso de violação pelo LICENCIADO dos
                  termos e condições ora acordados, ou em caso de atraso de pagamento não sanado no prazo de 90
                  (noventa) dias, conforme cláusula 6 acima.
                </p>
                <p>
                  No caso de rescisão do presente contrato, os dados pessoais, bancários, financeiros e demais
                  informações do LICENCIADO ficarão disponíveis conforme cláusula 7 deste termo, sendo excluídos
                  permanentemente após 90 (noventa) dias da data da rescisão.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Sua satisfação é muito importante para nós. Caso você perceba que a Profitfy.me não atende mais as suas
                necessidades, faremos de tudo para que não vá embora insatisfeito.
              </p>
              <p>
                Mas lembre-se: você só receberá a devolução total dos valores referentes ao seu plano se pedir o
                cancelamento em até 7 (sete) dias a partir do dia da compra ou conforme a regra de campanhas
                promocionais da qual você esteja participando.
              </p>
              <p>
                Após este prazo você poderá cancelar somente a renovação automática do seu plano, a qualquer momento,
                mediante utilização de função específica da plataforma, no perfil da conta. Lembramos que se você não
                fizer isso, não será aplicável qualquer tipo de reembolso.
              </p>
              <p>
                Se você cancelar a sua assinatura antes do término do contrato e depois de terem passado os prazos para
                devolução, você será responsável pelo pagamento do valor total do compromisso assumido.
              </p>
              <p>
                De toda forma, pedimos que deixe seu feedback, através da plataforma. Faremos o que for possível para
                não deixar de tê-lo como nosso cliente.
              </p>
            </div>
          </div>
          {/* 17. DAS DISPOSIÇÕES LEGAIS */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>17. DAS DISPOSIÇÕES LEGAIS</span>
              </div>
              <div className="content">
                <p>
                  O LICENCIADO não poderá prestar serviços a terceiros utilizando o SOFTWARE da LICENCIANTE sem
                  autorização prévia e expressa da LICENCIANTE. A autorização de uso do SOFTWARE é fornecida por CNPJ.
                  Desta forma, o SOFTWARE não pode operar sob o regime de multiempresa, necessitando para cada CNPJ uma
                  licença específica;
                </p>
                <br />
                <p>
                  Caso o LICENCIADO venha a desenvolver um novo módulo ou produto que caracterize cópia, de todo ou em
                  parte, quer seja do dicionário de dados quer seja do programa, será considerado como parte do SOFTWARE
                  fornecido pela LICENCIANTE, ficando, portanto, sua propriedade incorporada pela LICENCIANTE e seu uso
                  condicionado a estas cláusulas contratuais;
                </p>
                <br />
                <p>
                  Este EULA obriga as partes e seus sucessores e somente o LICENCIADO possui licença não exclusiva para
                  a utilização do SOFTWARE, sendo-lhe, entretanto, vedado transferir os direitos e obrigações acordados
                  por este instrumento. Tal limitação, no entanto, não atinge a LICENCIANTE, que poderá, a qualquer
                  tempo, ceder, no todo ou em parte, os direitos e obrigações inerentes ao presente EULA;
                </p>
                <br />
                <p>
                  A tolerância de uma parte para com a outra quanto ao descumprimento de qualquer uma das obrigações
                  assumidas neste instrumento não implicará em novação ou renúncia de direito. A parte tolerante poderá,
                  a qualquer tempo, exigir da outra parte o fiel e cabal cumprimento deste instrumento; Não constitui
                  causa de rescisão contratual o não cumprimento das obrigações aqui assumidas em decorrência de fatos
                  que independam da vontade das partes, tais como os que configuram o caso fortuito ou força maior,
                  conforme previsto no artigo 393 do Código Civil Brasileiro;
                </p>
                <br />
                <p>
                  Se qualquer disposição deste EULA for considerada nula, anulável, inválida ou inoperante, nenhuma
                  outra disposição deste EULA será afetada como consequência disso e, portanto, as disposições restantes
                  deste EULA permanecerão em pleno vigor e efeito como se tal disposição nula, anulável, inválida ou
                  inoperante não estivesse contida neste EULA;
                </p>
                <p>no SOFTWARE;</p>
                <br />
                <p>
                  A LICENCIANTE poderá, a seu exclusivo critério, a qualquer tempo e sem a necessidade de comunicação
                  prévia ao LICENCIADO:
                </p>
                <p>
                  <strong>a.</strong> Encerrar, modificar ou suspender, total ou parcialmente, o acesso do LICENCIADO ao
                  SOFTWARE, quando referido acesso ou cadastro estiver em violação das condições estabelecidas neste
                  EULA;
                </p>
                <p>
                  <strong>b.</strong> Excluir, total ou parcialmente, as informações cadastradas pelo LICENCIADO que não
                  estejam em consonância com as disposições deste EULA;
                </p>
                <p>
                  <strong>c.</strong> Acrescentar, excluir ou modificar o Conteúdo oferecido no site;
                </p>
                <p>
                  <strong>d.</strong> Alterar quaisquer termos e condições deste EULA mediante simples comunicação ao
                  LICENCIADO.
                </p>
                <br />
                <p>
                  A LICENCIANTE poderá, por meio de comunicação ao endereço eletrônico indicado pelo LICENCIADO em seu
                  cadastro ou por meio de aviso no Site, definir preços para a oferta de determinados conteúdos e/ou
                  serviços, ainda que inicialmente tais serviços tenham sido ofertados de forma gratuita, sendo a
                  utilização destes, após o referido aviso, considerada como concordância do LICENCIADO com a cobrança
                  de tais preços.
                </p>
                <br />
                <p>
                  Fica certo e entendido pelo LICENCIADO que somente a pessoa cadastrada pelo próprio LICENCIADO como
                  administradora de conta poderá solicitar que as informações do LICENCIADO inseridas no Software sejam
                  apagadas. Ainda, o LICENCIADO declara sua ciência de que uma vez apagadas, estas não poderão mais ser
                  recuperadas, ficando a LICENCIANTE isenta de qualquer responsabilidade por quaisquer perdas ou danos
                  decorrentes deste procedimento solicitado pelo LICENCIADO.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Poderemos modificar ou encerrar a Profitfy.me a qualquer momento, e inclusive definir preços para
                serviços anteriormente gratuitos ou alterar qualquer termo ou condição deste EULA. Nós avisaremos você
                sobre essas alterações por meio do nosso site ou por e-mail.
              </p>
              <br />
              <p>
                Somente a pessoa cadastrada por você como administradora de conta poderá solicitar que suas informações
                inseridas na plataforma sejam apagadas.
              </p>
              <br />
              <p>
                Ainda, você reafirma sua concordância e conhecimento de que uma vez apagadas, as informações não poderão
                ser mais recuperadas e a Profitfy.me não terá nenhuma responsabilidade pela execução deste procedimento.
              </p>
            </div>
          </div>
          {/* 18. DA LEI APLICÁVEL */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>18. DA LEI APLICÁVEL</span>
              </div>
              <div className="content">
                <p>
                  Este EULA será regido, interpretado e se sujeitará às leis brasileiras e, o LICENCIADO e a LICENCIANTE
                  desde logo elegem, de forma irrevogável e irretratável, o foro da Comarca da Cidade de São Paulo,
                  Estado de São Paulo, para dirimir quaisquer dúvidas ou controvérsias oriundas deste EULA, com a
                  exclusão de qualquer outro, por mais privilegiado que seja.
                </p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>
                Este documento se sujeita às leis brasileiras. Caso haja alguma discussão judicial sobre a Profitfy.me,
                deverá ser resolvida em São Paulo/SP.
              </p>
            </div>
          </div>
          {/* 19. DAS DEFINIÇÕES */}
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <div className="bullet-point" />
                <span>19. DAS DEFINIÇÕES</span>
              </div>
              <div className="content">
                <p>
                  Os termos utilizados neste instrumento deverão ser interpretados e usados conforme definições abaixo,
                  seja no singular ou plural:
                </p>
                <p>
                  INFORMAÇÕES DE PAGAMENTO: informações fornecidas pelo LICENCIADO dos dados necessários para pagamento
                  do plano contratado.
                </p>
                <p>
                  INFORMAÇÕES PESSOAIS: qualquer informação disponibilizada pelo LICENCIADO que o identifique ou torne
                  identificável, tais como nome, endereço, data de nascimento, número de telefone, fax, endereço
                  eletrônico, número de documentos, etc.
                </p>
                <p>
                  INFORMAÇÕES: entende-se todas as informações do LICENCIADO relacionadas a INFORMAÇÕES PESSOAIS e
                  INFORMAÇÕES DE PAGAMENTO.
                </p>
                <p>
                  LICENCIADO: pessoa física ou jurídica, com plena capacidade de contratar, que acessa o SOFTWARE da
                  LICENCIANTE por meio do site, realizando seu cadastro, aceitando os termos do presente EULA e
                  usufruindo das funcionalidades oferecidas de acordo com o plano de licenciamento contratado.
                </p>
                <p>
                  SOFTWARE: software de propriedade exclusiva da LICENCIANTE, cujas funcionalidades e serviços estão
                  disponibilizados pelo site, por meio do qual as INFORMAÇÕES DE PAGAMENTO do LICENCIADO serão
                  fornecidas diretamente por ele,
                </p>
                <p>
                  API: Application Programming Interface que em português significa Interface de Programação de
                  Aplicativos. É um conjunto de rotinas e padrões de programação para acesso a um aplicativo de software
                  ou plataforma baseado na Web.
                </p>
                <p>São Paulo, 23 de fevereiro de 2021</p>
                <p>Profitfy.me</p>
              </div>
            </div>
            <div className="overview">
              <div className="text-title">
                <div className="bullet-point" />
                <span>RESUMO</span>
              </div>
              <p>Ao longo deste instrumento, as expressões a seguir possuem os seguintes significados:</p>
              <p>INFORMAÇÕES PESSOAIS: nome completo, CPF, data de nascimento, telefone, endereço, e-mail e etc.</p>
              <p>INFORAMÕES DE PAGAMENTO: cartões de crédito, notas fiscais e etc.</p>
              <p>LICENCIADO: você, nosso cliente.</p>
              <p>SOFTWARE: a Plataforma Profitfy.me.</p>
            </div>
          </div>
        </div>
        <div className="submit-box">
          <Label check>
            <Input type="checkbox" name="terms" onChange={event => setHasAgreed(event.target.checked)} />
            Li e concordo com os Termos de Uso.
          </Label>
          <Button color="primary" onClick={handleConfirmButton} disabled={!hasAgreed}>
            Continuar
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default memo(TermsOfUsage);
