import React, { memo, useMemo } from 'react';
import { Card } from 'reactstrap';
import { ResponsivePie } from '@nivo/pie';

import { numberBrFormatter } from '../../../utils/utils';
import Tooltip from './Tooltip/Tooltip';

import './styles.scss';

const noData = [
  {
    id: 'Sem dados',
    label: 'Sem dados',
    value: 1,
    color: '#CACCCF',
  },
];

const Pix = ({ bankTransfers }) => {
  const data = useMemo(
    () => [
      {
        id: 'Aprovados',
        label: 'Aprovados',
        value: bankTransfers.approved.amount,
        color: '#003266',
      },
      {
        id: 'Pendentes',
        label: 'Pendentes',
        value: bankTransfers.pending.amount,
        color: '#007EFF',
      },
      {
        id: 'Recusados',
        label: 'Recusados',
        value: bankTransfers.cancelled.amount,
        color: '#80bfff',
      },
    ],
    [bankTransfers],
  );

  const colors = ['#003266', '#007EFF', '#66B2FF'];

  const isCardsAmountDifferentThanZero =
    (bankTransfers.approved.amount || bankTransfers.pending.amount || bankTransfers.cancelled.amount) !== 0;

  return (
    <Card className="product-details-pix-container">
      <div className="header">
        <span>PIX</span>
      </div>

      <div className="body">
        <div className="legend">
          <div className="approved">
            <div>
              <span>R$</span>
              <span>
                {numberBrFormatter(bankTransfers.approved.amount, 2)} (
                {numberBrFormatter(bankTransfers.approved.total, 0)})
              </span>
            </div>

            <div>
              <div className="icon" />
              <span>Aprovados</span>
            </div>
          </div>

          <div className="in-analysis">
            <div>
              <span>R$</span>
              <span>
                {numberBrFormatter(bankTransfers.pending.amount, 2)} (
                {numberBrFormatter(bankTransfers.pending.total, 0)})
              </span>
            </div>

            <div>
              <div className="icon" />
              <span>Pendentes</span>
            </div>
          </div>

          <div className="refused">
            <div>
              <span>R$</span>
              <span>
                {numberBrFormatter(bankTransfers.cancelled.amount, 2)} (
                {numberBrFormatter(bankTransfers.cancelled.total, 0)})
              </span>
            </div>

            <div>
              <div className="icon" />
              <span>Cancelados</span>
            </div>
          </div>
        </div>

        <div className="chart">
          {isCardsAmountDifferentThanZero ? (
            <ResponsivePie
              data={data}
              colors={colors}
              innerRadius={0.7}
              enableRadialLabels={false}
              enableSlicesLabels={false}
              tooltip={({ id, value }) => <Tooltip id={id} value={value} />}
            />
          ) : (
            <ResponsivePie
              data={noData}
              colors={['#CACCCF']}
              innerRadius={0.7}
              enableRadialLabels={false}
              enableSlicesLabels={false}
              isInteractive={false}
            />
          )}
          <div className="main-info">
            <span>{numberBrFormatter(bankTransfers.conversion.percentage, 2)}%</span>
            <span>Conversão</span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default memo(Pix);
