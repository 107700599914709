import React, { useEffect, useState, useCallback } from 'react';
import { Row, Container, Card } from 'reactstrap';
import moment from 'moment-timezone';
import { api } from '../../services/api';
import Spinner from '../Spinner/Spinner';
import 'react-input-range/lib/css/index.css';
import DefaultPlans from './DefaultPlans/DefaultPlans';
import { useAuth } from '../../hooks/User';

import './styles.scss';

const SubscribePlans = ({ onSubmitPlan, subTitle, loading, buttonText }) => {
  const [plans, setPlans] = useState(null);
  const user = useAuth();

  const loadPlans = useCallback(async () => {
    const { data } = await api('user-api').get(`api/v1/users/plans?category=default`);

    setPlans(data.plans);
  }, []);

  useEffect(() => {
    loadPlans();
  }, [loadPlans]);

  return plans ? (
    <Container className="plans px-md-6">
      <Row className="justify-content-center align-items-center">
        <Card className="shadow px-md-5 px-3 pt-4 pb-4 mb-6">
          <div>
            {moment().isSameOrAfter(moment(user?.subscription?.currentPeriodEnd)) ? (
              <h1 className="expired-plan-title font-weight-normal text-center pt-2 px-md-3">
                Olá, o seu plano encontra-se <b>EXPIRADO</b> e não foi renovado automaticamente pois precisamos de
                algumas informações para emissão de nossa nota fiscal.
              </h1>
            ) : (
              <h1 className="title font-weight-normal text-center pt-2 px-md-3">
                Os planos baseiam-se em seu faturamento! <br />
                Assim seu bolso fica feliz da vida, né?
              </h1>
            )}
          </div>
          <hr />
          <DefaultPlans
            onSubmitPlan={onSubmitPlan}
            plans={plans}
            subTitle={subTitle}
            loading={loading}
            buttonText={buttonText}
          />
        </Card>
      </Row>
    </Container>
  ) : (
    <Spinner loading />
  );
};

export default SubscribePlans;
