import React, { memo } from 'react';
import { ResponsiveBar } from '@nivo/bar';

const Chart = ({ data }) => {
  return (
    <ResponsiveBar
      data={data}
      keys={['gateway', 'checkout', 'taxes']}
      indexBy="id"
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      padding={0}
      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={['#90C2FF', '#1A8BFF', '#004C99']}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      enableGridY={false}
      enableGridX={false}
      enableLabel={false}
      legends={[]}
      animate
      motionStiffness={90}
      motionDamping={15}
      isInteractive={false}
    />
  );
};

export default memo(Chart);
