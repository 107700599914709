/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Card, Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import queryString from 'query-string';
import BilletTableHeader from './TableHeader/TableHeader';
import BilletTableBody from './TableBody/TableBody';
import BilletTableFooter from './TableFooter/TableFooter';
import Spinner from '../../../components/Spinner/Spinner';
import DatePicker from '../../../components/DatePicker/DatePicker';
import MiniCard from '../../../components/MiniCard/MiniCard';
import { api, shopsApi } from '../../../services/api';
import useQuery from '../../../hooks/Query';
import { numberBrFormatter } from '../../../utils/utils';
import noDataImage from '../../../assets/img/theme/no_data.svg';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';

const Billets = () => {
  const query = useQuery();
  const { user } = useAuth();
  const startDateQuery = useMemo(() => query.get('startDate'), [query]);
  const endDateQuery = useMemo(() => query.get('endDate'), [query]);

  const [billets, setBillets] = useState([]);
  const [metrics, setMetrics] = useState({
    revenue: 0,
    totalOrders: 0,
    cogs: 0,
    profit: 0,
  });
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [domain, setDomain] = useState('');
  const [filter, setFilter] = useState('approvedAt');
  const [billetPeriod, setBilletPeriod] = useState({
    startDate: startDateQuery ? moment(startDateQuery) : moment(),
    endDate: endDateQuery ? moment(endDateQuery) : moment(),
  });
  const [sort] = useState({
    createdAt: 'desc',
  });
  const { store, platform } = useParams();

  const loadOrdersData = useCallback(
    async load => {
      const limit = 20;
      try {
        setLoading(Boolean(load));

        const [{ data: response }, { data: billetMetrics }] = await Promise.all([
          api(shopsApi[platform]).get(
            `api/v1/users/${platform}/stores/${store}/orders/ticket?page=${
              page + 1
            }&limit=${limit}&startDate=${billetPeriod.startDate.format(
              'YYYY-MM-DD',
            )}&endDate=${billetPeriod.endDate.format('YYYY-MM-DD')}&filterBy=${filter}&${queryString.stringify({
              sort: JSON.stringify(sort),
            })}`,
          ),
          api(shopsApi[platform]).get(
            `api/v1/users/${platform}/stores/${store}/orders/ticket/profit?startDate=${billetPeriod.startDate.format(
              'YYYY-MM-DD&',
            )}&endDate=${billetPeriod.endDate.format('YYYY-MM-DD')}&filterBy=${filter}&${queryString.stringify({
              sort: JSON.stringify(sort),
            })}`,
          ),
        ]);

        setBillets(response.data.orders);
        setDomain(response.domain);
        setMetrics(billetMetrics.profit);
        setPageCount(response.data.numberOfPages);
      } finally {
        setLoading(false);
      }
    },

    [page, store, platform, billetPeriod, filter, sort],
  );

  const onChangePage = page => {
    setPage(page.selected);
  };

  const onDate = useCallback(({ startDate, endDate }) => {
    if (!startDate || !endDate) return;
    if (!startDate.isValid() || !endDate.isValid()) return;
    setPage(0);
    setBilletPeriod({ startDate, endDate });
  }, []);

  useEffect(() => {
    loadOrdersData(true);
  }, [loadOrdersData]);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_boletos',
        type: 'screen',
      });
    }
  }, [user]);

  return (
    <Container fluid className="pb-8 pt-0 pt-md-8">
      <Row className="mb-4">
        <Col>
          <DatePicker
            startDate={billetPeriod.startDate}
            endDate={billetPeriod.endDate}
            onDate={onDate}
            showPreDefinedDates
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <MiniCard title="Faturamento" prepend="R$" value={numberBrFormatter(metrics.revenue)} />
        </Col>
        <Col className="pl-0 pl-md-3">
          <MiniCard title="Custo dos Produtos" prepend="R$" value={numberBrFormatter(metrics.cogs)} />
        </Col>
        <Col className="mt-3 mt-md-0">
          <MiniCard title="Lucro Estimado" prepend="R$" value={numberBrFormatter(metrics.profit)} />
        </Col>
        <Col className="mt-3 mt-md-0 pl-0 pl-md-3">
          <MiniCard title="Total de Pedidos" value={metrics.totalOrders} />
        </Col>
      </Row>
      <Row className="mt-4">
        <div className="col">
          <Card className="shadow">
            <BilletTableHeader filter={filter} setFilter={setFilter} />
            {loading ? (
              <Spinner loading={loading} />
            ) : (
              <BilletTableBody billets={billets} platform={platform} domain={domain} />
            )}
            <BilletTableFooter pages={pageCount} onChangePage={onChangePage} page={page} />
          </Card>
        </div>
      </Row>

      {billets.length <= 0 && !loading && (
        <Row className="align-items-center flex-column mt-6">
          <img src={noDataImage} width="300px" alt="no data" />
          <h1 className="mt-4 text-muted text-center">Seus boletos ainda não foram compensados.</h1>
        </Row>
      )}
    </Container>
  );
};

export default Billets;
