import MercadoPagoWarn from '../../components/Warns/MercadoPago/MercadoPago';
import AppmaxWarn from '../../components/Warns/Appmax/Appmax';
import CieloWarn from '../../components/Warns/Cielo/Cielo';
import FacebookWarn from '../../components/Warns/Facebook/Facebook';
import GatewayWarn from '../../components/Warns/Gateway/Gateway';
import CloudFoxWarn from '../../components/Warns/Cloudfox/Cloudfox';
import PagseguroWarn from '../../components/Warns/Pagseguro/Pagseguro';
import OrderWithNoProduct from '../../components/Warns/OrderWithNoProduct/OrderWithNoProduct';
import PendingPayment from '../../components/Warns/PendingPayment';
import DoppusWarn from '../../components/Warns/Doppus/Doppus';
import UpnidWarn from '../../components/Warns/Upnid/Upnid';
import PagarmeWarn from '../../components/Warns/Pagarme/Pagarme';

const getWarnAlerts = (warns, pendingPayment) => {
  if (!warns && !pendingPayment) return [];

  const componentsWithWarn = [];

  if (pendingPayment) {
    componentsWithWarn.push(PendingPayment);
  }

  if (warns.hubSaleCredential) {
    componentsWithWarn.push(DoppusWarn);
  }

  if (warns.pagarmeCredential) {
    componentsWithWarn.push(PagarmeWarn);
  }

  if (warns.upnidCredential) {
    componentsWithWarn.push(UpnidWarn);
  }

  if (warns.mercadoPagoCredential) {
    componentsWithWarn.push(MercadoPagoWarn);
  }

  if (warns.appMaxCredential) {
    componentsWithWarn.push(AppmaxWarn);
  }

  if (warns.cieloCredential) {
    componentsWithWarn.push(CieloWarn);
  }

  if (warns.cloudFoxCredential) {
    componentsWithWarn.push(CloudFoxWarn);
  }

  if (warns.pagSeguroCredential) {
    componentsWithWarn.push(PagseguroWarn);
  }

  if (warns.facebookCredential) {
    componentsWithWarn.push(FacebookWarn);
  }

  if (warns.noGatewayCredential) {
    componentsWithWarn.push(GatewayWarn);
  }

  if (warns.ordersWithNoProduct) {
    componentsWithWarn.push(OrderWithNoProduct);
  }

  return componentsWithWarn;
};

export default getWarnAlerts;
