import React, { memo, useEffect, useCallback } from 'react';
import { Table, InputGroup, InputGroupText, InputGroupAddon, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import { useFacebookMarketing } from '../../../hooks/FacebookMarketing';
import Spinner from '../../Spinner/Spinner';
import AdAccountRow from '../AdAccountRow/AdAccountRow';
// import AdAccountTooltip from '../AdAccountTooltip/AdAccountTooltip';
// import usePersistedState from '../../../hooks/PersistedState';

import './styles.scss';

const AdAccountsTable = () => {
  const {
    pagesCountAdAccount,
    handleChangePageAdAccount,
    loading,
    adAccounts,
    setStore,
    setPlatform,
    setPageAdAccount,
    setAdAccountName,
  } = useFacebookMarketing();
  const { platform, store } = useParams();

  // const [isAdAccountTooltipOpen, setIsAdAccountTooltipOpen] = usePersistedState('isAdAccountTooltipOpen', true);

  // const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const onSearch = useCallback(
    async event => {
      const { value } = event.target;
      setPageAdAccount(1);
      setAdAccountName(value);
    },
    [setAdAccountName, setPageAdAccount],
  );

  // const handleTooltip = useCallback(() => setIsAdAccountTooltipOpen(false), [setIsAdAccountTooltipOpen]);

  useEffect(() => {
    setStore(store);
    setPlatform(platform);
  }, [store, platform, setStore, setPlatform]);

  // useEffect(() => {
  //   if (!loading) {
  //     setIsTooltipOpen(isAdAccountTooltipOpen);
  //   }
  // }, [isAdAccountTooltipOpen, loading]);

  return (
    <div className="facebook-ad-accounts-table-container">
      <div className="header">
        <div className="description">
          <span>Gerenciar contas de anúncio</span>
          <span>Selecione todas as contas de ads da sua loja para que possamos fazer os cálculos corretos.</span>
        </div>

        <div>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fa fa-search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input placeholder="Nome da conta de anúncio" type="text" name="Search" onChange={onSearch} />
          </InputGroup>
        </div>
      </div>

      <>
        <div className="body">
          {loading ? (
            <Spinner loading />
          ) : (
            <Table className="table-flush" responsive>
              <>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      <span className="facebook-form-table-title">Id da Conta</span>
                    </th>
                    <th scope="col">
                      <span className="facebook-form-table-title">Nome </span>
                    </th>
                    <th scope="col">
                      <span className="facebook-form-table-title">Perfil </span>
                    </th>
                    <th scope="col" className="text-center">
                      <span className="facebook-form-table-title" id="ad-account-actions">
                        Ações{' '}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {adAccounts.map(adAccount => (
                    <AdAccountRow adAccount={adAccount} key={adAccount.id} />
                  ))}
                </tbody>
              </>
            </Table>
          )}
        </div>

        {/* <AdAccountTooltip isOpen={isTooltipOpen} toggle={handleTooltip} target="ad-account-actions" /> */}

        <div className="footer">
          <ReactPaginate
            previousLabel=""
            nextLabel=""
            pageCount={pagesCountAdAccount}
            onPageChange={handleChangePageAdAccount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            containerClassName="pagination justify-content-end mb-0"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            disabledClassName="disabled"
            activeClassName="active"
            nextClassName="page-item"
            nextLinkClassName="page-link fas fa-angle-right"
            previousClassName="page-item"
            previousLinkClassName="page-link fas fa-angle-left"
          />
        </div>
      </>
    </div>
  );
};

export default memo(AdAccountsTable);
