import React, { memo } from 'react';
import { CurrencyDollarSimple } from 'phosphor-react';

import { numberBrFormatter } from '../../../../utils/utils';

import './styles.scss';

const Profit = ({ profit }) => {
  return (
    <div className="product-details-profit">
      <div className="header">
        <span>LUCRO LÍQUIDO</span>
        <div>
          <CurrencyDollarSimple size={16} weight="bold" />
        </div>
      </div>
      <div className="body">
        <div className="details">
          <div className="value-wrapper">
            <span>Faturamento Líquido</span>
            <span>
              <small>R$</small>
              {numberBrFormatter(profit.mainCards.revenue, 2)}
            </span>
          </div>

          <div className="value-wrapper">
            <span>Custo dos Produtos</span>
            <span>
              <small>{profit.mainCards.cogs !== 0 && '- '}R$</small>
              {numberBrFormatter(profit.mainCards.cogs, 2)}
            </span>
          </div>

          <div className="value-wrapper">
            <span>Marketing</span>
            <span>
              <small>{profit.mainCards.marketing !== 0 && '- '}R$</small>
              {numberBrFormatter(profit.mainCards.marketing, 2)}
            </span>
          </div>

          <div className="value-wrapper">
            <span>Taxa de Transações</span>
            <span>
              <small>{profit.mainCards.transactionTaxes !== 0 && '- '}R$</small>
              {numberBrFormatter(profit.mainCards.transactionTaxes, 2)}
            </span>
          </div>

          {profit.mainCards.chargedBackAmount + profit.mainCards.refundedAmount !== 0 && (
            <div className="value-wrapper refunded-chargeback">
              <span>Estornos e Chargebacks</span>
              <span>
                <small>- R$</small>
                {numberBrFormatter(profit.mainCards.chargedBackAmount + profit.mainCards.refundedAmount, 2)}
              </span>
            </div>
          )}
        </div>

        <hr />

        <div className="total-wrapper">
          <span>LÍQUIDO</span>
          <span>
            <small>{profit.mainCards.profit < 0 && '- '}R$</small>
            {profit.mainCards.profit < 0
              ? numberBrFormatter(profit.mainCards.profit * -1, 2)
              : numberBrFormatter(profit.mainCards.profit, 2)}
          </span>
        </div>
      </div>

      <div className="gradient-border-detail">
        <div />
      </div>
    </div>
  );
};

export default memo(Profit);
