import React from 'react';
import './styles.scss';
import { Card } from 'reactstrap';
import { numberBrFormatter } from '../../../../../utils/utils';

const ChartTooltip = ({ data: { color, data } }) => {
  return (
    <Card className="shadow chart-tooltip">
      <div>
        <div className="d-flex tooltip-title">
          <span className="font-weight-bold">{data.date}</span>
        </div>
        <div className="d-flex">
          <div className="tooltip-subtitle-name">
            <div className="d-flex">
              <div className="tooltip-color" style={{ backgroundColor: color.revenue }} />
              <span>Faturamento:</span>
            </div>
            <div className="d-flex">
              <div className="tooltip-color" style={{ backgroundColor: color.cogs }} />
              <span>Custo dos Produtos:</span>
            </div>
            <div className="d-flex">
              <div className="tooltip-color" style={{ backgroundColor: color.marketing }} />
              <span>Marketing:</span>
            </div>
            <div className="d-flex">
              <div className="tooltip-color" style={{ backgroundColor: color.transactionTax }} />
              <span>Taxa de Transações:</span>
            </div>
          </div>
          <div className="d-flex tooltip-value">
            <span>R$ {numberBrFormatter(data.revenue, 2)}</span>
            <span>R$ {numberBrFormatter(data.cogs, 2)}</span>
            <span>R$ {numberBrFormatter(data.marketing, 2)}</span>
            <span>R$ {numberBrFormatter(data.transactionTax, 2)}</span>
          </div>
        </div>
        <div className="d-flex tooltip-profit">
          <div className="d-flex">
            <div className="tooltip-color" style={{ backgroundColor: color.profit }} />
            <span className={`text-${data.profit > 0 ? 'green' : 'red'}`}>Lucro Líquido:</span>
          </div>
          <span className={`text-${data.profit > 0 ? 'green' : 'red'}`}>R$ {numberBrFormatter(data.profit, 2)}</span>
        </div>
      </div>
    </Card>
  );
};

export default ChartTooltip;
