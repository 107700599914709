import React, { memo, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { CaretLeft } from 'phosphor-react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import moment from 'moment-timezone';
import { Button, Tooltip } from 'reactstrap';
import { CircleSpinner } from 'react-spinners-kit';

import { useFacebookMarketing } from '../../../../hooks/FacebookMarketing';
import Spinner from '../../../../components/Spinner/Spinner';
import ProfileCard from '../../../../components/Facebook/ProfileCard/ProfileCard';
import AdAccountsTable from '../../../../components/Facebook/AdAccountsTable/AdAccountsTable';
import useQuery from '../../../../hooks/Query';
import ConnectWarningModal from '../../../../components/Facebook/ConnectWarningModal/ConnectWarningModal';
import facebookWhiteIcon from '../../../../assets/img/icons/common/facebook-white-icon.svg';

import './styles.scss';
import { api } from '../../../../services/api';

moment.locale('pt-br');

const Facebook = () => {
  const {
    handleFacebookLogin,
    profiles,
    setStore,
    setPlatform,
    loadProfiles,
    isLoadingProfiles,
    isLoggingOutProfile,
    loadAdAccounts,
    isLoggingInProfile,
    hasActivatedAdAccount,
  } = useFacebookMarketing();
  const { store, platform } = useParams();
  const query = useQuery();
  const history = useHistory();

  const sliderProfilesRef = useRef(null);

  const productId = useMemo(() => query.get('productId'), [query]);
  const isFromCampaigns = useMemo(() => query.get('campaigns'), [query]);

  const [isMouseMoveOn, setIsMouseMoveOn] = useState(false);
  const [onMouseDownEvent, setOnMouseDownEvent] = useState({});
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isSyncingAdAccount, setIsSyncingAdAccount] = useState(false);
  const [isConnectFacebookModalOpen, setIsConnectFacebookModalOpen] = useState(false);

  const handleConnectFacebookModalOpen = useCallback(
    () => setIsConnectFacebookModalOpen(!isConnectFacebookModalOpen),
    [isConnectFacebookModalOpen],
  );

  const handleSyncAdAccount = useCallback(async () => {
    setIsSyncingAdAccount(true);

    try {
      const profilesToSync = profiles.map(({ id }) =>
        api('user-api').put(`api/v1/users/facebook/credential/${id}/ad-accounts`),
      );

      await Promise.all(profilesToSync);

      loadAdAccounts({ isLoading: true, store, platform });
    } finally {
      setIsSyncingAdAccount(false);
    }
  }, [loadAdAccounts, profiles, platform, store]);

  const handleGoBackToCampaignsOnboarding = useCallback(() => {
    history.push(`/${platform}/${store}/dashboard/product-details/${productId}/product-details?campaigns=true`);
  }, [history, productId, store, platform]);

  const handleTooltip = useCallback(() => setIsTooltipOpen(!isTooltipOpen), [isTooltipOpen]);

  const handleOnMouseDown = useCallback(event => {
    setIsMouseMoveOn(true);
    setOnMouseDownEvent(event);
  }, []);

  const handleOnMouseUp = useCallback(() => {
    setIsMouseMoveOn(false);
    setScrollLeft(sliderProfilesRef.current.scrollLeft);
    setOnMouseDownEvent({});
  }, []);

  const handleOnMouseMove = useCallback(
    event => {
      event.persist();

      if (isMouseMoveOn && sliderProfilesRef) {
        const position = onMouseDownEvent.pageX - event.clientX;

        sliderProfilesRef.current.scrollLeft = scrollLeft + position;
      }
    },
    [isMouseMoveOn, scrollLeft, sliderProfilesRef, onMouseDownEvent],
  );

  const goToIntegrations = useCallback(() => {
    history.push(`/${platform}/${store}/dashboard/config/integrations`);
  }, [history, platform, store]);

  const goToCampaigns = useCallback(() => {
    history.push(`/${platform}/${store}/dashboard/product-details/${productId}/product-details?campaigns=true`);
  }, [history, platform, store, productId]);

  const handleFacebookConnect = useCallback(
    ({ onClick }) => {
      if (profiles.length >= 1) {
        handleConnectFacebookModalOpen();
      }

      onClick();
    },
    [profiles.length, handleConnectFacebookModalOpen],
  );

  useEffect(() => {
    setStore(store);
    setPlatform(platform);
  }, [store, platform, setStore, setPlatform]);

  useEffect(() => {
    loadProfiles();
  }, [loadProfiles]);

  useEffect(() => {
    if (platform && store) {
      loadAdAccounts({ isLoading: true, platform, store });
    }
  }, [loadAdAccounts, platform, store]);

  if (isLoadingProfiles || isLoggingOutProfile || isLoggingInProfile) {
    return <Spinner loading />;
  }

  return (
    <div className="facebook-integration-container">
      <div className="accounts-integration">
        <div className="go-back-wrapper">
          {productId && isFromCampaigns && (
            <button className="to-campaigns" onClick={goToCampaigns} type="button">
              <CaretLeft size={16} weight="bold" />
              <span>Voltar às campanhas</span>
            </button>
          )}

          {!productId && (
            <button className="to-integrations" onClick={goToIntegrations} type="button">
              <CaretLeft size={16} weight="bold" />
              <span>Voltar às integrações</span>
            </button>
          )}
        </div>

        <div className="header">
          <div>
            <span>Integração com o Facebook</span>
            <span>Conecte perfis do Facebook para contabilizar seus gastos com anúncios.</span>
          </div>

          <div className="buttons-wrapper">
            <FacebookLogin
              appId="2444384415680634"
              scope="ads_read,public_profile,business_management,ads_management"
              version="5.0"
              disableMobileRedirect
              callback={responseFacebook => handleFacebookLogin({ responseFacebook, store, platform })}
              render={props => (
                <button
                  className="facebook-add-account"
                  type="button"
                  onClick={() => handleFacebookConnect({ onClick: props.onClick })}
                >
                  <img className="facebook-logo" src={facebookWhiteIcon} alt="Profitfy.me Facebook" />

                  <span>Conectar com facebook</span>
                </button>
              )}
            />

            {profiles.length > 0 && (
              <Button
                className="sync-account"
                color="primary"
                outline
                disabled={isSyncingAdAccount}
                onClick={handleSyncAdAccount}
              >
                {isSyncingAdAccount ? (
                  <>
                    Sincronizando Contas
                    <CircleSpinner size={20} color="#a0a4a8" />
                  </>
                ) : (
                  <>Sincronizar Contas</>
                )}
              </Button>
            )}
          </div>
        </div>

        <div
          className="body"
          role="row"
          tabIndex={0}
          ref={sliderProfilesRef}
          onMouseDown={handleOnMouseDown}
          onMouseUp={handleOnMouseUp}
          onMouseMove={handleOnMouseMove}
        >
          <ConnectWarningModal isOpen={isConnectFacebookModalOpen} toggle={handleConnectFacebookModalOpen} />
          {profiles.map(profile => (
            <ProfileCard profile={profile} key={profile.id} />
          ))}
        </div>
      </div>

      <hr />

      {profiles.length > 0 && <AdAccountsTable />}

      {profiles.length > 0 && productId && !isFromCampaigns && (
        <div className="go-back-onboarding-wrapper">
          <div className="button-wrapper" id="activate-ad-account">
            <Button
              color="primary"
              className={hasActivatedAdAccount ? 'activated-ad-account' : undefined}
              disabled={!hasActivatedAdAccount}
              onClick={handleGoBackToCampaignsOnboarding}
            >
              Continuar sincronizando minhas campanhas
            </Button>
          </div>

          {!hasActivatedAdAccount && (
            <Tooltip placement="top" isOpen={isTooltipOpen} toggle={handleTooltip} target="activate-ad-account">
              É necessário ativar uma conta de anúncio para poder continuar.
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(Facebook);
