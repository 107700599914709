import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { usePlans } from '../../../hooks/Plans';

import SubscribePlans from '../../../components/SubscribePlans/SubscribePlans';

import './styles.scss';

const SelectPlans = () => {
  const history = useHistory();
  const { setPlan } = usePlans();

  const handleSubmit = useCallback(
    ({ planId, price, title, description, isFreePlan }) => {
      setPlan({ planId, price, title, description, isFreePlan });

      history.push('/subscribe/personal-info');
    },
    [setPlan, history],
  );

  return (
    <div className="main-select-plans-container">
      <div className="content-container">
        <div className="content-header">
          <img
            className="profitfy-logo"
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/logo_v2.svg"
            alt="Profitfy.me"
          />
          <div className="step-container">
            <div className="bar-container">
              <div className="bar-progress" />
            </div>
            <span>1/3</span>
          </div>
        </div>
        <SubscribePlans onSubmitPlan={handleSubmit} buttonText="Próximo Passo" />
      </div>
      <div className="creative-container">
        <p className="description-text">Assista a esse vídeo para ver nossos benefícios imperdíveis!!</p>
        <div className="video-container">
          <img
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/trial/trial-video-benefits-bg.svg"
            alt="Profitfy.me benefits"
          />
          <div className="video-container">
            <iframe
              title="Profitfy Trial Benefits"
              width="400"
              height="225"
              src="https://www.youtube.com/embed/DjweMZFgWjg"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPlans;
