import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './Login/Login';
import Register from './Register/Register';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';

const Auth = props => {
  return (
    <>
      <Switch>
        <Route path="/auth/login" component={() => <Login {...props} />} />
        <Route path="/auth/register" component={() => <Register {...props} />} />
        <Route path="/auth/forgot-password" component={() => <ForgotPassword {...props} />} />
        <Route path="/auth/reset-password/:resetToken" component={() => <ResetPassword {...props} />} />
      </Switch>
    </>
  );
};

export default Auth;
