export const getCancelationOption = option =>
  ({
    'OPTION-1': 'Estou fechando minha empresa',
    'OPTION-2': 'Faltou integrações',
    'OPTION-3': 'Meus dados estão incorretos',
    'OPTION-4': 'Tive problemas técnicos com a plataforma',
    'OPTION-5': 'Achei o valor alto',
    'OPTION-6': 'A plataforma é muito complexa',
    'OPTION-7': 'Não recebi o suporte necessário',
    'OPTION-8': 'Faltou ferramentas para atender meu negócio',
    'OPTION-9': 'Vou migrar para outra plataforma',
    'OPTION-10': 'Outros',
  }[option]);
