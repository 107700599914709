import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { WarningCircle } from 'phosphor-react';

import './styles.scss';

const TrialingWarn = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="appmax-trialing-warn-modal" centered>
      <ModalHeader className="header">
        <WarningCircle size={28} />
        <span>Aviso</span>
      </ModalHeader>
      <ModalBody className="body">
        <span>Não é possível continuar a ativação do plano Appmax.</span>
        <p>
          Para poder continuar a ativação do plano Appmax é necessário <strong>concluir o seu período de teste</strong>{' '}
          dentro da nossa plataforma. Por favor, tente novamente após o término do trial.
        </p>
        <Button color="primary" onClick={toggle}>
          Entendi
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default TrialingWarn;
