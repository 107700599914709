import React, { useState, useCallback } from 'react';
import { Row, Col, Form, FormGroup, Label, Input as StrapInput, Button } from 'reactstrap';
import useForm from 'react-hook-form';

const DsersLogin = ({ handleDsersCredential }) => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm({});

  const onSubmit = useCallback(
    async data => {
      const { email, password } = data;

      setLoading(true);

      await handleDsersCredential({ email, password });

      setLoading(false);
    },
    [handleDsersCredential],
  );

  return (
    <Row className="px-4">
      <Col md={6} className="text-left mt-5">
        <h2>Faça login na sua conta Dsers</h2>
        <p className="pr-4">Para podermos puxar os custos dos produtos, é preciso logar com sua conta dsers.</p>
      </Col>
      <Col>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="pl-lg-4">
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="input-email">
                    Email
                  </Label>
                  <StrapInput
                    name="email"
                    className="form-control-alternative"
                    id="input-email"
                    type="email"
                    innerRef={register}
                    placeholder="Email"
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="input-password">
                    Senha
                  </Label>
                  <StrapInput
                    name="password"
                    className="form-control-alternative"
                    id="input-password"
                    type="password"
                    innerRef={register}
                    placeholder="Senha"
                  />
                </FormGroup>

                <hr className="my-3" />
                <div className="d-flex justify-content-center">
                  <Button size="lg" color="primary" type="submit" className="mt-4 px-4" disabled={loading}>
                    <span className="text-uppercase">{loading ? 'Enviando ...' : 'Entrar'}</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default DsersLogin;
