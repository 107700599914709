import React, { memo, useCallback, useState } from 'react';
import { MonitorPlay, Check } from 'phosphor-react';
import InputRange from 'react-input-range';
import { Button, Tooltip } from 'reactstrap';

import { percentageInfoUtils } from '../../../../utils/percentageInfo';
import { numberBrFormatter } from '../../../../utils/utils';

import './styles.scss';

const SelectAverageCost = ({
  selectedPercentage,
  setSelectedPercentage,
  setConvertCurrencyStep,
  handleConfirmButton,
  toggle,
}) => {
  const { getPercentageInfo } = percentageInfoUtils;

  const [isSelectAverageCostTooltipOpen, setIsSelectAverageCostTooltipOpen] = useState(false);

  const handleSelectAverageCostTooltipOpen = useCallback(
    () => setIsSelectAverageCostTooltipOpen(!isSelectAverageCostTooltipOpen),
    [isSelectAverageCostTooltipOpen],
  );

  const handlePercentage = useCallback(
    percentage => {
      setSelectedPercentage(percentage);
    },
    [setSelectedPercentage],
  );

  const getProductCost = useCallback(() => {
    const parsedPercentage = (selectedPercentage + 1) / 10;

    const parsedProductCost = numberBrFormatter(99.9 * parsedPercentage, 2);

    return parsedProductCost;
  }, [selectedPercentage]);

  const handleConfirmPercentage = useCallback(() => {
    setConvertCurrencyStep('currency');
    handleConfirmButton();
    toggle();
  }, [setConvertCurrencyStep, toggle, handleConfirmButton]);

  return (
    <div className="edit-product-cost-select-average-cost">
      <span>
        A % de custo dos seus produtos é baseado no <strong>valor de venda</strong>
      </span>

      <div className="short-video" id="select-average-cost-tutorial-video">
        <MonitorPlay size={18} />
        <span>Precisa de ajuda? Assista um video curto</span>
        <div className="duration-badge">
          <span>0:30</span>
        </div>
      </div>

      <Tooltip
        isOpen={isSelectAverageCostTooltipOpen}
        toggle={handleSelectAverageCostTooltipOpen}
        placement="bottom"
        target="select-average-cost-tutorial-video"
        className="select-average-cost-tutorial-video"
        autohide={false}
      >
        <video
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/videos-tuto/cogs-average-cost-step.mp4"
          controls
          autoPlay
          muted
        >
          <track kind="captions" />
        </video>
      </Tooltip>

      <div className="benefits">
        <div>
          <Check size={16} weight="bold" />
          <span>Definição de custo de produto em massa</span>
        </div>

        <div>
          <Check size={16} weight="bold" />
          <span>Pré configuração de estimativa de custo dos produtos</span>
        </div>

        <div>
          <Check size={16} weight="bold" />
          <span>Economizar tempo</span>
        </div>
      </div>

      <span className="slider-instruction">Arraste o círculo até a % estimada</span>

      <div className="input-range-currency">
        <InputRange
          allowSameValues
          maxValue={9}
          minValues={0}
          value={selectedPercentage}
          formatLabel={() => getPercentageInfo(selectedPercentage)?.description}
          onChange={handlePercentage}
        />
      </div>

      <span className="example">
        Ex. Se você vende um produto por <strong>R$ 99,90</strong> o custo será de{' '}
        <strong>{`R$ ${getProductCost()}`}</strong>
      </span>

      <span className="instruction">Você poderá editar cada custo com mais assertividade posteriormente.</span>

      <Button onClick={handleConfirmPercentage} color="primary">
        Confirmar
      </Button>
    </div>
  );
};

export default memo(SelectAverageCost);
