import React, { memo } from 'react';
import { CardBody, Table } from 'reactstrap';

import CampaignsTableRow from '../CampaignsTableRow/CampaignsTableRow';

import Spinner from '../../Spinner/Spinner';

import './styles.scss';

const CampaignsTableBody = ({ campaigns, isLoadingCampaigns }) => {
  return (
    <CardBody className="campaigns-table-body">
      {isLoadingCampaigns ? (
        <Spinner loading />
      ) : (
        <Table className="table-flush campaigns-table" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">
                <span>Nome</span>
              </th>
              <th scope="col">
                <span>Conta de Anúncio</span>
              </th>
              <th scope="col">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {campaigns.map(campaign => (
              <CampaignsTableRow campaign={campaign} key={campaign.id} />
            ))}
          </tbody>
        </Table>
      )}
    </CardBody>
  );
};

export default memo(CampaignsTableBody);
