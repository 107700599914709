import React from 'react';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../styles.scss';

const PendingPayment = () => (
  <Alert color="white" className="d-flex align-items-center warn-danger warn-style">
    <span>
      <i className="fa fa-exclamation-triangle" />
      Você possui uma <strong>transação pendente</strong>! Realize o pagamento para não ficar sem o acesso à plataforma.{' '}
      <Link className="alert-link" to="user-profile">
        Clique aqui{' '}
      </Link>
      para olhar suas transações.
    </span>
  </Alert>
);

export default PendingPayment;
