import React, { memo, useState, useRef, useCallback } from 'react';
import { InputGroup, Input, Label } from 'reactstrap';
import { useParams } from 'react-router-dom';

import { useProductVariantKits } from '../../../../hooks/ProductCost';
import { useToast } from '../../../../hooks/Toast';
import { masks, currencyToNumber, numberBrFormatter } from '../../../../utils/utils';
import { api, shopsApi } from '../../../../services/api';
import Spinner from '../../../Spinner/Spinner';

import './styles.scss';

const VariantKits = ({ variant, selectedProduct, userCurrency }) => {
  const { platform, store } = useParams();
  const { variantKitsData, isLoadingVariantKits, mutate } = useProductVariantKits({
    platform,
    store,
    productId: selectedProduct?.id,
    variantId: variant?.id,
  });
  const { showToast } = useToast();

  const quantityInputRef = useRef(null);
  const totalCostInputRef = useRef(null);

  const [newKitQuantity, setNewKitQuantity] = useState('');
  const [newTotalCost, setNewTotalCost] = useState('');
  const [isLoadingNewKit, setIsLoadingNewKit] = useState(false);
  const [isDeletingKit, setIsDeletingKit] = useState(false);

  const onQuantityInputChange = useCallback(event => {
    setNewKitQuantity(event.target.value);
  }, []);

  const onTotalCostInputChange = useCallback(event => {
    event.target.value = masks.currency(event.target.value);

    setNewTotalCost(event.target.value);
  }, []);

  const verifyIfHasKitWithThisQuantity = useCallback(({ kits, quantity }) => {
    const filteredKit = kits.filter(innerKit => innerKit.quantity === Number(quantity));

    if (filteredKit.length) return true;

    return false;
  }, []);

  const handleAddNewKit = useCallback(async () => {
    if (newKitQuantity === '1') {
      showToast({
        message: 'Não é possível criar um kit com uma unidade apenas.',
        type: 'warn',
      });

      return;
    }

    const hasThisQuantity = verifyIfHasKitWithThisQuantity({
      kits: variantKitsData.variantsKit,
      quantity: newKitQuantity,
    });

    if (hasThisQuantity) {
      showToast({
        message: 'Já existe um kit com essa quantidade',
        type: 'warn',
      });

      return;
    }

    setIsLoadingNewKit(true);

    try {
      await api(shopsApi[platform]).post(
        `api/v1/users/${platform}/stores/${store}/products/${selectedProduct?.id}/variants/${variant.id}/variants-kit`,
        {
          quantity: newKitQuantity,
          cost: currencyToNumber(newTotalCost),
        },
      );

      await mutate();
    } finally {
      setIsLoadingNewKit(false);
    }
  }, [
    newKitQuantity,
    variantKitsData,
    verifyIfHasKitWithThisQuantity,
    mutate,
    platform,
    store,
    newTotalCost,
    variant,
    showToast,
    selectedProduct.id,
  ]);

  const handleDeleteKit = useCallback(
    async ({ id }) => {
      setIsDeletingKit(true);

      try {
        await api(shopsApi[platform]).delete(
          `api/v1/users/${platform}/stores/${store}/products/${selectedProduct?.id}/variants/${variant.id}/variants-kit/${id}`,
        );

        await mutate();
      } finally {
        setIsDeletingKit(false);
      }
    },
    [platform, store, mutate, variant, selectedProduct.id],
  );

  if (isLoadingVariantKits || isLoadingNewKit || isDeletingKit) {
    return <Spinner loading />;
  }

  return (
    <div className="variant-kits-wrapper">
      <span>Adicionar custo de Kits</span>

      <div className="variant-kit-inputs">
        <InputGroup>
          <Label>Qnt unidades</Label>
          <Input
            type="number"
            name="quantity"
            id="quantity"
            onChange={onQuantityInputChange}
            innerRef={quantityInputRef}
          />
        </InputGroup>

        <InputGroup>
          <Label>Custo Total</Label>
          <Input type="text" name="cost" id="cost" onChange={onTotalCostInputChange} innerRef={totalCostInputRef} />
        </InputGroup>

        <button type="button" onClick={handleAddNewKit}>
          Adicionar
        </button>
      </div>

      {variantKitsData.variantsKit.map(kit => (
        <div key={kit.id} className="variant-kits-row">
          <span>{kit.quantity}</span>
          <span>
            {userCurrency === 'USD' ? 'U$' : 'R$'} {numberBrFormatter(kit.cost, 2)}
          </span>
          <button type="button" onClick={() => handleDeleteKit(kit)}>
            Excluir
          </button>
        </div>
      ))}
    </div>
  );
};

export default memo(VariantKits);
