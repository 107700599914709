import React, { useEffect, useState, useCallback, useContext, memo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input as StrapInput,
  Button,
} from 'reactstrap';
import moment from 'moment-timezone';
import { useToast } from '../../hooks/Toast';
import { api, shopsApi } from '../../services/api';
import FirstTimeContext from '../../hooks/FirstTimeContext';
import CustomSwitch from '../CustomSwitch/CustomSwitch';
import DatePicker from '../DatePicker/DatePicker';
import { updateCustomerXTracking } from '../../utils/customerXTracking';
import { useAuth } from '../../hooks/User';

const Tax = () => {
  const { store, platform } = useParams();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [period, setPeriod] = useState({ startDate: moment(), endDate: moment() });
  const firstTime = useContext(FirstTimeContext);
  const { user } = useAuth();

  const [tax, setTax] = useState({
    id: null,
    calculateMode: 'with-cogs',
    aliquot: 0,
    category: 'intermediador',
  });

  const handleOnChange = useCallback(event => {
    const { value, name } = event.target;

    if (name === 'aliquot') {
      setTax(state => ({ ...state, ...{ [name]: String(value).replace(/,/g, '.') } }));
    } else {
      setTax(state => ({ ...state, ...{ [name]: value } }));
    }
  }, []);

  const handleCheckBox = useCallback(async event => {
    const { checked } = event.target;
    setChecked(checked);
  }, []);

  const handleDate = useCallback(({ startDate, endDate }) => {
    if (!startDate || !endDate) return;
    if (!startDate.isValid() || !endDate.isValid()) return;
    setPeriod({ startDate, endDate });
  }, []);

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);

      const requestType = tax.id ? 'put' : 'post';
      const { data } = await api(shopsApi[platform])[requestType](
        `/api/v1/users/${platform}/stores/${store}/taxes/${tax?.id || ''}`,
        tax,
      );

      setTax(data.tax);

      if (checked) {
        await api(shopsApi[platform]).put(
          `api/v1/users/${platform}/stores/${store}/orders/recalculate-tax?startDate=${period.startDate.format(
            'YYYY-MM-DD',
          )}&endDate=${period.endDate.format('YYYY-MM-DD')}`,
        );
      }

      showToast({ message: 'Taxas salvas com sucesso!', type: 'success' });

      updateCustomerXTracking({
        email: user?.email,
        userId: user?.id,
        identifier: 'onboarding_has_added_custom_taxes',
      });
    } finally {
      setLoading(false);
    }
  }, [platform, store, showToast, tax, checked, period, user]);

  const loadTax = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api(shopsApi[platform]).get(`/api/v1/users/${platform}/stores/${store}/taxes`);

      if (data.tax?.id) {
        setTax(data.tax);
      } else {
        const defaultTax = {
          id: null,
          calculateMode: 'with-cogs',
          aliquot: 0,
          category: 'intermediador',
        };

        setTax(defaultTax);
      }
    } finally {
      setLoading(false);
    }
  }, [store, platform]);

  useEffect(() => {
    loadTax();
  }, [loadTax]);

  return (
    <Card className="shadow">
      <CardHeader>
        <h3 className="mb-0">Impostos</h3>
        <p className="text-muted text-sm mb-0">Aqui você pode inserir seus impostos.</p>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={12} lg={5}>
            <legend className="text-sm">
              <span className="font-weight-bold">CNAE</span>
            </legend>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-id-badge fa-md text-blue-marine" />
                </InputGroupText>
              </InputGroupAddon>

              <StrapInput
                type="select"
                name="category"
                id="category"
                value={tax?.category}
                disabled={loading}
                onChange={handleOnChange}
              >
                <option value="intermediador">Intermediador de Negócios</option>
                <option value="marketing">Promoção de Vendas</option>
                <option value="other">Outro</option>
              </StrapInput>
            </InputGroup>
          </Col>
          <Col className="pt-md-0 pt-4" md={12} lg={5}>
            <legend className="text-sm">
              <span className="font-weight-bold">Tipo de Contribuição</span>
            </legend>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-share-alt-square fa-md text-blue-marine" />
                </InputGroupText>
              </InputGroupAddon>
              <StrapInput
                type="select"
                name="calculateMode"
                id="calculateMode"
                value={tax?.calculateMode}
                disabled={loading}
                onChange={handleOnChange}
              >
                <option value="with-cogs">Faturamento - Custo do Produto</option>
                <option value="without-cogs">Faturamento</option>
              </StrapInput>
            </InputGroup>
          </Col>
          <Col className="pt-md-0 pt-4" md={2}>
            <legend className="text-sm">
              <span className="font-weight-bold">Alíquota</span>
            </legend>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-percent fa-md text-blue-marine" />
                </InputGroupText>
              </InputGroupAddon>
              <StrapInput
                type="text"
                id="aliquot"
                name="aliquot"
                disabled={loading}
                value={tax?.aliquot}
                onChange={handleOnChange}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-5 align-items-end">
          {firstTime && !firstTime?.isNewUser && (
            <Col md={8}>
              <p className="font-weight-bold">Alterar o imposto para um período passado?</p>
              <div className="d-flex align-items-center">
                <CustomSwitch onClick={handleCheckBox} id="active-tax-recalc" status={checked} />
                {checked && (
                  <div className="ml-0 ml-md-3">
                    <DatePicker
                      onDate={handleDate}
                      startDate={period.startDate}
                      endDate={period.endDate}
                      position="start"
                    />
                  </div>
                )}
              </div>
            </Col>
          )}
          <Col className="text-right pt-xl-0 pt-4">
            <Button color="primary" className="btn-icon btn-2 text-right mt-1" disabled={loading} onClick={handleSave}>
              <span className="btn-inner--text">{loading ? 'Carregando...' : 'Salvar'}</span>
              <span className="btn-inner--icon">
                <i className="fas fa-paper-plane" />
              </span>
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default memo(Tax);
