import React, { useState, useCallback, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import UserPersonalInfo from '../../../components/UserPersonalInfo/UserPersonalInfo';
import { useAuth } from '../../../hooks/User';
import { api } from '../../../services/api';
import { usePlans } from '../../../hooks/Plans';

import './styles.scss';

const PersonalInfo = () => {
  const { user, loadUser } = useAuth();
  const { plan } = usePlans();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async ({ data, cpf, cnpj }) => {
      setLoading(true);
      try {
        const requestType = user?.address?.id ? 'put' : 'post';

        await Promise.all([
          api('user-api').put('/api/v1/users/me', {
            ...user,
            cpf,
            cnpj,
            companyName: data.companyName,
          }),
          api('user-api')[requestType](`/api/v1/users/addresses/${user?.address?.id || ''}`, {
            ...data,
            complementary: data.complementary || null,
            zipcode: data.cep,
          }),
        ]);

        await loadUser();

        history.push('/subscribe/payment');
      } finally {
        setLoading(false);
      }
    },
    [user, loadUser, history],
  );

  useEffect(() => {
    if (!plan) {
      history.push('/subscribe/plans');
    }
  }, [plan, history]);

  return (
    <div className="main-personal-info-container">
      <div className="content-container">
        <div className="content-header">
          <img
            className="profitfy-logo"
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/logo_v2.svg"
            alt="Profitfy.me"
          />
          <div className="step-container">
            <div className="bar-container">
              <div className="bar-progress" />
            </div>
            <span>2/3</span>
          </div>
        </div>
        <UserPersonalInfo onSubmit={handleSubmit} loading={loading} />
        <Link to="/subscribe/plans" className="prev-page">
          <i className="fas fa-arrow-left" />
        </Link>
      </div>
      <div className="creative-container">
        <h3>Resumo do Plano</h3>

        <div className="title-plan">
          <span>Nome do plano:</span>
          <span className="description-title">{plan.title}</span>
        </div>

        <div className="description-plan">
          <span>Descrição:</span>
          <span className="description">
            {plan.isFreePlan ? '+R$4,99 a cada R$1.000 em pedidos pagos, cobrado semanalmente' : `${plan.description}`}
          </span>
        </div>

        <img
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/Subscribe/subscribre-personal-info.svg"
          alt="Profitfy.me personal info"
        />
      </div>
    </div>
  );
};

export default PersonalInfo;
