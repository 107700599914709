import React, { useCallback, useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, Button, Input, InputGroup, Label } from 'reactstrap';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import SelectStoreCard from './SelectStoreCard/SelectStoreCard';
import shopifyLogo from '../../../assets/img/logos/shopify.svg';
import cartxLogo from '../../../assets/img/logos/cartx.svg';
// import woocommerceLogo from '../../../assets/img/logos/woocommerce.svg';
import CartxInput from './CartxInput/CartxInput';
import ShopifyInput from './ShopifyInput/ShopifyInput';
// import WoocommerceInput from './WoocommerceInput/WoocommerceInput';
import { newStore } from '../../../utils/newStore';
import './styles.scss';
import { api } from '../../../services/api';
import { useToast } from '../../../hooks/Toast';

const NewStoreModal = ({ isOpen, handleToggle, loadStore }) => {
  const { showToast } = useToast();

  const [store, setStore] = useState('none');
  const [storeData, setStoreData] = useState(undefined);
  const [isSending, setIsSending] = useState(false);
  const [isOthersIntegrationsSelected, setIsOthersIntegrationsSelected] = useState(false);
  const [selectedValue, setSelectedValue] = useState('woocommerce');
  const [othersIntegrationValue, setOthersIntegrationValue] = useState('');
  const [isSendingSuggestion, setIsSendingSuggestion] = useState(false);

  const stores = [
    { name: 'cartx', image: cartxLogo },
    { name: 'shopify', image: shopifyLogo },
    // { name: 'woocommerce', image: woocommerceLogo },
  ];

  const handleSelectStore = useCallback(store => {
    setStoreData(undefined);
    setStore(store);
    setIsOthersIntegrationsSelected(false);
    setSelectedValue('woocommerce');
  }, []);

  const storesInput = useMemo(
    () => ({
      cartx: <CartxInput setStoreData={setStoreData} />,
      shopify: <ShopifyInput setStoreData={setStoreData} />,
      // woocommerce: <WoocommerceInput setStoreData={setStoreData} />,
    }),
    [],
  );

  const handleNewStore = useCallback(async () => {
    setIsSending(true);
    try {
      const { data } = await newStore[store](storeData);

      if (data.url) {
        window.open(data.url, '_self');
      } else {
        handleToggle();
        await loadStore();
      }
    } finally {
      setIsSending(false);
    }
  }, [store, storeData, handleToggle, loadStore]);

  const handleNoIntegrationsClick = useCallback(() => {
    setIsOthersIntegrationsSelected(true);
    setStore('none');
  }, []);

  const submitNoIntegrationForm = useCallback(async () => {
    setIsSendingSuggestion(true);

    let suggestion = selectedValue;

    if (selectedValue === 'others') {
      suggestion = othersIntegrationValue;
    }

    try {
      await api('user-api').post('/api/v1/users/suggestion-integrations', {
        field: 'store',
        fieldValue: suggestion,
      });

      showToast({
        message: 'Sugestão enviada com sucesso!',
        type: 'success',
      });
    } finally {
      setIsSendingSuggestion(false);

      handleToggle();

      setSelectedValue('woocommerce');
    }
  }, [othersIntegrationValue, selectedValue, showToast, handleToggle]);

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} centered size="lg" className="new-store-modal">
      <ModalHeader toggle={handleToggle} />
      <ModalBody>
        <div className="d-flex text-align-center create-store-modal">
          <h1>Selecione a plataforma que deseja cadastrar</h1>
          <div className="d-flex create-store-card-group">
            <SelectStoreCard stores={stores} handleSelectStore={handleSelectStore} selectedStore={store} />
          </div>
          <div className="no-integrations-container">
            {!isOthersIntegrationsSelected && (
              <button type="button" onClick={handleNoIntegrationsClick} className="no-integrations-button">
                Não tem a integração que você queria? Nos informe
              </button>
            )}

            {isOthersIntegrationsSelected && (
              <>
                <InputGroup className="d-flex input-group">
                  <Label>Informe-nos qual integração você deseja</Label>

                  <Input
                    onChange={event => setSelectedValue(event.target.value)}
                    defaultValue="woocommerce"
                    type="select"
                    name="integration-select"
                  >
                    <option value="woocommerce">WooCommerce</option>
                    <option value="yampi">Yampi (loja)</option>
                    <option value="nuvemshop">Nuvemshop</option>
                    <option value="loja_integrada">Loja Integrada</option>
                    <option value="mercado_livre">Mercado Livre</option>
                    <option value="tray">Tray</option>
                    <option value="others">Outros</option>
                  </Input>
                </InputGroup>

                {selectedValue === 'others' && (
                  <InputGroup className="d-flex input-group">
                    <Label>Qual?</Label>

                    <Input
                      type="text"
                      name="others-value"
                      onChange={event => setOthersIntegrationValue(event.target.value)}
                      required
                    />
                  </InputGroup>
                )}

                <button
                  onClick={submitNoIntegrationForm}
                  type="button"
                  className="send-no-integrations-button"
                  disabled={isSendingSuggestion}
                >
                  Enviar
                </button>
              </>
            )}
          </div>

          <SwitchTransition mode="out-in">
            <CSSTransition
              key={store}
              classNames="store-input"
              addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
            >
              <div className="create-store-input">
                {storesInput[store]}
                <Button color="primary" onClick={handleNewStore} disabled={!storeData || isSending}>
                  Cadastrar
                </Button>
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NewStoreModal;
