import React, { memo, useCallback, useState, useRef, useEffect } from 'react';
import { InputGroup, InputGroupAddon, Input, Tooltip } from 'reactstrap';
import { CaretDown, CaretUp } from 'phosphor-react';

import { numberBrFormatter, masks, currencyToNumber } from '../../../../utils/utils';
import VariantKits from '../VariantKits/VariantKits';
import DefaultImage from '../../../DefaultImage/DefaultImage';

import './styles.scss';

const VariantRow = ({
  variant,
  allVariantsCost,
  setHasChangedData,
  editedVariants,
  setEditedVariants,
  selectedProduct,
  userCurrency,
}) => {
  const { image, title, sellPrice, cost, id } = variant;

  const variantCostInputRef = useRef(null);

  const [isKitsOpen, setIsKitsOpen] = useState(false);
  const [isTooltipTitleOpen, setIsTooltipTitleOpen] = useState(false);

  const handleTooltipTitleOpen = useCallback(() => setIsTooltipTitleOpen(!isTooltipTitleOpen), [isTooltipTitleOpen]);

  const handleIsKitsOpen = useCallback(() => setIsKitsOpen(!isKitsOpen), [isKitsOpen]);

  const getParsedVariants = useCallback(() => {
    const filteredVariants = editedVariants.filter(editedVariant => editedVariant.id !== variant.id);

    return filteredVariants;
  }, [editedVariants, variant.id]);

  const handleEditVariantCost = useCallback(
    event => {
      event.target.value = masks.currency(event.target.value);

      if (event.target.value) {
        setHasChangedData(true);
      }

      const parsedVariants = getParsedVariants();

      if (event.target.value === '') {
        setEditedVariants(parsedVariants);

        return;
      }

      parsedVariants.push({ ...variant, cost: currencyToNumber(event.target.value) });

      setEditedVariants(parsedVariants);
    },
    [setHasChangedData, getParsedVariants, setEditedVariants, variant],
  );

  useEffect(() => {
    if (allVariantsCost && variantCostInputRef?.current) {
      variantCostInputRef.current.value = allVariantsCost;
    }
  }, [allVariantsCost]);

  return (
    <>
      <tr className="product-cost-variant-row">
        <td className="image-column">
          {image ? <img src={image} alt="Profitfy.me Custo de Produto" /> : <DefaultImage size={60} />}
        </td>
        <td className="variant-name-column">
          <span id={`product-${id}`}>{title}</span>
        </td>
        <td className="variant-price-column">
          <span>R$ {numberBrFormatter(sellPrice, 2)}</span>
        </td>
        <td className="variant-cost-column">
          <InputGroup className="variant-cost-input-group">
            <InputGroupAddon addonType="prepend">{userCurrency === 'USD' ? 'U$' : 'R$'}</InputGroupAddon>
            <Input
              placeholder={cost || '00,00'}
              name="variant-cost"
              id="variant-cost"
              onChange={handleEditVariantCost}
              innerRef={variantCostInputRef}
            />
          </InputGroup>
        </td>
        <td className="chevron-column">
          <button type="button" onClick={handleIsKitsOpen}>
            {isKitsOpen ? <CaretUp size={20} /> : <CaretDown size={20} />}
          </button>
        </td>
      </tr>
      {isKitsOpen && (
        <tr className="edit-kits-wrapper">
          <td colSpan={5}>
            <div>
              <VariantKits variant={variant} selectedProduct={selectedProduct} userCurrency={userCurrency} />
            </div>
          </td>
        </tr>
      )}

      <Tooltip isOpen={isTooltipTitleOpen} toggle={handleTooltipTitleOpen} trigger="hover" target={`product-${id}`}>
        {title}
      </Tooltip>
    </>
  );
};

export default memo(VariantRow);
