import React, { memo, useState, useCallback, useEffect } from 'react';
import { DotsThree } from 'phosphor-react';
import { Popover, PopoverHeader, PopoverBody, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';

import { numberBrFormatter } from '../../../utils/utils';
import { api, shopsApi } from '../../../services/api';

import './styles.scss';

const CardOverview = ({ title, icon: Icon, value, gradientColor, storeConfigurationData, mutateStoreData }) => {
  const { platform, store } = useParams();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [storeConfiguration, setStoreConfiguration] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handlePopover = useCallback(() => setIsPopoverOpen(!isPopoverOpen), [isPopoverOpen]);

  const handleCustomSpends = useCallback(async () => {
    const status = storeConfiguration?.addCustomSpendInProfit || false;
    const requestType = storeConfiguration?.id ? 'put' : 'post';

    setIsLoading(true);

    try {
      const { data } = await api(shopsApi[platform])[requestType](
        `/api/v1/users/${platform}/stores/${store}/store-configurations/${storeConfiguration?.id || ''}`,
        {
          updateAliexpressCostToVariant: false,
          ...storeConfiguration,
          addCustomSpendInProfit: !status,
        },
      );

      await mutateStoreData();

      setStoreConfiguration(data.storeConfiguration);
    } finally {
      setIsLoading(false);
    }
  }, [platform, store, storeConfiguration, mutateStoreData]);

  useEffect(() => {
    if (storeConfigurationData?.storeConfiguration) {
      setStoreConfiguration(storeConfigurationData.storeConfiguration);
    }
  }, [storeConfigurationData.storeConfiguration]);

  return (
    <div className="shop-analytics-overview-card">
      <div className="header">
        <span>{title}</span>
        <div className="icons">
          {title === 'LUCRO LÍQUIDO' && (
            <div className="custom-value-icon">
              <DotsThree size={28} weight="bold" id="custom-spends-popover" />
              <Popover
                trigger="legacy"
                isOpen={isPopoverOpen}
                toggle={handlePopover}
                placement="bottom-end"
                target="custom-spends-popover"
                className="profit-custom-spends-popover"
              >
                <PopoverHeader className="header">CONFIGURAÇÕES</PopoverHeader>
                <PopoverBody className="body">
                  <div>
                    <span>Incluir valores adicionais</span>
                    {storeConfiguration?.addCustomSpendInProfit ? (
                      <Button className="deactivate" disabled={isLoading} outline onClick={handleCustomSpends}>
                        Desativar
                      </Button>
                    ) : (
                      <Button
                        className="activate"
                        disabled={isLoading}
                        outline
                        color="primary"
                        onClick={handleCustomSpends}
                      >
                        Ativar
                      </Button>
                    )}
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          )}
          <div className={`header-icon ${gradientColor}`}>
            <Icon size={16} weight="bold" />
          </div>
        </div>
      </div>

      <div className="body">
        <span>
          <small>R$</small>
          {numberBrFormatter(value)}
        </span>
      </div>

      <div className="gradient-border-detail">
        <div className={gradientColor} />
      </div>
    </div>
  );
};

export default memo(CardOverview);
