const customSpendUtils = {
  getColorByPeriod: (period, endDate) =>
    ({
      DAILY: 'primary',
      WEEKLY: 'info',
      MONTHLY: 'warning',
      ANNUALLY: 'darker',
    }[period || endDate] || 'darker'),

  parsePeriodName: (period, endDate) =>
    ({
      DAILY: 'Diária',
      WEEKLY: 'Semanal',
      MONTHLY: 'Mensal',
      ANNUALLY: 'Anual',
    }[endDate || period] || 'Uma Vez'),

  getColorByCategory: category =>
    ({
      APP: 'dark-green',
      MARKETING: 'indigo',
      EMPLOYEE: 'twitter',
      COSTS: 'red',
      NONE: 'gray',
    }[category]),

  parseCategoryName: category =>
    ({
      APP: 'App',
      MARKETING: 'Marketing',
      EMPLOYEE: 'Funcionários',
      COSTS: 'Custos',
      NONE: 'Nenhum',
    }[category]),
};

export { customSpendUtils };
