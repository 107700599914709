import React, { memo, useCallback, useState } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { MagnifyingGlass } from 'phosphor-react';

import EditIofModal from '../EditIofModal/EditIofModal';
import ConvertCurrencyModal from '../ConvertCurrencyModal/ConverCurrencyModal';

import './styles.scss';

const Header = ({
  setProductNameSearch,
  loadProducts,
  setSelectedCurrency,
  selectedPercentage,
  setSelectedPercentage,
  handleConfirmButton,
  isConvertCurrencyModalOpen,
  setIsConvertCurrencyModalOpen,
}) => {
  const [isEditIofModalOpen, setIsEditIofModalOpen] = useState(false);

  const handleEditIofModalOpen = useCallback(() => setIsEditIofModalOpen(!isEditIofModalOpen), [isEditIofModalOpen]);

  const handleConvertCurrencyModalOpen = useCallback(
    () => setIsConvertCurrencyModalOpen(!isConvertCurrencyModalOpen),
    [isConvertCurrencyModalOpen, setIsConvertCurrencyModalOpen],
  );

  const handleOptionsInput = useCallback(
    event => {
      const { value } = event.target;

      if (value === 'iof') {
        handleEditIofModalOpen();
      }

      if (value === 'convert') {
        handleConvertCurrencyModalOpen();
      }

      event.target.value = 'more-option';
    },
    [handleEditIofModalOpen, handleConvertCurrencyModalOpen],
  );

  return (
    <div className="edit-product-cost-table-header">
      <EditIofModal isOpen={isEditIofModalOpen} toggle={handleEditIofModalOpen} loadProducts={loadProducts} />

      <ConvertCurrencyModal
        isOpen={isConvertCurrencyModalOpen}
        toggle={handleConvertCurrencyModalOpen}
        setSelectedCurrency={setSelectedCurrency}
        selectedPercentage={selectedPercentage}
        setSelectedPercentage={setSelectedPercentage}
        handleConfirmButton={handleConfirmButton}
      />

      <span>Editar custo dos produtos</span>

      <div className="options">
        <div className="search-input">
          <InputGroup className="search-input-group">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <MagnifyingGlass size={24} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              className="search-input"
              name="search"
              type="text"
              placeholder="Pesquisar"
              onChange={event => setProductNameSearch(event.target.value)}
            />
          </InputGroup>
        </div>

        <div>
          <Input
            className="more-options"
            type="select"
            name="more-options"
            id="more-options"
            onChange={handleOptionsInput}
            defaultValue="more-option"
          >
            <option value="more-option" disabled defaultValue>
              Mais opções
            </option>
            <option value="iof">Alterar IOF para todos os produtos</option>
            <option value="convert">Converter moeda</option>
          </Input>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
