import React from 'react';
import Lottie from 'react-lottie';
import animationData from './Goat-Time.json';

const Spinner = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
  };

  return <Lottie options={defaultOptions} height={400} width={400} speed={3} />;
};

export default Spinner;
