exports.features = [
  'Lojas ilimitadas',
  'Cálculo do Lucro Líquido',
  'Sincronização com Gateways de pagamento e ferramentas de Marketing',
  'Análise top produtos mais rentáveis',
  'Integração com AliExpress',
  'Integração com Dsers',
  'Audience Insights',
  'Precificação 2.0',
];
exports.features2 = [
  'Retroativo de 1 Mês ao iniciar o plano',
  'Calculo do Custo de Marketing',
  'Gráficos de performance e análise',
  'Controle automático de conversão e compensação de boletos',
  'Aplicativo Profitfy Mobile',
  'Entradas e saídas personalizadas, como custos fixos e recorrentes',
  'Análise de Produto Individual',
  'Lives e Vídeos de Mentorias Exclusivas',
];

// - Controle automático de recusa, conversão e compensação de boletos em tempo real
// - Tabela de custos de seus produtos individuais, para saber quais estão dando mais lucro ou prejuizo
// - Tabela de status de pedidos
// - Tabela de top produtos  mais lucrátivos
// - Entradas e saídas personalizadas, como custos fixos e recorrentes
// - Suporte prioritário indepentemente do seu plano
// - Aplicativo Profitfy para acompanhar o financeiro de seu negócio de qualquer lugar
// - Sincronização com Gateways de pagamento e ferramentas de Marketing
