import React from 'react';
import moment from 'moment-timezone';
import { Badge, Card, CardBody, CardHeader, Progress } from 'reactstrap';
import { useAuth } from '../../../../hooks/User';
import { getStatusColor, parseStatusText } from '../../../../utils/subscribeUtils';
import { numberBrFormatter } from '../../../../utils/utils';

const TrialSubscribe = () => {
  const { user } = useAuth();

  return (
    <Card className="shadow user-plan">
      <CardHeader className="d-flex justify-content-between">
        <h3 className="mb-0">Meu plano</h3>
        <div>
          <Badge color={getStatusColor('trialing')}>
            <span className="text-lg text-capitalize">{parseStatusText('trialing')}</span>
          </Badge>
        </div>
      </CardHeader>
      <CardBody className="p-0">
        <div className="bg-gradient-light-dark-100 p-3 px-md-5 pt-md-3">
          <div className="d-flex justify-content-between align-items-center">
            <span className="text-uppercase text-muted text-sm ls-1">Plano Atual</span>
          </div>
          <p className="mb-0 font-weight-bold text-uppercase text-lg">Trial</p>
          <span className="mb-0 font-weight-bold text-uppercase text-lg">R$ Infinito</span>
          <span className="text-lg">/mês</span>
          <>
            <div className="mt-5 d-flex align-items-center justify-content-between">
              <div className="plan-usage">
                <span className="font-weight-bold text-muted text-sm">Total de consumo do plano</span>
                <div className="mt-2 d-flex align-items-center justify-content-between">
                  <p className="current-usage">R$ {numberBrFormatter(user?.monthlyRevenue, 2)}</p>
                  <p className="total-usage">R$ Infinito</p>
                </div>
                <Progress className="mt-1" value={(user?.monthlyRevenue * 100) / user?.subscription.limit} />
              </div>
            </div>

            <div className="mt-5 d-flex align-items-center justify-content-between">
              <div>
                <span className="text-muted text-sm">O seu plano expira dia: </span>
                <br />
                <span className="font-weight-bold text-blue-marine">
                  {moment(user?.subscription?.currentPeriodEnd).format('DD/MM/YYYY')}
                </span>
              </div>
            </div>
          </>
        </div>
      </CardBody>
    </Card>
  );
};

export default React.memo(TrialSubscribe);
