import React, { useState, useContext, memo, useCallback, useMemo, useEffect } from 'react';
import moment from 'moment-timezone';
import { Popover, PopoverBody } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import { VERTICAL_ORIENTATION, HORIZONTAL_ORIENTATION } from 'react-dates/constants';
import { CalendarBlank } from 'phosphor-react';

import FirstTimeContext from '../../hooks/FirstTimeContext';
import PreDefinedDates from './PreDefinedDates/PreDefinedDates';
import usePersistedState from '../../hooks/PersistedState';

import './styles.scss';

const smallDevice = window.matchMedia('(max-width: 727px)').matches;
const orientation = smallDevice ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION;

const NewDatePicker = ({ onDate, startDate, endDate, selectedDate, setSelectedDate }) => {
  const [isCalendarTipOpen, setIsCalendarTipOpen] = usePersistedState('isCalendarTipOpen', true);

  const [focusedInput, setFocusedInput] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isTipOpen, setIsTipOpen] = useState(false);

  const value = useContext(FirstTimeContext);

  const handlePopover = useCallback(() => setIsPopoverOpen(!isPopoverOpen), [isPopoverOpen]);

  const handleTip = useCallback(() => setIsTipOpen(true), []);

  const handlePredefinedDates = useCallback(
    (preStartDate, preEndDate) => {
      setSelectedStartDate(preStartDate);
      setSelectedEndDate(preEndDate);

      onDate({ startDate: preStartDate, endDate: preEndDate });
    },
    [onDate],
  );

  const handleSelectDate = useCallback(
    date => {
      setSelectedDate(date);

      if (date === 'today') {
        handlePredefinedDates(moment(), moment());
      }

      if (date === 'yesterday') {
        handlePredefinedDates(moment().subtract(1, 'days'), moment().subtract(1, 'day'));
      }

      if (date === 'seven') {
        handlePredefinedDates(moment().subtract(6, 'days'), moment());
      }

      if (date === 'thirty') {
        handlePredefinedDates(moment().subtract(29, 'days'), moment());
      }
    },
    [handlePredefinedDates, setSelectedDate],
  );

  const handlePreDefinedDate = useCallback(
    (preStartDate, preEndDate) => {
      setSelectedStartDate(preStartDate);
      setSelectedEndDate(preEndDate);

      onDate({ startDate: preStartDate, endDate: preEndDate });

      handleSelectDate('custom');
    },
    [onDate, handleSelectDate],
  );

  const handleUnderstoodTip = useCallback(() => setIsCalendarTipOpen(false), [setIsCalendarTipOpen]);

  const preDefinedDates = useMemo(() => {
    return {
      calendarInfoPosition: 'after',
      renderCalendarInfo: () => <PreDefinedDates handlePreDefinedDate={handlePreDefinedDate} />,
    };
  }, [handlePreDefinedDate]);

  useEffect(() => {
    setIsTipOpen(true);
  }, []);

  return (
    <div className="new-datepicker">
      <button
        type="button"
        className={`predefined-dates ${selectedDate === 'today' ? 'selected' : undefined}`}
        onClick={() => handleSelectDate('today')}
      >
        Hoje
      </button>
      <button
        type="button"
        className={`predefined-dates ${selectedDate === 'yesterday' ? 'selected' : undefined}`}
        onClick={() => handleSelectDate('yesterday')}
      >
        Ontem
      </button>
      <button
        type="button"
        className={`predefined-dates ${selectedDate === 'seven' ? 'selected' : undefined}`}
        onClick={() => handleSelectDate('seven')}
      >
        7 Dias
      </button>
      <button
        type="button"
        className={`predefined-dates ${selectedDate === 'thirty' ? 'selected' : undefined}`}
        onClick={() => handleSelectDate('thirty')}
      >
        30 Dias
      </button>
      <div id="custom-datepicker" className={selectedDate === 'custom' ? 'custom-selected' : undefined}>
        <DateRangePicker
          readOnly
          startDatePlaceholderText="Data inicio"
          startDateId="startDate"
          startDate={selectedStartDate}
          endDatePlaceholderText="Data final"
          endDate={selectedEndDate}
          endDateId="endDate"
          onDatesChange={({ startDate, endDate }) => {
            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate);

            if (endDate && focusedInput === 'endDate') {
              onDate({ startDate, endDate });
              handleSelectDate('custom');
            }
          }}
          initialVisibleMonth={() => moment().subtract(1, 'month').startOf('month')}
          focusedInput={focusedInput}
          onFocusChange={focusedInput => setFocusedInput(focusedInput)}
          isOutsideRange={date => {
            return date.isBefore(moment(value.storeCreatedAt), 'day') || date.isAfter(moment(), 'day');
          }}
          displayFormat="DD/MM/YYYY"
          orientation={orientation}
          withPortal
          noBorder
          minimumNights={0}
          customInputIcon={<CalendarBlank size={20} />}
          {...preDefinedDates}
        />
      </div>
      <Popover
        placement="top"
        isOpen={isPopoverOpen}
        target="custom-datepicker"
        toggle={handlePopover}
        trigger="hover"
        className="w-100"
      >
        <PopoverBody>
          {selectedDate === 'custom'
            ? `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`
            : 'Período Personalizado'}
        </PopoverBody>
      </Popover>

      {isCalendarTipOpen && (
        <Popover
          placement="top"
          isOpen={isTipOpen}
          target="custom-datepicker"
          toggle={handleTip}
          trigger="hover"
          className="w-100 custom-datepicker-tip"
        >
          <PopoverBody className="body">
            <span>Período Personalizado</span>
            <button type="button" onClick={handleUnderstoodTip}>
              Entendi
            </button>
          </PopoverBody>
        </Popover>
      )}
    </div>
  );
};

export default memo(NewDatePicker);
