import React, { useCallback, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

import Tax from '../../../components/OnboardingTaxes/OnboardingTaxes';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';

import './styles.scss';

const Taxes = () => {
  const { user } = useAuth();
  const { store, platform } = useParams();
  const history = useHistory();

  const handleNextPage = useCallback(() => {
    history.push('currency');
  }, [history]);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_onboarding_taxes',
        type: 'screen',
      });
    }
  }, [user]);

  return (
    <div className="main-onboarding-taxes-container">
      <div className="content-container">
        <div className="content-header">
          <img
            className="profitfy-logo"
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/logo_v2.svg"
            alt="Profitfy.me"
          />

          <div className="step-container">
            <div className="bar-container">
              <div className="bar-progress" />
            </div>

            <span>4/6</span>
          </div>
        </div>

        <Tax className="tax-form" />

        <div className="next-page-container">
          <Link to={`/${platform}/${store}/onboarding/checkout`} className="prev-page">
            <i className="fas fa-arrow-left" />
            <span>Voltar</span>
          </Link>
          <Button color="primary" type="button" onClick={handleNextPage} outline>
            <span>Próximo Passo</span>
            <i className="fas fa-arrow-right" />
          </Button>
        </div>
      </div>

      <div className="creative-container">
        <div className="video-container">
          <img
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/New+User+Flux/new-user-video.svg"
            alt="Profitfy.me new user"
          />

          {/* <div className="video-container">
            <iframe
              title="Profitfy Trial Benefits"
              width="400"
              height="225"
              src="https://www.youtube.com/embed/FO0iwx3PUHo"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Taxes;
