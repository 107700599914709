import React, { useState, useEffect, useCallback, memo } from 'react';
import { Container, Row, Col, Card, CardHeader, Button, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { useParams, useLocation, Link } from 'react-router-dom';
import { CaretLeft } from 'phosphor-react';

import { useToast } from '../../../hooks/Toast';
import Spinner from '../../Spinner/Spinner';
import { api, shopsApi } from '../../../services/api';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';
import { useAuth } from '../../../hooks/User';

const MercadoPagoForm = ({ fluid = true, onSave = () => {}, className }) => {
  const { store, platform } = useParams();
  const location = useLocation();
  const { showToast } = useToast();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({ publicKey: '', accessToken: '', id: null });

  const handlePublicKey = e => {
    const { value } = e.target;
    setCredentials({ ...credentials, ...{ publicKey: value } });
  };

  const handleAccessToken = e => {
    const { value } = e.target;
    setCredentials({ ...credentials, ...{ accessToken: value } });
  };

  const isOnboardingPage = useCallback(() => {
    if (location.pathname.includes('onboarding')) {
      return true;
    }

    return false;
  }, [location.pathname]);

  const onSubmit = async () => {
    const requestType = credentials.id ? 'put' : 'post';

    try {
      setLoading(true);
      const { data } = await api(shopsApi[platform])[requestType](
        `api/v1/users/${platform}/stores/${store}/mercado-pago-credentials/${credentials?.id || ''}`,
        credentials,
      );

      setCredentials({
        publicKey: data.mercadoPagoCredential.publicKey,
        accessToken: data.mercadoPagoCredential.token,
        id: data.mercadoPagoCredential.id,
      });

      showToast({ message: 'Credenciais salvas com sucesso!', type: 'success' });
      onSave('mercadoPago');

      if (isOnboardingPage()) {
        updateCustomerXTracking({
          email: user?.email,
          userId: user?.id,
          identifier: 'onboarding_has_added_gateway_credential',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const loadCredentials = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api(shopsApi[platform]).get(
        `api/v1/users/${platform}/stores/${store}/mercado-pago-credentials`,
      );

      if (data.mercadoPagoCredential) {
        setCredentials({
          publicKey: data.mercadoPagoCredential.publicKey,
          accessToken: data.mercadoPagoCredential.token,
          id: data.mercadoPagoCredential.id,
        });
      }
    } finally {
      setLoading(false);
    }

    setLoading(false);
  }, [platform, store]);

  const isIntegrationPage = useCallback(() => {
    if (location.pathname.includes('integrations')) {
      return true;
    }

    return false;
  }, [location.pathname]);

  useEffect(() => {
    loadCredentials();
  }, [loadCredentials]);

  return (
    <Container fluid={fluid} className={className || 'pb-8 pt-5 pt-md-8'}>
      <Row>
        <Col className="order-xl-1" xl="12">
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <>
              {isIntegrationPage() && (
                <div className="mb-3 ">
                  <Link
                    to={`/${platform}/${store}/dashboard/config/integrations`}
                    className="d-flex align-items-center"
                    style={{ color: '#ADB8C4' }}
                  >
                    <CaretLeft size={20} className="mr-1" />
                    Voltar às integrações
                  </Link>
                </div>
              )}
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Configurações</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button color="primary" size="md" onClick={onSubmit}>
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <p className="text-muted mb-5">
                      <a href="https://www.mercadopago.com/mlb/account/credentials" target="blank">
                        <span className="font-weight-bold">Clique aqui </span>
                      </a>
                      para obter sua Public Key e Access Token
                      <span className="text-uppercase font-weight-bold text-dark"> de produção</span> para se conectar
                      ao Mercado Pago.
                    </p>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Label className="form-control-label" htmlFor="input-public-key">
                              Public Key
                            </Label>
                            <Input
                              className="form-control-alternative"
                              id="input-public-key"
                              defaultValue={credentials.publicKey}
                              type="text"
                              onChange={handlePublicKey}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Label className="form-control-label" htmlFor="input-access-token">
                              Access Token
                            </Label>
                            <Input
                              className="form-control-alternative"
                              id="input-access-token"
                              type="text"
                              onChange={handleAccessToken}
                              defaultValue={credentials.accessToken}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default memo(MercadoPagoForm);
