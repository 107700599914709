import React, { memo } from 'react';

const SubHeaders = ({ setCustomSpendSort, customSpendSort, columns }) => {
  return columns.map(column => {
    const sortProps = {
      key: column.name,
      className: `px-3 ${column.sort && 'has-sort'}`,
      scope: 'col',
      onClick: () =>
        setCustomSpendSort({
          [column.name]: customSpendSort[column.name] === 'asc' ? 'desc' : 'asc',
        }),
    };

    return (
      <th {...sortProps}>
        {column.description}{' '}
        {column.sort && (
          <i className={customSpendSort[column.name] ? `fa fa-sort-${customSpendSort[column.name]}` : `fa fa-sort`} />
        )}
      </th>
    );
  });
};

export default memo(SubHeaders);
