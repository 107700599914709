import React, { useCallback, memo } from 'react';

import usePersistedState from '../../hooks/PersistedState';

import './styles.scss';

const CookiesUsage = () => {
  const [cookieUsage, setCookiesUsage] = usePersistedState('gdpr-cookie');

  const handleAgreement = useCallback(() => {
    setCookiesUsage(true);
  }, [setCookiesUsage]);

  return (
    <>
      {!cookieUsage && (
        <div className="cookies-usage-container">
          <div className="content-text">
            <p>
              <strong>Nós usamos cookies</strong> e outras tecnologias semelhantes para melhorar a sua experiência em
              nossos serviços, você concorda com tal monitoramento. Informamos que atualizamos nossa política de
              privacidade
            </p>
          </div>
          <button className="agreement-button" type="submit" onClick={handleAgreement}>
            Entendi
          </button>
        </div>
      )}
    </>
  );
};

export default memo(CookiesUsage);
