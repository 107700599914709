import React, { memo } from 'react';
import { CameraSlash } from 'phosphor-react';

import './styles.scss';

const DefaultImage = ({ size }) => {
  return (
    <div className="no-image-default" style={{ width: `${size}px`, height: `${size}px` }}>
      <CameraSlash size={36} />
    </div>
  );
};

export default memo(DefaultImage);
