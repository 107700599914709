import React, { memo } from 'react';
import { Table, Badge } from 'reactstrap';
import SubHeaders from './SubHeaders/SubHeaders';
import { numberBrFormatter } from '../../../../../../utils/utils';

const columns = [
  { name: 'markUp', description: 'Markup' },
  { name: 'finalPrice', description: 'Preço Final' },
  { name: 'fixedCost', description: 'Custo Fixo' },
  { name: 'marketingCost', description: 'Custo de Marketing' },
  { name: 'profit', description: 'Lucro' },
  { name: 'maxCPA', description: 'Máx. CPA' },
  { name: 'profitMargin', description: 'Margem de Lucro' },
];

const TableBody = ({ markup, finalPrice, fixedCost, marketingCost, profit, maxCpa, profitMargin }) => {
  return (
    <Table className="align-items-right table-flush" responsive>
      <thead className="thead-light">
        <tr className="text-right">
          <SubHeaders columns={columns} />
        </tr>
      </thead>
      <tbody>
        {markup.map((el, i) => (
          <tr key={el} className="align-items-center">
            <td className="text-right">
              <p className={`font-weight-bold mb-0 ${i === 5 && 'text-gray'}`}>{numberBrFormatter(el, 2)}x</p>
            </td>
            <td className="text-right">
              <p className="mb-0">R$ {numberBrFormatter(finalPrice[i])}</p>
            </td>
            <td className="text-right">
              <p className="mb-0">R$ {numberBrFormatter(fixedCost[i])}</p>
            </td>
            <td className="text-right">
              <p className="mb-0">R$ {numberBrFormatter(marketingCost[i])}</p>
            </td>
            <td className="text-right">
              <p className="font-weight-bold mb-0 h2">
                <Badge color="primary">R$ {numberBrFormatter(profit[i])}</Badge>
              </p>
            </td>
            <td className="text-right">
              <p className="mb-0">R$ {numberBrFormatter(maxCpa[i])}</p>
            </td>
            <td className="text-right">
              <p className="font-weight-bold mb-0">
                {numberBrFormatter(profitMargin[i], 2) !== 'NaN' ? numberBrFormatter(profitMargin[i], 2) : '0'}%
              </p>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default memo(TableBody);
