import React, { createContext, useContext, useCallback, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { api } from '../services/api';
import usePersistedState from './PersistedState';

const UserContext = createContext();
export const TOKEN_KEY = process.env.REACT_APP_STAGE === 'qa' ? 'jwt' : 'twj';

export const User = ({ children }) => {
  const [userData, setUserData] = usePersistedState('user', null);
  const history = useHistory();
  const location = useLocation();
  const publicURLs = location.pathname.includes('auth') || location.pathname.includes('prices');

  const loadUser = useCallback(async () => {
    const { data } = await api('user-api').get('/api/v1/users/me');
    setUserData(data.dashboardUser);
  }, [setUserData]);

  const logout = useCallback(() => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('user');
    history.push('/auth/login');
  }, [history]);

  const login = token => localStorage.setItem(TOKEN_KEY, token);

  const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

  const getToken = () => localStorage.getItem(TOKEN_KEY);

  const user = useMemo(() => userData, [userData]);

  useEffect(() => {
    if (!userData && !publicURLs) {
      logout();
    }
  }, [logout, userData, publicURLs]);

  return (
    <UserContext.Provider value={{ loadUser, logout, login, getToken, isAuthenticated, user }}>
      {children}
    </UserContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useAuth must be used within a UserProvider');
  }

  return context;
};
