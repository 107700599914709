import React, { useState, useCallback } from 'react';
import useForm from 'react-hook-form';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import {
  Button,
  FormGroup,
  Form,
  Input as StrapInput,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
} from 'reactstrap';

import { api } from '../../../services/api';
import { login } from '../../../services/auth';
import Spinner from '../../../components/Spinner/Spinner';
import { useAuth } from '../../../hooks/User';
import { useToast } from '../../../hooks/Toast';

import './styles.scss';

const qaUser =
  process.env.REACT_APP_STAGE === 'qa'
    ? { email: 'fake-shop@profitfy.me', password: '123321' }
    : { email: '', password: '' };

const Login = ({ history }) => {
  const validationSchema = yup.object().shape({
    email: yup.string().email('Você precisa inserir um email válido').required('Este campo é obrigatório'),
    password: yup.string().required('Este campo é obrigatório'),
  });

  const recaptchaRef = React.useRef();
  const { loadUser } = useAuth();
  const { showToast } = useToast();
  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    validationSchema,
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async data => {
      const recaptchaToken = await recaptchaRef.current.executeAsync();

      if (recaptchaToken) {
        setLoading(true);

        try {
          const response = await api('auth-api').post(`/auth/login`, data);
          const { token } = response.data;
          login(token);

          await loadUser();
          history.push('/stores');
        } catch (error) {
          const message = error?.response?.data?.message || 'Por Favor entrar em contato com suporte.';
          showToast({ message, type: 'error' });
        } finally {
          setLoading(false);
        }
      } else {
        const message = 'A validação do Recaptcha falhou.';
        showToast({ message, type: 'error' });
      }
    },
    [showToast, history, loadUser],
  );

  return (
    <div className="main-login-container">
      <div className="content-container">
        <img
          className="profitfy-logo"
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/logo.svg"
          alt="Profitfy.me"
        />
        <div className="form-title">
          <p>Insira suas credenciais</p>
        </div>
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <Form className="form-container" onSubmit={handleSubmit(onSubmit)} role="form">
            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6Ld-MP0ZAAAAANXTrzPRDn-EKA9A8g8MeFygCSw_" />
            <FormGroup className={`${errors.email && 'has-danger'} mb-3`}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className={`${errors.email && 'text-red'} ni ni-email-83`} />
                  </InputGroupText>
                </InputGroupAddon>
                <StrapInput
                  className={errors.email && 'is-invalid'}
                  placeholder="Email"
                  name="email"
                  innerRef={register}
                  defaultValue={qaUser.email}
                />
              </InputGroup>
              {errors.email && <small className="text-danger">{errors.email.message}</small>}
            </FormGroup>

            <FormGroup className={errors.password ? 'has-danger' : ''}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className={`${errors.password && 'text-red'} ni ni-lock-circle-open`} />
                  </InputGroupText>
                </InputGroupAddon>
                <StrapInput
                  className={errors.password && 'is-invalid'}
                  placeholder="Password"
                  type="password"
                  name="password"
                  innerRef={register}
                  defaultValue={qaUser.password}
                />
              </InputGroup>
              {errors.password && <small className="text-danger">{errors.password.message}</small>}
            </FormGroup>
            <div className="text-center">
              <Button className="mt-4 btn-icon btn-2 sign-in-button" color="primary" type="submit">
                <span className="btn-inner--icon">
                  <i className="ni ni-send" />
                </span>
                <span className="btn-inner--text button-text">Entrar</span>
              </Button>
            </div>
            <Row className="mt-3 link-container">
              <Col xs="6">
                <Link className="" to="/auth/forgot-password">
                  <span>Esqueceu sua senha?</span>
                </Link>
              </Col>
              <Col className="text-right" xs="6">
                <Link className="" to="/auth/register">
                  <span>Registrar</span>
                </Link>
              </Col>
            </Row>
          </Form>
        )}
      </div>
      <div className="creative-container">
        <p className="text-description">
          Junte-se a nossa comunidade e participe de lives, enquetes, convites exclusivos e fique por dentro de tudo que
          está por vir!
        </p>
        <img
          className="image-description"
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/signin-image.svg"
          alt="Profitfy.me"
        />
      </div>
    </div>
  );
};

export default Login;
