import React, { memo, useState, useCallback } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { MagnifyingGlass } from 'phosphor-react';

import EditIofModal from '../../../ProductCost/EditProductCostTable/EditIofModal/EditIofModal';

import './styles.scss';

const Header = ({ setProductNameSearch, loadProducts }) => {
  const [isEditIofModalOpen, setIsEditIofModalOpen] = useState(false);

  const handleEditIofModalOpen = useCallback(() => setIsEditIofModalOpen(!isEditIofModalOpen), [isEditIofModalOpen]);

  const handleOptionsInput = useCallback(
    event => {
      handleEditIofModalOpen();

      event.target.value = 'more-option';
    },
    [handleEditIofModalOpen],
  );

  return (
    <div className="edit-product-cost-table-header">
      <EditIofModal isOpen={isEditIofModalOpen} toggle={handleEditIofModalOpen} loadProducts={loadProducts} />

      <span>Editar custo dos produtos</span>

      <div className="options">
        <div className="search-input">
          <InputGroup className="search-input-group">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <MagnifyingGlass size={24} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              className="search-input"
              name="search"
              type="text"
              placeholder="Pesquisar"
              onChange={event => setProductNameSearch(event.target.value)}
            />
          </InputGroup>
        </div>

        <div>
          <Input
            className="more-options"
            type="select"
            name="more-options"
            id="more-options"
            onChange={handleOptionsInput}
            defaultValue="more-option"
          >
            <option value="more-option" disabled defaultValue>
              Mais opções
            </option>
            <option value="iof">Alterar IOF para todos os produtos</option>
          </Input>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
