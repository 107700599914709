/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { CardHeader, Row, Button, Col, UncontrolledPopover, PopoverBody } from 'reactstrap';

const BilletTableHeader = ({ filter, setFilter }) => {
  const [showFilter, setShowFilters] = useState(false);

  return (
    <>
      <CardHeader className="border-0">
        <Row className="d-flex justify-content-between p-2">
          <div>
            <h3 className="mb-0">Boletos</h3>
            <p className="text-muted text-sm mb-0">Veja quando seus boletos foram compensados.</p>
          </div>
          <Button className="text-blue-marine" id="filter" type="button" onClick={() => setShowFilters(!showFilter)}>
            Filtro
            <i className="ml-2 fa fa-filter" />
          </Button>
          <UncontrolledPopover placement="left" className="w-100" flip target="filter">
            <PopoverBody>
              <Row>
                <Col>
                  <div className="d-flex mr-2 mt-3">
                    <span className="text-blue-marine mr-3 font-weight-bold">Filtrar por: </span>
                    <div className="custom-control custom-radio">
                      <input
                        className="custom-control-input"
                        id="approvedAt"
                        name="date-picker"
                        type="radio"
                        checked={filter === 'approvedAt'}
                        onClick={() => setFilter('approvedAt')}
                      />
                      <label className="custom-control-label text-sm text-gray" htmlFor="approvedAt">
                        Compensado em
                      </label>
                    </div>
                    <div className="custom-control custom-radio mb-3 ml-4">
                      <input
                        className="custom-control-input "
                        id="createdAt"
                        name="date-picker"
                        type="radio"
                        checked={filter === 'createdAt'}
                        onClick={() => setFilter('createdAt')}
                      />
                      <label className="custom-control-label text-sm text-gray" htmlFor="createdAt">
                        Criado em
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
            </PopoverBody>
          </UncontrolledPopover>
        </Row>
      </CardHeader>
    </>
  );
};

export default BilletTableHeader;
