import React, { memo, useCallback, useState } from 'react';
import moment from 'moment-timezone';
import { Tooltip } from 'reactstrap';
import { useParams } from 'react-router-dom';

import { useFacebookMarketing } from '../../../hooks/FacebookMarketing';

import './styles.scss';

moment.locale('pt-br');

const ProfileCard = ({ profile }) => {
  const { handleFacebookLogout } = useFacebookMarketing();
  const { store, platform } = useParams();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleToggle = useCallback(() => setIsTooltipOpen(!isTooltipOpen), [isTooltipOpen]);

  return (
    <>
      <div className="profile-account-container" key={profile.id}>
        <div className="initial-letter-profile">
          <span>{profile.name.charAt(0).toUpperCase()}</span>
        </div>

        <span className="name" id={`profile-${profile.id}`}>
          {profile.name}
        </span>

        <span className="connected-at">{`Conectado ${moment(profile.updatedAt).fromNow()}`}</span>

        <button
          type="button"
          className="disconnect-button"
          onClick={() => handleFacebookLogout({ profileId: profile.id, isLoading: true, store, platform })}
        >
          Desconectar
        </button>
      </div>

      <Tooltip placemente="top" isOpen={isTooltipOpen} toggle={handleToggle} target={`profile-${profile.id}`}>
        {profile.name}
      </Tooltip>
    </>
  );
};

export default memo(ProfileCard);
