import React, { useState, useCallback } from 'react';
import { Button } from 'reactstrap';
import InputRange from 'react-input-range';
import Features from '../Features/Features';
import './styles.scss';

const getPlanInfo = plan =>
  ({
    0: { name: 'Free', description: 'ON DEMAND', value: 'Grátis' },
    1: { name: 'Lite', description: 'Até R$25mil / mês', value: '47,90' },
    2: { name: 'Starter', description: 'Até R$50mil / mês', value: '97,90' },
    3: { name: 'Essentials', description: 'Até R$100mil / mês', value: '147,90' },
    4: { name: 'Premium', description: 'Até R$300mil / mês', value: '297,90' },
    5: { name: 'Pro', description: 'Até R$500mil / mês', value: '497,90' },
    6: { name: 'Pro II', description: 'Até R$900mil / mês', value: '647,90' },
    7: { name: 'Pro III', description: 'Até R$2mi / mês', value: '747,90' },
    8: { name: 'Pro IV', description: 'Até R$4mi / mês', value: '997,90' },
    9: { name: 'Custom', description: '+ de R$500mil / mês', value: '?' },
  }[plan]);

const DefaultPlans = ({ onSubmitPlan, plans, subTitle, loading, buttonText }) => {
  const [selectedPlan, setSelectedPlan] = useState(0);

  const onSubmitPlanCallback = useCallback(
    ({ planId, price, title, description, isFreePlan }) => {
      return onSubmitPlan({ planId, price, title, description, isFreePlan });
    },
    [onSubmitPlan],
  );

  return (
    <div>
      <div>
        <div className="plans-prices">
          <div className="plans-slider">
            <h2>Qual seu faturamento mensal?</h2>
            <span>{getPlanInfo(selectedPlan).description}</span>
            <InputRange maxValue={8} allowSameValues minValue={0} value={selectedPlan} onChange={setSelectedPlan} />
          </div>
          <div className="vertical-rule" />
          <div className="plan-details">
            {selectedPlan === 0 ? (
              <div className="free-plan-description">
                <span className="title">Como funciona nosso plano on demand?</span>
                <span>
                  Apenas <strong>R$4,99</strong> a cada <strong>R$1.000</strong> em <strong>pedidos pagos</strong>{' '}
                  (faturamento líquido)
                </span>
                <span className="detail">(cobrado por semana)</span>
              </div>
            ) : (
              <>
                {selectedPlan === 9 ? (
                  <div className="custom-price">
                    <span>
                      Entre em contato com suporte pelo email:
                      <br /> contato@profitfy.me
                      <br /> ou
                      <br /> pelo Whatsapp:
                      <br /> (41) 98879-5650
                    </span>
                  </div>
                ) : (
                  <>
                    <span className="description-title">Como funciona nossos planos pagos?</span>
                    <span>Você paga apenas pelo faturamento líquido</span>
                    <span className="description-price">R$ {getPlanInfo(selectedPlan).value}</span>
                    <span>
                      Você pode fazer upgrade a qualquer
                      <br /> momento após ultrapassar o limite do plano
                    </span>
                    <span className="description-detail">(cobrado por mês)</span>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <Features selectedPlan={selectedPlan} />
      <hr className="mt-3 mb-4" />
      <p className="mb-4 text-lead font-weight-bold">{subTitle}</p>
      <Button
        color="primary"
        className="mb-4 shadow"
        block
        disabled={selectedPlan === 9 || loading}
        onClick={() => {
          const parsedPlans = plans.find(plan => plan.name === getPlanInfo(selectedPlan).name);

          return onSubmitPlanCallback({
            planId: parsedPlans?.id,
            price: parsedPlans?.amount,
            title: parsedPlans?.name,
            description: getPlanInfo(selectedPlan).description,
            isFreePlan: selectedPlan === 0,
          });
        }}
      >
        <p className="text-uppercase mb-0 font-weight-bold text-lg">{buttonText}</p>
      </Button>
    </div>
  );
};

export default DefaultPlans;
