import React, { memo } from 'react';
import { Card } from 'reactstrap';
import FacebookBreakEvenTableHeader from './TableHeader/TableHeader';
import FacebookBreakEvenTableBody from './TableBody/TableBody';

const FacebookBreakEven = ({ markup, finalPrice, maxCpa }) => {
  return (
    <Card className="shadow mt-4">
      <FacebookBreakEvenTableHeader />
      <FacebookBreakEvenTableBody markup={markup} finalPrice={finalPrice} maxCpa={maxCpa} />
    </Card>
  );
};

export default memo(FacebookBreakEven);
