import React from 'react';

const BubblesBackground = ({ children }) => {
  return (
    <div className="bg-area">
      <ul className="circles">
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
      {children}
    </div>
  );
};

export default BubblesBackground;
