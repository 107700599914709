import React, { memo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import useForm from 'react-hook-form';
import * as yup from 'yup';
import {
  Button,
  FormGroup,
  Form,
  Input as StrapInput,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
} from 'reactstrap';

import { api } from '../../../services/api';
import Spinner from '../../Spinner/Spinner';
import { useToast } from '../../../hooks/Toast';

import './styles.scss';

const FormPage = ({ setIsEmailSent }) => {
  const { showToast } = useToast();

  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup.string().email('Você precisa inserir um email válido').required('Este campo é obrigatório'),
  });

  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    validationSchema,
  });

  const onSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        await api('auth-api').post(`/auth/forgot-password`, data);

        setIsEmailSent(true);
      } catch (error) {
        const message = error?.response?.data?.message || 'Entre em contato com o suporte.';

        showToast({
          message,
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [showToast, setIsEmailSent],
  );

  return (
    <div className="main-forgot-password-form-container">
      <div className="content-container">
        <img
          className="profitfy-logo"
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/logo.svg"
          alt="Profitfy.me"
        />
        <div className="form-title">
          <p>Enviaremos um link de recuperação para:</p>
        </div>
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <Form className="form-container" onSubmit={handleSubmit(onSubmit)} role="form">
            <FormGroup className={`${errors.email && 'has-danger'} mb-3`}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className={`${errors.email && 'text-red'} ni ni-email-83`} />
                  </InputGroupText>
                </InputGroupAddon>
                <StrapInput placeholder="Email" name="email" innerRef={register} />
              </InputGroup>
              {errors.email && <small className="text-danger">{errors.email.message}</small>}
            </FormGroup>
            <div className="text-center">
              <Button className="mt-4 btn-icon btn-2 sign-in-button" color="primary" type="submit">
                <span className="btn-inner--icon">
                  <i className="ni ni-send" />
                </span>
                <span className="btn-inner--text button-text">Enviar</span>
              </Button>
            </div>
            <Row className="mt-3 link-container">
              <Col xs="6">
                <Link className="" to="/auth/register">
                  <span>Registrar</span>
                </Link>
              </Col>
              <Col className="text-right" xs="6">
                <Link className="" to="/auth/login">
                  <span>Entrar</span>
                </Link>
              </Col>
            </Row>
          </Form>
        )}
      </div>
      <div className="creative-container">
        <img
          className="image-description"
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/Forgot+Password/forgot-password-form.svg"
          alt="Profitfy.me"
        />
      </div>
    </div>
  );
};

export default memo(FormPage);
