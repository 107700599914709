import React, { memo } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { WarningCircle } from 'phosphor-react';

import './styles.scss';

const NotAllowedWarn = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="not-allowed-appmax-warn-modal" centered>
      <ModalHeader className="header">
        <WarningCircle size={28} />
        <span>Aviso</span>
      </ModalHeader>
      <ModalBody className="body">
        <span>Não é possível cancelar seu plano atual!</span>
        <p>
          Você está usando o plano Appmax no momento, e por conta disso não é possível cancelar o seu plano atual. Para
          poder continuar com o procedimento, entre em contato com o nosso suporte.
        </p>
        <Button color="primary" onClick={toggle}>
          Entendi
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default memo(NotAllowedWarn);
