const percentageInfoUtils = {
  getPercentageInfo: percentage =>
    [
      { description: '10%', value: 0.1 },
      { description: '20%', value: 0.2 },
      { description: '30%', value: 0.3 },
      { description: '40%', value: 0.4 },
      { description: '50%', value: 0.5 },
      { description: '60%', value: 0.6 },
      { description: '70%', value: 0.7 },
      { description: '80%', value: 0.8 },
      { description: '90%', value: 0.9 },
      { description: '100%', value: 1.0 },
    ][percentage],
};

export { percentageInfoUtils };
