import React, { memo } from 'react';

import { numberBrFormatter } from '../../../../../utils/utils';

import './styles.scss';

const Tooltip = ({ id, value }) => {
  return (
    <div className="pix-tooltip">
      <span>{id}:</span>
      <span>R$ {numberBrFormatter(value, 2)}</span>
    </div>
  );
};

export default memo(Tooltip);
