import React from 'react';
import { ModalHeader, ModalBody, Modal } from 'reactstrap';
import './styles.scss';

const SuggestionModal = ({ open, toggle }) => {
  return (
    <Modal centered fade isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle} />
      <ModalBody className="suggestion-form embed-responsive embed-responsive-16by9">
        <iframe
          title="suggestion-form"
          className="embed-responsive-item"
          src="https://forms.clickup.com/f/28kd8-1232/VGR4U66AQKFXD8KNCZ"
          frameBorder="0"
          width="100%"
          height="100%"
        />
      </ModalBody>
    </Modal>
  );
};

export default SuggestionModal;
