import React from 'react';

const CustomSwitch = ({ onClick, id, status, dataOn = 'On', dataOff = 'Off' }) => {
  return (
    <label className="switch mb-0" htmlFor={id}>
      <input className="switch-input" type="checkbox" id={id} onClick={onClick} checked={status} />
      <span className="switch-label" data-on={dataOn} data-off={dataOff} />
      <span className="switch-handle" />
    </label>
  );
};

export default CustomSwitch;
