import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from './User';
import { api, shopsApi } from '../services/api';
import usePersistedState from './PersistedState';
import { isAuthenticated } from '../services/auth';

const AppmaxContext = createContext();

export const AppmaxProvider = ({ children }) => {
  const { user, loadUser } = useAuth();
  const history = useHistory();

  const [hasAccessedAppmaxPlanPage, setHasAccessAppmaxPlanPage] = useState(false);
  const [isValidatingAppmax, setIsValidatingAppmax] = useState(false);
  const [stores, setStores] = useState([]);
  const [isNoCredentialsWarnOpen, setIsNoCredentialsWarnOpen] = useState(false);
  const [isAppmaxPlanSuccess, setIsAppmaxPlanSuccess] = useState(false);
  const [appmaxFailReason, setAppmaxFailReason] = useState('');
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const [hasAccessedAppmaxPlanPagePersistedState, setHasAccessedAppmaxPlanPagePersistedState] = usePersistedState(
    'hasAccessedAppmaxPlan',
    false,
  );

  const handleHasAccessedAppmaxPlanPagePersistedState = useCallback(() => {
    setHasAccessedAppmaxPlanPagePersistedState(true);
  }, [setHasAccessedAppmaxPlanPagePersistedState]);

  const hasOthersGatewaysCredentials = useCallback(() => {
    for (const store of stores) {
      if (
        store.appMaxCredentialId ||
        store.cieloCredentialId ||
        store.cloudFoxCredentialId ||
        store.hubSaleCredentialId ||
        store.mercadoPagoCredentialId ||
        store.pagSeguroCredentialId ||
        store.pagarmeCredentialId ||
        store.upnidCredentialId
      )
        return true;
    }

    return false;
  }, [stores]);

  const handleIsNoCredentialsWarnOpen = useCallback(
    () => setIsNoCredentialsWarnOpen(!isNoCredentialsWarnOpen),
    [isNoCredentialsWarnOpen],
  );

  const handleHasAccessedAppmaxPlanPage = useCallback(status => {
    setHasAccessAppmaxPlanPage(status);
  }, []);

  const loadStore = useCallback(async () => {
    setIsLoadingStores(true);

    try {
      const response = await api('user-api').get('/api/v1/users/stores');

      if (response.data) {
        setStores(response.data.stores);
      }
    } finally {
      setIsLoadingStores(false);
    }
  }, []);

  const deleteAllCredentials = useCallback(stores => {
    const requests = stores.map(store =>
      api(shopsApi[store.platform]).delete(`/api/v1/users/${store.platform}/stores/${store.id}/credentials`),
    );

    return requests;
  }, []);

  const verifyAppmax = useCallback(
    async toggle => {
      setIsValidatingAppmax(true);

      try {
        const requestsToDelete = deleteAllCredentials(stores);

        await Promise.all(requestsToDelete);

        const { data, status } = await api('user-api').post(
          `api/v1/users/subscriptions/${user?.subscription?.id}/appmax-plan`,
        );

        if (status === 200 && !data?.message) {
          setIsAppmaxPlanSuccess(true);

          await api('user-api').put('/api/v1/users/me', {
            ...user,
            acceptAppmaxPlanTerms: true,
          });

          await loadUser();

          toggle();

          history.push('/subscribe/appmax/success');
        }

        if (status === 200 && data?.message) {
          setAppmaxFailReason(data?.message);

          toggle();

          history.push('/subscribe/appmax/error');
        }
      } finally {
        setIsValidatingAppmax(false);
      }
    },
    [deleteAllCredentials, stores, user, history, loadUser],
  );

  useEffect(() => {
    if (isAuthenticated()) {
      loadStore();
    }
  }, [loadStore]);

  return (
    <AppmaxContext.Provider
      value={{
        hasAccessedAppmaxPlanPage,
        handleHasAccessedAppmaxPlanPage,
        isNoCredentialsWarnOpen,
        handleIsNoCredentialsWarnOpen,
        verifyAppmax,
        isValidatingAppmax,
        isAppmaxPlanSuccess,
        appmaxFailReason,
        hasOthersGatewaysCredentials,
        isLoadingStores,
        hasAccessedAppmaxPlanPagePersistedState,
        handleHasAccessedAppmaxPlanPagePersistedState,
      }}
    >
      {children}
    </AppmaxContext.Provider>
  );
};

export const useAppmax = () => {
  const context = useContext(AppmaxContext);

  if (!context) {
    throw new Error('useAppmax must bet used within a AppmaxProvider');
  }

  return context;
};
