import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Card, Button } from 'reactstrap';

import Next from '../../../assets/img/icons/common/next.svg';
import facebook from '../../../assets/img/logos/facebook.svg';
import { useFacebookMarketing } from '../../../hooks/FacebookMarketing';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';
import FacebookAccounts from '../../../components/OnboardingMarketing/Facebook/FacebooKAccoounts/FacebookAccounts';
import AdAccountsTable from '../../../components/Facebook/AdAccountsTable/AdAccountsTable';
import Spinner from '../../../components/Spinner/Spinner';
import NoMarketingModal from '../../../components/NoMarketingModal/NoMarketingModal';

import './styles.scss';

const Marketing = () => {
  const { user } = useAuth();
  const history = useHistory();

  const [selectedMarketing, setSelectedMarketing] = useState('');
  const [isSuggestionModalOpen, setIsSuggestionModalOpen] = useState(false);
  const { store, platform } = useParams();
  const {
    loadAdAccounts,
    loadProfiles,
    setStore,
    setPlatform,
    adAccounts,
    isLoadingProfiles,
    isLoggingOutProfile,
    isLoggingInProfile,
  } = useFacebookMarketing();

  const handleSuggestionModal = useCallback(
    () => setIsSuggestionModalOpen(!isSuggestionModalOpen),
    [isSuggestionModalOpen],
  );

  useEffect(() => {
    setStore(store);
    setPlatform(platform);
  }, [store, platform, setStore, setPlatform]);

  const marketingProviders = useCallback(
    marketing =>
      ({
        facebook: (
          <>
            {isLoadingProfiles || isLoggingOutProfile || isLoggingInProfile ? (
              <Spinner loading />
            ) : (
              <div>
                <FacebookAccounts />
                <hr />
                {adAccounts.length !== 0 && <AdAccountsTable />}
              </div>
            )}
          </>
        ),
      }[marketing]),
    [adAccounts.length, isLoadingProfiles, isLoggingInProfile, isLoggingOutProfile],
  );

  const handleMarketingClick = useCallback(
    event => {
      loadAdAccounts({ isLoading: true, platform, store });
      loadProfiles();

      setSelectedMarketing(event.target.id);
    },
    [loadAdAccounts, loadProfiles, platform, store],
  );

  const handleNextPage = useCallback(() => {
    history.push('checkout');
  }, [history]);

  useEffect(() => {
    setStore(store);
    setPlatform(platform);
  }, [platform, store, setPlatform, setStore]);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_onboarding_marketing',
        type: 'screen',
      });
    }
  }, [user]);

  return (
    <div className="main-new-user-marketing-container">
      <div className="content-container">
        <div className="content-header">
          <img
            className="profitfy-logo"
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/logo_v2.svg"
            alt="Profitfy.me"
          />

          <div className="step-container">
            <div className="bar-container">
              <div className="bar-progress" />
            </div>

            <span>2/6</span>
          </div>
        </div>

        {selectedMarketing ? (
          <div className="facebook-marketing">{marketingProviders(selectedMarketing)}</div>
        ) : (
          <>
            <span>CONECTE-SE A UMA PLATAFORMA DE MARKETING</span>
            <p>
              Isso é importante para que possamos cruzar alguns dados que irão alimentar seu Dashboard em tempo real.
              Facilitando a análise de dados sob a performance de suas vendas.
            </p>
            <div className="gateways-container">
              <Card className="marketing-card shadow" onClick={handleMarketingClick}>
                <div className="d-flex justify-content-between" id="facebook">
                  <div className="d-flex main-marketing-content">
                    <img src={facebook} alt="Profitfy.me Facebook" />
                    <div>
                      <p className="marketing-name" id="facebook">
                        Facebook
                      </p>
                      <p className="marketing-description" id="facebook">
                        Integração com o Facebook
                      </p>
                    </div>
                  </div>
                  <img src={Next} alt="Profitfy.me next" id="facebook" />
                </div>
              </Card>
            </div>
          </>
        )}

        <div className="suggestion-wrapper">
          <button className="suggestion-button" type="button" onClick={handleSuggestionModal}>
            Não encontrou o gateway que você usa? Nos informe
          </button>
        </div>

        <NoMarketingModal isOpen={isSuggestionModalOpen} toggle={handleSuggestionModal} />

        <div className={selectedMarketing ? 'next-page-selected-marketing-container' : 'next-page-container'}>
          {selectedMarketing ? (
            <Button type="button" className="prev-page" onClick={() => setSelectedMarketing('')}>
              <i className="fas fa-arrow-left" />
              <span>Voltar</span>
            </Button>
          ) : (
            <Link to={`/${platform}/${store}/onboarding/gateway`} className="prev-page">
              <i className="fas fa-arrow-left" />
              <span>Voltar</span>
            </Link>
          )}
          <Button color="primary" type="button" onClick={handleNextPage} outline>
            {selectedMarketing ? <span>Próximo Passo</span> : <span>Pular</span>}
            <i className="fas fa-arrow-right" />
          </Button>
        </div>
      </div>

      <div className="creative-container">
        {selectedMarketing ? (
          <div className="facebook-container">
            <div className="about-facebook-ads">
              <h1>Sobre a integração com o Facebook Ads.</h1>
              <p>
                Você pode adicionar quantos perfils do facebook desejar.Da mesma forma com as contas de anúncios.Essa
                integração não prejudica sua contingência.
              </p>
            </div>
            <div className="important-content">
              <h1>Importante!</h1>
              <p>
                Uma conta de anúncio pode ser vinculada somente à um perfil do Facebook.Caso ela seja vinculada à um
                perfil compartilhado, o Facebook poderá pedir para que você mude a senha e reconecte o seu perfil
                novamente na Profitfy.me.
              </p>
            </div>
            <img
              src="https://profitfy-media.s3-us-west-2.amazonaws.com/New+User+Flux/new-user-facebook.svg"
              alt="Profitfy.me Facebook"
            />
          </div>
        ) : (
          <div className="video-container">
            <img
              src="https://profitfy-media.s3-us-west-2.amazonaws.com/New+User+Flux/new-user-video.svg"
              alt="Profitfy.me new user"
            />

            {/* <div className="video-container">
              <iframe
                title="Profitfy Trial Benefits"
                width="400"
                height="225"
                src="https://www.youtube.com/embed/FO0iwx3PUHo"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Marketing;
