import React from 'react';
import {
  CardBody,
  Col,
  Card,
  CardHeader,
  InputGroupAddon,
  Row,
  InputGroup,
  InputGroupText,
  Input as StrapInput,
} from 'reactstrap';
import './styles.scss';

const CustomMarkup = ({ setCustomMarkup }) => {
  return (
    <Col>
      <Card className="shadow custom-markup ml-2 mr-2">
        <CardHeader>
          <h3 className="mb-0">
            <i>Markup</i> Customizado
          </h3>
        </CardHeader>
        <CardBody className="custom-markup-body">
          <Row className="justify-content-between align-items-center mb-3 pr-4 pl-4">
            <span>Markup</span>
            <InputGroup className="input-group-alternative input-size" size="sm">
              <StrapInput
                className="text-right"
                type="number"
                placeholder="1"
                name="customMarkup"
                onChange={e => setCustomMarkup(Number(e.target.value) || 1)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>x</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CustomMarkup;
