import React, { useState, memo } from 'react';
import { Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from 'reactstrap';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import CustomSpendsModal from '../Modal/Modal';
import SubHeaders from './SubHeaders/SubHeaders';
import { numberBrFormatter } from '../../../../utils/utils';
import { api, shopsApi } from '../../../../services/api';
import brazilFlag from '../../../../assets/img/theme/brasil.jpg';
import euaFlag from '../../../../assets/img/theme/eua.jpg';
import { customSpendUtils } from '../../../../utils/customSpend';

const columns = [
  { name: 'createdAt', description: 'Criado Em', sort: true },
  { name: 'status', description: 'Status', sort: false },
  { name: 'category', description: 'Categoria', sort: false },
  { name: 'description', description: 'Descrição', sort: true },
  { name: 'startDate', description: 'Começa Em', sort: true },
  { name: 'frequency', description: 'Frequência', sort: false },
  { name: 'currency', description: 'Moeda', sort: true },
  { name: 'amount', description: 'Valor', sort: true },
  { name: 'type', description: 'Tipo', sort: true },
  { name: 'action', description: 'Ação', sort: false },
];

const CustomSpendsTableBody = ({ customSpends, setCustomSpends, setCustomSpendSort, customSpendSort }) => {
  const { store, platform } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [customSpend, setCustomSpend] = useState();

  const getSpendById = id => {
    const customSpend = customSpends.find(spend => String(spend.id) === id);
    return customSpend;
  };

  const toggle = event => {
    setIsOpen(!isOpen);
    const customSpend = getSpendById(event?.target?.id);
    setCustomSpend(customSpend);
  };

  const handleDelete = async event => {
    const { id } = event.target;

    const result = await Swal.fire({
      title: 'Você tem certeza que quer deletar?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, deletar!',
    });

    if (result.value) {
      await api(shopsApi[platform]).delete(`/api/v1/users/${platform}/stores/${store}/custom-spends/${id}`);
      Swal.fire({
        icon: 'success',
        title: 'Custo deletado com sucesso!',
        showConfirmButton: false,
        timer: 1500,
      });

      const newCustomSpends = customSpends.filter(spend => spend.id !== id);
      setCustomSpends(newCustomSpends);
    }
  };

  return (
    <>
      {customSpend && (
        <CustomSpendsModal
          toggle={toggle}
          isOpen={isOpen}
          customSpends={customSpends}
          setCustomSpends={setCustomSpends}
          customSpend={customSpend}
          title="Edite o valor do custo adicional"
        />
      )}
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr className="text-center">
            <SubHeaders columns={columns} setCustomSpendSort={setCustomSpendSort} customSpendSort={customSpendSort} />
          </tr>
        </thead>
        <tbody>
          {customSpends.map(el => (
            <tr key={el.id}>
              <td>
                <p className="mb-0">{moment(el.createdAt).format('DD-MM-YYYY')}</p>
              </td>
              <td className="text-center">
                <p className="mb-0">
                  {el.active ? (
                    <Badge color="" className="badge-dot mr-4">
                      <i className="bg-yellow" />
                      Em progresso
                    </Badge>
                  ) : (
                    <Badge color="" className="badge-dot mr-4">
                      <i className="bg-primary" />
                      Finalizado
                    </Badge>
                  )}
                </p>
              </td>
              <td className=" text-truncate text-center" style={{ maxWidth: '120px' }}>
                <p className="mb-0 h2">
                  <Badge color={customSpendUtils.getColorByCategory(el.category)}>
                    {customSpendUtils.parseCategoryName(el.category)}
                  </Badge>
                </p>
              </td>
              <td className=" text-truncate" style={{ maxWidth: '300px' }}>
                <span>{el.description}</span>
              </td>
              <td>
                <p className="mb-0">{moment(el.startDate).format('DD-MM-YYYY')}</p>
              </td>
              <td className="text-center">
                <p className="mb-0 h2">
                  <Badge color={customSpendUtils.getColorByPeriod(el.period, el.endDate)}>
                    {customSpendUtils.parsePeriodName(el.period, el.endDate)}
                  </Badge>
                </p>
              </td>
              <td>
                <img width="30px" src={el.currency === 'BRL' ? brazilFlag : euaFlag} alt="currency flag" />
              </td>
              <td>
                <p className="mb-0">{`${el.currency === 'BRL' ? 'R$' : 'U$'} ${numberBrFormatter(el.amount)}`} </p>
              </td>
              <td>
                <p className="mb-0 h2 text-center">
                  <Badge color={el.type === 'IN' ? 'info' : 'danger'}>{el.type === 'IN' ? 'Entrada' : 'Saída'}</Badge>
                </p>
              </td>
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#pablo"
                    role="button"
                    size="sm"
                    onClick={e => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow">
                    <DropdownItem onClick={toggle} id={el.id}>
                      Alterar
                    </DropdownItem>
                    <DropdownItem onClick={handleDelete} id={el.id}>
                      Deletar
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default memo(CustomSpendsTableBody);
