import React, { useState, useCallback, memo } from 'react';
import { CardHeader, Row, InputGroup, InputGroupAddon, InputGroupText, Button, Input } from 'reactstrap';

const AudienceInsightsTableHeader = ({ handleFilter }) => {
  const [interest, setInterest] = useState({
    interest: null,
  });

  const handleInterest = useCallback(() => {
    handleFilter(interest);
  }, [handleFilter, interest]);

  return (
    <CardHeader className="border-0">
      <Row className="d-flex justify-content-between p-2">
        <div>
          <h3 className="mb-0">Audience Insights</h3>
          <p className="text-muted text-sm mb-0">Descubra segmentações ocultas para o Facebook.</p>
        </div>
        <div className="mt-2 mt-md-0 d-flex align-items-center">
          <InputGroup className="mr-3 input-group-alternative">
            <InputGroupAddon addonTYpe="prepend">
              <InputGroupText>
                <i className="mt-2 fa fa-search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Pesquisar interesse"
              type="text"
              name="interest"
              onChange={interest => setInterest({ interest: interest.target.value })}
            />
          </InputGroup>
          <Button color="primary" className="btn-icon btn-2" onClick={handleInterest}>
            <span className="btn-inner--text">Buscar</span>
          </Button>
        </div>
      </Row>
    </CardHeader>
  );
};

export default memo(AudienceInsightsTableHeader);
