import React, { memo, useState, useMemo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';

import Header from './Header/Header';
import Body from './Body/Body';
import Footer from './Footer/Footer';
import { makeSearchRequest, shopsApi } from '../../../services/api';

const ProductCostTable = ({ setSelectedProduct }) => {
  const { store, platform } = useParams();

  const [page, setPage] = useState(0);
  const [productNameSearch, setProductNameSearch] = useState('');
  const [products, setProducts] = useState([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [period] = useState({
    startDate: moment(),
    endDate: moment(),
  });

  const searchFetch = useMemo(
    () =>
      makeSearchRequest({
        api: shopsApi[platform],
      }),
    [platform],
  );

  const loadProducts = useCallback(async () => {
    const limit = 10;
    setIsLoadingProducts(true);

    const data = await searchFetch(
      `api/v1/users/${platform}/stores/${store}/products?page=${
        page + 1
      }&title=${productNameSearch}&limit=${limit}&startDate=${period.startDate.format(
        'YYYY-MM-DD',
      )}&endDate=${period.startDate.format('YYYY-MM-DD')}`,
    );

    if (data) {
      setPageCount(data.numberOfPages);
      setProducts(data.products);
      setIsLoadingProducts(false);
    }
  }, [page, platform, productNameSearch, searchFetch, store, period]);

  const onPageChange = useCallback(page => {
    setPage(page.selected);
  }, []);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  return (
    <>
      <Header setProductNameSearch={setProductNameSearch} onPageChange={onPageChange} loadProducts={loadProducts} />
      <Body isLoadingProducts={isLoadingProducts} products={products} setSelectedProduct={setSelectedProduct} />
      <Footer page={page} onPageChange={onPageChange} pageCount={pageCount} />
    </>
  );
};

export default memo(ProductCostTable);
