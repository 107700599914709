import React, { memo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import AdminRoute from '../components/AdminRoute/AdminRoute';
import Auth from '../pages/Auth/Auth';
import Stores from '../pages/Stores/Stores';
import Dashboard from '../pages/Dashboard/Dashboard';
import Trial from '../pages/Trial/Trial';
import Prices from '../pages/Prices/Prices';
import Admin from '../pages/Admin/Admin';
import Profile from '../components/Profile/Profile';
import SelectPlans from '../pages/Subscribe/SelectPlans/SelectPlans';
import SubscribeFinish from '../pages/Subscribe/Finish/Finish';
import Taxes from '../pages/Onboarding/Taxes/Taxes';
import CreditCardPayment from '../pages/Subscribe/CreditCardPayment/CreditCardPayment';
import PersonalInfo from '../pages/Subscribe/PersonalInfo/PersonalInfo';
import Gateway from '../pages/Onboarding/Gateway/Gateway';
import Currency from '../pages/Onboarding/Currency/Currency';
import Marketing from '../pages/Onboarding/Marketing/Marketing';
import CheckoutFee from '../pages/Onboarding/CheckoutFee/CheckoutFee';
import OnboardingFinish from '../pages/Onboarding/Finish/Finish';
import Phone from '../pages/Onboarding/Phone/Phone';
import AppmaxSubscribe from '../pages/Subscribe/Appmax/Appmax';
import AppmaxSubscribeSuccess from '../pages/Subscribe/Appmax/Success/Success';
import AppmaxSubscribeError from '../pages/Subscribe/Appmax/Error/Error';

export const Routes = memo(() => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/stores" />} />
      <Route path="/auth" render={props => <Auth {...props} />} />
      <Route path="/prices" render={props => <Prices {...props} />} />
      <AdminRoute path="/admin" component={props => <Admin {...props} />} />
      <PrivateRoute path="/subscribe/plans" component={props => <SelectPlans {...props} />} />
      <PrivateRoute path="/subscribe/finish" component={props => <SubscribeFinish {...props} />} />
      <PrivateRoute path="/subscribe/payment" component={props => <CreditCardPayment {...props} />} />
      <PrivateRoute path="/subscribe/personal-info" component={props => <PersonalInfo {...props} />} />
      <PrivateRoute path="/trial" exact component={props => <Trial {...props} />} />
      <PrivateRoute path="/stores" component={props => <Stores {...props} />} exact />
      <PrivateRoute path="/stores/user-profile" component={props => <Profile {...props} fluid={false} exact />} />
      <PrivateRoute path="/:platform/:store/dashboard" component={props => <Dashboard {...props} />} />
      <PrivateRoute path="/:platform/:store/onboarding/currency" component={props => <Currency {...props} />} />
      <PrivateRoute path="/:platform/:store/onboarding/gateway" component={props => <Gateway {...props} />} />
      <PrivateRoute path="/:platform/:store/onboarding/checkout" component={props => <CheckoutFee {...props} />} />
      <PrivateRoute path="/:platform/:store/onboarding/marketing" component={props => <Marketing {...props} />} />
      <PrivateRoute path="/:platform/:store/onboarding/taxes" component={props => <Taxes {...props} />} />
      <PrivateRoute path="/:platform/:store/onboarding/finish" component={props => <OnboardingFinish {...props} />} />
      <PrivateRoute path="/:platform/:store/onboarding/phone" component={props => <Phone {...props} />} />
      <PrivateRoute path="/subscribe/appmax" exact component={props => <AppmaxSubscribe {...props} />} />
      <PrivateRoute path="/subscribe/appmax/success" exact component={props => <AppmaxSubscribeSuccess {...props} />} />
      <PrivateRoute path="/subscribe/appmax/error" exact component={props => <AppmaxSubscribeError {...props} />} />
    </Switch>
  );
});
