import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';

const RefusedPaymentAlert = () => {
  return (
    <div className="refused-payment-container">
      <i className="fas fa-exclamation-circle" />
      <span>
        O pagamento do seu plano foi recusado e consta como pendente!{' '}
        <Link className="alert-link" to="user-profile/change-credit-card">
          Clique aqui
        </Link>{' '}
        para arrumar as suas informações.
      </span>
    </div>
  );
};

export default memo(RefusedPaymentAlert);
