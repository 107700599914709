import React, { memo } from 'react';

const SubHeaders = ({ audienceInsightsSort, setAudienceInsightsSort, columns }) => {
  return columns.map(column => {
    const sortProps = {
      key: column.name,
      className: `px-3 ${column.sort && 'has-sort'}`,
      scope: 'col',
      onClick: () =>
        setAudienceInsightsSort({
          [column.name]: audienceInsightsSort[column.name] === 'asc' ? 'desc' : 'asc',
        }),
    };

    return (
      <th {...sortProps}>
        {column.description}{' '}
        {column.sort && (
          <i
            className={
              audienceInsightsSort[column.name] ? `fa fa-sort-${audienceInsightsSort[column.name]}` : `fa fa-sort`
            }
          />
        )}
      </th>
    );
  });
};

export default memo(SubHeaders);
