import React, { memo } from 'react';
import { CircleSpinner } from 'react-spinners-kit';

import './styles.scss';

const SwitchInput = ({ id, onChange, defaultChecked, disabled, isLoading, status }) => {
  return (
    <div className="custom-switch-container">
      <label className="custom-switch-input" htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          onChange={onChange}
          defaultChecked={defaultChecked || false}
          disabled={disabled || false}
        />
        <span className={`slider round ${disabled ? 'disabled' : undefined}`} />
      </label>

      {isLoading && <CircleSpinner size={18} color={status ? '#A0A4A8' : '#7DD56F'} />}
    </div>
  );
};

export default memo(SwitchInput);
