import React, { memo, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { CircleSpinner } from 'react-spinners-kit';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { useFacebookMarketing } from '../../../../hooks/FacebookMarketing';
import { api } from '../../../../services/api';
import ProfileCard from '../../../Facebook/ProfileCard/ProfileCard';
import ConnectWarningModal from '../../../Facebook/ConnectWarningModal/ConnectWarningModal';
import facebookWhiteIcon from '../../../../assets/img/icons/common/facebook-white-icon.svg';

import './styles.scss';

const FacebookAccounts = () => {
  const { handleFacebookLogin, profiles, loadAdAccounts } = useFacebookMarketing();
  const { store, platform } = useParams();

  const sliderProfilesRef = useRef(null);

  const [isMouseMoveOn, setIsMouseMoveOn] = useState(false);
  const [onMouseDownEvent, setOnMouseDownEvent] = useState({});
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isSyncingAdAccount, setIsSyncingAdAccount] = useState(false);
  const [isConnectFacebookModalOpen, setIsConnectFacebookModalOpen] = useState(false);

  const handleConnectFacebookModalOpen = useCallback(
    () => setIsConnectFacebookModalOpen(!isConnectFacebookModalOpen),
    [isConnectFacebookModalOpen],
  );

  const handleSyncAdAccount = useCallback(async () => {
    setIsSyncingAdAccount(true);

    try {
      const profilesToSync = profiles.map(({ id }) =>
        api('user-api').put(`api/v1/users/facebook/credential/${id}/ad-accounts`),
      );

      await Promise.all(profilesToSync);

      loadAdAccounts({ isLoading: true, store, platform });
    } finally {
      setIsSyncingAdAccount(false);
    }
  }, [loadAdAccounts, profiles, platform, store]);

  const handleOnMouseDown = useCallback(event => {
    setIsMouseMoveOn(true);
    setOnMouseDownEvent(event);
  }, []);

  const handleOnMouseUp = useCallback(() => {
    setIsMouseMoveOn(false);
    setScrollLeft(sliderProfilesRef.current.scrollLeft);
    setOnMouseDownEvent({});
  }, []);

  const handleOnMouseMove = useCallback(
    event => {
      event.persist();

      if (isMouseMoveOn && sliderProfilesRef) {
        const position = onMouseDownEvent.pageX - event.clientX;

        sliderProfilesRef.current.scrollLeft = scrollLeft + position;
      }
    },
    [isMouseMoveOn, scrollLeft, sliderProfilesRef, onMouseDownEvent],
  );

  const handleFacebookConnect = useCallback(
    ({ onClick }) => {
      if (profiles.length >= 1) {
        handleConnectFacebookModalOpen();
      }

      onClick();
    },
    [profiles.length, handleConnectFacebookModalOpen],
  );

  return (
    <div className="onboarding-facebook-accounts-integration">
      <div className="header">
        <div>
          <span>Integração com o Facebook</span>
          <span>Conecte perfis do Facebook para contabilizar seus gastos com anúncios.</span>
        </div>

        <div className="buttons-wrapper">
          <FacebookLogin
            appId="2444384415680634"
            scope="ads_read,public_profile,business_management,ads_management"
            version="5.0"
            disableMobileRedirect
            callback={responseFacebook => handleFacebookLogin({ responseFacebook, store, platform })}
            render={props => (
              <button
                className="facebook-add-account"
                type="button"
                onClick={() => handleFacebookConnect({ onClick: props.onClick })}
              >
                <img className="facebook-logo" src={facebookWhiteIcon} alt="Profitfy.me Facebook" />

                <span>Conectar com facebook</span>
              </button>
            )}
          />

          {profiles.length > 0 && (
            <Button
              className="sync-account"
              color="primary"
              outline
              disabled={isSyncingAdAccount}
              onClick={handleSyncAdAccount}
            >
              {isSyncingAdAccount ? (
                <>
                  Sincronizando Contas
                  <CircleSpinner size={20} color="#a0a4a8" />
                </>
              ) : (
                <>Sincronizar Contas</>
              )}
            </Button>
          )}
        </div>
      </div>

      <div
        className="body"
        role="row"
        tabIndex={0}
        ref={sliderProfilesRef}
        onMouseDown={handleOnMouseDown}
        onMouseUp={handleOnMouseUp}
        onMouseMove={handleOnMouseMove}
      >
        <ConnectWarningModal isOpen={isConnectFacebookModalOpen} toggle={handleConnectFacebookModalOpen} />
        {profiles.map(profile => (
          <ProfileCard profile={profile} key={profile.id} />
        ))}
      </div>
    </div>
  );
};

export default memo(FacebookAccounts);
