import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import Pricing from './Pricing/Pricing';
import AudienceInsights from './AudienceInsights/AudienceInsights';

const Tools = () => {
  return (
    <Switch>
      <PrivateRoute
        path="/:platform/:store/dashboard/tools/a/pricing-tool"
        component={props => <Pricing {...props} />}
        exact
      />
      <PrivateRoute
        path="/:platform/:store/dashboard/tools/a/audience-insights"
        component={props => <AudienceInsights {...props} />}
        exact
      />
    </Switch>
  );
};

export default Tools;
