import React, { memo } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { WarningCircle } from 'phosphor-react';

import './styles.scss';

const CustomSpendsWarn = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="custom-spends-warn-modal">
      <ModalHeader className="header">
        <WarningCircle size={28} />
        <span>Aviso</span>
      </ModalHeader>
      <ModalBody className="body">
        <img
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/svgs/custom-spends-warn.svg"
          alt="Profitfy.me Valores Adicionais Aviso"
        />
        <span>Isso pode levar alguns minutos!</span>
        <p>
          Estamos <strong>processando</strong> o seu valor adicional, e essa etapa pode demorar até{' '}
          <strong>10 minutos</strong> para poder <strong>aparecer no dashboard</strong>. Não se preocupe, você pode
          continuar navegando normalmente ;)
        </p>
        <Button color="primary" onClick={toggle}>
          Entendi
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default memo(CustomSpendsWarn);
