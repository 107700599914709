import React, { memo, useCallback, useState, useEffect } from 'react';
import { Card, Button } from 'reactstrap';
import { FacebookLogo, ArrowsClockwise } from 'phosphor-react';
import { useParams, useHistory } from 'react-router-dom';

import CampaignsTableHeader from '../CampaignsTableHeader/CampaignsTablePage';
import CampaignsTableBody from '../CampaignsTableBody/CampaignsTableBody';
import CampaignsTableFooter from '../CampaignsTableFooter/CampaignsTableFooter';
import usePersistedState from '../../../hooks/PersistedState';
import Spinner from '../../Spinner/Spinner';
import { api } from '../../../services/api';
import ConfirmSyncModal from '../ConfirmSyncModal/ConfirmSyncModal';

import './styles.scss';

const CampaignsTablePage = ({
  campaigns,
  pageCount,
  handlePaginationChange,
  page,
  handleCampaignsSearch,
  isLoadingCampaigns,
  loadCampaigns,
}) => {
  const { platform, store, productId } = useParams();
  const [syncFacebookCampaign, setSyncFacebookCampaign] = usePersistedState('syncFacebookCampaign');
  const history = useHistory();

  const [syncCampaignsLoading, setSyncCampaignsLoading] = useState(false);
  const [isConfirmSyncModalOpen, setIsConfirmSyncModalOpen] = useState(false);

  const handleConfirmSyncModalOpen = useCallback(
    () => setIsConfirmSyncModalOpen(!isConfirmSyncModalOpen),
    [isConfirmSyncModalOpen],
  );

  const goToFacebookForm = useCallback(() => {
    history.push(`/${platform}/${store}/dashboard/integrations/facebook?productId=${productId}&campaigns=true`);
  }, [platform, store, productId, history]);

  const getQueryStatus = useCallback(
    async syncFacebookCampaign => {
      const interval = setInterval(async () => {
        const { data } = await api('user-api').get(
          `api/v1/users/facebook/ad-accounts/${platform}/campaigns/${syncFacebookCampaign.id}`,
        );
        syncFacebookCampaign = data.syncFacebookCampaign;

        if (syncFacebookCampaign.status !== 'PENDING') {
          clearInterval(interval);
          setSyncCampaignsLoading(false);
          setSyncFacebookCampaign(syncFacebookCampaign);

          await loadCampaigns({ nameSearch: '', page: 0 });
        }
      }, 10000);
    },
    [setSyncFacebookCampaign, loadCampaigns, platform],
  );

  const handleSyncCampaigns = useCallback(async () => {
    setSyncCampaignsLoading(true);

    try {
      const { data } = await api('user-api').post(`api/v1/users/facebook/ad-accounts/${platform}/campaigns`);

      setSyncFacebookCampaign(data.syncFacebookCampaign);

      if (data.syncFacebookCampaign.status === 'PENDING') {
        getQueryStatus(data.syncFacebookCampaign);
      }
    } catch {
      setSyncCampaignsLoading(false);
    }
  }, [getQueryStatus, setSyncFacebookCampaign, platform]);

  useEffect(() => {
    const verifySyncFacebookCampaignStatus = async () => {
      if (syncFacebookCampaign?.status === 'PENDING') {
        setSyncCampaignsLoading(true);

        await getQueryStatus(syncFacebookCampaign);
      }
    };

    verifySyncFacebookCampaignStatus();
  }, [getQueryStatus, syncFacebookCampaign]);

  return (
    <div className="campaigns-table-page-container">
      <div className="header">
        <Button className="facebook-button" onClick={goToFacebookForm}>
          <FacebookLogo size={24} />
          <span>Ir para contas de anúncio</span>
        </Button>
        <Button outline color="primary" onClick={handleConfirmSyncModalOpen} disabled={syncCampaignsLoading}>
          <span>Sincronizar campanhas</span>
          <ArrowsClockwise size={24} />
        </Button>
      </div>

      <ConfirmSyncModal
        isOpen={isConfirmSyncModalOpen}
        toggle={handleConfirmSyncModalOpen}
        onConfirm={handleSyncCampaigns}
      />

      <Card className="campaigns-table">
        {syncCampaignsLoading ? (
          <>
            <Spinner loading />
            <span className="sync-campaigns-load">Nesse momento as suas campanhas estão sendo sincronizadas!</span>
            <span className="sync-campaigns-load">
              Esse processo pode demorar alguns minutos, então pedimos que você aguarde alguns instantes :)
            </span>
          </>
        ) : (
          <>
            <CampaignsTableHeader handleCampaignsSearch={handleCampaignsSearch} />
            <CampaignsTableBody campaigns={campaigns} isLoadingCampaigns={isLoadingCampaigns} />
            <CampaignsTableFooter pageCount={pageCount} handlePageChange={handlePaginationChange} page={page} />
          </>
        )}
      </Card>
    </div>
  );
};

export default memo(CampaignsTablePage);
