import React, { memo, useCallback, useState } from 'react';
import { Modal, ModalHeader, ModalBody, InputGroup, Label, Input, Button } from 'reactstrap';

import { api } from '../../services/api';
import { useToast } from '../../hooks/Toast';

import './styles.scss';

const NoGatewayModal = ({ isOpen, toggle }) => {
  const { showToast } = useToast();

  const [selectedValue, setSelectedValue] = useState('ebanx');
  const [otherSuggestionValue, setOtherSuggestionValue] = useState('');
  const [isSendingSuggestion, setIsSendingSuggestion] = useState(false);

  const handleSubmit = useCallback(async () => {
    setIsSendingSuggestion(true);

    let suggestion = selectedValue;

    if (selectedValue === 'others') {
      suggestion = otherSuggestionValue;
    }

    try {
      if (selectedValue) {
        await api('user-api').post('/api/v1/users/suggestion-integrations', {
          field: 'gateway',
          fieldValue: suggestion,
        });

        showToast({
          message: 'Sugestão enviada com sucesso!',
          type: 'success',
        });

        toggle();
      }
    } finally {
      setIsSendingSuggestion(false);
    }
  }, [otherSuggestionValue, selectedValue, showToast, toggle]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <span>Sugestão de Gateway</span>
      </ModalHeader>

      <ModalBody className="no-gateway-modal-content">
        <InputGroup className="input-group">
          <Label>Qual Gateway você deseja integrar?</Label>

          <Input defaultValue="ebanx" type="select" onChange={event => setSelectedValue(event.target.value)}>
            <option value="ebanx">EBANX</option>
            <option value="wirecard">Wirecard</option>
            <option value="yapay">Yapay</option>
            <option value="getnet">Getnet</option>
            <option value="rede">Rede</option>
            <option value="others">Outros</option>
          </Input>
        </InputGroup>

        {selectedValue === 'others' && (
          <InputGroup className="input-group">
            <Label>Nos informe</Label>

            <Input
              name="suggestion_value"
              type="text"
              onChange={event => setOtherSuggestionValue(event.target.value)}
            />
          </InputGroup>
        )}

        <Button color="primary" onClick={handleSubmit} disabled={isSendingSuggestion}>
          Enviar
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default memo(NoGatewayModal);
