import React, { useState, useCallback, useEffect } from 'react';
import useForm from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import {
  Button,
  FormGroup,
  Form,
  Input as StrapInput,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
} from 'reactstrap';

import PhoneInput from 'react-phone-input-2';
import { api } from '../../../services/api';
import { login } from '../../../services/auth';
import Spinner from '../../../components/Spinner/Spinner';
import { useAuth } from '../../../hooks/User';
import { useToast } from '../../../hooks/Toast';
import usePersistedState from '../../../hooks/PersistedState';
import { useAppmax } from '../../../hooks/Appmax';

import './styles.scss';

const patternPassword = /^[a-zA-Z0-9!@#$%&*.-=?+._]{6,25}$/;

const schema = yup.object().shape({
  firstName: yup
    .string('Este campo não pode haver números.')
    .matches(/^[a-zA-Z\u00C0-\u00FF]*$/, 'Este campo deve conter apenas o primeiro nome.')
    .min(2, 'Precisa conter no minímo 2 letras')
    .max(30, 'O nome pode conter no máximo 30 letras'),
  lastName: yup
    .string('Este campo não pode haver números.')
    .matches(/^[a-zA-Z\u00C0-\u00FF ]*$/, 'Este campo deve conter apenas o primeiro nome.')
    .min(2, 'Precisa conter no minímo 2 letras')
    .max(30, 'O nome pode conter no máximo 30 letras'),
  email: yup.string().email('Você precisa inserir um email válido').required('Este campo é obrigatório'),
  password: yup.string().matches(patternPassword, 'A senha deve no mínimo 6 caracteres'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Está diferente da senha.'),
});

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Register = ({ history }) => {
  const query = useQuery();
  const { showToast } = useToast();
  const { handleHasAccessedAppmaxPlanPagePersistedState } = useAppmax();
  const [affiliate, setAffiliate] = usePersistedState('affiliate');

  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);

  const { loadUser } = useAuth();
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    validationSchema: schema,
  });

  const onSubmit = useCallback(
    async data => {
      setLoading(true);

      try {
        const response = await api('auth-api').post(`/auth/signup`, {
          ...data,
          phone,
          ref: affiliate,
        });

        login(response?.data?.token);

        loadUser();

        history.push('/stores');
      } catch (error) {
        const message = error?.response?.data?.message || 'Entre em contato com o suporte.';

        showToast({
          message,
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [history, loadUser, phone, showToast, affiliate],
  );

  useEffect(() => {
    const ref = query.get('ref');
    const appmax = query.get('appmax');
    const formattedRef = ref?.split('?')[0];

    if (ref && ref !== 'null') {
      setAffiliate(formattedRef);
    }

    if (appmax === 'true') {
      handleHasAccessedAppmaxPlanPagePersistedState();
    }
  }, [query, setAffiliate, handleHasAccessedAppmaxPlanPagePersistedState]);

  return (
    <div className="main-register-container">
      <div className="content-container">
        <img
          className="profitfy-logo"
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/logo.svg"
          alt="Profitfy.me"
        />
        <div className="form-title">
          <p>Informe seus dados</p>
        </div>
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <Form id="signup-form" className="form-container" onSubmit={handleSubmit(onSubmit)} role="form">
            <FormGroup className={errors.firstName && 'has-danger'}>
              <InputGroup className="mb-1 sign-up-input">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className={`${errors.firstName && 'text-red'} ni ni-single-02`} />
                  </InputGroupText>
                </InputGroupAddon>
                <StrapInput placeholder="Nome" type="text" name="firstName" innerRef={register} />
              </InputGroup>
              {errors.firstName && <small className="text-danger ml-3">{errors.firstName.message}</small>}
            </FormGroup>
            <FormGroup className={`${errors.lastName && 'has-danger'}`}>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className={`${errors.lastName && 'text-red'} ni ni-circle-08`} />
                  </InputGroupText>
                </InputGroupAddon>
                <StrapInput placeholder="Sobrenome" type="text" name="lastName" innerRef={register} />
              </InputGroup>
              {errors.lastName && <small className="text-danger ml-3">{errors.lastName.message}</small>}
            </FormGroup>
            <FormGroup className={`${errors.email && 'has-danger'}`}>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className={`${errors.email && 'text-red'} ni ni-email-83`} />
                  </InputGroupText>
                </InputGroupAddon>
                <StrapInput placeholder="Email Pessoal" type="email" innerRef={register} name="email" />
              </InputGroup>
              {errors.email && <small className="text-danger ml-3">{errors.email.message}</small>}
            </FormGroup>
            <FormGroup className={`${errors.phone && 'has-danger'}`}>
              <InputGroup className="mb-1 phone-input">
                <PhoneInput country="br" value={phone} onChange={phone => setPhone(phone)} />
              </InputGroup>
              {errors.phone && <small className="text-danger ml-3">É necessário um telefone válido</small>}
            </FormGroup>
            <FormGroup className={`${errors.password && 'has-danger'}`}>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className={`${errors.password && 'text-red'} ni ni-lock-circle-open`} />
                  </InputGroupText>
                </InputGroupAddon>
                <StrapInput placeholder="Senha" type="password" innerRef={register} name="password" />
              </InputGroup>
              {errors.password && <small className="text-danger ml-3">{errors.password.message}</small>}
            </FormGroup>
            <FormGroup className={`${errors.confirmPassword && 'has-danger'}`}>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className={`${errors.confirmPassword && 'text-red'} ni ni-lock-circle-open`} />
                  </InputGroupText>
                </InputGroupAddon>
                <StrapInput placeholder="Confirmar Senha" type="password" innerRef={register} name="confirmPassword" />
              </InputGroup>
              {errors.confirmPassword && <small className="text-danger ml-3">{errors.confirmPassword.message}</small>}
            </FormGroup>
            <div className="terms-and-conditions">
              <small className="text-muted">
                Ao se cadastrar você automaticamente concorda com nossos{' '}
                <a
                  className="text-purple"
                  href="https://docs.profitfy.me/pt/article/termos-de-uso-1lhgptp/?_ga=2.131919692.2126003728.1636375991-2139385036.1632245431"
                  target="blank"
                >
                  Termos de Uso
                </a>
              </small>
            </div>
            <div className="text-center">
              <Button className="mt-4 btn-icon btn-2 sign-up-button" color="primary" type="submit">
                <span className="btn-inner--icon">
                  <i className="ni ni-send" />
                </span>
                <span className="btn-inner--text button-text">Cadastrar</span>
              </Button>
            </div>
            <Row className="mt-3 link-container">
              <Col xs="6">
                <Link className="" to="/auth/forgot-password">
                  <span>Esqueceu sua senha?</span>
                </Link>
              </Col>
              <Col className="text-right" xs="6">
                <Link className="" to="/auth/login">
                  <span>Entrar</span>
                </Link>
              </Col>
            </Row>
          </Form>
        )}
      </div>
      <div className="creative-container">
        <p className="text-description">
          Seus números são a interpretação da realidade do seu e-commerce e estamos aqui para descomplicar esse mundo
          para você!
        </p>
        <img
          className="image-description"
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/signup-image.svg"
          alt="Profitfy.me"
        />
      </div>
    </div>
  );
};

export default Register;
