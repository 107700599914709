import React, { memo, useMemo } from 'react';
import { Card } from 'reactstrap';
import { ResponsivePie } from '@nivo/pie';

import { numberBrFormatter } from '../../../../utils/utils';
import Tooltip from './Tooltip/Tooltip';

import './styles.scss';

const noData = [
  {
    id: 'Sem dados',
    label: 'Sem dados',
    value: 1,
    color: '#CACCCF',
  },
];

const CreditCard = ({ cards }) => {
  const total = useMemo(() => cards?.approved?.total + cards?.processing?.total + cards?.rejected?.total, [cards]);

  const data = useMemo(
    () => [
      {
        id: 'Aprovados',
        label: 'Aprovados',
        value: cards.approved.amount,
        color: '#003266',
      },
      {
        id: 'Em Análise',
        label: 'Em Análise',
        value: cards.processing.amount,
        color: '#007EFF',
      },
      {
        id: 'Recusados',
        label: 'Recusados',
        value: cards.rejected.amount,
        color: '#80bfff',
      },
    ],
    [cards],
  );

  const colors = ['#003266', '#007EFF', '#66B2FF'];

  const isCardsAmountDifferentThanZero =
    (cards.approved.amount || cards.processing.amount || cards.rejected.amount) !== 0;

  return (
    <Card className="credit-card-container">
      <div className="header">
        <span>CARTÕES</span>
      </div>

      <div className="body">
        <div className="legend">
          <div className="approved">
            <div>
              <span>R$</span>
              <span>
                {numberBrFormatter(cards.approved.amount, 2)} ({numberBrFormatter(cards.approved.total, 0)})
              </span>
            </div>

            <div>
              <div className="icon" />
              <span>Aprovados</span>
            </div>
          </div>

          <div className="in-analysis">
            <div>
              <span>R$</span>
              <span>
                {numberBrFormatter(cards.processing.amount, 2)} ({numberBrFormatter(cards.processing.total, 0)})
              </span>
            </div>

            <div>
              <div className="icon" />
              <span>Em análise</span>
            </div>
          </div>

          <div className="refused">
            <div>
              <span>R$</span>
              <span>
                {numberBrFormatter(cards.rejected.amount, 2)} ({numberBrFormatter(cards.rejected.total, 0)})
              </span>
            </div>

            <div>
              <div className="icon" />
              <span>Recusados</span>
            </div>
          </div>
        </div>

        <div className="chart">
          {isCardsAmountDifferentThanZero ? (
            <ResponsivePie
              data={data}
              colors={colors}
              innerRadius={0.7}
              enableRadialLabels={false}
              enableSlicesLabels={false}
              tooltip={({ id, value }) => <Tooltip id={id} value={value} />}
            />
          ) : (
            <ResponsivePie
              data={noData}
              colors={['#CACCCF']}
              innerRadius={0.7}
              enableRadialLabels={false}
              enableSlicesLabels={false}
              isInteractive={false}
            />
          )}
          <div className="main-info">
            <span>{total}</span>
            <span>Total</span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default memo(CreditCard);
