import React, { memo, useState, useCallback } from 'react';
import { Play, X } from 'phosphor-react';

import './styles.scss';

const Video = ({ thumbnail, videoUrl, title, duration, thumbnailAlt, isOpen, toggle }) => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const handleVideoModal = useCallback(() => setIsVideoOpen(!isVideoOpen), [isVideoOpen]);

  return (
    <>
      {isOpen && (
        <div className="video-helper-container">
          <div className="header">
            <div>
              <span className="duration">{duration}</span>
              <span className="title">{title}</span>
            </div>

            <div className="close-video" onClick={toggle} role="button" onKeyPress={() => {}} tabIndex={0}>
              <X size={12} weight="bold" />
            </div>
          </div>

          <div className="body" onClick={handleVideoModal} role="button" onKeyPress={() => {}} tabIndex={0}>
            <img src={thumbnail} alt={thumbnailAlt} />
            <div className="player-icon">
              <Play size={24} />
            </div>
          </div>
        </div>
      )}

      {isVideoOpen && (
        <div className="video-modal">
          <div className="background" onClick={handleVideoModal} role="button" onKeyPress={() => {}} tabIndex={0} />
          <div className="video-wrapper">
            <iframe
              width="768"
              height="432"
              src={videoUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      )}
    </>
  );
};

export default memo(Video);
