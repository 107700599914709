import React, { memo, useCallback, useState } from 'react';
import { Megaphone, CaretUp, CaretDown, MonitorPlay, Copy, CheckCircle } from 'phosphor-react';
import { Button, Tooltip } from 'reactstrap';
import { useParams } from 'react-router-dom';

import usePersistedState from '../../../hooks/PersistedState';
import { useFacebookMarketing } from '../../../hooks/FacebookMarketing';

import './styles.scss';

const AddCampaigns = ({ selectedStep, setSelectedStep }) => {
  const { profiles } = useFacebookMarketing();
  const [activatedCampaigns, setActivatedCampaigns] = usePersistedState('activatedCampaigns', false);
  const { productId } = useParams();

  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isVideoTooltipOpen, setIsVideoTooltipOpen] = useState(false);
  const [copyToClipboardText, setCopyToClipboardText] = useState('Copiado com sucesso!');

  const handleVideoTooltip = useCallback(() => setIsVideoTooltipOpen(!isVideoTooltipOpen), [isVideoTooltipOpen]);

  const handleTooltip = useCallback(() => setIsTooltipOpen(!isTooltipOpen), [isTooltipOpen]);

  const handleCopyToClipboard = useCallback(async () => {
    setIsCopiedToClipboard(true);
    setIsTooltipOpen(true);

    try {
      await navigator.clipboard.writeText(productId);

      setCopyToClipboardText('Copiado com sucesso!');
    } catch {
      setCopyToClipboardText('Sem permissão para copiar!');
    }

    setTimeout(() => {
      setIsTooltipOpen(false);
      setIsCopiedToClipboard(false);
    }, 3000);
  }, [productId]);

  const handleOpenClose = useCallback(() => {
    if (selectedStep !== 'add-campaigns' && profiles.length !== 0) {
      setSelectedStep('add-campaigns');

      return;
    }
    setSelectedStep('');
  }, [setSelectedStep, selectedStep, profiles.length]);

  const handleNextStep = useCallback(() => {
    setActivatedCampaigns(true);
    setSelectedStep('sync-campaigns');
  }, [setActivatedCampaigns, setSelectedStep]);

  return (
    <div className={`add-campaigns-wrapper ${activatedCampaigns && profiles.length !== 0 && 'step-done'}`}>
      <div className="facebook-icon">
        <Megaphone size={48} />
      </div>

      <div className="content">
        <div className="info" onClick={handleOpenClose} onKeyPress={() => {}} role="button" tabIndex={0}>
          <div className="title-description">
            <div className="header">
              <span>Adicionar ID nas campanhas</span>

              <div
                className="open-close-icon"
                onClick={handleOpenClose}
                onKeyPress={() => {}}
                role="button"
                tabIndex={0}
              >
                {activatedCampaigns && profiles.length !== 0 && (
                  <div className="completed">
                    <span>Concluído</span>
                  </div>
                )}
                {selectedStep === 'add-campaigns' ? <CaretUp size={24} /> : <CaretDown size={24} />}
              </div>
            </div>

            {selectedStep === 'add-campaigns' ? (
              <span>Para sincronizar as campanhas siga os seguintes passos:</span>
            ) : (
              <span>
                Segundo passo é <strong>adicionar o ID</strong> para atrelar suas campanhas.
              </span>
            )}
          </div>
        </div>

        {selectedStep === 'add-campaigns' && (
          <div className="description">
            <div className="watch-video" id="add-campaigns-tutorial-video">
              <MonitorPlay size={18} />
              <span>Assista a um video curto</span>
              <div className="video-duration-wrapper">
                <span>0:05</span>
              </div>
            </div>

            <Tooltip
              isOpen={isVideoTooltipOpen}
              toggle={handleVideoTooltip}
              placement="top"
              target="add-campaigns-tutorial-video"
              className="add-campaigns-tutorial-video"
              autohide={false}
            >
              <video
                src="https://profitfy-media.s3.us-west-2.amazonaws.com/videos-tuto/sincronizar-campanhas.mp4"
                controls
                autoPlay
                muted
              >
                <track kind="captions" />
              </video>
            </Tooltip>

            <div className="first-step">
              <span>
                1. Copie o código <strong>{productId}</strong>
              </span>
              <div id="copy-product-details-id">
                {isCopiedToClipboard ? (
                  <CheckCircle className="copied" size={24} />
                ) : (
                  <Copy size={24} onClick={handleCopyToClipboard} />
                )}
              </div>
              <Tooltip
                isOpen={isTooltipOpen}
                toggle={handleTooltip}
                placement="top"
                target="copy-product-details-id"
                trigger="click"
              >
                {copyToClipboardText}
              </Tooltip>
            </div>

            <span className="second-step">2. Edite o nome da campanha no facebook, e adicione o código no final</span>
            <span className="example-text">Ex. Minha campanha LT #1 - {productId}</span>

            <Button color="primary" className="connect-facebook-button" onClick={handleNextStep}>
              Continuar
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(AddCampaigns);
