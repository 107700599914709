import React, { useCallback, useState, useMemo, memo, useEffect, Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { DropdownMenu, UncontrolledDropdown, DropdownToggle, Navbar, Nav, Container, Button } from 'reactstrap';
import { SignOut, UserCircleGear, Storefront } from 'phosphor-react';

import SuggestionModal from '../SuggestionModal/SuggestionModal';
import { useAuth } from '../../hooks/User';
import { getPlatformImage } from '../../utils/platformImage';

import './styles.scss';

const DashboardNavbar = ({ brandText, stores, productTourLink }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { store: id, platform } = useParams();
  const { logout } = useAuth();
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filteredStores, setFilteredStores] = useState([]);

  const selectedStore = useMemo(() => stores.find(store => store.id === id), [stores, id]);

  const toggleSuggestionModal = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const handleChangeStore = useCallback(
    (id, platform) => {
      history.push(`/${platform}/${id}/dashboard/home`);

      setIsMenuOpen(false);
    },
    [history],
  );

  const handleNewStore = useCallback(() => {
    history.push('/stores');

    setIsMenuOpen(false);
  }, [history]);

  const handleProfile = useCallback(() => {
    history.push(`/${platform}/${id}/dashboard/user-profile`);

    setIsMenuOpen(false);
  }, [history, platform, id]);

  const handleIsMenuOpen = useCallback(() => setIsMenuOpen(!isMenuOpen), [isMenuOpen]);

  useEffect(() => {
    const filtered = stores.filter(store => selectedStore?.id !== store.id);

    setFilteredStores(filtered);
  }, [stores, selectedStore]);

  return (
    <>
      <SuggestionModal toggle={toggleSuggestionModal} open={isOpen} />

      <Navbar className="navbar-top shadow d-none d-md-inline-block py-2" expand="md" id="navbar-main">
        <Container fluid>
          <div className="d-flex flex-column">
            <p className="text-lg mb-0 text-gray text-uppercase d-none d-lg-inline-block">{brandText}</p>

            {productTourLink && (
              <a
                className="d-none d-lg-inline-block text-blue"
                href={`https://dashboard.profitfy.me/${platform}/${id}/dashboard/${productTourLink}`}
              >
                Refaça o tour clicando aqui
              </a>
            )}
          </div>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <Button className="btn-link text-default mr-0" onClick={toggleSuggestionModal} nav="true">
              <i className="fa fa-lightbulb-o" />
            </Button>

            <UncontrolledDropdown
              isOpen={isMenuOpen}
              toggle={handleIsMenuOpen}
              className="border-left gray-dark ml-3 mr--3 "
              nav
            >
              <DropdownToggle className="pr-2" nav>
                <span className="font-weight-bold">{selectedStore?.name}</span>
                <i className="ni ni-bold-down text-light-dark ml-2" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <span>Você está visualizando</span>
                <span>{selectedStore?.name}</span>
                <span>{selectedStore?.domain}</span>
                <span>Outras lojas:</span>

                {filteredStores.map(store => (
                  <Fragment key={store.id}>
                    <button
                      type="button"
                      className="navbar-other-store"
                      onClick={() => handleChangeStore(store.id, store.platform)}
                    >
                      <img src={getPlatformImage(store.platform)} alt={store.platform} />
                      <span>{store.name}</span>
                      <i className="fas fa-chevron-right" />
                    </button>
                    <hr />
                  </Fragment>
                ))}

                <button type="button" className="new-store" onClick={handleNewStore}>
                  <Storefront size={24} />
                  <span>Registrar nova loja</span>
                </button>
                <button type="button" className="profile" onClick={handleProfile}>
                  <UserCircleGear size={24} />
                  <span>Meu Perfil</span>
                </button>
                <button type="button" className="exit" onClick={logout}>
                  <SignOut size={24} />
                  <span>Sair</span>
                </button>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default memo(DashboardNavbar);
