import React, { memo } from 'react';
import { Card } from 'reactstrap';

const DashboardCard = ({ value, title, prepend }) => {
  return (
    <>
      <Card className="shadow">
        <div className="bg-gradient-primary card-line-top mb--1" />
        <div className="py-3 px-4">
          <span className="text-gray font-weight-bold">{title}</span>
          <div className="py-4">
            <span className="text-gray">{prepend} </span>
            <span className="h1 font-weight-bold">{value}</span>
          </div>
        </div>
      </Card>
    </>
  );
};

export default memo(DashboardCard);
