import React, { useState, useCallback, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';

import AddCreditCard from '../../AddCreditCard/AddCreditCard';
import { useAuth } from '../../../hooks/User';
import { api } from '../../../services/api';
import ChangePlanModal from './ChangePlanModal/ChangePlanModal';
import TrialSubscribe from './TrialSubscribe';
import PaidSubscribe from './PaidSubscribe';
import FreeSubscribe from './FreeSubscribe';
import CanceledSubscribe from './CanceledSubscribe';
import CancelationForm from '../../CancelationForm/CancelationForm';
import AppmaxSubscribe from './AppmaxSubscribe/AppmaxSubscribe';

import './styles.scss';

const ProfileSubscribe = () => {
  const { user, loadUser } = useAuth();
  const location = useLocation();

  const [isOpenCreditCard, setIsOpenCreditCard] = useState(false);
  const [isOpenPlans, setIsOpenPlans] = useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleCreditCard = React.useCallback(() => {
    setIsOpenCreditCard(!isOpenCreditCard);
  }, [isOpenCreditCard]);

  const togglePlans = useCallback(() => {
    setIsOpenPlans(!isOpenPlans);
  }, [isOpenPlans]);

  const toggleCancelModal = useCallback(() => {
    setIsOpenCancelModal(!isOpenCancelModal);
  }, [isOpenCancelModal]);

  const onSubmitPlan = async ({ planId, isFreePlan }) => {
    try {
      setLoading(true);

      if (isFreePlan) {
        await api('user-api').post(`api/v1/users/subscriptions/${user.subscription.id}/free-plan`);
      } else {
        await api('user-api').post('api/v1/users/subscriptions', {
          planId: String(planId),
          documentNumber: user.cpf,
        });
      }

      await Swal.fire({
        icon: 'success',
        title: 'Plano alterado com sucesso!',
        showConfirmButton: false,
        timer: 1500,
      });

      await loadUser();
    } finally {
      setLoading(false);
      togglePlans();
    }
  };

  const handleOpenTypeForm = useCallback(() => {
    toggleCancelModal();
  }, [toggleCancelModal]);

  const subscriptionStatusComponent = React.useCallback(
    status =>
      ({
        trialing: <TrialSubscribe />,
        free_plan_paid: (
          <FreeSubscribe
            handleOpenTypeForm={handleOpenTypeForm}
            toggleCreditCard={toggleCreditCard}
            togglePlans={togglePlans}
          />
        ),
        appmax_plan_paid: <AppmaxSubscribe toggleCreditCard={toggleCreditCard} togglePlans={togglePlans} />,
        canceled: <CanceledSubscribe toggleCreditCard={toggleCreditCard} togglePlans={togglePlans} />,
        free_plan_canceled: <CanceledSubscribe toggleCreditCard={toggleCreditCard} togglePlans={togglePlans} />,
        free_plan_payment_refused: (
          <FreeSubscribe
            handleOpenTypeForm={handleOpenTypeForm}
            toggleCreditCard={toggleCreditCard}
            togglePlans={togglePlans}
          />
        ),
      }[status] || (
        <PaidSubscribe
          handleOpenTypeForm={handleOpenTypeForm}
          toggleCreditCard={toggleCreditCard}
          togglePlans={togglePlans}
        />
      )),
    [handleOpenTypeForm, togglePlans, toggleCreditCard],
  );

  useEffect(() => {
    if (location?.pathname.includes('change-credit-card')) {
      setIsOpenCreditCard(true);
    }
  }, [location.pathname]);

  return (
    <>
      <AddCreditCard title="Atualizar Cartão" isOpen={isOpenCreditCard} toggle={toggleCreditCard} />
      <ChangePlanModal
        isOpenPlans={isOpenPlans}
        togglePlans={togglePlans}
        loading={loading}
        onSubmitPlan={onSubmitPlan}
      />

      <CancelationForm isOpen={isOpenCancelModal} toggle={toggleCancelModal} />
      {subscriptionStatusComponent(user.subscription.status)}
    </>
  );
};

export default ProfileSubscribe;
