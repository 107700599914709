import React, { useState } from 'react';

import FormPage from '../../../components/ForgotPassword/FormPage/FormPage';
import EmailSent from '../../../components/ForgotPassword/EmailSent/EmailSent';

const ForgotPassword = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);

  return <>{isEmailSent ? <EmailSent /> : <FormPage setIsEmailSent={setIsEmailSent} />}</>;
};

export default ForgotPassword;
