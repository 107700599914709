import axios from 'axios';

export const warningTracking = ({ warning, pendingPayment, externalId }) => {
  return axios.put(
    `https://api.customerx.com.br/api/v1/clients/?external_id_client=${externalId}`,
    {
      custom_attributes: [
        {
          name: 'pending_payment_warning',
          label: 'Aviso de Pagamento Pendente',
          value: pendingPayment ? 1 : 0,
        },
        {
          name: 'mercado_pago_warning',
          label: 'Aviso de Credencial Mercado Pago',
          value: warning.mercadoPagoCredential ? 1 : 0,
        },
        {
          name: 'pag_seguro_warning',
          label: 'Aviso de Credencial PagSeguro',
          value: warning.pagSeguroCredential ? 1 : 0,
        },
        {
          name: 'app_max_warning',
          label: 'Aviso de Credencial Appmax',
          value: warning.appMaxCredential ? 1 : 0,
        },
        {
          name: 'cielo_warning',
          label: 'Aviso de Credencial Cielo',
          value: warning.cieloCredential ? 1 : 0,
        },
        {
          name: 'cloud_fox_warning',
          label: 'Aviso de Credencial CloudFox',
          value: warning.cloudFoxCredential ? 1 : 0,
        },
        {
          name: 'doppus_warning',
          label: 'Aviso de Credencial Doppus',
          value: warning.hubSaleCredential ? 1 : 0,
        },
        {
          name: 'pagarme_warning',
          label: 'Aviso de Credencial Pagar.me',
          value: warning.pagarmeCredential ? 1 : 0,
        },
        {
          name: 'upnid_warning',
          label: 'Aviso de Credencial Upnid',
          value: warning.upnidCredential ? 1 : 0,
        },
        {
          name: 'facebook_warning',
          label: 'Aviso de Credencial Facebook',
          value: warning.facebookCredential ? 1 : 0,
        },
        {
          name: 'no_gateway_warning',
          label: 'Aviso de Sem Integração com Gateway',
          value: warning.noGatewayCredential ? 1 : 0,
        },
        {
          name: 'orders_with_no_products_warning',
          label: 'Aviso de Pedidos sem Produtos',
          value: warning.ordersWithNoProduct ? 1 : 0,
        },
      ],
    },
    {
      headers: {
        credential: process.env.REACT_APP_CUSTOMERX_CREDENTIAL,
        key: process.env.REACT_APP_CUSTOMERX_KEY,
        Authorization: process.env.REACT_APP_CUSTOMERX_API,
      },
    },
  );
};
