import React from 'react';

import './styles.scss';

const VideoModal = ({ isOpen, toggle, videoUrl }) => {
  return (
    <>
      {isOpen && (
        <>
          <div className="video-modal-container">
            <div className="background" onClick={toggle} role="button" onKeyPress={() => {}} tabIndex={0} />
            <div className="video-wrapper">
              <iframe
                width="768"
                height="432"
                src={videoUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VideoModal;
