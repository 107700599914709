import React, { useState, useCallback, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import pagarme from 'pagarme';
import TagManager from 'react-gtm-module';

import { useAuth } from '../../../hooks/User';
import { api } from '../../../services/api';
import { usePlans } from '../../../hooks/Plans';
import CreditCard from '../../../components/CreditCard/CreditCard';
import usePersistedState from '../../../hooks/PersistedState';

import './styles.scss';

const CreditCardPayment = () => {
  const { user } = useAuth();
  const { plan } = usePlans();
  const history = useHistory();
  const [affiliate] = usePersistedState('affiliate');

  const [isLoading, setIsLoading] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [cardData, setCardData] = useState({
    number: '4111111111111111',
    cvv: '202',
    holderName: 'Yung Buda',
    month: '12',
    year: '22',
  });

  const onChangeCard = useCallback(
    event => {
      const { name, value } = event.target;
      const parsedData = { [name]: value };

      setCardData({ ...cardData, ...parsedData });
    },
    [cardData],
  );

  const handleFlipCard = useCallback(() => setIsFlipped(!isFlipped), [isFlipped]);

  const handleSubmit = useCallback(async () => {
    const parsedCardDataToEncryption = {
      card_holder_name: cardData.holderName,
      card_expiration_date: `${cardData.month}${cardData.year}`,
      card_number: cardData.number,
      card_cvv: cardData.cvv,
    };

    const client = await pagarme.client.connect({
      encryption_key: process.env.REACT_APP_PAGARME_ENCRYPTION_KEY,
    });

    const cardHash = await client.security.encrypt(parsedCardDataToEncryption);

    const parsedCardData = {
      number: cardData.number,
      holderName: cardData.holderName,
      expirationDate: `${cardData.month}${cardData.year}`,
      cardHash,
    };

    try {
      setIsLoading(true);

      await api('user-api').post(`api/v1/users/cards`, parsedCardData);

      if (plan.isFreePlan) {
        await api('user-api').post(`api/v1/users/subscriptions/${user.subscription.id}/free-plan`);
      } else {
        await api('user-api').post('api/v1/users/subscriptions', {
          planId: String(plan.planId),
        });
      }

      TagManager.dataLayer({
        dataLayer: {
          event: 'subscription',
          var_plan: plan.isFreePlan ? 'On Demand' : plan.title,
          var_price: plan.isFreePlan ? undefined : plan.price / 100,
          affiliate,
        },
      });

      history.push('/subscribe/finish');
    } finally {
      setIsLoading(false);
    }
  }, [
    cardData.cvv,
    cardData.holderName,
    cardData.month,
    cardData.number,
    cardData.year,
    user.subscription.id,
    plan,
    history,
    affiliate,
  ]);

  useEffect(() => {
    if (!plan) {
      history.push('/subscribe/plans');
    } else if (!user?.address) {
      history.push('/subscription/personal-info');
    }
  }, [plan, history, user.address]);

  return (
    <div className="main-personal-info-container">
      <div className="content-container">
        <div className="content-header">
          <img
            className="profitfy-logo"
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/logo_v2.svg"
            alt="Profitfy.me"
          />

          <div className="step-container">
            <div className="bar-container">
              <div className="bar-progress" />
            </div>
            <span>3/3</span>
          </div>
        </div>
        <CreditCard
          isLoading={isLoading}
          cardData={cardData}
          isFlipped={false}
          handleFlipCard={handleFlipCard}
          onChangeCard={onChangeCard}
          onSubmit={handleSubmit}
        />
        <Link to="/subscribe/personal-info" className="prev-page">
          <i className="fas fa-arrow-left" />
        </Link>
      </div>

      <div className="creative-container">
        <div className="content-header">
          <img
            className="profitfy-logo"
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/logo_v2.svg"
            alt="Profitfy.me"
          />

          <div className="step-container">
            <div className="bar-container">
              <div className="bar-progress" />
            </div>
            <span>3/3</span>
          </div>
        </div>
        <h3>Resumo do Plano</h3>
        <div className="title-plan">
          <span>Nome do plano:</span>
          <span className="description-title">{plan.title}</span>
        </div>

        <div className="description-plan">
          <span>Descrição:</span>
          <span className="description">
            {plan.isFreePlan ? '+R$4,99 a cada R$1.000 em pedidos pagos, cobrado semanalmente' : `${plan.description}`}
          </span>
        </div>

        <img
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/Subscribe/subscribre-personal-info.svg"
          alt="Profitfy.me personal info"
          className="image-svg"
        />
      </div>
    </div>
  );
};

export default CreditCardPayment;
