import React, { useState, useCallback, memo } from 'react';
import { Card, Button } from 'reactstrap';
import moment from 'moment-timezone';

import CustomSpendsModal from '../Modal/Modal';
import CustomSpendsWarn from '../../../../components/CustomSpendsWarn/CustomSpendsWarn';

import './styles.scss';

const NoCustomSpends = ({ customSpends, setCustomSpends }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCustomSpendsWarnModalOpen, setIsCustomSpendsWarnModalOpen] = useState(false);

  const handleIsModalOpen = useCallback(() => setIsModalOpen(!isModalOpen), [isModalOpen]);

  const handleIsCustomSpendsWarnModalOpen = useCallback(
    () => setIsCustomSpendsWarnModalOpen(!isCustomSpendsWarnModalOpen),
    [isCustomSpendsWarnModalOpen],
  );

  return (
    <>
      <CustomSpendsWarn isOpen={isCustomSpendsWarnModalOpen} toggle={handleIsCustomSpendsWarnModalOpen} />

      <CustomSpendsModal
        toggle={handleIsModalOpen}
        isOpen={isModalOpen}
        customSpends={customSpends}
        setCustomSpends={setCustomSpends}
        customSpend={{
          id: null,
          description: null,
          amount: null,
          startDate: moment(),
          active: true,
          period: null,
        }}
        title="Insira um novo custo adicional"
        handleIsWarningModalOpen={handleIsModalOpen}
      />
      <Card className="no-custom-spends-container">
        <div>
          <img
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/svgs/custom-spends.svg"
            alt="Profitfy No Custom Spends"
          />
          <h2>Cadastrar valores adicionais</h2>
          <p>
            Lance custos/despesas adicionais, como softwares/app, influencers, plano do shopify, taxas, salários, etc.
          </p>
          <Button color="primary" onClick={handleIsModalOpen}>
            Cadastrar valor adicional
          </Button>
        </div>
      </Card>
    </>
  );
};

export default memo(NoCustomSpends);
