import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Tutorials from '../../../components/Home/Tutorials/Tutorials';
import VideosMentoring from '../../../components/Home/VideosMentoring/VideosMentoring';
import ProcessingDataModal from '../../../components/ProcessingDataModal/ProcessingDataModal';
import RefusedPaymentAlert from '../../../components/RefusedPaymentAlert/RefusedPaymentAlert';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';

import './styles.scss';

const smallDevice = window.matchMedia('(max-width: 1279px)').matches;

const Home = () => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();

  const [isOpen, setIsOpen] = useState(true);

  const isNewStore = useCallback(() => {
    return location.pathname.includes('first-time');
  }, [location]);

  const handleToggle = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (smallDevice) {
      history.push('shop-analytics');
    }
  }, [history]);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        email: user.email,
        userId: user.id,
        identifier: 'pageview_home',
        type: 'screen',
      });
    }
  }, [user]);

  return (
    <div className="pt-5 pt-md-8 home-container">
      {isNewStore() && <ProcessingDataModal isOpen={isOpen} toggle={handleToggle} />}
      {user?.subscription?.status === 'unpaid' && <RefusedPaymentAlert />}
      <div className="social-media-wrapper">
        <a href="https://www.facebook.com/groups/profitfy" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook-square" />
        </a>
        <a href="https://www.instagram.com/profitfy.me" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram" />
        </a>
      </div>
      <div className="videos-mentoring">
        <VideosMentoring />
      </div>
      <div className="tutorials">
        <Tutorials />
      </div>
    </div>
  );
};

export default Home;
