import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment-timezone';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

import { useAuth } from '../../hooks/User';
import CalendarSchedule from '../CalendarSchedule/CalendarSchedule';
import { getContact, hasRefusedOnboarding } from '../../utils/customerx';
import usePersistedState from '../../hooks/PersistedState';

import './styles.scss';

const OnboardingModal = () => {
  const { user } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [customerXClient, setCustomerXClient] = useState();
  const [rejectScheduleOnboardingDate, setRejectScheduleOnboardingDate] = usePersistedState(
    'rejectScheduleOnboardingDate',
    undefined,
  );
  const [scheduleOnboardingRejectCount, setScheduleOnboardingRejectCount] = usePersistedState(
    'scheduleOnboardingRejectCount',
    0,
  );

  const handleIsOpen = useCallback(() => {
    if (isOpen) {
      setRejectScheduleOnboardingDate(moment().format('DD/MM/YYYY'));
    }

    if (isOpen && scheduleOnboardingRejectCount === 2) {
      hasRefusedOnboarding({ userId: user.id });
    }

    setScheduleOnboardingRejectCount(scheduleOnboardingRejectCount + 1);

    setIsOpen(!isOpen);
  }, [isOpen, setRejectScheduleOnboardingDate, scheduleOnboardingRejectCount, setScheduleOnboardingRejectCount, user]);

  const handleIsCalendarOpen = useCallback(() => {
    setIsCalendarOpen(!isCalendarOpen);
  }, [isCalendarOpen]);

  const onCancelClick = useCallback(() => {
    handleIsOpen();
  }, [handleIsOpen]);

  const onScheduleClick = useCallback(() => {
    handleIsOpen();

    handleIsCalendarOpen();
  }, [handleIsCalendarOpen, handleIsOpen]);

  const loadCustomerXClient = useCallback(async () => {
    const { data } = await getContact({ userId: user.id });

    if (data.length) {
      setCustomerXClient(data.pop());
    }
  }, [user]);

  useEffect(() => {
    loadCustomerXClient();
  }, [loadCustomerXClient]);

  useEffect(() => {
    if (customerXClient) {
      const foundTag = customerXClient.tags.find(tag => tag.description === 'Onboarding');

      const foundCustomAttribute = customerXClient.custom_attributes.find(
        attribute => attribute.name === 'onboarding_agendado',
      );

      const foundRefusedOnboardingAttribute = customerXClient.custom_attributes.find(
        attribute => attribute.name === 'onboarding_recusado',
      );

      if (foundTag && !foundCustomAttribute) {
        if (
          rejectScheduleOnboardingDate &&
          moment(rejectScheduleOnboardingDate).isBefore(moment().format('DD/MM/YYYY')) &&
          !foundRefusedOnboardingAttribute?.value
        ) {
          setIsOpen(true);
        }

        if (!rejectScheduleOnboardingDate) {
          setIsOpen(true);
        }
      }
    }
  }, [customerXClient, rejectScheduleOnboardingDate]);

  return (
    <>
      <CalendarSchedule isOpen={isCalendarOpen} toggle={handleIsCalendarOpen} />

      <Modal className="onboarding-modal" isOpen={isOpen} toggle={handleIsOpen} centered>
        <ModalHeader className="onboarding-modal-header">Benefício exclusivo!</ModalHeader>

        <ModalBody className="onboarding-modal-body">
          <span className="welcome-text">Olá, {user?.firstName}! Seja muito bem-vindo(a).</span>

          <span className="william-text">Como diria William Edwards Deming,</span>
          <span className="quote-text">&quot;Sem dados, você é somente mais uma pessoa com uma opinião&quot;.</span>
          <span className="first-benefit-text">
            Contudo, de nada adianta ter dados se você não sabe o que fazer com eles.
          </span>
          <span className="second-benefit-text">
            E por conta do benefício de seu plano,{' '}
            <strong>você tem o direito de receber uma reunião de 20 minutos comigo</strong>, Bruna Moares, sua gerente
            de conta.
          </span>

          <span className="to-do-text">O que faremos nessa reunião:</span>

          <ul className="to-do-list">
            <li className="config-benefit">
              <strong>Vamos configurar juntos</strong> o seu Dashboard da maneira correta.
            </li>
            <li className="extract-into">
              Vou te ensinar a extrair o máximo da ferramenta para que você{' '}
              <strong>receba o real valor do seu investimento.</strong>
            </li>
            <li className="tips">
              Você também <strong>receberá dicas estratégicas</strong> para tomar decisões mais assertivas em sua
              operação
            </li>
          </ul>

          <span className="schedule-text">
            Tudo o que você precisa fazer é clicar no botão abaixo e marcar um café online comigo. Vamos nessa?
          </span>

          <div className="modal-footer">
            <div>
              <img
                src="https://profitfy-media.s3.us-west-2.amazonaws.com/avatar/bruna.png"
                alt="Profitfy.me Customer Success"
              />

              <div className="customer-success-info">
                <span>Bruna Moraes</span>
                <span>Customer Success</span>
              </div>
            </div>

            <div className="buttons-wrapper">
              <Button onClick={onCancelClick} className="cancel-button">
                Não quero o benefício
              </Button>
              <Button onClick={onScheduleClick} className="schedule-button" color="primary">
                Agendar reunião
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OnboardingModal;
