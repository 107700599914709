import React, { useState, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

import { useAppmax } from '../../hooks/Appmax';

import './styles.scss';

const AppmaxTermsOfUsage = ({ isOpen, toggle }) => {
  const { verifyAppmax, isValidatingAppmax } = useAppmax();

  const [hasReachedBottom, setHasReachedBottom] = useState(false);

  const handleScroll = useCallback(e => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      setHasReachedBottom(true);
    } else {
      setHasReachedBottom(false);
    }
  }, []);

  const onSubmit = useCallback(() => {
    verifyAppmax(toggle);
  }, [toggle, verifyAppmax]);

  return (
    <Modal className="appmax-terms-of-usage-container" isOpen={isOpen} size="lg" centered fade>
      <ModalHeader className="header">
        <img src="https://s3-us-west-2.amazonaws.com/profitfy.me/images/icon.svg" alt="Profitfy.me Icon" />
        <div className="title">
          <span>Termo de Adesão à Campanha “Appmax Paga seu Plano na Profitfy”</span>
        </div>
        <div />
      </ModalHeader>
      <ModalBody className="body">
        <div className="terms-of-usage-content" onScroll={handleScroll}>
          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <span>1. OBJETO</span>
              </div>
              <div className="content">
                <p>
                  <strong>1.1.</strong> O objeto deste termo é a adesão à campanha{' '}
                  <strong>“Appmax Paga seu Plano na Profitfy”</strong>, de iniciativa da empresa Appmax Plataforma de
                  Vendas LTDA, em parceria com a empresa Profitfy Tecnologia LTDA. No qual a Appmax oferece, em caráter
                  promocional e temporário, para clientes indicados pela Profitfy, o custeio do plano mensal em que o
                  cliente indicado estiver enquadrado plataforma Profitfy - a saber, quaisquer dos seguintes planos:
                  Lite, Starter, Essentials, Premium, Pro, Pro II, Pro III e Pro IV – enquanto essas plataformas
                  dispuserem do serviço prestado e enquanto o cliente indicado for usuário, simultaneamente, de ambas as
                  plataformas, Appmax e Profitfy.
                </p>
                <p>
                  <strong>1.2.</strong> O motivo da campanha é a amplificação da participação de mercado de ambas as
                  plataformas, mediante adesão e uso simultâneos de suas plataformas por clientes indicados.
                </p>
              </div>
            </div>
          </div>

          <div className="terms-of-usage-row">
            <div className="full-text">
              <div className="text-title">
                <span>2. REGULAMENTO</span>
              </div>
              <div className="content">
                <p>
                  <strong>2.1. Critérios de Elegibilidade</strong>
                </p>
                <p>
                  <span className="bullet-point" /> A campanha é válida para clientes indicados pela Profitfy que (i)
                  estejam inativos na Appmax há pelo menos 3 meses ou (ii) que ainda não sejam usuários da plataforma
                  Appmax.
                </p>
                <p>
                  <span className="bullet-point" /> Têm direito ao benefício clientes que tenham, nos últimos 30 dias,
                  <strong>
                    faturamento mensal igual ou superior a R$ 5.000,00 (cinco mil reais.) via cartão de crédito, dentro
                    da plataforma Appmax, não incluindo vendas processadas em outras plataformas.
                  </strong>
                </p>
                <p>
                  <span className="bullet-point" /> Valem para o cálculo os pedidos compensados, não sendo
                  contabilizados chargebacks, devoluções, estornos ou pedidos não confirmados.
                </p>
                <p>
                  <span className="bullet-point" /> A promoção é válida somente para usuários que fizerem o seu cadastro
                  na página oficial da promoção ou nos demais meios e materiais de divulgação oficiais desta promoção,
                  por meio do link:{' '}
                  <a href="https://appmax.com.br/cadastro?i=10269" target="_blank" rel="noopener noreferrer">
                    https://appmax.com.br/cadastro?i=10269
                  </a>
                  ;
                </p>
                <p>
                  <strong>2.2. Validade da Promoção</strong>
                </p>
                <p>
                  A campanha tem validade da zero hora do dia 22 de novembro de 2021 até as 23h e 59min do dia 31 de
                  dezembro de 2021. Após esse período, novos clientes da Appmax indicados pela Profitfy não terão o
                  benefício objeto desta campanha.
                </p>
              </div>
            </div>
          </div>

          <div className="content-footer">
            <p>São Paulo, 22 de novembro de 2021</p>
            <p>
              <strong>Profitfy Tecnologia LTDA - 37365788000140</strong>
            </p>
          </div>
        </div>

        <Button onClick={onSubmit} color="primary" disabled={!hasReachedBottom || isValidatingAppmax}>
          Li e concordo com os termos
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default AppmaxTermsOfUsage;
