import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';

import dsersIcon from '../../../../assets/img/theme/dsers.png';
import ConfirmScreen from './ConfirmScreen/ConfirmScreen';
import DsersLogin from './DsersLogin/DsersLogin';
import useQuery from '../../../../hooks/Query';
import { useDsersCredentials } from '../../../../hooks/Dsers';
import { api, shopsApi } from '../../../../services/api';
import { useToast } from '../../../../hooks/Toast';
import { useAuth } from '../../../../hooks/User';
import { updateCustomerXTracking } from '../../../../utils/customerXTracking';

const SyncDsers = ({ checked, onChange, disabled }) => {
  const query = useQuery();
  const { store, platform } = useParams();
  const { showToast } = useToast();
  const { user } = useAuth();

  const syncOrders = useMemo(() => query.get('syncOrders'), [query]);
  const syncOrdersStartDate = useMemo(() => query.get('syncOrdersStartDate'), [query]);
  const syncOrdersEndDate = useMemo(() => query.get('syncOrdersEndDate'), [query]);

  const getEndDate = useCallback(() => {
    if (!syncOrdersEndDate) return moment();

    if (moment(syncOrdersEndDate).diff(moment(syncOrdersStartDate), 'days') > 14) {
      const endDate = moment(syncOrdersStartDate).add(14, 'days');

      return endDate;
    }

    return moment(syncOrdersEndDate);
  }, [syncOrdersEndDate, syncOrdersStartDate]);

  const [isOpen, setIsOpen] = useState(false);
  const [dsersCredential, setDsersCredential] = useState({ expired: false });
  const [period, setPeriod] = useState({
    startDate: syncOrdersStartDate ? moment(syncOrdersStartDate) : moment(),
    endDate: getEndDate(),
  });
  const [isLoadingSyncOrders, setIsLoadingSyncOrders] = useState(false);

  const { dsersCredentialsData, isLoadingDsersCredentials } = useDsersCredentials({ store, platform });

  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const syncDsersOrders = useCallback(
    async ({ startDate, endDate }) => {
      setIsLoadingSyncOrders(true);

      try {
        await api(shopsApi[platform]).post(`/api/v1/users/${platform}/stores/${store}/orders/dsers/sync`, {
          startDate,
          endDate,
        });

        showToast({
          message: 'Vamos enviar um email assim que tudo estiver pronto!',
          type: 'success',
        });

        toggle();

        if (user) {
          updateCustomerXTracking({ email: user.email, userId: user.id, identifier: 'has_synced_dsers' });
        }
      } catch (error) {
        const { message } = error?.data;

        setDsersCredential(previousState => ({ ...previousState, expired: true }));

        if (message === 'Seu token expirou, por favor refaça seu login!') {
          showToast({
            message: 'Sua credencial do Dsers está expirada! Por favor, logue novamente.',
            type: 'error',
          });
        }

        if (message === 'Cadastre uma credencial para poder sincronizar com o Dsers!') {
          showToast({
            message: 'Entre na sua conta do Dsers para poder sincronizar os pedidos.',
            type: 'error',
          });
        }
      } finally {
        setIsLoadingSyncOrders(false);
      }
    },
    [platform, store, showToast, toggle, user],
  );

  const handleDsersCredential = useCallback(
    async ({ email, password }) => {
      try {
        const requestType = dsersCredential?.id ? 'put' : 'post';

        const { data } = await api(shopsApi[platform])[requestType](
          `api/v1/users/${platform}/stores/${store}/dsers-credentials/${dsersCredential?.id || ''}`,
          {
            email,
            password,
          },
        );

        setDsersCredential(data?.dsersCredential);

        showToast({ message: 'Login efetuado com sucesso!', type: 'success' });

        await syncDsersOrders({ startDate: period.startDate, endDate: period.endDate });
      } catch {
        showToast({ message: 'Ocorreu um problema ao efetuar o login. Por favor, tente novamente.', type: 'error' });
      }
    },
    [dsersCredential.id, platform, showToast, store, period, syncDsersOrders],
  );

  useEffect(() => {
    if (dsersCredentialsData) {
      setDsersCredential(previousState => ({ ...previousState, ...dsersCredentialsData.dsersCredential }));
    }
  }, [dsersCredentialsData]);

  useEffect(() => {
    if (syncOrders) {
      setIsOpen(true);
    }
  }, [syncOrders]);

  return (
    <>
      <Modal centered fade isOpen={isOpen} toggle={toggle} size={dsersCredential.expired ? 'lg' : 'md'}>
        <ModalHeader toggle={toggle}>Sincronizar custo dos produtos</ModalHeader>
        <ModalBody className="mx-3 my-3 bg-1 text-center">
          {dsersCredential.expired || !dsersCredential ? (
            <DsersLogin handleDsersCredential={handleDsersCredential} />
          ) : (
            <ConfirmScreen
              syncDsersOrders={syncDsersOrders}
              period={period}
              setPeriod={setPeriod}
              isLoadingSyncOrders={isLoadingSyncOrders}
              isLoadingDsersCredentials={isLoadingDsersCredentials}
              checked={checked}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        </ModalBody>
      </Modal>
      <Button className="text-right text-default btn-inner--icon py-2 " color="white" onClick={toggle}>
        Sincronizar custos com Dsers
        <img src={dsersIcon} alt="Dsers Profitfy" width="20px" className="ml-2" />
      </Button>
    </>
  );
};

export default SyncDsers;
