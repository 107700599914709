import React, { memo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FacebookLogo, CaretUp, CaretDown } from 'phosphor-react';
import { Button } from 'reactstrap';

import './styles.scss';

const ConnectFacebook = ({ selectedStep, setSelectedStep, userStep }) => {
  const history = useHistory();
  const { productId, store, platform } = useParams();

  const handleConnectFacebook = useCallback(() => {
    history.push(`/${platform}/${store}/dashboard/integrations/facebook?productId=${productId}`);
  }, [history, platform, store, productId]);

  const handleOpenClose = useCallback(() => {
    if (selectedStep !== 'connect-facebook') {
      setSelectedStep('connect-facebook');

      return;
    }
    setSelectedStep('');
  }, [setSelectedStep, selectedStep]);

  return (
    <div className={`connect-facebook-wrapper ${userStep !== 'connect-facebook' && 'step-done'}`}>
      <div className="facebook-icon">
        <FacebookLogo size={48} />
      </div>

      <div className="content">
        <div className="info" onClick={handleOpenClose} onKeyPress={() => {}} role="button" tabIndex={0}>
          <div className="title-description">
            <div className="header">
              <span>Conectar ao Facebook</span>

              <div
                className="open-close-icon"
                onClick={handleOpenClose}
                onKeyPress={() => {}}
                role="button"
                tabIndex={0}
              >
                {userStep !== 'connect-facebook' && (
                  <div className="connected">
                    <span>Conectado</span>
                  </div>
                )}
                {selectedStep === 'connect-facebook' ? <CaretUp size={24} /> : <CaretDown size={24} />}
              </div>
            </div>
            <span>Faça a integração com a nossa plataforma</span>
          </div>
        </div>

        {selectedStep === 'connect-facebook' && (
          <div className="description">
            <p>
              Primeiramente você precisa <strong>conectar um perfil do Facebook</strong>. Clique no botão abaixo e você
              será redirecionado a tela para adicionar sua conta.
            </p>
            <Button color="primary" className="connect-facebook-button" onClick={handleConnectFacebook}>
              Conectar com Facebook
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ConnectFacebook);
