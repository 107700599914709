import React, { useState, memo, useEffect, useCallback } from 'react';

import ConnectFacebook from '../ConnectFacebook/ConnectFacebook';
import AddCampaigns from '../AddCampaigns/AddCampaigns';
import SyncCampaigns from '../SyncCampaigns/SyncCampaigns';
import { useFacebookMarketing } from '../../../hooks/FacebookMarketing';
import usePersistedState from '../../../hooks/PersistedState';
import SyncingModal from '../SyncingModal/SyncingModal';
import Spinner from '../../Spinner/Spinner';

import './styles.scss';

const MarketingOnboarding = ({
  loadCampaigns,
  isLoadingCampaigns,
  setHasCompletedCampaignsOnboarding,
  hasCompletedCampaignsOnboarding,
  campaigns,
  handleActivatedCampaignModal,
}) => {
  const { profiles } = useFacebookMarketing();
  const [activatedCampaigns, setActivatedCampaigns] = usePersistedState('activatedCampaigns', false);

  const [selectedStep, setSelectedStep] = useState('connect-facebook');
  const [userStep, setUserStep] = useState('connect-facebook');
  const [isSyncingModalOpen, setIsSyncingModalOpen] = useState(false);

  const handleSyncingModal = useCallback(() => setIsSyncingModalOpen(!isSyncingModalOpen), [isSyncingModalOpen]);

  useEffect(() => {
    if (profiles.length === 0) {
      setUserStep('connect-facebook');
      setSelectedStep('connect-facebook');

      return;
    }

    if (!activatedCampaigns) {
      setUserStep('add-campaigns');
      setSelectedStep('add-campaigns');

      return;
    }

    setUserStep('sync-campaigns');
    setSelectedStep('sync-campaigns');
  }, [activatedCampaigns, profiles.length, setActivatedCampaigns]);

  if (isLoadingCampaigns) {
    return <Spinner loading />;
  }

  return (
    <>
      <SyncingModal isOpen={isSyncingModalOpen} toggle={handleSyncingModal} />

      <div className="campaigns-onboarding-container">
        <div className="header">
          <span>Gerenciar Campanhas</span>
          <span>Atribua campanhas à um produto</span>
        </div>

        <div className="body">
          <ConnectFacebook selectedStep={selectedStep} userStep={userStep} setSelectedStep={setSelectedStep} />

          <div className={`step-connection ${userStep !== 'connect-facebook' && 'completed'}`} />

          <AddCampaigns selectedStep={selectedStep} userStep={userStep} setSelectedStep={setSelectedStep} />

          <div className={`step-connection ${userStep === 'sync-campaigns' && 'completed'}`} />

          <SyncCampaigns
            selectedStep={selectedStep}
            userStep={userStep}
            setSelectedStep={setSelectedStep}
            handleSyncingModal={handleSyncingModal}
            loadCampaigns={loadCampaigns}
            setHasCompletedCampaignsOnboarding={setHasCompletedCampaignsOnboarding}
            hasCompletedCampaignsOnboarding={hasCompletedCampaignsOnboarding}
            campaigns={campaigns}
            handleActivatedCampaignModal={handleActivatedCampaignModal}
          />
        </div>
      </div>
    </>
  );
};

export default memo(MarketingOnboarding);
