import { api } from '../services/api';

const newStore = {
  shopify: storeData => {
    return api('urgot-shopify-api').post(`/api/v1/users/shopify/stores/?shop=${storeData.storeName}`);
  },

  cartx: storeData => {
    return api('ornn-cartx-api').post(`/api/v1/users/cartx/stores`, {
      token: storeData.token,
      shop: storeData.storeName,
    });
  },
};

export { newStore };
