import React, { memo, useCallback } from 'react';
import { ArrowLeft } from 'phosphor-react';
import { useHistory, useParams } from 'react-router-dom';

import './styles.scss';

const Header = ({ handleUnsavedModalOpen, productData, hasChangedData }) => {
  const { product } = productData;
  const history = useHistory();
  const { platform, store } = useParams();

  const goBack = useCallback(() => {
    if (hasChangedData) {
      handleUnsavedModalOpen();

      return;
    }

    history.push(`/${platform}/${store}/dashboard/config/products-costs`);
  }, [history, platform, store, handleUnsavedModalOpen, hasChangedData]);

  return (
    <button className="product-cost-variant-header" type="button" onClick={goBack}>
      <ArrowLeft size={32} />

      <h2>{product.title}</h2>
    </button>
  );
};

export default memo(Header);
