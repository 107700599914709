import React, { memo, useRef, useState, useCallback } from 'react';

import Revenue from '../CardDetails/Revenue/Revenue';
import ProductCost from '../CardDetails/ProductCost/ProductCost';
import Marketing from '../CardDetails/Marketing/Marketing';
import TransactionTaxes from '../CardDetails/TransactionTaxes/TransactionTaxes';
import Profit from '../CardDetails/Profit/Profit';
import CardInfo from '../CardInfo/CardInfo';
import FunnelChart from '../FunnelChart/FunnelChart';
import Card from '../Card/Card';
import Boleto from '../Boleto/Boleto';
import Pix from '../Pix/Pix';

import './styles.scss';

const transactionTaxes = {
  gateway: 0,
  checkout: 0,
  tax: 0,
};

const customSpends = {
  appIn: 0,
  costIn: 0,
  employeeIn: 0,
  noneIn: 0,
  appOut: 0,
  costOut: 0,
  employeeOut: 0,
  noneOut: 0,
};

const DetailsPage = ({ product, profit, facebookInsights, setPage, campaigns, storePeriod }) => {
  const sliderRef = useRef(null);
  const sliderPaymentCardsRef = useRef(null);

  const [isDetailsMouseMoveOn, setIsDetailsMouseMoveOn] = useState(false);
  const [onDetailsMouseDownEvent, setOnDetailsMouseDownEvent] = useState({});
  const [detailsScrollLeft, setDetailsScrollLeft] = useState(0);
  const [isPaymentCardsMouseMoveOn, setIsPaymentCardsMouseMoveOn] = useState(false);
  const [onPaymentCardsMouseDownEvent, setOnPaymentCardsMouseDownEvent] = useState(false);
  const [paymentCardsScrollLeft, setPaymentCardsScrollLeft] = useState(0);

  const handleOnDetailsMouseDown = useCallback(event => {
    setIsDetailsMouseMoveOn(true);
    setOnDetailsMouseDownEvent(event);
  }, []);

  const handleOnDetailsMouseUp = useCallback(() => {
    setIsDetailsMouseMoveOn(false);
    setDetailsScrollLeft(sliderRef.current.scrollLeft);
    setOnDetailsMouseDownEvent({});
  }, []);

  const handleOnDetailsMouseMove = useCallback(
    event => {
      event.persist();

      if (isDetailsMouseMoveOn && sliderRef) {
        const position = onDetailsMouseDownEvent.pageX - event.clientX;

        sliderRef.current.scrollLeft = detailsScrollLeft + position;
      }
    },
    [isDetailsMouseMoveOn, detailsScrollLeft, sliderRef, onDetailsMouseDownEvent],
  );

  const handlePaymentCardsMouseDown = useCallback(event => {
    setIsPaymentCardsMouseMoveOn(true);
    setOnPaymentCardsMouseDownEvent(event);
  }, []);

  const handleOnPaymentCardsMouseUp = useCallback(() => {
    setIsPaymentCardsMouseMoveOn(false);
    setPaymentCardsScrollLeft(sliderPaymentCardsRef.current.scrollLeft);
    setOnPaymentCardsMouseDownEvent({});
  }, []);

  const handleOnPaymentCardsMouseMove = useCallback(
    event => {
      event.persist();

      if (isPaymentCardsMouseMoveOn && sliderPaymentCardsRef) {
        const position = onPaymentCardsMouseDownEvent.pageX - event.clientX;

        sliderPaymentCardsRef.current.scrollLeft = paymentCardsScrollLeft + position;
      }
    },
    [isPaymentCardsMouseMoveOn, paymentCardsScrollLeft, sliderPaymentCardsRef, onPaymentCardsMouseDownEvent],
  );

  return (
    <div className="product-details-details">
      <div
        className="cards-details-wrapper"
        role="row"
        tabIndex={0}
        ref={sliderRef}
        onMouseDown={handleOnDetailsMouseDown}
        onMouseUp={handleOnDetailsMouseUp}
        onMouseMove={handleOnDetailsMouseMove}
      >
        <Revenue profit={profit} storePeriod={storePeriod} />
        <ProductCost profit={profit} />
        <Marketing profit={profit} mutateStoreData={() => {}} setPage={setPage} campaigns={campaigns} />
        <TransactionTaxes profit={profit} transactionTaxes={transactionTaxes} />
        <Profit
          profit={profit}
          storeConfigurationData={{}}
          customSpends={customSpends}
          mutateStoreData={() => {}}
          storePeriod={storePeriod}
        />
      </div>

      <div className="info-cards-wrapper">
        <div className="info-and-funnel">
          <CardInfo product={product} metrics={profit.metrics} />
          <FunnelChart facebookInsights={facebookInsights} />
        </div>

        <div
          className="cards-boletos-and-pix"
          role="row"
          tabIndex={0}
          ref={sliderPaymentCardsRef}
          onMouseDown={handlePaymentCardsMouseDown}
          onMouseUp={handleOnPaymentCardsMouseUp}
          onMouseMove={handleOnPaymentCardsMouseMove}
        >
          <Card cards={profit.cards} />
          <Boleto billets={profit.billets} storePeriod={storePeriod} />
          <Pix bankTransfers={profit.bankTransfers} />
        </div>
      </div>
    </div>
  );
};

export default memo(DetailsPage);
