import Swal from 'sweetalert2';
import { logout } from './auth';

const stages = {
  prod: 'prod',
  dev: 'dev',
  qa: 'qa',
};

const stage = stages[process.env.REACT_APP_STAGE] || 'dev';

const errorHandler = err => {
  const error = err?.response?.data;

  if (error?.plan || error?.subscription || error?.upgradePlan) {
    if (error?.upgradePlan) {
      Swal.fire({
        icon: 'info',
        title: error.message,
        showConfirmButton: true,
      }).then(() => {
        window.location.replace(`/stores/user-profile`);
      });

      return Promise.reject(err.response);
    }

    if (error?.plan && !error?.isNewUser) {
      Swal.fire({
        icon: 'info',
        title: 'Você precisa atualizar seu plano!',
        showConfirmButton: true,
      }).then(() => {
        window.location.replace('/subscribe/plans');
      });

      return Promise.reject(err.response);
    }

    if (error?.plan && error?.isNewUser) {
      Swal.fire({
        icon: 'info',
        title: 'Selecione um plano para continuar',
        text: error.message,
        showConfirmButton: true,
      }).then(() => {
        window.location.replace('/subscribe/plans');
      });

      return Promise.reject(err.response);
    }

    if (error?.subscription) {
      Swal.fire({
        icon: 'error',
        title: 'Oops... Houve um Erro!',
        text: error?.message,
        showConfirmButton: true,
      });
      return Promise.reject(err.response);
    }
  }

  if (err.message === 'Network Error') {
    if (stage !== 'dev') {
      logout();

      window.location.href = 'https://dashboard.profitfy.me';
    }
  }

  if (error?.message === 'Não existe uma loja com esse id') {
    return Promise.reject(err.response);
  }

  if (error?.message === 'Seu token expirou, por favor refaça seu login!') {
    return Promise.reject(err.response);
  }

  if (error?.message === 'Cadastre uma credencial para poder sincronizar com o Dsers!') {
    return Promise.reject(err.response);
  }

  Swal.fire({
    icon: 'error',
    title: 'Oops... Houve um Erro!',
    text: error?.message,
    showConfirmButton: true,
  });

  return Promise.reject();
};

export default errorHandler;
