import axios from 'axios';

export const sendNPSToCustomerX = async ({ nps, feedback, user }) => {
  await axios.post(
    'https://api.customerx.com.br/api/v1/net_promoter_scores',
    {
      response: nps,
      note: feedback,
      date_response: new Date().toISOString(),
      date_send: new Date().toISOString(),
      user,
    },
    {
      headers: {
        credential: process.env.REACT_APP_CUSTOMERX_CREDENTIAL,
        key: process.env.REACT_APP_CUSTOMERX_KEY,
        Authorization: process.env.REACT_APP_CUSTOMERX_API,
      },
    },
  );
};

export const sendCustomAttributesToCustomerX = async ({ data, externalId }) => {
  if (data?.ces_ap_2) {
    await axios.put(
      `https://api.customerx.com.br/api/v1/clients/?external_id_client=${externalId}`,
      {
        custom_attributes: [
          {
            name: 'ces_ap_2',
            label: '(CES) Análise de Produto 2.0',
            value: data?.ces_ap_2,
          },
          {
            name: 'ces_ap_2_feedback',
            label: '(CES) Análise de Produto 2.0 Feedback',
            value: data?.feedback,
          },
        ],
      },
      {
        headers: {
          credential: process.env.REACT_APP_CUSTOMERX_CREDENTIAL,
          key: process.env.REACT_APP_CUSTOMERX_KEY,
          Authorization: process.env.REACT_APP_CUSTOMERX_API,
        },
      },
    );
  }
};
