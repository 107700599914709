import React, { createContext, useContext, useState } from 'react';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';

import { useFetch } from './Fetch';
import { shopsApi } from '../services/api';

const ProductDetailsContext = createContext();

export const useProductCampaigns = () => {
  const { platform, store, productId } = useParams();

  const limit = 10;
  const page = 1;

  const { data, error, mutate } = useFetch({
    url: `api/v1/users/${platform}/stores/${store}/products/${productId}/facebook/campaigns?page=${page}&limit=${limit}`,
    apiName: shopsApi[platform],
  });

  return {
    mutate,
    campaignsData: data,
    isLoadingInitialCampaigns: !error && !data,
    isError: error,
  };
};

export const useProductDetail = ({ productId, startDate, endDate }) => {
  const { platform, store } = useParams();

  const { data, error, mutate } = useFetch({
    url: `/api/v1/users/${platform}/stores/${store}/products/${productId}/profit?startDate=${startDate.format(
      'YYYY-MM-DD',
    )}&endDate=${endDate.format('YYYY-MM-DD')}`,
    apiName: shopsApi[platform],
  });

  return {
    mutate,
    productDetailData: data,
    isLoadingProductDetail: !error && !data,
    isError: error,
  };
};

export const useFetcher = ({ startDate, endDate }) => {
  const { platform, store, productId } = useParams();

  const { data, error, mutate } = useFetch({
    url: `/api/v1/users/${platform}/stores/${store}/products/${productId}/profit?startDate=${startDate.format(
      'YYYY-MM-DD',
    )}&endDate=${endDate.format('YYYY-MM-DD')}`,
    apiName: shopsApi[platform],
  });

  return {
    mutate,
    productDetails: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const ProductDetails = ({ children }) => {
  const [period, setPeriod] = useState({ startDate: moment(), endDate: moment() });

  return (
    <ProductDetailsContext.Provider value={{ period, setPeriod, useFetcher }}>
      {children}
    </ProductDetailsContext.Provider>
  );
};

export const useProductDetails = () => {
  const context = useContext(ProductDetailsContext);
  if (!context) {
    throw new Error('useProductDetails must be used within a ProductDetailsProvider');
  }

  return context;
};
