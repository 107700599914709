import React, { memo } from 'react';
import { Button } from 'reactstrap';

import './styles.scss';

const SaveChanges = ({ handleSaveChanges }) => {
  return (
    <div className="product-cost-save-edit-product">
      <Button onClick={handleSaveChanges} color="primary">
        Salvar alterações
      </Button>
    </div>
  );
};

export default memo(SaveChanges);
