import React, { useMemo, useState, useCallback, memo } from 'react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Wrench, PlayCircle } from 'phosphor-react';

import SidebarMobile from './SidebarMobile/SidebarMobile';
import routes from '../../routes/menuRoutes';
import icon from '../../assets/img/theme/icon.svg';
import logoWhite from '../../assets/img/brand/logo-white.svg';
import './styles.scss';

import '../../assets/scss/sidebar-override.scss';

const smallDevice = window.matchMedia('(max-width: 1279px)').matches;

const Sidebar = ({ stores, logo }) => {
  const { store, platform } = useParams();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();

  const getModuleName = useCallback(() => location.pathname.split('dashboard/').pop(), [location]);

  const handlerExpand = useCallback(
    value => () => {
      setExpanded(value);
    },
    [],
  );

  const handleOnSelect = useCallback(
    selected => {
      const to = `/${selected}`;
      if (location.pathname !== to) {
        history.push(to);
      }
    },
    [history, location.pathname],
  );

  const secondNavItems = useMemo(() => {
    return routes.map(prop => {
      if (prop.layout === '/dashboard/config') {
        const Icon = prop.icon;

        return (
          <NavItem className="sidebar-icon" key={prop.path} eventKey={`${platform}/${store}${prop.layout}${prop.path}`}>
            <NavIcon>
              <Icon size={24} />
            </NavIcon>
            <NavText> {prop.name}</NavText>
          </NavItem>
        );
      }
      return [];
    });
  }, [platform, store]);

  const firstNavItems = useMemo(() => {
    return routes.map(prop => {
      if (prop.layout === '/dashboard' && !(smallDevice && prop.path === '/home')) {
        const Icon = prop.icon;

        return (
          <NavItem className="sidebar-icon" key={prop.path} eventKey={`${platform}/${store}${prop.layout}${prop.path}`}>
            <NavIcon>
              <div className="custom-sidebar-icon">
                <Icon size={24} />

                {prop.path === '/product-analytics' && (
                  <div className="pulse-animation-wrapper">
                    <div className="circle" />
                    <div className="pulse" />
                    <div className="pulse-2" />
                  </div>
                )}
              </div>
            </NavIcon>
            <NavText>
              <div className="custom-sidebar-text">
                {prop.name}

                {prop.path === '/product-analytics' && (
                  <div className="new-tag">
                    <span>NOVO</span>
                  </div>
                )}
              </div>
            </NavText>
          </NavItem>
        );
      }
      return [];
    });
  }, [platform, store]);

  return (
    <>
      <SideNav
        onMouseLeave={handlerExpand(false)}
        onMouseEnter={handlerExpand(true)}
        expanded={expanded}
        onToggle={handlerExpand(true)}
        className="d-none d-md-block sidebar-body"
        onSelect={handleOnSelect}
      >
        <SideNav.Toggle />
        <div className="logo">
          <img src={icon} alt="profitfy" />
          <img src={logoWhite} alt="profitfy" />
        </div>
        <SideNav.Nav defaultSelected={`${platform}/${store}/dashboard/${getModuleName()}`}>
          {firstNavItems}
          {secondNavItems}
          <NavItem className="sidebar-icon" eventKey="tools">
            <NavIcon>
              <Wrench size={24} />
            </NavIcon>
            <NavText>Ferramentas</NavText>

            <NavItem
              eventKey={`${platform}/${store}/dashboard/tools/pricing-tool`}
              active={location.pathname === `/${platform}/${store}/dashboard/tools/pricing-tool`}
            >
              <NavText>Precificação</NavText>
            </NavItem>
            <NavItem
              eventKey={`${platform}/${store}/dashboard/tools/audience-insights`}
              active={location.pathname === `/${platform}/${store}/dashboard/tools/audience-insights`}
            >
              <NavText>Audience Explorer</NavText>
            </NavItem>
          </NavItem>
          <NavItem
            className="sidebar-icon"
            eventKey={`${platform}/${store}/dashboard/videos`}
            active={location.pathname === `/${platform}/${store}/dashboard/videos`}
          >
            <NavIcon>
              <PlayCircle size={24} />
            </NavIcon>
            <NavText>Mentoria</NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
      <SidebarMobile routes={routes} stores={stores} logo={logo} />
    </>
  );
};

export default memo(Sidebar);
