import React, { memo, useCallback } from 'react';
import { Package } from 'phosphor-react';
import { Button } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';

import { numberBrFormatter } from '../../../../utils/utils';

import './styles.scss';

const ProductCost = ({ mainCards, storePeriod, ordersToSync }) => {
  const history = useHistory();
  const { platform } = useParams();

  const handleEditCost = useCallback(() => {
    history.push('config/products-costs');
  }, [history]);

  const handleSyncOrders = useCallback(() => {
    history.push(
      `orders?syncOrders=true&syncOrdersStartDate=${storePeriod.startDate}&syncOrdersEndDate=${storePeriod.endDate}`,
    );
  }, [history, storePeriod]);

  return (
    <div className="card-details-product-cost">
      <div className="header">
        <span>CUSTO DOS PRODUTOS</span>
        <div>
          <Package size={16} weight="bold" />
        </div>
      </div>
      <div className="body">
        <div className="total-wrapper">
          <span>TOTAL</span>
          <span>
            <small>R$</small>
            {numberBrFormatter(mainCards.cogs, 2)}
          </span>
        </div>

        <hr />

        {ordersToSync > 0 && platform !== 'cartx' ? (
          <div className="orders-to-sync">
            <span>
              Você possui <strong>{`(${ordersToSync}) pedidos`}</strong> que podem ser sincronizados pelo AliExpress!
            </span>

            <Button onClick={handleSyncOrders}>Sincronizar pedidos</Button>
          </div>
        ) : (
          <div className="content">
            <div className="text">
              <span>Seus custos estão com valores incorretos?</span>
              <a
                href="https://docs.profitfy.me/pt-BR/articles/5043264-sobre-o-funcionamento-da-sincronizacao-com-o-aliexpress-e-dsers"
                target="blank"
              >
                <span>Veja como sincronizar os custos com Aliexpress</span>
              </a>
            </div>

            <Button onClick={handleEditCost}>Editar Custos</Button>
          </div>
        )}
      </div>

      <div className="gradient-border-detail">
        <div />
      </div>
    </div>
  );
};

export default memo(ProductCost);
