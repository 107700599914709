import React, { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';

import VariantRow from '../VariantRow/VariantRow';

import './styles.scss';

const VariantTable = ({
  variants,
  allVariantsCost,
  setHasChangedData,
  editedVariants,
  setEditedVariants,
  showDeletedVariants,
  userCurrency,
}) => {
  const [variantsToShow, setVariantsToShow] = useState([]);

  useEffect(() => {
    if (!showDeletedVariants) {
      const filteredVariants = variants.filter(variant => !variant.deleted);

      setVariantsToShow(filteredVariants);

      return;
    }

    setVariantsToShow(variants);
  }, [showDeletedVariants, variants]);

  return (
    <Table className="product-cost-variants-table" responsive>
      <thead className="table-header">
        <tr>
          <th />
          <th>
            <span>NOME</span>
          </th>
          <th>
            <span>PREÇO</span>
          </th>
          <th>
            <span>CUSTO DA VARIANTE</span>
          </th>
          <th />
        </tr>
      </thead>

      <tbody className="table-body">
        {variantsToShow.map(variant => (
          <VariantRow
            key={variant.id}
            variant={variant}
            allVariantsCost={allVariantsCost}
            setHasChangedData={setHasChangedData}
            editedVariants={editedVariants}
            setEditedVariants={setEditedVariants}
            id={variant.id}
            userCurrency={userCurrency}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default memo(VariantTable);
