import React, { useState, useCallback } from 'react';
import { Card, Input } from 'reactstrap';
import { ResponsiveBar } from '@nivo/bar';
import { useParams } from 'react-router-dom';
import { ResponsiveLine } from '@nivo/line';
import { parseBarData, parseLineData, parseTooltipData } from '../../../../utils/chartUtils';
import { numberBrFormatter } from '../../../../utils/utils';
import { chartBarOptions, chartLineOptions } from './ChartOptions';
import ChartTooltip from './ChartTooltip/ChartTooltip';
import { useFetch } from '../../../../hooks/Fetch';
import { shopsApi } from '../../../../services/api';
import Spinner from '../../../../components/Spinner/Spinner';
import './styles.scss';

const Chart = ({ chartPeriod }) => {
  const { platform, store } = useParams();
  const [chart, setChart] = useState('bar');

  const { data } = useFetch({
    url: `/api/v1/users/${platform}/stores/${store}/profit/chart/main?period=${chartPeriod}`,
    apiName: shopsApi[platform],
  });

  const handleChartSelect = useCallback(chart => setChart(chart), []);

  const axisYTick = useCallback(tick => {
    return (
      <g transform={`translate(${tick.x - 22},${tick.y}) rotate(-45)`}>
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fill: '#333',
            fontSize: 10,
          }}
        >
          {`R$ ${numberBrFormatter(tick.value, 0)}`}
        </text>
      </g>
    );
  }, []);

  return (
    <Card className="chart-card">
      <div className="d-flex justify-content-between chart-header">
        <div>
          <h3>GRÁFICO DE PERFORMANCE</h3>
          <p>Veja como está sua evolução</p>
        </div>
        <div>
          <Input
            className="input-group-alternative fa"
            type="select"
            name="chart"
            id="chart"
            defaultValue="bar"
            onChange={e => handleChartSelect(e.target.value)}
          >
            <option value="bar">&#xf080; Gráfico de Barra</option>
            <option value="line">&#xf201; Gráfico de Linha</option>
          </Input>
        </div>
      </div>
      <hr />
      {!data ? (
        <Spinner loading={data?.chart} />
      ) : (
        <div className="chart-content">
          {chart === 'bar' ? (
            <ResponsiveBar
              data={parseBarData(data?.chart)}
              {...chartBarOptions}
              axisLeft={{
                renderTick: axisYTick,
              }}
              tooltip={({ data: { notParsedDate } }) => (
                <ChartTooltip data={parseTooltipData(data?.chart, notParsedDate)} />
              )}
            />
          ) : (
            <ResponsiveLine
              data={parseLineData(data?.chart)}
              {...chartLineOptions}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -45,
                renderTick: axisYTick,
              }}
              tooltip={({
                point: {
                  data: { notParsedDate },
                },
              }) => <ChartTooltip data={parseTooltipData(data?.chart, notParsedDate)} />}
            />
          )}
        </div>
      )}
    </Card>
  );
};

export default Chart;
