import { useFetch } from './Fetch';
import { shopsApi } from '../services/api';

export const useDsersCredentials = ({ store, platform }) => {
  const { data, error, mutate } = useFetch({
    url: `/api/v1/users/${platform}/stores/${store}/dsers-credentials`,
    apiName: shopsApi[platform],
  });

  return {
    mutate,
    dsersCredentialsData: data,
    isLoadingDsersCredentials: !error && !data,
    isError: error,
  };
};
