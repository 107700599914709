import React, { memo } from 'react';
import { Megaphone } from 'phosphor-react';
import { Button } from 'reactstrap';

import { numberBrFormatter } from '../../../../utils/utils';

import './styles.scss';

const Marketing = ({ profit, setPage, campaigns }) => {
  return (
    <div className="product-details-marketing">
      <div className="header">
        <span>MARKETING</span>
        <div>
          <Megaphone size={16} weight="bold" />
        </div>
      </div>

      <div className="body">
        {campaigns.length === 0 ? (
          <div className="no-campaigns">
            <span>Parece que você não possui nenhuma campanha</span>
            <span>Verifique suas campanhas</span>

            <Button onClick={() => setPage('campaigns')}>Ativar Campanhas</Button>
          </div>
        ) : (
          <div className="value-details">
            <div className="total-wrapper">
              <span>TOTAL</span>
              <span>
                <small>R$</small>
                {numberBrFormatter(profit.mainCards.marketing, 2)}
              </span>
            </div>

            <hr />

            <Button onClick={() => setPage('campaigns')}>Campanhas</Button>
          </div>
        )}
      </div>

      <div className="gradient-border-detail">
        <div />
      </div>
    </div>
  );
};

export default memo(Marketing);
