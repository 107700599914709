const getStatusColor = status => {
  return (
    {
      paid: 'primary',
      authorized: 'primary',
      refunded: 'danger',
      waiting_payment: 'warning',
      pending_refund: 'warning',
      refused: 'danger',
      chargedback: 'danger',
      analyzing: 'warning',
      pending_review: 'warning',
    }[status] || 'darker'
  );
};

const getFreePlanStatusColor = status => {
  return (
    {
      paid: 'primary',
      authorized: 'primary',
      refunded: 'danger',
      waiting_payment: 'warning',
      pending_refund: 'warning',
      refused: 'danger',
      chargedback: 'danger',
      analyzing: 'warning',
      pending_review: 'warning',
    }[status] || 'darker'
  );
};

const parseFreePlanStatusName = status => {
  return (
    {
      paid: 'Pago',
      authorized: 'Autorizado',
      refunded: 'Estornado',
      waiting_payment: 'Aguardando Pagamento',
      pending_refund: 'Estorno Pendente',
      refused: 'Recusado',
      chargedback: 'Chargeback',
      analyzing: 'Em análise',
      pending_review: 'Análise Pendente',
    }[status] || status
  );
};

const parseStatusName = status => {
  return (
    {
      paid: 'Pago',
      authorized: 'Autorizado',
      refunded: 'Estornado',
      waiting_payment: 'Aguardando Pagamento',
      pending_refund: 'Estorno Pendente',
      refused: 'Recusado',
      chargedback: 'Chargeback',
      analyzing: 'Em análise',
      pending_review: 'Análise Pendente',
    }[status] || status
  );
};

export { parseStatusName, getStatusColor, getFreePlanStatusColor, parseFreePlanStatusName };
