import React, { useState, memo, useCallback, useRef, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { Check, Megaphone, Package, CurrencyDollarSimple, ChartBarHorizontal, ShoppingCart } from 'phosphor-react';

import Spinner from '../../../components/Spinner/Spinner';
import {
  useShopAnalytics,
  useStoreConfiguration,
  useTopProduct,
  usePaymentTransaction,
} from '../../../hooks/ShopAnalytics';
import Chart from './Chart/Chart';
import CardInfo from './CardInfo/CardInfo';
import ProcessingDataModal from '../../../components/ProcessingDataModal/ProcessingDataModal';
import RevenueCard from '../../../components/ShopAnalytics/CardDetails/Revenue/Revenue';
import ProductCostCard from '../../../components/ShopAnalytics/CardDetails/ProductCost/ProductCost';
import MarketingCard from '../../../components/ShopAnalytics/CardDetails/Marketing/Marketing';
import TransactionTaxesCard from '../../../components/ShopAnalytics/CardDetails/TransactionTaxes/TransactionTaxes';
import ProfitCard from '../../../components/ShopAnalytics/CardDetails/Profit/Profit';
import NewDatePicker from '../../../components/NewDatePicker/NewDatePicker';
import TopProduct from '../../../components/TopProduct/TopProduct';
import PaymentMethodConversion from './PaymentMethodConversion/PaymentMethodConversion';
import CardOverview from '../../../components/ShopAnalytics/CardOverview/CardOverview';
import usePersistedState from '../../../hooks/PersistedState';
import CreditCard from './CreditCard/CreditCard';
import Billet from './Billet/Billet';
import Pix from './Pix/Pix';
import { api, shopsApi } from '../../../services/api';
import getWarnAlerts from '../../../utils/warns/warns';
import { useAuth } from '../../../hooks/User';
import RefusedPaymentAlert from '../../../components/RefusedPaymentAlert/RefusedPaymentAlert';
import useQuery from '../../../hooks/Query';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';

import './styles.scss';
import { warningTracking } from '../../../utils/warns/customerXTracking';

const ShopAnalytics = () => {
  const lineChartPeriod = 6;

  const [showDetails, setShowDetails] = usePersistedState('showDetails', true);
  const { platform, store } = useParams();
  const { user } = useAuth();
  const query = useQuery();

  const firstTime = useMemo(() => query.get('firstTime'), [query]);

  const [storePeriod, setStorePeriod] = useState({ startDate: moment(), endDate: moment() });
  const [isOpen, setIsOpen] = useState(true);
  const [isMouseMoveOn, setIsMouseMoveOn] = useState(false);
  const [onMouseDownEvent, setOnMouseDownEvent] = useState({});
  const [scrollLeft, setScrollLeft] = useState(0);
  const [selectedDate, setSelectedDate] = useState('today');
  const [isCreatingStoreConfiguration, setIsCreatingStoreConfiguration] = useState(false);
  const [storeConfiguration, setStoreConfiguration] = useState(false);
  const [pendingPayment, setPendingPayment] = useState();

  const sliderRef = useRef(null);

  const {
    storeData,
    isLoadingShopAnalytics,
    mutate: mutateStoreData,
  } = useShopAnalytics({
    startDate: storePeriod.startDate,
    endDate: storePeriod.endDate,
  });

  const {
    storeConfigurationData,
    isLoadingStoreConfiguration,
    isError: isStoreConfigurationError,
  } = useStoreConfiguration();

  const { topProductsData, isLoadingTopProducts } = useTopProduct({
    startDate: storePeriod.startDate,
    endDate: storePeriod.endDate,
  });

  const { isLoadingPaymentTransactions, paymentTransactionData } = usePaymentTransaction({ user });

  const handleToggle = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOnMouseDown = useCallback(event => {
    setIsMouseMoveOn(true);
    setOnMouseDownEvent(event);
  }, []);

  const handleOnMouseUp = useCallback(() => {
    setIsMouseMoveOn(false);
    setScrollLeft(sliderRef.current.scrollLeft);
    setOnMouseDownEvent({});
  }, []);

  const createStoreConfiguration = useCallback(async () => {
    setIsCreatingStoreConfiguration(true);

    try {
      const { data } = await api(shopsApi[platform]).post(
        `/api/v1/users/${platform}/stores/${store}/store-configurations`,
        {
          updateAliexpressCostToVariant: false,
          addCustomSpendInProfit: true,
        },
      );

      setStoreConfiguration(data);
    } finally {
      setIsCreatingStoreConfiguration(false);
    }
  }, [platform, store]);

  const handleOnMouseMove = useCallback(
    event => {
      event.persist();

      if (isMouseMoveOn && sliderRef) {
        const position = onMouseDownEvent.pageX - event.clientX;

        sliderRef.current.scrollLeft = scrollLeft + position;
      }
    },
    [isMouseMoveOn, scrollLeft, sliderRef, onMouseDownEvent],
  );

  const onDate = useCallback(({ startDate, endDate }) => {
    if (!startDate || !endDate) return;

    if (!startDate.isValid() || !endDate.isValid()) return;

    setStorePeriod({ startDate, endDate });
  }, []);

  const handleShowDetails = useCallback(() => setShowDetails(!showDetails), [showDetails, setShowDetails]);

  useEffect(() => {
    if (!isStoreConfigurationError && !isLoadingStoreConfiguration && !storeConfigurationData?.storeConfiguration) {
      createStoreConfiguration();
    }
  }, [createStoreConfiguration, isStoreConfigurationError, isLoadingStoreConfiguration, storeConfigurationData]);

  useEffect(() => {
    setStoreConfiguration(storeConfigurationData);
  }, [storeConfigurationData]);

  useEffect(() => {
    if (paymentTransactionData?.length) {
      const lastTransaction = paymentTransactionData[0];

      if (lastTransaction?.status === 'refused') setPendingPayment(lastTransaction);
    }
  }, [paymentTransactionData]);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        email: user.email,
        userId: user.id,
        identifier: 'pageview_shop_analytics',
        type: 'screen',
      });
    }
  }, [user]);

  useEffect(() => {
    if (storeData?.warning) {
      warningTracking({ warning: storeData.warning, pendingPayment, externalId: user?.id });
    }
  }, [pendingPayment, storeData, user]);

  if (
    isLoadingShopAnalytics ||
    !storeData ||
    isLoadingTopProducts ||
    isLoadingStoreConfiguration ||
    isCreatingStoreConfiguration ||
    isLoadingPaymentTransactions
  ) {
    return <Spinner />;
  }

  const { profit } = storeData;

  return (
    <>
      {firstTime && <ProcessingDataModal isOpen={isOpen} toggle={handleToggle} />}
      <div className="shop-analytics-container">
        <div>
          {user?.subscription?.status === 'unpaid' && <RefusedPaymentAlert />}
          {getWarnAlerts(storeData?.warning, pendingPayment).map(Component => (
            <Component key={Component} id={storeData.warning.id} store={storeData.store} />
          ))}
          <div className="datepicker">
            <button type="button" onClick={handleShowDetails}>
              <Check size={20} /> {showDetails ? 'OCULTAR DETALHES' : 'MOSTRAR DETALHES'}
            </button>

            <NewDatePicker
              startDate={storePeriod.startDate}
              endDate={storePeriod.endDate}
              onDate={onDate}
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
            />
          </div>

          {showDetails ? (
            <>
              <div
                role="row"
                onMouseDown={handleOnMouseDown}
                onMouseUp={handleOnMouseUp}
                onMouseMove={handleOnMouseMove}
                ref={sliderRef}
                tabIndex={0}
                className="card-details"
              >
                <RevenueCard mainCards={profit?.mainCards} storePeriod={storePeriod} />
                <ProductCostCard
                  mainCards={profit?.mainCards}
                  ordersToSync={profit?.ordersToSync?.total}
                  storePeriod={storePeriod}
                />
                <MarketingCard mainCards={profit?.mainCards} mutateStoreData={mutateStoreData} />
                <TransactionTaxesCard mainCards={profit?.mainCards} transactionTaxes={profit?.transactionTaxes} />
                <ProfitCard
                  mainCards={profit?.mainCards}
                  storeConfigurationData={storeConfiguration}
                  customSpends={profit?.customSpends}
                  mutateStoreData={mutateStoreData}
                  storePeriod={storePeriod}
                  refunds={profit?.refunds}
                />
              </div>
            </>
          ) : (
            <div className="overview-cards">
              <CardOverview
                title="FATURAMENTO"
                icon={ChartBarHorizontal}
                value={profit?.mainCards.revenue}
                gradientColor="yellow"
                storeConfigurationData={storeConfigurationData}
              />
              <CardOverview
                title="CUSTO DOS PRODUTOS"
                icon={Package}
                value={profit?.mainCards.cogs}
                gradientColor="orange"
                storeConfigurationData={storeConfigurationData}
              />
              <CardOverview
                title="MARKETING"
                icon={Megaphone}
                value={profit?.mainCards.marketing}
                gradientColor="purple"
                storeConfigurationData={storeConfigurationData}
              />
              <CardOverview
                title="TAXA DE TRANSAÇÕES"
                icon={ShoppingCart}
                value={profit?.mainCards.transactionTaxes}
                gradientColor="blue"
                storeConfigurationData={storeConfigurationData}
              />
              <CardOverview
                title="LUCRO LÍQUIDO"
                icon={CurrencyDollarSimple}
                value={profit?.mainCards.profit}
                gradientColor="green"
                storeConfigurationData={storeConfigurationData}
                mutateStoreData={mutateStoreData}
              />
            </div>
          )}

          <div className="chart-and-top-products">
            <Chart chartPeriod={lineChartPeriod} />

            <div className="top-products">
              {topProductsData?.map((product, index) => (
                <TopProduct ranking={index + 1} product={product} key={product.id} />
              ))}
            </div>
          </div>

          <div className="info-and-payment-conversion">
            <div>
              <CardInfo metrics={profit?.metrics} />
            </div>
            <div>
              <PaymentMethodConversion billetsCards={profit?.billetsCardsChart} />
            </div>
          </div>

          <div className="card-billets-pix">
            <CreditCard cards={profit?.cards} />
            <Billet billets={profit?.billets} storePeriod={storePeriod} />
            <Pix bankTransfers={profit?.bankTransfers} />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ShopAnalytics);
