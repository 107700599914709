import React, { useCallback } from 'react';
import { Label, InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import './styles.scss';

const ShopifyInput = ({ setStoreData }) => {
  const handleInputData = useCallback(
    shopifyProperty => {
      setStoreData(storeData => ({
        ...storeData,
        ...shopifyProperty,
      }));
    },
    [setStoreData],
  );

  return (
    <>
      <Label className="form-control-label">Digite o nome da sua loja</Label>
      <InputGroup className="input-group-alternative">
        <Input onChange={e => handleInputData({ storeName: e.target.value })} />
        <InputGroupAddon addonType="append">
          <InputGroupText>.myshopify.com</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </>
  );
};

export default ShopifyInput;
