import React, { memo } from 'react';
import { Table } from 'reactstrap';

import Spinner from '../../../Spinner/Spinner';
import ProductRow from '../ProductRow/ProductRow';

import './styles.scss';

const Body = ({ setSelectedProduct, isLoadingProducts, products }) => {
  if (isLoadingProducts) {
    return <Spinner loading />;
  }

  return (
    <Table className="edit-product-cost-table" responsive>
      <thead className="table-header">
        <tr>
          <th />
          <th>
            <span>NOME</span>
          </th>
          <th>
            <span>MOEDA</span>
          </th>
          <th>
            <span>TAXA DE IOF</span>
          </th>
          <th>
            <span>AÇÕES</span>
          </th>
        </tr>
      </thead>

      <tbody className="table-body">
        {products.map(product => (
          <ProductRow key={product.id} product={product} setSelectedProduct={setSelectedProduct} />
        ))}
      </tbody>
    </Table>
  );
};

export default memo(Body);
