import React from 'react';
import { Col, Row } from 'reactstrap';
import { features, features2 } from './featuresText';

const Features = ({ selectedPlan }) => {
  return (
    <>
      <h2>Benefícios do Plano</h2>
      <Row className=" align-content-center mt-2">
        <Col className="text-left">
          {selectedPlan >= 3 && (
            <div className="d-flex align-items-center mt-2">
              <i className="text-green ni ni-check-bold mr-2" /> <p className="mb-0"> Onboarding Personalizado</p>
            </div>
          )}
          {features.map(el => (
            <div key={el} className="d-flex align-items-center mt-2">
              <i className="text-green ni ni-check-bold mr-2" /> <p className="mb-0"> {el}</p>
            </div>
          ))}
        </Col>
        <Col className="text-left">
          {features2.map(el => {
            return (
              <div key={el} className="d-flex align-items-center mt-2">
                <i className="text-green ni ni-check-bold mr-2" /> <p className="mb-0"> {el}</p>
              </div>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

export default Features;
