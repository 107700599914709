import React, { memo, useCallback, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import processingPastDataSvg from '../../assets/img/theme/processing-past-data.svg';
import { getProcessingTips } from '../../utils/processingTips';
import lightbulbIcon from '../../assets/img/icons/common/lightbulb-icon.svg';

import './styles.scss';

const FADE = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
    },
  },
  exit: {
    opacity: 0,
  },
};

const ProcessingData = () => {
  const [tipIndex, setTipIndex] = useState(0);

  const changeTip = useCallback(() => {
    setInterval(() => {
      setTipIndex(previousState => {
        if (previousState === 5) return 0;

        return previousState + 1;
      });
    }, 10000);
  }, []);

  const getTipComponent = useCallback(
    () => (
      <motion.div key={tipIndex} variants={FADE} initial="hidden" animate="visible" exit="exit">
        <img src={lightbulbIcon} alt="Profitfy light bulb icon" />
        <span>{getProcessingTips(tipIndex)}</span>
      </motion.div>
    ),
    [tipIndex],
  );

  useEffect(() => {
    changeTip();
  }, [changeTip]);

  return (
    <div className="calculating-past-two-days-orders">
      <img src={processingPastDataSvg} alt="Profitfy.me processando dados" />
      <h1>Estamos preparando seus dados!</h1>

      <AnimatePresence exitBeforeEnter>{getTipComponent()}</AnimatePresence>
    </div>
  );
};

export default memo(ProcessingData);
