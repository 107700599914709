import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import TopProducts from './TopProducts/TopProducts';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';
import './styles.scss';

const ProductAnalytics = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_product_analytics',
        type: 'screen',
      });
    }
  }, [user]);

  return (
    <Container fluid className="pb-8 pt-0 pt-md-8">
      <TopProducts />
    </Container>
  );
};

export default ProductAnalytics;
