import React, { memo } from 'react';
import { PlayCircle } from 'phosphor-react';

import './styles.scss';

const VideosMentoring = () => {
  return (
    <div className="main-home-videos-mentoring-container">
      <h4>CONHECENDO A PROFITFY!</h4>
      <p>Aqui você vai encontrar os principais vídeos para mostrar as funcionalidades da nossa plataforma!</p>
      <div className="videos-container">
        <a className="video" href="https://www.youtube.com/embed/TG-QrEk2_vQ" target="_blank" rel="noreferrer noopener">
          <img
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/Thumbnails/thumbnail-profitfy.me2-min.png"
            alt="Profitfy.me"
          />
          <PlayCircle className="play-video-icon" size={64} />
        </a>

        <a className="video" href="https://www.youtube.com/embed/yUkZhr5uf08" target="_blank" rel="noreferrer noopener">
          <img
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/Thumbnails/thumbnail-profitfy.me3-min.png"
            alt="Profitfy.me"
          />
          <PlayCircle className="play-video-icon" size={64} />
        </a>

        <a className="video" href="https://www.youtube.com/embed/RjkXsjE_XTw" target="_blank" rel="noreferrer noopener">
          <img
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/Thumbnails/thumbnail-profitfy.me4-min.png"
            alt="Profitfy.me"
          />
          <PlayCircle className="play-video-icon" size={64} />
        </a>

        <a className="video" href="https://www.youtube.com/embed/nTTcCaSyimY" target="_blank" rel="noreferrer noopener">
          <img
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/Thumbnails/thumbnail-profitfy.me5-min.png"
            alt="Profitfy.me"
          />
          <PlayCircle className="play-video-icon" size={64} />
        </a>
      </div>
    </div>
  );
};

export default memo(VideosMentoring);
