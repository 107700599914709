import React, { memo } from 'react';
import {
  Row,
  InputGroup,
  Input as StrapInput,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardHeader,
  CardBody,
  Col,
} from 'reactstrap';

import './styles.scss';

const FixedCost = ({ setIofAliexpress, setCheckout, setGateway, setTax, setShipmentFee }) => {
  return (
    <Col>
      <Card className="shadow fixed-cost ml-2 mr-2">
        <CardHeader>
          <h3 className="mb-0">Custos Fixo</h3>
        </CardHeader>
        <CardBody className="fixed-cost-body">
          <Row className="justify-content-between align-items-center mb-3 px-4">
            <div>
              <i className="fa fa-coins mr-2 text-gray" />
              <span>IOF Aliexpress</span>
            </div>
            <InputGroup className="input-group-alternative input-size">
              <StrapInput
                className="text-right"
                type="select"
                name="iofAliexpress"
                size="sm"
                id="iofAliexpress"
                onChange={e => setIofAliexpress(Number(e.target.value))}
              >
                <option value="0.0038">0,38%</option>
                <option value="0.0638">6,38%</option>
                <option value="0">Nenhum</option>
              </StrapInput>
            </InputGroup>
          </Row>
          <Row className="justify-content-between align-items-center mb-3 px-4">
            <div>
              <i className="fa fa-truck mr-2 text-gray" />
              <span>Frete</span>
            </div>
            <InputGroup className="input-group-alternative input-size" size="sm">
              <StrapInput
                className="text-right"
                placeholder="0,00"
                type="number"
                name="shipmentFee"
                onChange={e => setShipmentFee(Number(e.target.value) || 0.0)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>R$</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Row>
          <Row className="justify-content-between align-items-center mb-3 px-4">
            <div>
              <i className="fa fa-shopping-cart mr-2 text-gray" />
              <span>Checkout</span>
            </div>
            <InputGroup className="input-group-alternative input-size" size="sm">
              <StrapInput
                className="text-right"
                placeholder="2,5"
                type="number"
                name="checkout"
                onChange={e => setCheckout(Number(e.target.value) / 100 || 0.025)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>%</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Row>
          <Row className="justify-content-between align-items-center mb-3 px-4">
            <div>
              <i className="fa fa-credit-card mr-2 text-gray" />
              <span>Gateway</span>
            </div>
            <InputGroup className="input-group-alternative input-size" size="sm">
              <StrapInput
                className="text-right"
                placeholder="5"
                type="number"
                name="gateway"
                onChange={e => setGateway(Number(e.target.value) / 100 || 0.05)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>%</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Row>
          <Row className="justify-content-between align-items-center mb-3 px-4">
            <div>
              <i className="mr-2 text-gray">%</i>
              <span>Imposto</span>
            </div>
            <InputGroup className="input-group-alternative input-size" size="sm">
              <StrapInput
                className="text-right"
                placeholder="0"
                type="number"
                name="tax"
                onChange={e => setTax(Number(e.target.value) / 100 || 0)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>%</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default memo(FixedCost);
