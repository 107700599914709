import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment-timezone';
import { Card, CardHeader, Badge, Table, Modal, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2';
import _ from 'lodash';
import Spinner from '../../Spinner/Spinner';
import { api } from '../../../services/api';
import { useAuth } from '../../../hooks/User';
import { numberBrFormatter } from '../../../utils/utils';
import { parseStatusName, getStatusColor } from '../../../utils/transactions';
import './styles.scss';

const ProfileTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoadingPendingPayment, setIsLoadingPendingPayment] = useState(false);
  const { user, loadUser } = useAuth();

  const loadTransactions = useCallback(async () => {
    try {
      const allTransactions = [];

      setLoading(true);

      const [{ data: paidPlanTransactionData }, { data: freePlanTransactionData }] = await Promise.all([
        api('user-api').get(`api/v1/users/subscriptions/${user?.subscription?.id}`),
        api('user-api').get(`api/v1/users/subscriptions/${user?.subscription?.id}/free-plan/transactions`),
      ]);

      if (freePlanTransactionData.transactions) {
        const freePlanTransactions = freePlanTransactionData.transactions;

        allTransactions.push(...freePlanTransactions);
      }

      if (paidPlanTransactionData.subscription) {
        const { transactions: paidPlanTransactions } = paidPlanTransactionData.subscription;

        allTransactions.push(...paidPlanTransactions);
      }

      const orderedTransactions = _.orderBy(allTransactions, ['createdAt'], ['desc']);

      const slicedTransactions = orderedTransactions.slice(0, 10);

      setTransactions(slicedTransactions);
    } finally {
      setLoading(false);
    }
  }, [user.subscription.id]);

  const handlePendingPayment = React.useCallback(async () => {
    try {
      setIsLoadingPendingPayment(true);

      await api('user-api').post(
        `api/v1/users/subscriptions/${user.subscription.id}/free-plan/charge-pending-transactions`,
      );

      await Swal.fire({
        icon: 'info',
        title: 'A transação está sendo processada!',
        showConfirmButton: false,
        timer: 5000,
      });

      await loadUser();
    } finally {
      setIsLoadingPendingPayment(false);
    }
  }, [loadUser, user.subscription.id]);

  useEffect(() => {
    if (user) {
      loadTransactions();
    }
  }, [loadTransactions, user]);

  return (
    <>
      <Modal isOpen={isLoadingPendingPayment} centered>
        <ModalBody>
          <Spinner loading={isLoadingPendingPayment} />
        </ModalBody>
      </Modal>
      <Card className="shadow mt-4">
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <>
            <CardHeader className="d-flex justify-content-between">
              <h3 className="mb-1">Minhas Transações</h3>
            </CardHeader>

            <Table className="table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Data</th>
                  <th scope="col">Valor</th>
                  <th scope="col">Status</th>
                  <th className="free-plan-payment-label" scope="col">
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction, index) => (
                  <tr key={transaction.createdAt}>
                    <td>
                      <p className="mb-0 font-weight-bold">{moment(transaction.createdAt).format('DD/MM/YYYY')}</p>
                    </td>
                    <td>
                      <p className=" mb-0 font-weight-bold">R$ {numberBrFormatter(transaction.amount, 2)}</p>
                    </td>
                    <td>
                      <p className="mb-0 h2">
                        <Badge color={getStatusColor(transaction.status)}>{parseStatusName(transaction.status)}</Badge>
                      </p>
                    </td>
                    <td>
                      {index === 0 && (transaction.status === 'waiting_payment' || transaction.status === 'refused') ? (
                        <button className="pending-payment-button" type="button" onClick={handlePendingPayment}>
                          Pagar <i className="ml-1 fas fa-credit-card" />
                        </button>
                      ) : (
                        <div />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </Card>
    </>
  );
};

export default ProfileTransactions;
