export const getCardBrandImage = cardBrand =>
  ({
    amex: 'https://profitfy-media.s3-us-west-2.amazonaws.com/amex.svg',
    visa: 'https://profitfy-media.s3-us-west-2.amazonaws.com/visa.svg',
    mastercard: 'https://profitfy-media.s3-us-west-2.amazonaws.com/mastercard.svg',
    elo: 'https://profitfy-media.s3-us-west-2.amazonaws.com/elo.svg',
    hipercard: 'https://profitfy-media.s3-us-west-2.amazonaws.com/hiper.svg',
  }[cardBrand] || 'https://profitfy-media.s3-us-west-2.amazonaws.com/visa.svg');

export const getStatusColor = status =>
  ({
    canceled: 'danger',
    paid: 'primary',
    pending: 'warning',
    trialing: 'info',
    free: 'primary',
  }[status]);

export const parseStatusText = status =>
  ({
    canceled: 'cancelado',
    paid: 'ativo',
    pending: 'pendente',
    trialing: 'em trial',
    free: 'grátis',
  }[status]);
