import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Card, Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import queryString from 'query-string';
import OrdersTableHeader from './TableHeader/TableHeader';
import OrdersTableBody from './TableBody/TableBody';
import OrdersTableFooter from './TableFooter/TableFooter';
import Spinner from '../../../components/Spinner/Spinner';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { shopsApi, makeSearchRequest, api } from '../../../services/api';
import useQuery from '../../../hooks/Query';
import ufoImage from '../../../assets/img/theme/ufo.svg';
import SyncDsers from './SyncDsers/SyncDsers';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';
import { useStoreConfigurations } from '../../../hooks/AliexpressForm';

const Orders = () => {
  const query = useQuery();
  const { user } = useAuth();
  const { store, platform } = useParams();

  const { isLoading, storeConfigurationsData } = useStoreConfigurations({ store, platform });

  const startDateQuery = useMemo(() => query.get('startDate'), [query]);
  const endDateQuery = useMemo(() => query.get('endDate'), [query]);
  const statusQuery = useMemo(() => query.get('status'), [query]);
  const selectedStartDate = useMemo(() => query.get('selectedStartDate'), [query]);
  const refusedAndChargedback = useMemo(() => query.get('refusedAndChargedback'), [query]);

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagesCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [domain, setDomain] = useState('');
  const [orderFilters, setOrderFilters] = useState({
    name: null,
    hasTrackingNumber: false,
    type: null,
    status: statusQuery,
  });
  const [orderSort, setOrderSort] = useState({
    createdAt: 'desc',
    name: '',
    revenue: '',
    cogs: '',
    profit: '',
  });
  const [orderPeriod, setOrderPeriod] = useState({
    startDate: startDateQuery ? moment(startDateQuery) : moment(),
    endDate: endDateQuery ? moment(endDateQuery) : moment(),
  });
  const [updateAliexpressCostToVariant, setUpdateAliexpressCostToVariant] = useState(false);
  const [storeConfiguration, setStoreConfiguration] = useState({ id: null });
  const [isUpdatingCheckbox, setIsUpdatingCheckbox] = useState(false);

  const handleCheckbox = useCallback(
    async event => {
      const { checked } = event.target;

      try {
        setIsUpdatingCheckbox(true);
        setUpdateAliexpressCostToVariant(checked);

        const requestType = storeConfiguration.id ? 'put' : 'post';

        const { data } = await api(shopsApi[platform])[requestType](
          `/api/v1/users/${platform}/stores/${store}/store-configurations/${storeConfiguration?.id || ''}`,
          {
            ...storeConfiguration,
            updateAliexpressCostToVariant: checked,
          },
        );

        setStoreConfiguration(data.storeConfiguration);
        setUpdateAliexpressCostToVariant(checked);

        setIsUpdatingCheckbox(false);
      } catch {
        setIsUpdatingCheckbox(false);

        setUpdateAliexpressCostToVariant(!checked);
      }
    },
    [platform, store, storeConfiguration],
  );

  const searchFetch = useMemo(
    () =>
      makeSearchRequest({
        api: shopsApi[platform],
      }),
    [platform],
  );

  const getOrderFilter = useCallback(status => {
    return {
      name: null,
      hasTrackingNumber: false,
      type: null,
      status,
    };
  }, []);

  const loadOrdersData = useCallback(
    async load => {
      const limit = 20;
      setLoading(Boolean(load));

      try {
        const response = await searchFetch(
          `api/v1/users/${platform}/stores/${store}/orders?page=${
            page + 1
          }&limit=${limit}&startDate=${orderPeriod.startDate.format('YYYY-MM-DD')}&endDate=${orderPeriod.endDate.format(
            'YYYY-MM-DD',
          )}&${queryString.stringify({
            filter: JSON.stringify(orderFilters),
          })}&${queryString.stringify({
            sort: JSON.stringify(orderSort),
          })}`,
        );

        if (response) {
          setDomain(response.domain);
          setOrders(response.data.orders);
          setPageCount(response.data.numberOfPages);
        }
      } finally {
        setLoading(false);
      }
    },

    [page, store, platform, orderPeriod, orderFilters, orderSort, searchFetch],
  );

  const loadRefusedAndChargedbackOrdersData = useCallback(
    async load => {
      const limit = 20;
      setLoading(Boolean(load));

      try {
        const chargedBackRequest = searchFetch(
          `api/v1/users/${platform}/stores/${store}/orders?page=${
            page + 1
          }&limit=${limit}&startDate=${orderPeriod.startDate.format('YYYY-MM-DD')}&endDate=${orderPeriod.endDate.format(
            'YYYY-MM-DD',
          )}&${queryString.stringify({
            filter: JSON.stringify(getOrderFilter('charged_back')),
          })}&${queryString.stringify({
            sort: JSON.stringify(orderSort),
          })}`,
        );

        const refundedRequest = searchFetch(
          `api/v1/users/${platform}/stores/${store}/orders?page=${
            page + 1
          }&limit=${limit}&startDate=${orderPeriod.startDate.format('YYYY-MM-DD')}&endDate=${orderPeriod.endDate.format(
            'YYYY-MM-DD',
          )}&${queryString.stringify({
            filter: JSON.stringify(getOrderFilter('refunded')),
          })}&${queryString.stringify({
            sort: JSON.stringify(orderSort),
          })}`,
        );

        const [chargedBackResponse, refundedResponse] = await Promise.all([chargedBackRequest, refundedRequest]);

        const filteredChargedBackOrders = chargedBackResponse?.data?.orders.filter(
          order =>
            !moment(moment(order.refundedAt).format('MM/DD/YYYY')).isBefore(
              moment(selectedStartDate).format('MM/DD/YYYY'),
            ),
        );

        const filteredRefundedOrders = refundedResponse?.data?.orders.filter(
          order =>
            !moment(moment(order.refundedAt).format('MM/DD/YYYY')).isBefore(
              moment(selectedStartDate).format('MM/DD/YYYY'),
            ),
        );

        if (chargedBackResponse && refundedResponse) {
          setDomain(chargedBackResponse.domain || refundedResponse.domain);
          setOrders([...filteredChargedBackOrders, ...filteredRefundedOrders]);
          setPageCount(
            chargedBackResponse.data.numberOfPages > refundedResponse.data.numberOfPages
              ? chargedBackResponse.data.numberOfPages
              : refundedResponse.data.numberOfPages,
          );
        }
      } finally {
        setLoading(false);
      }
    },

    [page, store, platform, orderPeriod, orderSort, searchFetch, getOrderFilter, selectedStartDate],
  );

  const handleFilter = async orderParams => {
    setPage(0);
    setOrderFilters({ ...orderFilters, ...orderParams });
  };

  const onChangePage = page => {
    setPage(page.selected);
  };

  const onDate = useCallback(({ startDate, endDate }) => {
    if (!startDate || !endDate) return;
    if (!startDate.isValid() || !endDate.isValid()) return;
    setPage(0);
    setOrderPeriod({ startDate, endDate });
  }, []);

  useEffect(() => {
    if (refusedAndChargedback) {
      loadRefusedAndChargedbackOrdersData(true);
    }
  }, [loadRefusedAndChargedbackOrdersData, refusedAndChargedback]);

  useEffect(() => {
    if (!refusedAndChargedback) {
      loadOrdersData(true);
    }
  }, [loadOrdersData, refusedAndChargedback]);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_orders',
        type: 'screen',
      });
    }
  }, [user]);

  useEffect(() => {
    if (storeConfigurationsData?.storeConfiguration) {
      setStoreConfiguration(storeConfigurationsData.storeConfiguration);
      setUpdateAliexpressCostToVariant(storeConfigurationsData.storeConfiguration.updateAliexpressCostToVariant);
    }
  }, [storeConfigurationsData]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Container fluid className="pb-8 pt-0 pt-md-8">
      <Row className="mb-4">
        <Col>
          <DatePicker
            startDate={orderPeriod.startDate}
            endDate={orderPeriod.endDate}
            onDate={onDate}
            showPreDefinedDates
          />
        </Col>
      </Row>
      <Row className="mt-4 mb-2">
        <Col className="d-flex justify-content-end">
          {platform === 'shopify' && (
            <SyncDsers
              checked={updateAliexpressCostToVariant}
              onChange={handleCheckbox}
              disabled={isUpdatingCheckbox}
            />
          )}
        </Col>
      </Row>

      <Row>
        <div className="col">
          <Card className="shadow">
            <OrdersTableHeader handleFilter={handleFilter} />
            {loading ? (
              <Spinner loading={loading} />
            ) : (
              <OrdersTableBody
                orders={orders}
                platform={platform}
                domain={domain}
                setOrderSort={setOrderSort}
                orderSort={orderSort}
                loadOrdersData={loadOrdersData}
              />
            )}
            <OrdersTableFooter pages={pagesCount} onChangePage={onChangePage} page={page} />
          </Card>
        </div>
      </Row>
      {orders.length <= 0 && !loading && (
        <Row className="align-items-center flex-column mt-6">
          <img src={ufoImage} width="300px" alt="no data" />
          <h1 className="mt-4 text-muted text-center">Seus pedidos foram abduzidos</h1>
        </Row>
      )}
    </Container>
  );
};

export default Orders;
