import React from 'react';
import moment from 'moment-timezone';
import { Badge, Button, Card, CardBody, CardHeader, Progress } from 'reactstrap';
import { useAuth } from '../../../../hooks/User';
import Spinner from '../../../Spinner/Spinner';
import { getCardBrandImage, getStatusColor, parseStatusText } from '../../../../utils/subscribeUtils';
import { numberBrFormatter } from '../../../../utils/utils';
import { api } from '../../../../services/api';

const PaidSubscribe = ({ handleOpenTypeForm, toggleCreditCard, togglePlans }) => {
  const { user } = useAuth();

  const [card, setCard] = React.useState({
    lastDigits: '0000',
    brand: null,
  });
  const [plan, setPlan] = React.useState({
    name: 'Pago',
    amount: '0,00',
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [subscription, setSubscription] = React.useState({});

  const loadData = React.useCallback(async () => {
    try {
      setIsLoading(true);

      const [{ data: subscriptionData }, { data: cardData }, { data: planData }] = await Promise.all([
        api('user-api').get(`api/v1/users/subscriptions/${user.subscription.id}`),
        api('user-api').get(`api/v1/users/cards/${user.subscription.cardId}`),
        api('user-api').get(`api/v1/users/plans/${user.subscription.planId}`),
      ]);

      setCard(cardData.card);
      setPlan(planData.plan);
      setSubscription(subscriptionData.subscription);
    } finally {
      setIsLoading(false);
    }
  }, [user.subscription.id, user.subscription.cardId, user.subscription.planId]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Card className="shadow user-plan">
      {isLoading ? (
        <Spinner loading={isLoading} />
      ) : (
        <>
          <CardHeader className="d-flex justify-content-between">
            <h3 className="mb-0">Meu plano</h3>
            <div>
              <Badge color={getStatusColor(subscription.status)}>
                <span className="text-lg text-capitalize">{parseStatusText(subscription.status)}</span>
              </Badge>
            </div>
          </CardHeader>
          <CardBody className="p-0">
            <div className="bg-gradient-light-dark-100 p-3 px-md-5 pt-md-3">
              <div className="d-flex justify-content-between align-items-center">
                <span className="text-uppercase text-muted text-sm ls-1">Plano Atual</span>
                <div>
                  <Button className="btn-link border-0 text-primary" onClick={togglePlans}>
                    <span> Mudar de Plano</span>
                    <i className="fa fa-pen" />
                  </Button>
                </div>
              </div>
              <p className="mb-0 font-weight-bold text-uppercase text-lg">{plan.name}</p>
              <span className="mb-0 font-weight-bold text-uppercase text-lg">
                R$ {numberBrFormatter(plan.amount / 100, 2)}
              </span>
              <span className="text-lg">/mês</span>
              <>
                <div className="mt-5 d-flex align-items-center justify-content-between">
                  <div className="plan-usage">
                    <span className="font-weight-bold text-muted text-sm">Total de consumo do plano</span>
                    <div className="mt-2 d-flex align-items-center justify-content-between">
                      <p className="current-usage">R$ {numberBrFormatter(user?.monthlyRevenue, 2)}</p>
                      <p className="total-usage">R$ {numberBrFormatter(user.subscription.limit, 2)}</p>
                    </div>
                    <Progress className="mt-1" value={(user?.monthlyRevenue * 100) / user?.subscription.limit} />
                  </div>
                </div>

                <div className="mt-5 d-flex align-items-center justify-content-between">
                  <div>
                    <span className="text-muted text-sm">O seu plano expira dia: </span>
                    <br />
                    <span className="font-weight-bold text-blue-marine">
                      {moment(user?.subscription?.currentPeriodEnd).format('DD/MM/YYYY')}
                    </span>
                  </div>
                  <Button className="btn-link text-muted" onClick={handleOpenTypeForm}>
                    <span>Cancelar</span>
                  </Button>
                </div>
              </>
            </div>
            <div className="bg-gradient-light-dark-200 px-5 py-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <img src={getCardBrandImage(card.brand)} alt="card" width="35px" />
                  <p className="text-uppercase font-weight-bold text-blue-marine ls-1 mb-0 ml-3">
                    ***************{card.lastDigits}
                  </p>
                </div>
                <Button className="btn-link border-0 text-blue text-right" onClick={toggleCreditCard}>
                  <span> Alterar</span>
                </Button>
              </div>
            </div>
          </CardBody>
        </>
      )}
    </Card>
  );
};

export default React.memo(PaidSubscribe);
