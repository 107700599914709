import React, { useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import ProfileSubscribe from './Subscribe/Subscribe';
import ProfileUser from './User/User';
import ProfileTransactions from './Transactions/Transactions';
import { useAuth } from '../../hooks/User';

const Profile = ({ fluid = true }) => {
  const { loadUser, user } = useAuth();
  const match = useRouteMatch({
    path: '/stores/user-profile',
    strict: true,
    sensitive: true,
  });

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <Container fluid={fluid} className="pb-8 pt-3 pt-md-8">
      {match && (
        <Link to="/" className="pl-0">
          <Button color="link" className="pl-0">
            <i className="fas fa-arrow-left mr-2" />
            VOLTAR PARA MINHAS LOJAS
          </Button>
        </Link>
      )}
      <Row>
        <Col md="12" lg="6" xl="5">
          <ProfileSubscribe />

          {user?.subscription?.status !== 'trialing' && user?.subscription?.status !== 'appmax_plan_paid' && (
            <ProfileTransactions />
          )}
        </Col>
        <Col className="order-xl-1 pt-md-0 pt-3">{user?.subscription?.status !== 'trialing' && <ProfileUser />}</Col>
      </Row>
    </Container>
  );
};
export default Profile;
