import React, { useCallback } from 'react';
import { Card } from 'reactstrap';
import ShopifyLogo from '../../../assets/img/brand/shopify.svg';
import CartxLogo from '../../../assets/img/brand/cartx.svg';
import Next from '../../../assets/img/icons/common/next.svg';
import './styles.scss';

const StoreCard = ({ storeName, storePlatform, storeId, domain, handleClick }) => {
  const handleStoreClick = useCallback(() => {
    handleClick({ storeId, storePlatform });
  }, [handleClick, storeId, storePlatform]);

  return (
    <Card className="shadow store-card" onClick={handleStoreClick}>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <img className="platform" src={storePlatform === 'shopify' ? ShopifyLogo : CartxLogo} alt="Shopify" />
          <div className="store-content">
            <p className="font-weight-bold platform-name">{storeName}</p>
            <p className="platform-link">{domain}</p>
          </div>
        </div>
        <img className="next-icon" src={Next} alt="" />
      </div>
    </Card>
  );
};

export default StoreCard;
