import React, { memo } from 'react';
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';

import { useAuth } from '../../hooks/User';
import { updateCustomerXTracking } from '../../utils/customerXTracking';

import './Footer.css';

const Footer = ({ transparent }) => {
  const { user } = useAuth();

  const onTutorialsClick = React.useCallback(() => {
    if (user) {
      updateCustomerXTracking({
        email: user.email,
        userId: user.id,
        identifier: 'tutorials_click',
      });
    }
  }, [user]);

  return (
    <>
      <footer className={transparent ? 'footer bg-transparent' : 'footer'}>
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className={`copyright text-center text-xl-left text-muted ${transparent && 'text-white'}`}>
                © 2021
                <a className={`font-weight-bold ml-1 ${transparent && 'text-white'} `} href="https://profitfy.me">
                  Profitfy.me
                </a>
              </div>
            </Col>
            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                <NavItem>
                  <NavLink
                    href="https://docs.profitfy.me/pt/"
                    target="_blank"
                    className={transparent && 'text-white'}
                    onClick={onTutorialsClick}
                  >
                    Tutoriais
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="contato@profitfy.me" target="_blank" className={transparent && 'text-white'}>
                    contato@profitfy.me
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default memo(Footer);
