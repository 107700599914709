import React, { memo, useCallback, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { useFacebookMarketing } from '../../../hooks/FacebookMarketing';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';
import SwitchInput from '../../SwitchInput/SwitchInput';
import { useAuth } from '../../../hooks/User';

import './styles.scss';

const AdAccountRow = ({ adAccount }) => {
  const { handleCheckBox } = useFacebookMarketing();
  const { store, platform } = useParams();
  const location = useLocation();
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [adAccountData, setAdAccountData] = useState(adAccount);

  const isOnboardingPage = useCallback(() => {
    if (location.pathname.includes('onboarding')) {
      return true;
    }

    return false;
  }, [location.pathname]);

  const handleSwitchInput = useCallback(
    async event => {
      event.persist();

      setIsLoading(true);

      try {
        const newAdAccount = await handleCheckBox({ event, store, platform });

        if (newAdAccount.status !== 'SUCCESS') {
          event.target.checked = !event.target.checked;
        } else {
          setAdAccountData({ ...adAccount, ...newAdAccount.adAccount });

          if (isOnboardingPage()) {
            updateCustomerXTracking({
              email: user?.email,
              userId: user?.id,
              identifier: 'onboarding_has_added_ad_account',
            });
          }
        }
      } finally {
        setIsLoading(false);
      }
    },
    [store, platform, handleCheckBox, adAccount, isOnboardingPage, user],
  );

  return (
    <tr key={adAccountData.id} className="ad-account-row-container">
      <td>
        <span className="facebook-form-table-title">{adAccountData.id}</span>
      </td>
      <td>
        <span className="facebook-form-table-title">{adAccountData.name}</span>
      </td>
      <td>
        <span className="facebook-form-table-title">{adAccountData.facebook_credential?.name}</span>
      </td>
      <td className="ad-account-switch">
        <div>
          <SwitchInput
            id={adAccountData.id}
            defaultChecked={adAccountData.active}
            onChange={handleSwitchInput}
            disabled={isLoading}
            isLoading={isLoading}
            status={adAccountData.active}
          />
        </div>
      </td>
    </tr>
  );
};

export default memo(AdAccountRow);
