import React, { memo, useCallback, useState } from 'react';
import { Percent, CaretUp, CaretDown, MonitorPlay, Check } from 'phosphor-react';
import InputRange from 'react-input-range';
import { Button, Tooltip } from 'reactstrap';

import { percentageInfoUtils } from '../../../utils/percentageInfo';
import { numberBrFormatter } from '../../../utils/utils';

import './styles.scss';

const AverageCostStep = ({
  selectedPercentage,
  setSelectedPercentage,
  selectedStep,
  setSelectedStep,
  userStep,
  setUserStep,
}) => {
  const { getPercentageInfo } = percentageInfoUtils;

  const [isSelectAverageCostTooltipOpen, setIsSelectAverageCostTooltipOpen] = useState(false);

  const handleSelectAverageCostTooltipOpen = useCallback(
    () => setIsSelectAverageCostTooltipOpen(!isSelectAverageCostTooltipOpen),
    [isSelectAverageCostTooltipOpen],
  );

  const handleStepOpen = useCallback(() => {
    if (userStep === 'currency') {
      return;
    }

    if (selectedStep !== 'average-cost') {
      setSelectedStep('average-cost');

      return;
    }

    setSelectedStep('');
  }, [selectedStep, setSelectedStep, userStep]);

  const handlePercentage = useCallback(
    percentage => {
      setSelectedPercentage(percentage);
    },
    [setSelectedPercentage],
  );

  const handleConfirmPercentage = useCallback(() => {
    setSelectedStep('iof');
    setUserStep('iof');
  }, [setSelectedStep, setUserStep]);

  const getProductCost = useCallback(() => {
    const parsedPercentage = (selectedPercentage + 1) / 10;

    const parsedProductCost = numberBrFormatter(99.9 * parsedPercentage, 2);

    return parsedProductCost;
  }, [selectedPercentage]);

  return (
    <div
      className={`onboarding-average-cost-step ${selectedStep === 'average-cost' ? 'selected' : undefined} ${
        userStep === 'iof' ? 'completed' : undefined
      }`}
    >
      <div className="header">
        <Percent size={36} />

        <div className="title-and-completed-badge">
          <h2>Defina o preço médio dos custos de produto em %</h2>

          {userStep === 'iof' && (
            <div className="completed-step">
              <span>Conectado</span>
            </div>
          )}
        </div>

        {selectedStep === 'average-cost' ? (
          <CaretUp size={32} onClick={handleStepOpen} />
        ) : (
          <CaretDown size={32} onClick={handleStepOpen} />
        )}
      </div>

      <div className="body">
        {selectedStep === 'average-cost' ? (
          <div className="content">
            <span>
              A % de custo dos seus produtos é baseado no <strong>valor de venda</strong>
            </span>

            <div className="short-video" id="select-average-cost-tutorial-video">
              <MonitorPlay size={18} />
              <span>Precisa de ajuda? Assista um video curto</span>
              <div className="duration-badge">
                <span>0:30</span>
              </div>
            </div>

            <Tooltip
              isOpen={isSelectAverageCostTooltipOpen}
              toggle={handleSelectAverageCostTooltipOpen}
              placement="bottom"
              target="select-average-cost-tutorial-video"
              className="select-average-cost-tutorial-video"
              autohide={false}
            >
              <video
                src="https://profitfy-media.s3-us-west-2.amazonaws.com/videos-tuto/cogs-average-cost-step.mp4"
                controls
                autoPlay
                muted
              >
                <track kind="captions" />
              </video>
            </Tooltip>

            <div className="benefits">
              <div>
                <Check size={16} weight="bold" />
                <span>Definição de custo de produto em massa</span>
              </div>

              <div>
                <Check size={16} weight="bold" />
                <span>Pré configuração de estimativa de custo dos produtos</span>
              </div>

              <div>
                <Check size={16} weight="bold" />
                <span>Economizar tempo</span>
              </div>
            </div>

            <span className="slider-instruction">Arraste o círculo até a % estimada</span>

            <div className="input-range-currency">
              <InputRange
                allowSameValues
                maxValue={9}
                minValues={0}
                value={selectedPercentage}
                formatLabel={() => getPercentageInfo(selectedPercentage)?.description}
                onChange={handlePercentage}
              />
            </div>

            <span className="example">
              Ex. Se você vende um produto por <strong>R$ 99,90</strong> o custo será de{' '}
              <strong>{`R$ ${getProductCost()}`}</strong>
            </span>

            <span className="instruction">
              Não se preocupe, você poderá editar cada custo com mais exatidão posteriormente.{' '}
            </span>

            <Button onClick={handleConfirmPercentage} color="primary">
              Confirmar
            </Button>
          </div>
        ) : (
          <span>O segundo passo é definir o preço médio de seus produtos</span>
        )}
      </div>
    </div>
  );
};

export default memo(AverageCostStep);
