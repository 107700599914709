import useSWR from 'swr';
import { api } from '../services/api';

const fetcher = (apiName, url) => {
  return api(apiName)(url).then(response => {
    return response.data;
  });
};

export const useFetch = ({ url, apiName }) => {
  const { data, error, mutate, revalidate } = useSWR(url, () => fetcher(apiName, url), {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    revalidateOnReconnect: false,
    dedupingInterval: 5000,
  });

  return { data, error, mutate, revalidate };
};
