import React from 'react';
import { Modal, ModalHeader, Card, CardBody } from 'reactstrap';
import Plans from '../../../Plans/Plans';

const ChangePlanModal = ({ isOpenPlans, togglePlans, loading, onSubmitPlan }) => {
  return (
    <Modal isOpen={isOpenPlans} size="lg">
      <ModalHeader toggle={togglePlans}>
        <span className="bold" style={{ fontSize: '14px' }}>
          Escolha um Plano
        </span>
      </ModalHeader>
      <Card className="bg-secondary shadow border-0">
        <CardBody className="p-0 p-md-4">
          <Plans
            loading={loading}
            onSubmitPlan={onSubmitPlan}
            buttonText="Confirmar"
            subTitle="Ao alterar de um plano pago para outro plano pago, será feito um cálculo para saber a
  proporção não usufruída, e assim cobraremos um valor justo. O dia da próxima cobrança será depois de um mês da alteração do plano."
          />
        </CardBody>
      </Card>
    </Modal>
  );
};

export default ChangePlanModal;
