import React, { memo, useCallback } from 'react';
import { CheckCircle, CaretUp, CaretDown, Barcode, CreditCard, Prohibit } from 'phosphor-react';
import { FormGroup, Input, Label, Button } from 'reactstrap';
import { MetroSpinner } from 'react-spinners-kit';

import './styles.scss';

const IofStep = ({
  setSelectedIof,
  selectedIof,
  selectedStep,
  setSelectedStep,
  userStep,
  handleConfirmButton,
  isProcessing,
  isProcessingError,
}) => {
  const handleStepOpen = useCallback(() => {
    if (userStep !== 'iof') {
      return;
    }

    if (selectedStep !== 'iof') {
      setSelectedStep('iof');

      return;
    }

    setSelectedStep('');
  }, [selectedStep, setSelectedStep, userStep]);

  const handleIof = useCallback(iofType => setSelectedIof(iofType), [setSelectedIof]);

  return (
    <div className={`onboarding-iof-step ${selectedStep === 'iof' ? 'selected' : undefined}`}>
      <div className="header">
        <CheckCircle size={36} />

        <div className="title-and-completed-badge">
          <h2>Defina taxa de IOF</h2>
        </div>

        {selectedStep === 'iof' ? (
          <CaretUp size={32} onClick={handleStepOpen} />
        ) : (
          <CaretDown size={32} onClick={handleStepOpen} />
        )}
      </div>

      <div className="body">
        {selectedStep === 'iof' ? (
          <div className="content">
            <span>Caso seu fornecedor seja internacional, escolha o método de pagamento.</span>

            <FormGroup className="iof-options-input">
              <FormGroup>
                <Label className={`radio-input ${selectedIof === 'TICKET' && 'selected'}`}>
                  <Barcode size={30} />
                  <div>
                    <span>Boleto</span>
                    <span>0,38%</span>
                  </div>
                  <Input
                    type="radio"
                    name="iof"
                    value="TICKET"
                    onChange={() => handleIof('TICKET')}
                    defaultChecked={selectedIof === 'TICKET'}
                  />
                </Label>
              </FormGroup>

              <FormGroup>
                <Label className={`radio-input ${selectedIof === 'CARD' && 'selected'}`}>
                  <CreditCard size={30} />
                  <div>
                    <span>Cartão</span>
                    <span>6,38%</span>
                  </div>
                  <Input
                    type="radio"
                    name="iof"
                    value="CARD"
                    onChange={() => handleIof('CARD')}
                    defaultChecked={selectedIof === 'CARD'}
                  />
                </Label>
              </FormGroup>

              <FormGroup>
                <Label className={`radio-input none ${selectedIof === 'NONE' && 'selected'}`}>
                  <div className="none-option">
                    <Prohibit size={30} />
                    <span>Nenhum</span>
                  </div>
                  <Input
                    type="radio"
                    name="iof"
                    value="NONE"
                    onChange={() => handleIof('NONE')}
                    defaultChecked={selectedIof === 'NONE'}
                  />
                </Label>
              </FormGroup>
            </FormGroup>

            {isProcessing && (
              <Button className="processing-button" disabled>
                <span>Processando</span>
                <MetroSpinner size={20} color="#A0A4A8" loading />
              </Button>
            )}

            {!isProcessing && isProcessingError && (
              <div className="processing-error">
                <span>Ocorreu um problema ao processar os dados. Por favor, tente novamente.</span>
                <Button className="process-again-button" onClick={handleConfirmButton} color="primary">
                  Processar novamente
                </Button>
              </div>
            )}

            {!isProcessing && !isProcessingError && (
              <Button className="confirm-processing-button" onClick={handleConfirmButton} color="primary">
                Confirmar
              </Button>
            )}
          </div>
        ) : (
          <span>Etapa de finalização</span>
        )}
      </div>
    </div>
  );
};

export default memo(IofStep);
