import React, { createContext, useContext, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const ToastContext = createContext();

export const Toast = ({ children }) => {
  const showToast = useCallback(({ message, type }) => {
    const backgrounds = {
      error: 'bg-gradient-danger',
      success: 'bg-gradient-primary',
      warn: 'bg-orange',
    };

    toast[type](message, { className: `${backgrounds[type]}` });
  }, []);

  return <ToastContext.Provider value={{ showToast }}>{children}</ToastContext.Provider>;
};

export const useToast = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
};
