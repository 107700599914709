import mercadoPagoLogo from '../assets/img/theme/mercadopago.png';
import cieloLogo from '../assets/img/theme/cielo.png';
import appMaxLogo from '../assets/img/theme/appmax.png';
import cloudFoxLogo from '../assets/img/theme/cloudfox.png';
import pagseguroLogo from '../assets/img/theme/pagseguro.png';
import upnidLogo from '../assets/img/theme/upnid.svg';
import pagarmeLogo from '../assets/img/theme/pagarme.svg';
import doppusLogo from '../assets/img/logos/doppus.svg';

export const gateways = [
  {
    title: 'App Max',
    logo: appMaxLogo,
    id: 'appMax',
    description: 'Integração com o gateway de pagamento Appmax',
    credentialName: 'appMaxCredentialId',
  },
  {
    title: 'Mercado Pago',
    logo: mercadoPagoLogo,
    id: 'mercadoPago',
    description: 'Integração com o gateway de pagamento Mercado Pago',
    credentialName: 'mercadoPagoCredentialId',
  },
  {
    title: 'Upnid',
    logo: upnidLogo,
    id: 'upnid',
    description: 'Integração com o gateway de pagamento Upnid',
    credentialName: 'upnidCredentialId',
  },
  {
    title: 'Doppus',
    logo: doppusLogo,
    id: 'doppus',
    description: 'Integração com o gateway de pagamento Doppus',
    credentialName: 'hubSaleCredentialId',
  },
  {
    title: 'Cielo',
    logo: cieloLogo,
    id: 'cielo',
    description: 'Integração com o gateway de pagamento Cielo',
    credentialName: 'cieloCredentialId',
  },
  {
    title: 'CloudFox',
    logo: cloudFoxLogo,
    id: 'cloudFox',
    description: 'Integração com o gateway de pagamento CloudFox',
    credentialName: 'cloudFoxCredentialId',
  },
  {
    title: 'Pagseguro',
    logo: pagseguroLogo,
    id: 'pagseguro',
    description: 'Integração com o gateway de pagamento Pagseguro',
    credentialName: 'pagSeguroCredentialId',
  },
  {
    title: 'Pagar.me',
    logo: pagarmeLogo,
    id: 'pagarme',
    description: 'Integração com o gateway de pagamento Pagar.me',
    credentialName: 'pagarmeCredentialId',
  },
];
