import React, { memo, useCallback, useState } from 'react';
import { Card, Tooltip } from 'reactstrap';
import { CopySimple, CheckCircle } from 'phosphor-react';

import { numberBrFormatter } from '../../../utils/utils';
import DefaultImage from '../../DefaultImage/DefaultImage';

import './styles.scss';

const CardInfo = ({ product, metrics }) => {
  const [isNameTooltipOpen, setIsNameTooltipOpen] = useState(false);
  const [isCopyToClipboardTooltipOpen, setIsCopyToClipboardTooltipOpen] = useState(false);
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);
  const [copyToClipboardText, setCopyToClipboardText] = useState('Copiado com sucesso!');

  const handleNameTooltip = useCallback(() => setIsNameTooltipOpen(!isNameTooltipOpen), [isNameTooltipOpen]);

  const handleCopyTooltip = useCallback(
    () => setIsCopyToClipboardTooltipOpen(!isCopyToClipboardTooltipOpen),
    [isCopyToClipboardTooltipOpen],
  );

  const handleCopyToClipboard = useCallback(async () => {
    setIsCopiedToClipboard(true);
    setIsCopyToClipboardTooltipOpen(true);

    try {
      await navigator.clipboard.writeText(product.id);

      setCopyToClipboardText('Copiado com sucesso!');
    } catch {
      setCopyToClipboardText('Sem permissão para copiar!');
    }

    setTimeout(() => {
      setIsCopyToClipboardTooltipOpen(false);
      setIsCopiedToClipboard(false);
    }, 3000);
  }, [product]);

  return (
    <>
      <Tooltip isOpen={isNameTooltipOpen} toggle={handleNameTooltip} placement="top" target={`product-${product.id}`}>
        {product.title}
      </Tooltip>

      <Tooltip
        isOpen={isCopyToClipboardTooltipOpen}
        toggle={handleCopyTooltip}
        placement="top"
        target="copy-product-details-id"
        trigger="click"
      >
        {copyToClipboardText}
      </Tooltip>

      <Card className="product-details-card-info">
        <div className="header">
          <span>INFORMAÇÕES DO PRODUTO</span>
        </div>
        <div className="body">
          <div className="product-info">
            {product?.image ? (
              <img src={product.image} alt="Profitfy Detalhes do Produto" />
            ) : (
              <DefaultImage className="default-image" size={72} />
            )}
            <span id={`product-${product.id}`}>{product.title}</span>
            <div>
              <span>ID: {product.id}</span>
              <button type="button" id="copy-product-details-id">
                {isCopiedToClipboard ? (
                  <CheckCircle className="copied" size={16} />
                ) : (
                  <CopySimple size={16} onClick={handleCopyToClipboard} />
                )}
              </button>
            </div>
          </div>

          <div className="details">
            <div className="product-details">
              <div className="info">
                <span>
                  <small>R$</small>
                  {numberBrFormatter(metrics.cpa.amount, 2)}
                </span>
                <span>Custo Por Aquisição</span>
              </div>

              <div className="info">
                <span>{metrics.totalOrdersApproved}</span>
                <span>Quantidade Vendida</span>
              </div>
            </div>

            <div className="product-details">
              <div className="info">
                <span>{metrics.profitMargin.percentage}%</span>
                <span>Margem de Lucro</span>
              </div>

              <div className="info">
                <span>
                  <small>R$</small> {numberBrFormatter(metrics.averageTicket.amount, 2)}
                </span>
                <span>Ticket Médio</span>
              </div>
            </div>

            <div className="product-details">
              <div className="info">
                <span>{metrics.totalOrders.amount}</span>
                <span>Total de Pedidos</span>
              </div>

              <div className="info">
                <span>{metrics.pendingOrders.total}</span>
                <span>Pedidos Pendentes</span>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default memo(CardInfo);
