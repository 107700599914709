import React, { useEffect, useState, memo } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ProfitEstimate from './ProfitEstimate/ProfitEstimate';
import PricingCalculator from './PricingCalculator/PricingCalculator';
import FacebookBreakEven from './FacebookBreakEven/FacebookBreakEven';
import { useAuth } from '../../../../hooks/User';
import { updateCustomerXTracking } from '../../../../utils/customerXTracking';

const Pricing = () => {
  const { user } = useAuth();

  const [productCost, setProductCost] = useState(0);
  const [iofAliexpress, setIofAliexpress] = useState(0.0038);
  const [checkout, setCheckout] = useState(0.025);
  const [gateway, setGateway] = useState(0.05);
  const [tax, setTax] = useState(0.0);
  const [marketing, setMarketing] = useState(0.2);
  const [customMarkup, setCustomMarkup] = useState(1);
  const [shipmentFee, setShipmentFee] = useState(0.0);

  const markup = [1.75, 2.0, 2.25, 2.75, 3.0, customMarkup];
  const finalPrice = [
    (productCost + shipmentFee) * markup[0],
    (productCost + shipmentFee) * markup[1],
    (productCost + shipmentFee) * markup[2],
    (productCost + shipmentFee) * markup[3],
    (productCost + shipmentFee) * markup[4],
    (productCost + shipmentFee) * markup[5],
  ];
  const fixedCost = [
    productCost * iofAliexpress +
      finalPrice[0] * checkout +
      finalPrice[0] * gateway +
      finalPrice[0] * tax +
      productCost +
      shipmentFee,
    productCost * iofAliexpress +
      finalPrice[1] * checkout +
      finalPrice[1] * gateway +
      finalPrice[1] * tax +
      productCost +
      shipmentFee,
    productCost * iofAliexpress +
      finalPrice[2] * checkout +
      finalPrice[2] * gateway +
      finalPrice[2] * tax +
      productCost +
      shipmentFee,
    productCost * iofAliexpress +
      finalPrice[3] * checkout +
      finalPrice[3] * gateway +
      finalPrice[3] * tax +
      productCost +
      shipmentFee,
    productCost * iofAliexpress +
      finalPrice[4] * checkout +
      finalPrice[4] * gateway +
      finalPrice[4] * tax +
      productCost +
      shipmentFee,
    productCost * iofAliexpress +
      finalPrice[5] * checkout +
      finalPrice[5] * gateway +
      finalPrice[5] * tax +
      productCost +
      shipmentFee,
  ];
  const marketingCost = [
    finalPrice[0] * marketing,
    finalPrice[1] * marketing,
    finalPrice[2] * marketing,
    finalPrice[3] * marketing,
    finalPrice[4] * marketing,
    finalPrice[5] * marketing,
  ];
  const profit = [
    finalPrice[0] - fixedCost[0] - marketingCost[0],
    finalPrice[1] - fixedCost[1] - marketingCost[1],
    finalPrice[2] - fixedCost[2] - marketingCost[2],
    finalPrice[3] - fixedCost[3] - marketingCost[3],
    finalPrice[4] - fixedCost[4] - marketingCost[4],
    finalPrice[5] - fixedCost[5] - marketingCost[5],
  ];
  const maxCpa = [
    marketingCost[0] + profit[0],
    marketingCost[1] + profit[1],
    marketingCost[2] + profit[2],
    marketingCost[3] + profit[3],
    marketingCost[4] + profit[4],
    marketingCost[5] + profit[5],
  ];
  const profitMargin = [
    (profit[0] * 100) / finalPrice[0],
    (profit[1] * 100) / finalPrice[1],
    (profit[2] * 100) / finalPrice[2],
    (profit[3] * 100) / finalPrice[3],
    (profit[4] * 100) / finalPrice[4],
    (profit[5] * 100) / finalPrice[5],
  ];

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_pricing_tool',
        type: 'screen',
      });
    }
  }, [user]);

  return (
    <Container fluid className="pb-8">
      <Row>
        <Col className="pt-5 pt-md-8">
          <PricingCalculator
            setProductCost={setProductCost}
            setIofAliexpress={setIofAliexpress}
            setCheckout={setCheckout}
            setGateway={setGateway}
            setTax={setTax}
            setMarketing={setMarketing}
            setCustomMarkup={setCustomMarkup}
            setShipmentFee={setShipmentFee}
            finalPrice={finalPrice}
          />
        </Col>
        <Col className="pt-5 pt-md-8" md="8">
          <ProfitEstimate
            markup={markup}
            finalPrice={finalPrice}
            fixedCost={fixedCost}
            marketingCost={marketingCost}
            profit={profit}
            maxCpa={maxCpa}
            profitMargin={profitMargin}
          />
          <FacebookBreakEven markup={markup} finalPrice={finalPrice} maxCpa={maxCpa} />
        </Col>
      </Row>
    </Container>
  );
};

export default memo(Pricing);
