import moment from 'moment-timezone';

const currencyFormatter = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d)(\d{2})$/, '$1,$2')
    .replace(/(?=(\d{3})+(\D))\B/g, '.');
};

const numberBrFormatter = (number, minimumFractionDigits = 1) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits,
  });

  return `${formatter.format(number)}`;
};

const calculateProductMargin = (cogs, productSellPrice) => {
  const result = (cogs * 100) / productSellPrice;
  return Number(Math.round(Math.abs(result - 100)).toFixed(0));
};

const parseLineChartData = data => {
  if (!data) return {};

  const profit = data[0]?.map(el => ({ t: moment(el.t), y: el.y.toFixed(2) }));
  // const marketing = data[1]?.map(el => ({ t: new Date(el.t), y: el.y }));
  const cogs = data[1]?.map(el => ({ t: moment(el.t), y: el.y.toFixed(2) }));
  const revenue = data[2]?.map(el => ({ t: moment(el.t), y: el.y.toFixed(2) }));

  return {
    profit,
    //  marketing,
    cogs,
    revenue,
  };
};

const masks = {
  cpf(value = '') {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  },
  cnpj(value = '') {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  },
  cep(value = '') {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1');
  },
  currency(value = '') {
    return value
      .replace(/\D/g, '')
      .replace(/(\d)(\d{2})$/, '$1,$2')
      .replace(/(?=(\d{3})+(\D))\B/g, '.');
  },
};

const currencyToNumber = value => {
  if (value.length > 2) {
    const parsedValue = value.replace(',', ' ').replaceAll('.', '').replace(' ', '.');

    return parsedValue;
  }

  return value;
};

export { numberBrFormatter, calculateProductMargin, parseLineChartData, masks, currencyToNumber, currencyFormatter };
