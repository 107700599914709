import React, { useState, useCallback } from 'react';
import { Alert } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { api, shopsApi } from '../../../services/api';
import '../styles.scss';

const OrderWithNoProduct = ({ id }) => {
  const [visible, setVisible] = useState(true);
  const { store, platform } = useParams();

  const handleOnDismiss = useCallback(async () => {
    try {
      await api(shopsApi[platform]).patch(`/api/v1/users/${platform}/stores/${store}/store-warnings/${id}`, {
        ordersWithNoProduct: false,
      });
    } finally {
      setVisible(!visible);
    }
  }, [id, platform, store, visible]);

  return (
    <Alert
      color="white"
      className="d-flex align-items-center warn-alert warn-style"
      isOpen={visible}
      toggle={handleOnDismiss}
    >
      <span>
        Existe um ou mais pedidos com produto(s) deletado(s).{' '}
        <b>
          Ajuste isso, para podermos processar normalmente seus pedidos! Caso contrário haverá inconsistência nos dados.{' '}
        </b>
        Verifique na Shopify os seus últimos pedidos e veja se o produto vendido está deletado.
      </span>
    </Alert>
  );
};

export default OrderWithNoProduct;
