import { useParams } from 'react-router-dom';
import queryString from 'query-string';

import { useFetch } from './Fetch';
import { shopsApi } from '../services/api';

export const useShopAnalytics = ({ startDate, endDate }) => {
  const { platform, store } = useParams();

  const { data, error, mutate } = useFetch({
    url: `/api/v1/users/${platform}/stores/${store}/profit?startDate=${startDate.format(
      'YYYY-MM-DD',
    )}&endDate=${endDate.format('YYYY-MM-DD')}`,
    apiName: shopsApi[platform],
  });

  return {
    mutate,
    storeData: data,
    isLoadingShopAnalytics: !error && !data,
    isError: error,
  };
};

export const useStoreConfiguration = () => {
  const { platform, store } = useParams();

  const { data, error, mutate } = useFetch({
    url: `/api/v1/users/${platform}/stores/${store}/store-configurations`,
    apiName: shopsApi[platform],
  });

  return {
    mutate,
    storeConfigurationData: data,
    isLoadingStoreConfiguration: !error && !data,
    isError: error,
  };
};

export const useTopProduct = ({ startDate, endDate }) => {
  const filter = { field: 'revenue', sort: 'DESC', title: '' };
  const { platform, store } = useParams();

  const { data, error, mutate } = useFetch({
    url: `/api/v1/users/${platform}/stores/${store}/products/orders?${queryString.stringify({
      filter: JSON.stringify(filter),
    })}&startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}&page=1&limit=10`,
    apiName: shopsApi[platform],
  });

  return {
    mutate,
    topProductsData: data?.topProducts,
    isLoadingTopProducts: !error && !data,
    isError: error,
  };
};

export const usePaymentTransaction = ({ user }) => {
  const { data, error, mutate } = useFetch({
    url: `api/v1/users/subscriptions/${user?.subscription?.id}/free-plan/transactions`,
    apiName: 'user-api',
  });

  return {
    mutate,
    paymentTransactionData: data?.transactions,
    isLoadingPaymentTransactions: !error && !data,
    isError: error,
  };
};
