import React, { useState, useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { percentageInfoUtils } from '../../../utils/percentageInfo';
import { api, shopsApi } from '../../../services/api';
import ProductCostOnboarding from '../../../components/OnboadingCurrency/ProductCostOnboarding/ProductCostOnboarding';
import ProductCostTable from '../../../components/OnboadingCurrency/ProductCostTable/ProductCostTable';
import ProductVariantTable from '../../../components/OnboadingCurrency/ProductVariantTable/ProductVariantTable';
import Footer from '../../../components/OnboadingCurrency/Footer/Footer';
import usePersistedState from '../../../hooks/PersistedState';
import { useAuth } from '../../../hooks/User';
import { updateCustomerXTracking } from '../../../utils/customerXTracking';

import './styles.scss';

const Currency = () => {
  const { user } = useAuth();
  const { platform, store } = useParams();
  const history = useHistory();
  const [hasProcessedOnboardingCurrency, setHasProcessedOnboardingCurrency] = usePersistedState(
    'hasProcessedOnboardingCurrency',
    {},
  );
  const [hasEditedOnboardingCurrency, setHasEditedOnboardingCurrency] = usePersistedState(
    'hasEditedOnboardingCurrency',
    false,
  );

  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showProductCostTable, setShowProductCostTable] = useState(false);
  const [isProcessingError, setIsProcessingError] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [selectedPercentage, setSelectedPercentage] = useState(4);
  const [selectedIof, setSelectedIof] = useState('TICKET');

  const handleHasEditedOnboardingCurrency = useCallback(
    () => setHasEditedOnboardingCurrency(true),
    [setHasEditedOnboardingCurrency],
  );

  const getQueryStatus = useCallback(
    notification => {
      const interval = setInterval(async () => {
        try {
          const { data } = await api(shopsApi[platform]).get(
            `/api/v1/users/${platform}/stores/${store}/variants/calculate/cost/notification/${notification.id}`,
          );

          notification = data.variantCalculateNotification;

          if (notification.status === 'FAILED') {
            clearInterval(interval);
            setIsProcessing(false);
            setIsProcessingError(true);
          }

          if (notification.status === 'SUCCESS') {
            clearInterval(interval);
            setIsProcessing(false);
            setShowProductCostTable(true);
            setHasProcessedOnboardingCurrency(prevState => ({ ...prevState, [store]: true }));
          }
        } catch {
          setIsProcessingError(true);
        }
      }, 10000);
    },
    [store, platform, setHasProcessedOnboardingCurrency],
  );

  const handleConfirmButton = useCallback(async () => {
    const body = {
      currency: selectedCurrency,
      percentageOfCost: percentageInfoUtils.getPercentageInfo(selectedPercentage).value,
    };

    setIsProcessing(true);

    try {
      await api(shopsApi[platform]).patch(`api/v1/users/${platform}/stores/${store}/products/buy-method`, {
        buyMethod: selectedIof,
      });

      const { data } = await api(shopsApi[platform]).post(
        `/api/v1/users/${platform}/stores/${store}/variants/calculate/cost`,
        body,
      );

      if (data?.variantCalculateNotification.status === 'PENDING') {
        setIsProcessing(true);
        getQueryStatus(data.variantCalculateNotification);
      }
    } catch {
      setIsProcessingError(true);
      setIsProcessing(false);
    }
  }, [getQueryStatus, platform, store, selectedCurrency, selectedIof, selectedPercentage]);

  const handlePreviousPage = useCallback(() => {
    history.push('taxes');
  }, [history]);

  const handleNextPage = useCallback(() => {
    history.push('phone');
  }, [history]);

  useEffect(() => {
    if (hasProcessedOnboardingCurrency[store]) {
      setShowProductCostTable(true);
    }
  }, [hasProcessedOnboardingCurrency, store]);

  useEffect(() => {
    if (user) {
      updateCustomerXTracking({
        userId: user.id,
        email: user.email,
        identifier: 'pageview_onboarding_cogs',
        type: 'screen',
      });
    }
  }, [user]);

  return (
    <div className="main-onboarding-currency-container">
      <div className={`content-container ${showProductCostTable ? 'product-cost-table' : undefined}`}>
        <div className="content-header">
          <img
            className="profitfy-logo"
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/logo_v2.svg"
            alt="Profitfy.me"
          />

          <div className="step-container">
            <div className="bar-container">
              <div className="bar-progress" />
            </div>

            <span>5/6</span>
          </div>
        </div>

        <div>
          {showProductCostTable ? (
            <div>
              {selectedProduct ? (
                <ProductVariantTable
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  handleHasEditedOnboardingCurrency={handleHasEditedOnboardingCurrency}
                />
              ) : (
                <ProductCostTable setSelectedProduct={setSelectedProduct} />
              )}
            </div>
          ) : (
            <ProductCostOnboarding
              setSelectedCurrency={setSelectedCurrency}
              setSelectedPercentage={setSelectedPercentage}
              setSelectedIof={setSelectedIof}
              selectedCurrency={selectedCurrency}
              selectedPercentage={selectedPercentage}
              selectedIof={selectedIof}
              handleConfirmButton={handleConfirmButton}
              isProcessingError={isProcessingError}
              isProcessing={isProcessing}
            />
          )}

          <Footer
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            isProcessing={isProcessing}
            hasEditedOnboardingCurrency={hasEditedOnboardingCurrency}
          />
        </div>
      </div>
    </div>
  );
};

export default Currency;
