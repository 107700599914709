import React, { useState } from 'react';
import { Container, Card, Row, Col, Button, CardBody } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import InputRange from 'react-input-range';
import Features from '../../components/Plans/Features/Features';
import logo from '../../assets/img/brand/logo-white.svg';
import BubblesBackground from '../../components/BubblesBackground/BubblesBackground';
import './styles.scss';

const getPlanInfo = plan =>
  ({
    0: { name: 'Free', description: 'On Demand', value: 'Grátis' },
    1: { name: 'Lite', description: 'Até R$25mil / mês', value: '47,90' },
    2: { name: 'Starter', description: 'Até R$50mil / mês', value: '97,90' },
    3: { name: 'Essentials', description: 'Até R$100mil / mês', value: '147,90' },
    4: { name: 'Premium', description: 'Até R$300mil / mês', value: '297,90' },
    5: { name: 'Pro', description: 'Até R$500mil / mês', value: '497,90' },
    6: { name: 'Pro II', description: 'Até R$900mil / mês', value: '647,90' },
    7: { name: 'Pro III', description: 'Até R$2mi / mês', value: '747,90' },
    8: { name: 'Pro IV', description: 'Até R$4mi / mês', value: '997,90' },
    9: { name: 'Custom', description: '+ de R$500mil / mês', value: '?' },
  }[plan]);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Prices = () => {
  const query = useQuery();
  const [selectedPlan, setSelectedPlan] = useState(0);
  const ref = query.get('ref');

  return (
    <BubblesBackground>
      <div className="main-content subscribe">
        <div className="header py-5 py-lg-4 vh-90">
          <Container className="subscribe-width">
            <Row className="justify-content-center">
              <Col>
                <div className="img-fluid text-center">
                  <img alt="profitfy" className="img-fluid" width="350px" src={logo} />
                </div>
              </Col>
            </Row>
            <Row className="mt-6">
              <Col>
                <Card className="card-shadow">
                  <CardBody className="pb-5">
                    <Container className="plans px-md-6">
                      <Row className="justify-content-center align-items-center">
                        <div className="d-flex flex-column">
                          <h1 className="title font-weight-normal text-center pt-2 px-md-3">
                            Os planos baseiam-se em seu faturamento! <br />
                            Assim seu bolso fica feliz da vida, né?
                          </h1>
                          <h3 className="text-primary mt-3 mb-3 display-4 text-center">
                            Assine hoje e tenha 14 dias grátis!
                          </h3>
                        </div>
                        <Card className="shadow px-md-5 px-3 pt-4">
                          <div>
                            <div className="plans-prices">
                              <div className="plans-slider">
                                <h2>Qual seu faturamento mensal?</h2>
                                <span>{getPlanInfo(selectedPlan).description}</span>
                                <InputRange
                                  maxValue={8}
                                  allowSameValues
                                  minValue={0}
                                  value={selectedPlan}
                                  onChange={setSelectedPlan}
                                />
                              </div>
                              <div className="vertical-rule" />
                              <div className="plan-details">
                                {selectedPlan === 0 ? (
                                  <div className="free-plan-description">
                                    <span className="title">Como funciona nosso plano on demand?</span>
                                    <span>
                                      Apenas <strong>R$4,99</strong> a cada <strong>R$1.000</strong> em{' '}
                                      <strong>pedidos pagos</strong> (faturamento líquido)
                                    </span>
                                    <span className="detail">(cobrado por semana)</span>
                                  </div>
                                ) : (
                                  <>
                                    {selectedPlan === 9 ? (
                                      <div className="custom-price">
                                        <span>
                                          Entre em contato com suporte pelo email:
                                          <br /> contato@profitfy.me
                                          <br /> ou
                                          <br /> pelo Whatsapp:
                                          <br /> (41) 98879-5650
                                        </span>
                                      </div>
                                    ) : (
                                      <>
                                        <span className="description-title">Como funciona nossos planos pagos?</span>
                                        <span>Você paga apenas pelo faturamento líquido</span>
                                        <span className="description-price">R$ {getPlanInfo(selectedPlan).value}</span>
                                        <span>
                                          Você pode fazer upgrade a qualquer
                                          <br /> momento após ultrapassar o limite do plano
                                        </span>
                                        <span className="description-detail">(cobrado por mês)</span>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>

                          <Features selectedPlan={selectedPlan} />
                          <hr className="mt-3 mb-4" />
                          <Link to={`/auth/register?ref=${ref}`}>
                            <Button color="primary" className="mb-4 shadow" block>
                              <p className="text-uppercase mb-0 font-weight-bold text-lg">Testar por 14 dias Grátis</p>
                            </Button>
                          </Link>
                        </Card>
                      </Row>
                    </Container>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </BubblesBackground>
  );
};

export default Prices;
