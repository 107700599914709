import React, { memo } from 'react';
import { Button } from 'reactstrap';
import { ArrowLeft, ArrowRight } from 'phosphor-react';

import './styles.scss';

const Footer = ({ handlePreviousPage, handleNextPage, isProcessing, hasEditedOnboardingCurrency }) => {
  return (
    <div className="onboarding-currency-footer">
      <Button onClick={handlePreviousPage}>
        <ArrowLeft size={20} />
        <span>Voltar</span>
      </Button>

      <Button onClick={handleNextPage} disabled={isProcessing || !hasEditedOnboardingCurrency} outline color="primary">
        <span>Próximo Passo</span>
        <ArrowRight size={20} />
      </Button>
    </div>
  );
};

export default memo(Footer);
