import React, { memo, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { Info } from 'phosphor-react';
import { useHistory, useParams } from 'react-router-dom';

import './styles.scss';

const UnsavedModal = ({ isOpen, toggle, page = 'dashboard', setSelectedProduct }) => {
  const history = useHistory();
  const { store, platform } = useParams();

  const handleStayInPage = useCallback(() => {
    toggle();
  }, [toggle]);

  const handleExitPage = useCallback(() => {
    if (page === 'dashboard') {
      history.push(`/${platform}/${store}/dashboard/config/products-costs`);
      toggle();

      return;
    }

    setSelectedProduct(null);
    toggle();
  }, [history, platform, store, toggle, page, setSelectedProduct]);

  return (
    <Modal className="unsaved-product-info-modal" isOpen={isOpen}>
      <ModalHeader className="unsaved-modal-header">
        <Info size={32} />
        <span>Aviso</span>
      </ModalHeader>

      <ModalBody className="unsaved-modal-body">
        <span>Deseja sair da página sem salvar suas alterações?</span>
        <span>Ao clicar em continuar, as suas alterações serão perdidas</span>

        <div>
          <button type="button" className="stay-in-page" onClick={handleStayInPage}>
            Continuar na página
          </button>

          <Button className="exit-page" onClick={handleExitPage} color="primary">
            Sair da página
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default memo(UnsavedModal);
