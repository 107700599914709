import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button } from 'reactstrap';

import './styles.scss';

const Tutorials = () => {
  return (
    <Card className="main-home-tutorials-container">
      <h3>TUTORIAIS DASHBOARD</h3>
      <p>Logo abaixo você vai encontrar alguns tutoriais que te ajudarão a extrair o máximo da Profitfy.me.</p>

      <div className="tutorials-cards-container">
        <div className="shop-and-products-analytics-container">
          <div className="tutorial-card shop-analytics-card">
            <div>
              <h4>ANÁLISE DE LOJA</h4>
              <p>Aprenda analisar o desempenho de sua loja de forma smart.</p>
            </div>
            <Button color="primary" disabled>
              Em breve
            </Button>
            <img
              src="https://profitfy-media.s3-us-west-2.amazonaws.com/Home/home-tutorials-shop-analytics.svg"
              alt="Profitfy.me Tutorial Análise de Loja"
            />
          </div>
          <div className="tutorial-card products-analytics-card">
            <div>
              <h4>ANÁLISE DE PRODUTOS</h4>
              <p>Aprenda a fazer análises estratégicas de seus produtos individualmente</p>
            </div>
            <Button color="primary" disabled>
              Em breve
            </Button>
            <img
              src="https://profitfy-media.s3-us-west-2.amazonaws.com/Home/home-tutorials-products-analytics.svg"
              alt="Profitfy.me Tutorial Análise de Produtos"
            />
          </div>
        </div>
        <div className="orders-and-billets-analytics-container">
          <div className="tutorial-card orders-analytics-card">
            <div>
              <h4>CUSTO DOS PRODUTOS</h4>
              <p>Aprenda a editar algumas informações de seus produtos, como custos e kits.</p>
            </div>
            <a
              href="https://docs.profitfy.me/pt/article/como-alterar-custo-dos-produtos-o0ffh6/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color="primary">Acesse</Button>
            </a>
            <img
              src="https://profitfy-media.s3-us-west-2.amazonaws.com/Home/home-tutorials-orders-analytics.svg"
              alt="Profitfy.me Tutorial Análise de Pedidos"
            />
          </div>
          <div className="tutorial-card billets-analytics-card">
            <div>
              <h4>ALIEXPRESS E DSERS</h4>
              <p>Aprenda a sincronizar os custos dos seus produtos sob a cotação do dólar.</p>
            </div>
            <a
              href="https://docs.profitfy.me/pt/article/sobre-o-funcionamento-da-sincronizacao-com-o-aliexpress-e-dsers-pktcoy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color="primary">Acesse</Button>
            </a>
            <img
              src="https://profitfy-media.s3-us-west-2.amazonaws.com/Home/home-tutorials-billet-analytics.svg"
              alt="Profitfy.me Tutorial Análise de Boletos"
            />
          </div>
        </div>
      </div>

      <div className="others-tutorials-container">
        <Link to="videos">
          <Button color="primary" outline>
            Acessar nossas mentorias
          </Button>
        </Link>
        <a href="https://docs.profitfy.me/pt/" target="_blank" rel="noopener noreferrer">
          <Button color="primary" outline>
            Acessar outros tutoriais
          </Button>
        </a>
      </div>
    </Card>
  );
};

export default memo(Tutorials);
