import React, { createContext, useContext, useCallback, useMemo } from 'react';
import usePersistedState from './PersistedState';

const PlanContext = createContext();

export const Plans = ({ children }) => {
  const [selectedPlan, setSelectedPlan] = usePersistedState('selectedPlan', '');

  const setPlan = useCallback(plan => setSelectedPlan(plan), [setSelectedPlan]);

  const plan = useMemo(() => selectedPlan, [selectedPlan]);

  return <PlanContext.Provider value={{ plan, setPlan }}>{children}</PlanContext.Provider>;
};

export const usePlans = () => {
  const context = useContext(PlanContext);

  if (!context) {
    throw new Error('usePlans must be used within a PlanProvider');
  }

  return context;
};
