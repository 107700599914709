const videos = [
  {
    title: 'Quebrando paradigmas e fazendo o que ninguém faz!',
    url: 'YBHle6b9y_I',
    author: 'Alexandre Gauthiê',
    thumbnail: 'https://profitfy-media.s3-us-west-2.amazonaws.com/videos-mentoring/10-12-2020.png',
  },
  {
    title: 'Aprenda a analisar suas métricas do Facebook a nível Chuck Norris!',
    url: 'AeonnziYEoA',
    author: 'Alexandre Gauthiê',
    thumbnail: 'https://profitfy-media.s3-us-west-2.amazonaws.com/videos-mentoring/09-12-2020.png',
  },
  {
    title: 'Esse foi o fluxo que me tirou d zero o 100k/mês!',
    url: 'vWK25NY0Zgk',
    author: 'Alexandre Gauthiê',
    thumbnail: 'https://profitfy-media.s3-us-west-2.amazonaws.com/videos-mentoring/21-11-2020.png',
  },
  {
    title: 'Aprenda a transformar seu negócio em uma marca irresistível e lucrativa!',
    url: 'loLFkSt0eQ0',
    author: 'Caio Rodrigo',
    thumbnail: 'https://profitfy-media.s3-us-west-2.amazonaws.com/videos-mentoring/22-10-2020.png',
  },
  {
    title: 'O Que Você Precisa Saber Pra Vender No Taboola',
    url: 'Mx_n__f6Rto',
    author: 'Ian Dalla',
    thumbnail: 'https://profitfy-media.s3-us-west-2.amazonaws.com/videos-mentoring/08-10-2020.png',
  },
  {
    title: 'Como destravar sua loja e vender todos os dias com o modelo de dropshopping!',
    url: 'pgY-sWaw0ag',
    author: 'Vilson Magner',
    thumbnail: 'https://profitfy-media.s3-us-west-2.amazonaws.com/videos-mentoring/24-09-2020.png',
  },
  {
    title: 'Aprenda fabricar milhas e transformar seu cartão em uma máquina de riqueza!',
    url: 'IfgynbChugQ',
    author: 'Anderson Mesquita',
    thumbnail: 'https://profitfy-media.s3-us-west-2.amazonaws.com/videos-mentoring/04-09-2020.png',
  },
  {
    title: 'Esqueça o produto. O que vende é a oferta!',
    url: 'e5SVvLTq__A',
    author: 'Rafael Lima',
    thumbnail: 'https://profitfy-media.s3-us-west-2.amazonaws.com/videos-mentoring/20-08-2020.png',
  },
  {
    title: 'Aumente sua margem de lucro com menor risco em sua operação!',
    url: '6v7_O9Y7xR0',
    author: 'Caio Pertile',
    thumbnail: 'https://profitfy-media.s3-us-west-2.amazonaws.com/videos-mentoring/06-08-2020.png?versionId=null',
  },
  {
    title: 'Aprenda a escalar suas vendas em meio a crise com a Profitfy.me!',
    url: '98gb62bAu2o',
    author: 'Christian Marcelo',
    thumbnail: 'https://profitfy-media.s3-us-west-2.amazonaws.com/videos-mentoring/23-07-2020.png',
  },
];

export { videos };
