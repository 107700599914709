import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import * as serviceWorker from '../../serviceWorker';

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onSWUpdate = registration => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    // eslint-disable-next-line no-unused-expressions
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });

    setShowReload(false);
    window.location.reload(true);
  };

  const displayMessage = () => {
    Swal.fire({
      icon: 'info',
      title: 'Atualização Disponível',
      text: 'Novo conteúdo disponível, é necessário atualizar para funcionar tudo corretamente.',
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: true,
      confirmButtonText: 'Atualizar',
      backdrop: false,
    }).then(result => {
      if (result.value) reloadPage();
    });
  };

  return <div className="display-none">{showReload && displayMessage()}</div>;
};

export default ServiceWorkerWrapper;
