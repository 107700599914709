import React, { memo } from 'react';

import processingCogsAnimation from '../../../assets/img/theme/processing-cogs-animation.svg';

import './styles.scss';

const ProcessingData = () => {
  return (
    <div className="processing-cost-of-products-container">
      <img src={processingCogsAnimation} alt="Profitfy.me processando custo dos produtos" />
      <h1>Estamos preparando seus dados!</h1>
    </div>
  );
};

export default memo(ProcessingData);
